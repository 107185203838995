import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Grid } from 'components/common/Cards/styled-components'
import Pagination from 'components/common/Pagination'
import { DefaultText } from 'components/common/TextEditor/styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import { Wrapper } from './styled-components'
import IconButton from '@mui/material/IconButton'
import SyncIcon from '@mui/icons-material/Sync'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { Input } from 'components/common/TextInput'
import { PersonalInformationHeader } from 'pages/profile/AdminProfile/styled-components'
import useGetTestAssessmentsByUser from 'hooks/tests/TestLayout/useGetTestAssessmentsByUser'
import TestItem from 'pages/tests/testItem/TestItem'
import { useNavigate } from 'react-router-dom'
import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'

export const UserTestAssessments: React.FC<{
  user: IPersonalInformation
}> = ({ user }) => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(12)
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()

  const { tests, refetch } = useGetTestAssessmentsByUser(
    currentPage,
    perPage,
    '',
    user.id,
  )

  const [isTestsVisible, setTestsVisible] = useState(true)

  const handlePaginationClick = (
    _: ChangeEvent<unknown> | null,
    value: number,
  ): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const resetFilter = (): void => {
    setSearchText('')
    refetch({
      currentPage: 1,
      perPage,
      searchValue: '',
    })
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    setCurrentPage(1)
    refetch({
      currentPage: 1,
      perPage,
      searchValue: searchText,
    })
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value)
    if (e.target.value === '') {
      refetch({
        currentPage: 1,
        perPage,
        searchValue: '',
      })
    }
  }

  return (
    <div>
      <PersonalInformationHeader
        onClick={(): void => setTestsVisible((x) => !x)}
      >
        {!isTestsVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {t('general.tests')}
      </PersonalInformationHeader>

      {isTestsVisible && (
        <>
          <Wrapper>
            <ToolbarItem>
              <IconButton disabled={!searchText} onClick={resetFilter}>
                <SyncIcon fontSize="small" />
              </IconButton>
            </ToolbarItem>
            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={onSearchChange}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </Wrapper>
          <Grid
            className="card"
            style={{ position: 'relative', marginTop: ' 17px' }}
            cols={4}
          >
            {tests ? (
              tests.data.map((test, index: number) => (
                <TestItem
                  key={index}
                  item={test}
                  isFromUser={true}
                  handleClick={({ id: testAssessmentId, companyId }): void =>
                    navigate(`/`, {
                      state: { testAssessmentId, user, companyId },
                    })
                  }
                />
              ))
            ) : (
              <DefaultText style={{ width: '50vw' }}>
                {t('default_texts.courses')}
              </DefaultText>
            )}
          </Grid>
        </>
      )}
      {tests && tests.totalPages > 1 && (
        <div
          style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
        >
          <Pagination
            currentPage={tests.currentPage || 1}
            totalPages={tests.totalPages || 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )}
    </div>
  )
}
