// src/services/books/deleteBookService.ts

import { useMutation } from '@apollo/client'
import { DELETE_BOOKS, GET_BOOKS_BY_COMPANY_ID } from 'gql/books/books.query'
import {
  IFetchBooksByCompanyIdProps,
  IDeleteBookResponse,
} from './bookServices.interface'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'

export const useDeleteBookService = ({
  companyId,
}: IFetchBooksByCompanyIdProps): IDeleteBookResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [deleteBook, { data, error, loading }] = useMutation(DELETE_BOOKS, {
    refetchQueries: [
      {
        query: GET_BOOKS_BY_COMPANY_ID,
        variables: {
          companyId,
          searchValue: '',
          currentPage: 1,
          perPage: 12,
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleDeleteBook = async (bookId: string): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      const { errors } = await deleteBook({ variables: { ids: [bookId] } })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('books_layout.book_deleted')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }

  return {
    deleteBook: (bookId: string) => handleDeleteBook(bookId),
    books: (data && data.deleteBook) || [],
    loading,
    error: errorMessage,
  }
}
