import React, { memo, ReactElement } from 'react'

import NavigationBar from 'components/ExpansionMenu/NavigationBar'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import {
  StyledDrawer,
  StyledLogo,
  StyledSwitchSize,
  StyledToolbar,
} from './styled-components'
import { IExpansionItemProps } from './ExpansionMenu/ExpansionItem.interface'
import HorizontalArrowIcon from './common/Tile/HorizontalArrow'
import { ISidebarProps } from './sidebar.interface'

const Sidebar = (
  { currentUser, open, toggleDrawer }: ISidebarProps,
  { item, role, index }: IExpansionItemProps,
): ReactElement => {
  return (
    <StyledDrawer open={open}>
      <div>
        <StyledToolbar open={open}>
          <Link to="/">
            <StyledLogo src="/suada-logo.png" open={open} />
          </Link>
        </StyledToolbar>
        <NavigationBar
          isOpened={open}
          currentUser={currentUser}
          item={item}
          role={role}
          index={index}
        />
      </div>
      <StyledSwitchSize onClick={toggleDrawer}>
        <HorizontalArrowIcon rotate={!open} />
      </StyledSwitchSize>
    </StyledDrawer>
  )
}

export default memo(Sidebar, (prev, next) => {
  if (!_.isEqual(prev.currentUser, next.currentUser)) return false
  if (prev.open !== next.open) return false
  return true
})
