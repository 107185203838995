import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  ReactElement,
} from 'react'
import { IDataState } from 'store/reducers/dataReducer'
import { IReducerAction } from 'store/types'

export const DataContext = createContext(
  {} as [IDataState, React.Dispatch<IReducerAction>],
)

interface DataProviderProps {
  reducer: (state: IDataState, action: IReducerAction) => IDataState
  initialState: IDataState
  children: ReactNode
}

export const DataProvider = ({
  reducer,
  initialState,
  children,
}: DataProviderProps): ReactElement => (
  <DataContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </DataContext.Provider>
)

export const useData = (): [IDataState, React.Dispatch<IReducerAction>] =>
  useContext(DataContext)
