import { useQuery } from '@apollo/client'
import { GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import { ILesson } from 'interfaces/modules'

interface IGetLessonByIdServiceReturn {
  lessonData: ILesson
  loading: boolean
}

export const getLessonByIdService = (lesson: {
  id: string
}): IGetLessonByIdServiceReturn => {
  const { data, loading } = useQuery(GET_LESSON_BY_ID, {
    variables: {
      lessonId: lesson.id,
    },
  })

  const lessonData = data?.getLessonById

  return {
    lessonData,
    loading,
  }
}
