import React, { ReactElement } from 'react'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons'

import { ToolbarControls } from './styled-components'

import HeadlinesPicker from './HeadlinesPicker/HeadlinesPicker'
import { linkPlugin } from './Plugins'
import { EditorState } from 'draft-js'

const HeadlinesButton = (props: {
  onOverrideContent: (content: unknown) => void
}): ReactElement => {
  const onClick = (): void => {
    props.onOverrideContent(HeadlinesPicker)
  }

  return (
    <div>
      <button onClick={onClick}>H</button>
    </div>
  )
}

export interface IControlsProps {
  onOverrideContent: (content: unknown) => void
  setEditorState: (editorState: EditorState) => void
  getEditorState: () => EditorState
}

const Controls = ({
  externalProps,
}: {
  externalProps: IControlsProps
}): ReactElement => {
  return (
    <ToolbarControls>
      <BoldButton {...externalProps} />
      <ItalicButton {...externalProps} />
      <UnderlineButton {...externalProps} />
      <linkPlugin.LinkButton {...(externalProps as object)} />
      <CodeButton {...externalProps} />
      <HeadlinesButton {...externalProps} />
      <UnorderedListButton {...externalProps} />
      <OrderedListButton {...externalProps} />
      <BlockquoteButton {...externalProps} />
    </ToolbarControls>
  )
}

export default Controls
