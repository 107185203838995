import React, { useCallback, useEffect, useState } from 'react'
import {
  ResetContent,
  ResetTitle,
  Container,
  LoginButton,
} from './styled-components'
import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import AuthLayout from 'components/AuthLayout'
import { Button } from 'components/common/Button'
import { useNavigate, useParams } from 'react-router-dom'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { Api } from 'api'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CHECK_RESET_PASSWORD_TOKEN } from 'gql/users.query'
import { FormGroupItem } from 'components/common/Form'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import { isValidPassword } from 'pages/profile/AdminProfile/ChangePassword'
import { PasswordWithValidations } from 'pages/profile/AdminProfile/PasswordWithValidations'
import { AxiosError } from 'axios'

const ForgotPassword = (): React.JSX.Element => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { setSnackbar } = useSnackbarAlert()
  const { token } = useParams<{ token: string }>()

  const { error } = useQuery(CHECK_RESET_PASSWORD_TOKEN, {
    variables: { token },
  })
  if (error) {
    const errorMessage: string = error.message.split('GraphQL error: ')[1]
    setSnackbar({ message: errorMessage, variant: 'error' })
  }
  const { handleSubmit, handleChange, values, touched } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required('New Password is Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword')], 'Passwords do not match')
        .required('Password confirm is required'),
    }),
    onSubmit: (values: {
      token?: string
      newPassword: string
      confirmPassword: string
    }) => {
      const data = { ...values }
      setLoading(true)

      data.token = token

      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      Api('post', 'users/resetPassword', data)
        .then(() => {
          message = `Successful`
          variant = 'success'
          setLoading(false)
          setSnackbar({ message, variant })
          navigate('/login')
        })
        .catch((err: AxiosError) => {
          message = err.response?.data?.message || 'An error occurred'
          variant = 'error'
          setLoading(false)
          setSnackbar({ message, variant })
          navigate('/login')
        })
    },
  })

  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const onChangeConfirmPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(e)
      if (values.confirmPassword !== e.target.value) {
        setConfirmPasswordError('Passwords do not match')
      } else setConfirmPasswordError('')
    },
    [values.confirmPassword, confirmPasswordError],
  )

  useEffect(() => {
    if (
      values.newPassword !== values.confirmPassword &&
      values.confirmPassword &&
      values.confirmPassword.length > 0
    ) {
      setConfirmPasswordError('Passwords do not match')
    } else setConfirmPasswordError('')
  }, [values.newPassword, values.confirmPassword])

  const onSave = (): void => {
    const isValid = isValidPassword(values.newPassword)
    if (isValid && values.confirmPassword === values.newPassword) {
      handleSubmit()
    }
  }

  useEffect(() => {
    if (error) navigate('/login')
  }, [error])

  return (
    <AuthLayout>
      <Container>
        <ResetTitle>{t('reset_password.choose_new_password')}</ResetTitle>

        <ResetContent>
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>): void =>
              e.preventDefault()
            }
          >
            <PasswordWithValidations handleChange={handleChange} />

            <FormGroupItem>
              <PasswordInput
                touched={touched.confirmPassword}
                label={t('form_fields.confirm_password')}
                name="confirmPassword"
                size="small"
                onChange={onChangeConfirmPassword}
                value={values.confirmPassword}
                error={confirmPasswordError.length > 1}
              />
              <div style={{ color: '#f44336' }}>{confirmPasswordError}</div>
            </FormGroupItem>

            <LoginButton>
              <Button
                btnType="submit"
                text={t('actions.change_password')}
                loading={loading}
                size="large"
                background="#06C68F"
                onClick={onSave}
              />
            </LoginButton>
          </form>
        </ResetContent>
      </Container>
    </AuthLayout>
  )
}

export default ForgotPassword
