import { Check } from '@mui/icons-material'
import { FormGroupItem } from 'components/common/Form'
import React, { useCallback, useState, useEffect, ReactElement } from 'react'
import styled from 'styled-components'
import { PasswordInput } from 'components/common/TextInput/PasswordInput'
import { isValidPassword } from './ChangePassword'
import { useTranslation } from 'react-i18next'
import {
  validations,
  IValidationObject,
} from 'components/UsersLayout/staticData'

export const PasswordWithValidations = ({
  handleChange,
}: {
  handleChange: (
    eventOrTextValue: string | React.ChangeEvent<HTMLInputElement>,
  ) => void
}): ReactElement => {
  const { t } = useTranslation()
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<IValidationObject[]>([])

  const chengeError = useCallback((key: string, err: boolean) => {
    setErrors((x: IValidationObject[]) => {
      const foundIndex = x.findIndex(
        (it: IValidationObject) => it.value === key,
      )
      const before = x.slice(0, foundIndex)
      const after = x.slice(foundIndex + 1)
      const current = [
        ...before,
        {
          label: x[foundIndex].label,
          value: x[foundIndex].value,
          error: err,
        },
        ...after,
      ]

      return current
    })
  }, [])

  const onPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = e.target.value
      handleChange(e)
      setPassword(currentValue)
      if (currentValue.length >= 8) {
        chengeError('length', false)
      } else chengeError('length', true)

      if (hasLowerCase(currentValue)) {
        chengeError('lowercase', false)
      } else chengeError('lowercase', true)

      if (hasUpperCase(currentValue)) {
        chengeError('uppercase', false)
      } else chengeError('uppercase', true)

      if (hasDigit(currentValue)) {
        chengeError('digit', false)
      } else chengeError('digit', true)

      if (hasSpecial(currentValue)) {
        chengeError('special', false)
      } else chengeError('special', true)
    },
    [password],
  )

  useEffect(() => {
    if (validations) {
      setErrors(validations)
    }
  }, [validations])

  return (
    <FormGroupItem>
      <PasswordInput
        label={t('form_fields.new_password')}
        name="newPassword"
        size="small"
        type="password"
        fullWidth
        onChange={onPasswordChange}
        value={password}
        error={isValidPassword(password)}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {errors.map((error: IValidationObject, i: number) => (
          <Label key={i} text={error.label} hasError={error.error} />
        ))}
      </div>
    </FormGroupItem>
  )
}

export const Label: React.FC<{ text: string; hasError?: boolean }> = ({
  text,
  hasError,
}) => {
  return (
    <LabelContainer
      style={
        hasError ? { color: '#f44336', marginLeft: 29 } : { color: '#06C68F' }
      }
    >
      {!hasError && <Check />}
      <div>{text}</div>
    </LabelContainer>
  )
}

const LabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 5px;
`

const hasLowerCase = (str: string): boolean => {
  return /[a-z]/.test(str)
}

const hasUpperCase = (str: string): boolean => {
  return /[A-Z]/.test(str)
}

const hasDigit = (str: string): boolean => {
  return /\d/.test(str)
}

const hasSpecial = (str: string): boolean => {
  return /[.,@#$%^&*]/.test(str)
}
