import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ILessonProgress, IModuleProgress, IUser } from 'interfaces/users'
import {
  collapseColumnConfig,
  getGridConfig,
  nestedCollapseColumnConfig,
} from 'pages/courses/CourseDetail/CourseStudents/gridConfig'
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getGroupedStudentsByCourseIdService } from 'services/analytics/getGrupedStudentsService'
import {
  IUseCourseStudentsParams,
  IUseCourseStudentsResponse,
} from './useCourseStudent.interface'

const useCourseStudents = ({
  courseId,
  groupId,
  groupIds,
}: IUseCourseStudentsParams): IUseCourseStudentsResponse => {
  const { t } = useTranslation()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const { data, loading } = getGroupedStudentsByCourseIdService({
    courseId,
    filter: {
      group: {
        type: LowerCaseFilterableFieldType.EXACT,
        value: groupId || groupIds,
      },
    },
    currentPage: currentPage,
    perPage: rowsPerPage,
    getDetailedProgress: true,
  })

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
  }

  const handlePageChange = (
    _: ChangeEvent<unknown>,
    newPage?: number,
  ): void => {
    if (newPage !== undefined) {
      setCurrentPage(newPage)
    }
  }

  const totalPages = data?.totalPages || 0
  const students = data?.students || []
  const gridConfig = getGridConfig(t)
  const collapseConfig = collapseColumnConfig(t)
  const getCollapsedData = (row: IUser): IModuleProgress[] =>
    row.moduleProgresses || []
  const nestedCollapseConfig = nestedCollapseColumnConfig(t)
  const getNestedCollapsedData = (row: IModuleProgress): ILessonProgress[] =>
    row.lessonProgresses || []

  return {
    t,
    loading,
    students,
    gridConfig,
    collapseConfig,
    getCollapsedData,
    getNestedCollapsedData,
    nestedCollapseConfig,
    rowsPerPage,
    handleRowsPerPageChange,
    currentPage,
    handlePageChange,
    totalPages,
  }
}

export default useCourseStudents
