import React from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { ReactComponent as DeleteIcon } from 'assets/trash-can.svg'
import CheckBox from 'components/common/Checkbox'
import { Button } from 'components/common/Button'
import TextInput from 'components/common/TextInput/TextInput'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import FlashCardItem from './FlashCardItem'
import Modal from 'components/common/Modal'
import AudioPlayer from 'components/common/AudioPlayer'
import { Wrapper, FlashCarGrid } from './styled-components'
import { Action } from 'components/UsersLayout/styled-components'
import Tooltip from '@mui/material/Tooltip'
import useFlashCardsLayout from 'hooks/modules/FlashCardsLayout/useFlashCardsLayout'
import { SplashScreen } from 'components/common'
import { ActionItem, IParams } from './interfaces'

const FlashCardsLayout = ({
  module,
  companyCourse,
}: IParams): React.JSX.Element => {
  const {
    state,
    handleCreateFlashCardClick,
    selectedItem,
    handleDeleteClick,
    t,
    selectAllItem,
    filteredFlashCards,
    searchValue,
    handleSearchChange,
    actions,
    selectItem,
    handleAudioClick,
    audioModalOpened,
    setAudioModalOpened,
    currentAudio,
    flashCards,
    createButton,
    loading,
  } = useFlashCardsLayout({ module, companyCourse })

  if (!flashCards?.length) {
    return (
      <>
        <SplashScreen
          title={t('flashcards_layout.no_flashcards')}
          subTitle={t('flashcards_layout.create_flashcards_information')}
          createButton={createButton}
        />
      </>
    )
  }

  if (loading) {
    return <div>{t('general.loading')}...</div>
  }

  return (
    <div style={{ paddingTop: '20px' }}>
      <Wrapper>
        {state.userPermission?.createFlashcard && (
          <Wrapper>
            {companyCourse ? null : (
              <>
                {' '}
                <Button
                  text={t('flashcards_layout.create_flashcard')}
                  size="small"
                  color="secondary"
                  onClick={(): void => handleCreateFlashCardClick()}
                  icon={<AddCircleRoundedIcon />}
                  background="#06C68F"
                />
                <Wrapper>
                  <GridMultipleActions
                    selectedItems={selectedItem.length}
                    actions={
                      companyCourse
                        ? []
                        : [
                            {
                              id: 0,
                              color: 'secondary',
                              disabled: selectedItem.length < 1,
                              onClick: (): void =>
                                handleDeleteClick(selectedItem),
                              component: (
                                <Action $hoverColor="#EA382A">
                                  <Tooltip title={t('actions.delete')} arrow>
                                    <DeleteIcon />
                                  </Tooltip>
                                </Action>
                              ),
                            },
                          ].filter((i: ActionItem) => !i.hide)
                    }
                  />
                  <Wrapper>
                    <CheckBox
                      onChange={selectAllItem}
                      checked={
                        filteredFlashCards.length > 0 &&
                        filteredFlashCards.length === selectedItem.length
                      }
                    />
                    {filteredFlashCards.length === selectedItem.length
                      ? t('general.diselect_all')
                      : t('general.select_all')}
                  </Wrapper>
                </Wrapper>
              </>
            )}
          </Wrapper>
        )}

        <form style={{ marginLeft: 10 }}>
          <TextInput
            label={t('general.search_placeholder')}
            type="text"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            icon={
              <IconButton type="submit">
                <SearchRoundedIcon />
              </IconButton>
            }
            $iconInStart
          />
        </form>
      </Wrapper>
      {filteredFlashCards.length === 0 ? (
        <SplashScreen subTitle={t('flashcards_layout.no_flashcards_found')} />
      ) : (
        <FlashCarGrid>
          {filteredFlashCards.map((flashCard) => (
            <FlashCardItem
              key={flashCard.id}
              flashCard={flashCard}
              actions={
                companyCourse || !state.userPermission.createFlashcard
                  ? []
                  : actions
              }
              selectedItems={selectedItem}
              selectItem={selectItem}
              handleAudioClick={handleAudioClick}
            />
          ))}
        </FlashCarGrid>
      )}
      <Modal
        isOpened={audioModalOpened}
        onClose={(): void => setAudioModalOpened(false)}
      >
        <AudioPlayer url={currentAudio && currentAudio} />
      </Modal>
    </div>
  )
}

export default FlashCardsLayout
