import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchBooksByGroup } from 'services/books/getBooksByGroupService'
import { debounce } from 'lodash'
import {
  GetBooksByGroupFiltersByEnums,
  IBooksResponse,
} from '../../../services/books/bookServices.interface'
import { IGroup } from 'interfaces/groups'
import { TFunction } from 'interfaces/TFunction'

const useGroupBooks = ({
  group,
}: {
  group: IGroup
}): {
  t: TFunction
  books: IBooksResponse
  refetchBooks: (params: { searchValue: string; groupId: string }) => void
  createButton: () => React.JSX.Element
  searchValue: string
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  groupId?: string
  loading: boolean
  isSearchType: boolean
  initialLoad: boolean
} => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const groupId = group.id

  const openAddDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'addBookToGroup', true, {
      groupData: group,
    })
  }

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('books_layout.add_book')}
      color="secondary"
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )

  const {
    books,
    loading: fetchLoading,
    refetch,
  } = useFetchBooksByGroup({
    searchValue: '',
    filterBy: GetBooksByGroupFiltersByEnums.IN_GROUP,
    groupId,
  })

  useEffect(() => {
    if (!fetchLoading) {
      setInitialLoad(false)
    }
  }, [fetchLoading])

  const debouncedRefetch = useCallback(
    debounce((value: string) => {
      setLoading(true)
      refetch({
        searchValue: value,
        groupId,
        filterBy: GetBooksByGroupFiltersByEnums.IN_GROUP,
      }).finally(() => setLoading(false))
      setIsSearchType(value !== '')
    }, 500),
    [groupId],
  )

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const newSearchValue = e.target.value
    setSearchValue(newSearchValue)
    debouncedRefetch(newSearchValue)
    setIsSearchType(true)
  }

  const onSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setLoading(true)
    refetch({
      searchValue,
      groupId,
    }).finally(() => setLoading(false))
  }

  return {
    t,
    books,
    refetchBooks: refetch,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    groupId,
    loading: fetchLoading || loading,
    isSearchType,
    initialLoad,
  }
}

export default useGroupBooks
