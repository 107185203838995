import { gql } from 'graphql.macro'

export const GET_SKILL_TEST_VIDEOS_TEMPLATES = gql`
  query GetSkillTestVideosTemplates($moduleId: String!) {
    getSkillTestVideosTemplates(moduleId: $moduleId) {
      id
      status
      title
      description
      tips
      version
      coaches
      students
      isTemplate
      videosCount
    }
  }
`

export const ADD_SKILL_TEST_VIDEO_TEMPLATE = gql`
  mutation AddSkillTestVideoTemplate(
    $moduleId: String!
    $input: SkillTestVideoNewInput
  ) {
    addSkillTestVideoTemplate(moduleId: $moduleId, input: $input) {
      id
      status
      title
      description
      tips
      version
      coaches
      students
      isTemplate
      videosCount
    }
  }
`
export const UPDATE_SKILL_TEST_VIDEO_TEMPLATE = gql`
  mutation UpdateSkillTestVideoTemplate(
    $id: String!
    $input: SkillTestVideoNewInput
  ) {
    updateSkillTestVideoTemplate(id: $id, input: $input) {
      id
      status
      title
      description
      tips
      version
      coaches
      students
      isTemplate
      videosCount
    }
  }
`
export const DELETE_SKILL_TEST_VIDEO_TEMPLATE = gql`
  mutation($ids: [String]) {
    deleteSkillTestVideoTemplate(ids: $ids)
  }
`

export const SET_SKILL_TEST_VIDEO_CRITERIA = gql`
  mutation setSkillTestVideoCriteria(
    $criterias: [SkillTestVideoCriteriaDto]!
    $moduleId: String!
    $idsToRemain: [String]!
  ) {
    setSkillTestVideoCriteriasOnModule(
      criterias: $criterias
      idsToRemain: $idsToRemain
      moduleId: $moduleId
    ) {
      criterias {
        id
        criteria
        createDate
        orderIndex
        moduleId
      }
    }
  }
`

export const GET_SKILL_TEST_VIDEOS_CRITERIAS = gql`
  query GetSkillTestVideoCriteriasByModuleId(
    $moduleId: String!
    $skip: Int
    $count: Int
  ) {
    getSkillTestVideoCriteriasByModuleId(
      moduleId: $moduleId
      skip: $skip
      count: $count
    ) {
      total
      criterias {
        id
        criteria
        createDate
        orderIndex
        moduleId
      }
    }
  }
`
