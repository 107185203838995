import React, { ReactElement } from 'react'
import {
  StatisticFlexBox,
  StatisticTitle,
  StatisticFlex,
} from './styled-components'

import { ReactComponent as GroupSvg } from '../../../assets/group.svg'
import { ReactComponent as EducationSvg } from '../../../assets/education.svg'
import { ReactComponent as UserSpeaker } from '../../../assets/user-speaker.svg'
import Tile from 'components/common/TileV2'
import { IStatisticsProps } from './groupsAnalytics.interface'
import { useTranslation } from 'react-i18next'
import useCustomCardStyles from 'hooks/tileCards/useCustomCardStyle'

const Statistic = ({
  additionalQuantities,
  loading,
}: IStatisticsProps): ReactElement => {
  const { t } = useTranslation()

  const customCardStyles = useCustomCardStyles({
    smallWidth: '330px',
    mediumWidth: '370px',
    largeWidth: '432px',
    smallBreakpoint: 1462,
    mediumBreakpoint: 1636,
  })

  return (
    <StatisticTitle>
      <StatisticFlex>
        <StatisticFlexBox>
          {additionalQuantities && (
            <>
              <Tile
                customCardStyles={customCardStyles}
                value={
                  loading
                    ? t('general.loading')
                    : additionalQuantities.groupsQuantity
                }
                text={t('general.total_unique_groups')}
                gap={50}
                icon={<GroupSvg fill="#0074D9" />}
                iconColor="#0074D9"
                $backgroundColor="#E1EBFA"
              />
              <Tile
                customCardStyles={customCardStyles}
                value={
                  loading
                    ? t('general.loading')
                    : additionalQuantities.studentsQuantity
                }
                text={t('general.total_unique_students')}
                gap={50}
                icon={<EducationSvg />}
                iconColor="#8438C6"
                $backgroundColor="#F1DFF7"
              />
              <Tile
                customCardStyles={customCardStyles}
                value={
                  loading
                    ? t('general.loading')
                    : additionalQuantities.skillTestVideosQuantity
                }
                text={t('general.total_skill_test_videos')}
                gap={50}
                icon={<UserSpeaker />}
                iconColor="#20A8C6"
                $backgroundColor="#DAEDF2"
              />
            </>
          )}
        </StatisticFlexBox>
      </StatisticFlex>
    </StatisticTitle>
  )
}

export default Statistic
