import { OperationVariables, useQuery } from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

import { GET_GROUP } from 'gql/group/group.query'
import { IGroup } from 'interfaces/groups'

export const PER_PAGE = 10

interface IUseFetchGroupsServiceResponse {
  groups?: IUseFetchGroupsResponse
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
}

export interface IUseFetchGroupsResponse {
  data: IGroup[]
  totalCount: number
  totalPages: number
  currentPage: number
}

interface IGetGroupsResponse {
  getAllGroups: IUseFetchGroupsResponse
}

interface IGetGroupsVariables {
  filter: IFilterQueryType
  currentPage: number
  perPage: number
}

export const useFetchGroups = (
  currentPage = 1,
  perPage: number = PER_PAGE,
  filter: IFilterQueryType,
): IUseFetchGroupsServiceResponse => {
  const { data, loading, error, refetch } = useQuery<
    IGetGroupsResponse,
    IGetGroupsVariables
  >(GET_GROUP, {
    variables: {
      filter: { ...filter },
      currentPage,
      perPage,
    },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    groups: data && data.getAllGroups,
    loading,
    error: errorMessage,
    refetch,
  }
}
