// disabled eslint as parameters documents and cache can be any type
/* eslint-disable */
interface IUpdateCacheParams {
  objectName: string
  query: any
  cache: any
  params?: any
  document: any
  action: string
  nestedFindObjectName?: string
  nestedDeleteObjectName?: string
}

export const updateCache = ({
  objectName,
  query,
  cache,
  params = {},
  document,
  action,
  nestedFindObjectName = '',
  nestedDeleteObjectName = '',
}: IUpdateCacheParams): boolean | undefined => {
  try {
    const result = cache.readQuery({
      query,
      variables: params,
    })

    const object = result[objectName]
    let documentArray = [document]

    if (action === 'addMany' || action === 'deleteMany') {
      documentArray = document
    }

    if (action === 'add') {
      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: documentArray.concat(object) },
      })
      return false
    }

    if (action === 'addToTopSingle') {
      let updatedDocument = documentArray.concat(object)

      if (nestedFindObjectName) {
        const findArray = object[nestedFindObjectName]
        updatedDocument = {
          ...object,
          [nestedFindObjectName]: findArray.concat(documentArray),
        }
      }

      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: updatedDocument },
      })
      return false
    }

    if (action === 'addToTop') {
      let updatedDocument = documentArray.concat(object)

      if (nestedFindObjectName) {
        updatedDocument = {
          ...object,
          [nestedFindObjectName]: document[nestedFindObjectName],
        }
      }

      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: updatedDocument },
      })
      return false
    }

    if (action === 'addToLast') {
      let updatedDocument = documentArray.concat(object)

      if (nestedFindObjectName) {
        updatedDocument = {
          ...object,
          [nestedFindObjectName]:
            object[nestedFindObjectName].concat(documentArray),
        }
      }

      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: updatedDocument },
      })
      return false
    }

    if (action === 'addMany') {
      cache.writeQuery({
        query,
        variables: params,
        data: { [objectName]: documentArray.concat(object) },
      })
      return false
    }

    if (action === 'delete') {
      cache.writeQuery({
        query,
        variables: params,
        data: {
          [objectName]: object.filter((item: any) => item.id !== document.id),
        },
      })
      return false
    }

    if (action === 'deleteMany') {
      let updatedDocument = object
      let deleteFrom = document

      if (nestedDeleteObjectName) {
        deleteFrom = document[nestedDeleteObjectName]
      }

      if (nestedFindObjectName) {
        const fileteredItems = object[nestedFindObjectName].filter(
          (item: any) => {
            const arr = deleteFrom.map((doc: any) =>
              typeof doc === 'string'
                ? item.id !== doc
                : item.id !== doc.id && item,
            )[0]
            return arr
          },
        )

        updatedDocument = {
          ...object,
          [nestedFindObjectName]: fileteredItems,
        }
      } else {
        updatedDocument = object.filter((item: any) => {
          const arr = deleteFrom.map((doc: any) =>
            typeof doc === 'string'
              ? item.id !== doc
              : item.id !== doc.id && item,
          )[0]
          return arr
        })
      }

      cache.writeQuery({
        query,
        variables: params,
        data: {
          [objectName]: updatedDocument,
        },
      })
      return false
    }

    if (action === 'update') {
      let updatedDocument = object

      if (nestedFindObjectName) {
        updatedDocument = {
          ...object,
          [nestedFindObjectName]: object[nestedFindObjectName].map(
            (item: any) => {
              if (item.id === document.id) {
                return document
              }
              return item
            },
          ),
        }
      }

      cache.writeQuery({
        query,
        variables: params,
        data: {
          [objectName]: updatedDocument,
        },
      })
      return false
    }
  } catch (error) {
    console.log('Error from cache update', error) //eslint-disable-line
  }
}
