import React, { ChangeEvent, ReactElement } from 'react'
import { CenteredText } from '../../testLayout/styled-components'

import { t } from 'i18next'
import {
  MultipleAction,
  MultipleActionTooltip,
} from 'components/common/Grid/styled-components'

import IconButton from '@mui/material/IconButton'
import Grid from 'components/common/GridV2'
import {
  Header,
  LayoutWrapper,
  Wrapper,
  Whiteboard,
  SelectFieldContainer,
} from './styled-components'
import SyncIcon from '@mui/icons-material/Sync'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import SelectField from 'components/common/SelectField/SelectField'
import { Input } from 'components/common/TextInput'
import useStudentsLayout from './useStudentsLayout'
import { IStudentsLayout } from './students.interface'
import TablePagination from 'components/common/Pagination/TablePagination'
import Presentation from 'components/common/PresentationIcon/Presentation'

const StudentsLayout = ({
  testAssessmentId,
}: IStudentsLayout): ReactElement => {
  const {
    onSearchSubmit,
    setSelectedGroup,
    setSearchText,
    selectedGroup,
    searchText,
    groupOptions,
    handleClick,
    users,
    loading,
    currentPage,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleResetClick,
    config,
  } = useStudentsLayout({
    testAssessmentId,
  })

  return (
    <LayoutWrapper>
      <Header>
        <Wrapper>
          <MultipleAction>
            <IconButton
              disabled={!selectedGroup && !searchText}
              onClick={handleResetClick}
            >
              <SyncIcon fontSize="small" />
            </IconButton>
            <MultipleActionTooltip>{t('actions.reset')}</MultipleActionTooltip>
          </MultipleAction>
          <SelectFieldContainer>
            <SelectField
              placeholder={'Select Group'}
              options={groupOptions}
              onChange={(e): void => setSelectedGroup(e)}
              value={selectedGroup}
            />
          </SelectFieldContainer>
          <form onSubmit={onSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchText}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setSearchText(e.target.value)
              }
              icon={
                <IconButton onClick={onSearchSubmit}>
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
            />
          </form>
        </Wrapper>
      </Header>
      {users && users.data.length ? (
        <div>
          <Grid
            config={config}
            data={users.data}
            redirectToDetailsHandler={handleClick}
          />
          <TablePagination
            currentPage={currentPage}
            rowsPerPage={perPage}
            count={users.totalCount}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      ) : (
        !loading && (
          <Whiteboard>
            <Presentation />
            <CenteredText>
              <h4> {t('tests_layout.no_students')} </h4>
            </CenteredText>
          </Whiteboard>
        )
      )}
    </LayoutWrapper>
  )
}

export default StudentsLayout
