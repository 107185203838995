import { useState } from 'react'
import addStudentService from 'services/users/addStudentService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { useTranslation } from 'react-i18next'
import { IUser } from 'interfaces/users'

const useAddUserService = (
  companyId: string,
): {
  addStudent: (
    input: IUser,
    role: string,
    companyId: string,
    onSuccess?: (data: IUser) => void,
  ) => void
  addStudentLoading: boolean
  user: IUser | string
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState<IUser | ''>('')
  const [error, setError] = useState('')
  const { addStudentMutation, loading } = addStudentService(companyId)
  const [, dispatch] = useData()

  const addStudent = async (
    input: IUser,
    role: string,
    companyId: string,
    onSuccess?: (data: IUser) => void,
  ): Promise<false | undefined> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { data, errors } = await addStudentMutation({
      variables: {
        input,
        companyId,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return false
      }
      variant = 'error'
    } else if (data) {
      setUser(data.addStudent)
      message = `${t('messages.new')} ${t(`general.${role.toLowerCase()}`)} ${t(
        'messages.added',
      )}`
      variant = 'success'

      dispatch({
        type: SET_CREATED_USER,
        payload: {
          obj: data.addStudent,
          type: role.toLowerCase(),
        },
      })

      if (onSuccess) onSuccess(data.addStudent)
    }

    setSnackbar({ message, variant })
  }

  return {
    addStudent,
    addStudentLoading: loading,
    user,
    error,
  }
}

export default useAddUserService
