import React from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import {
  Header,
  LayoutWrapper,
  RightContainer,
  Text,
  Wrapper,
} from '../../../questions/styled-components'
import Drawer from 'components/common/Drawer'
import LinkQuestionsToModuleDrawer from '../../../questions/LinkQuestionsToModuleDrawer/LinkQuestionsToModuleDrawer'
import { Button } from 'components/common/Button'
import Grid from 'components/common/GridV2'
import Pagination from 'components/common/Pagination'
import FilterDrawer from '../../../questions/FilterDrawer'
import { roles } from 'utils/permission'
import {
  CenteredText,
  Whiteboard,
} from 'pages/tests/testLayout/styled-components'
import Presentation from 'components/common/PresentationIcon/Presentation'

import {
  GridLayoutActions,
  MultipleActionTooltip,
  StudentsActionsContainer,
} from 'components/common/GridV2/styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as FilterIcon } from 'assets/filter.svg'
import TextInput from 'components/common/TextInput/TextInput'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import { ReactComponent as RedFilterIcon } from 'assets/new-filter.svg'
import _ from 'lodash'
import useQuestionsGrid from 'hooks/questions/useQuestionsGrid'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { Params } from './questionList.interface'
import { SplashScreen } from 'components/common'
import { IModule } from 'pages/modules/ModulesList.interface'

const QuestionsList = ({
  topics,
  currentModule,
  refetchModule,
  manualCompanyId,
  handleTabChange,
  setOpenCreateQuizDrawer,
  canModify = true,
}: // handleTabChange,

// setOpenCreateQuizDrawer = () => {},
Params): React.JSX.Element => {
  const {
    questions,
    loading,
    currentPage,
    searchValue,
    selectedItem,
    handleSearchSubmit,
    handleSearchChange,
    handlePaginationClick,
    handleFilterClick,
    handleCloseDrawer,
    LinkQuestionDrawerOpened,
    setLinkQuestionDrawerOpened,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    isFiltered,
    setIsFiltered,
    actions,
    config,
    state,
    t,
    redirectToQuestion,
    selectItem,
    selectAllItem,
    cards,
    refetch,
    setCurrentPage,
    questionActions,
  } = useQuestionsGrid({
    currentModule,
    manualCompanyId,
    topics,
    refetchModule,
    canModify,
    handleTabChange,
    setOpenCreateQuizDrawer,
  })

  if (
    state.currentUser.role === roles.consultant &&
    !state.selectedCompany?.id
  ) {
    return (
      <Whiteboard>
        <CenteredText>
          <Presentation />
          <h4>{t('questions_layout.switch_company')} </h4>
        </CenteredText>
      </Whiteboard>
    )
  }

  const createButton = (): React.JSX.Element => (
    <div>
      <GridActionMenu
        actionConfig={questionActions}
        row={currentModule}
        icon={
          <Button
            className="create-question-button"
            text={t('general.questions')}
            size="small"
            color="secondary"
            icon={<ArrowDropDownIcon />}
            iconPosition="right"
            background="#06C68F"
          />
        }
      />
    </div>
  )

  return (
    <>
      {!cards?.length && !searchValue ? (
        <SplashScreen
          title={t('questions_layout.no_questions')}
          subTitle={t('questions_layout.create_questions_information')}
          createButton={createButton}
        />
      ) : (
        <div style={{ paddingTop: '20px' }}>
          <LayoutWrapper>
            <Header>
              <Wrapper>
                <Wrapper>
                  <div>
                    <GridActionMenu
                      actionConfig={questionActions}
                      row={currentModule}
                      icon={
                        <Button
                          className="create-question-button"
                          text={t('general.questions')}
                          size="small"
                          color="secondary"
                          icon={<ArrowDropDownIcon />}
                          iconPosition="right"
                          background="#06C68F"
                        />
                      }
                    />
                  </div>

                  <GridLayoutActions>
                    <GridMultipleActions
                      selectedItems={
                        (selectedItem[currentPage] &&
                          selectedItem[currentPage].length) ||
                        0
                      }
                      actions={[]}
                    />
                  </GridLayoutActions>
                </Wrapper>
                <RightContainer>
                  <ToolbarItem>
                    {currentModule && (
                      <Text>
                        {t('questions_layout.questions_recommendation')}
                      </Text>
                    )}
                    <IconButton
                      onClick={(): void => setFilterDrawerOpened(true)}
                    >
                      {isFiltered ? (
                        <RedFilterIcon color="#06C68F" />
                      ) : (
                        <FilterIcon />
                      )}
                    </IconButton>
                    <MultipleActionTooltip>
                      {t('actions.filters')}
                    </MultipleActionTooltip>
                  </ToolbarItem>
                  <form onSubmit={handleSearchSubmit}>
                    <TextInput
                      label={t('general.search_placeholder')}
                      type="text"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                      $iconInStart
                    />
                  </form>
                  {loading && !cards.length && (
                    <div>{t('general.loading')}...</div>
                  )}
                </RightContainer>
              </Wrapper>
            </Header>
            {cards.length > 0 ? (
              <>
                <Grid
                  actionConfig={actions}
                  actionTilesLength={4}
                  config={config}
                  data={cards}
                  selectedItems={selectedItem[currentPage] || []}
                  selectAllItem={selectAllItem}
                  selectItem={selectItem}
                  selected={_.size(selectedItem[currentPage] || [])}
                  redirectToDetailsHandler={redirectToQuestion}
                  width={40}
                  indexOfSpecificWidth={0}
                  ActionsContainer={StudentsActionsContainer}
                />

                <Pagination
                  currentPage={questions ? questions.currentPage : 1}
                  totalPages={questions ? questions.totalPages : 0}
                  handleChange={handlePaginationClick}
                />
              </>
            ) : (
              <SplashScreen subTitle={t('questions_layout.no_matched')} />
            )}
          </LayoutWrapper>
        </div>
      )}
      <Drawer
        opened={LinkQuestionDrawerOpened}
        toggleDrawer={(): void => undefined}
        totalWidth="700px"
      >
        <LinkQuestionsToModuleDrawer
          title={t('questions_layout.link_questions_to_module')}
          handleCloseDrawer={handleCloseDrawer}
          closeDrawer={(): void => setLinkQuestionDrawerOpened(false)}
          currentModule={currentModule as IModule}
          refetch={(): void => {
            refetch()
            if (refetchModule) {
              refetchModule()
            }
          }}
          currentModuleQuestionsLength={cards.length}
          manualCompanyId={manualCompanyId}
        />
      </Drawer>
      <Drawer
        opened={filterDrawerOpened}
        toggleDrawer={(): void => undefined}
        totalWidth="600px"
      >
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          handleFilterClick={handleFilterClick}
          setIsFiltered={setIsFiltered}
          filterData={filterData}
          quizModel={currentModule ? 'module' : 'general'}
          setFilterData={setFilterData}
          refetch={refetch}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </>
  )
}

export default QuestionsList
