import Swal, { SweetAlertCustomClass } from 'sweetalert2'
import { useTranslation } from 'react-i18next'

interface ISwalProps {
  title?: string
  titleSize?: string
  text?: string
  input?:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'tel'
    | 'range'
    | 'textarea'
    | 'select'
    | 'radio'
    | 'checkbox'
    | 'file'
    | 'url'
  inputConfirmValue?: string
  inputPlaceholder?: string
  cancelButtonColor?: string
  inputValidationMessage?: string
  type?: string
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question' | null
  onConfirm?: () => void
  onClose?: () => void
  customClass?: string | SweetAlertCustomClass | undefined
  confirmText?: string
  cancelText?: string
  width?: string | number
  showCancelButton?: boolean
  showConfirmButton?: boolean
  denyText?: string
  onDeny?: () => void
}

export const useSwal = (): { fireSwal: (props: ISwalProps) => void } => {
  const { t } = useTranslation()
  const fireSwal = ({
    icon = 'warning',
    title,
    titleSize = '17px',
    text,
    input,
    inputConfirmValue,
    inputValidationMessage,
    onConfirm = (): void => undefined,
    onClose,
    customClass,
    inputPlaceholder,
    confirmText,
    cancelText = t('actions.cancel'),
    cancelButtonColor,
    denyText,
    onDeny,
    width = '20%',
    showCancelButton = true,
    showConfirmButton = true,
  }: ISwalProps): void => {
    Swal.fire({
      title: `<div style="font-size: ${titleSize};">${title}</div>`,
      text,
      input,
      // Note: when "null" is proved it wont show icon, undefined will still show default icon of warning
      icon: icon || undefined,
      showCancelButton: showCancelButton,
      showDenyButton: !!denyText,
      denyButtonColor: '#06C68F',
      denyButtonText: denyText,
      showConfirmButton: showConfirmButton,
      confirmButtonColor: '#06C68F',
      cancelButtonColor: cancelButtonColor || '#E0E1E2',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
      inputPlaceholder,
      customClass,
      width,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value === inputConfirmValue) {
            resolve(null)
          } else {
            resolve(inputValidationMessage as string)
          }
        })
      },
    }).then((result) => {
      console.log(result)
      if (result.value) {
        onConfirm()
      } else if (onClose && result.dismiss === Swal.DismissReason.cancel) {
        onClose()
      } else if (onDeny && result.isDenied) {
        onDeny()
      }
    })
  }

  return {
    fireSwal,
  }
}

export const VerticalSwalButtonClasses = {
  actions: 'custom-swal2-vertical-buttons',
  confirmButton: 'custom-swal2-button',
  cancelButton: 'custom-swal2-button',
  denyButton: 'custom-swal2-button',
}
