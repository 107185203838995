import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import { GET_MODULE } from 'gql/modules.query'
import { GET_TOPIC, EDIT_TOPIC, CHANGE_TOPICS_ORDER } from 'gql/topics.query'
import { CHANGE_LESSONS_ORDER } from 'gql/lesson/lesson.query'
import { ITopic } from 'pages/modules/ModulesList.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { ILesson } from 'interfaces/modules'

interface IEditTopicServiceResponse {
  editTopicMutation: MutationTuple<IEditTopicResponse, IEditTopicParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IEditTopicResponse {
  editTopic: ITopic
}

interface IEditTopicParams {
  topicId: string
  input: ITopic
}

export const editTopicService = (params: {
  topicId: string
}): IEditTopicServiceResponse => {
  const [editTopicMutation, { loading, error }] = useMutation<
    IEditTopicResponse,
    IEditTopicParams
  >(EDIT_TOPIC, {
    update(cache, { data }) {
      if (data?.editTopic) {
        const document = data.editTopic
        updateCache({
          objectName: 'topic',
          query: GET_TOPIC,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    editTopicMutation,
    loading,
    error,
  }
}

interface IChangeTopicsOrderServiceResponse {
  changeTopicsOrderMutation: MutationTuple<
    IChangeTopicsOrderResponse,
    IChangeTopicsOrderParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IChangeTopicsOrderParams {
  draggedId: string
  droppedId: string
}

interface IChangeTopicsOrderResponse {
  changeTopicsOrder: ITopic
}

export interface ILessonOrderServiceProps {
  moduleId?: string
  topicId?: string
  filter: IFilterQueryType
}

export const changeTopicsOrderService = (
  params: ILessonOrderServiceProps,
): IChangeTopicsOrderServiceResponse => {
  const [changeTopicsOrderMutation, { loading, error }] = useMutation<
    IChangeTopicsOrderResponse,
    IChangeTopicsOrderParams
  >(CHANGE_TOPICS_ORDER, {
    update(cache, { data }) {
      if (data?.changeTopicsOrder) {
        const document = data.changeTopicsOrder
        updateCache({
          objectName: 'getModule',
          query: GET_MODULE,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    changeTopicsOrderMutation,
    loading,
    error,
  }
}

interface IChangeLessonOrderServiceResponse {
  changeLessonOrderMutation: MutationTuple<
    IChangeLessonOrderResponse,
    IChangeLessonOrderParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IChangeLessonOrderParams {
  draggedId: string
  droppedId: string
}

interface IChangeLessonOrderResponse {
  changeLessonOrder: ILesson
}

export const changeLessonOrderService = (
  params: ILessonOrderServiceProps,
): IChangeLessonOrderServiceResponse => {
  const [changeLessonOrderMutation, { loading, error }] = useMutation<
    IChangeLessonOrderResponse,
    IChangeLessonOrderParams
  >(CHANGE_LESSONS_ORDER, {
    update(cache, { data }) {
      if (data?.changeLessonOrder) {
        const document = data.changeLessonOrder
        updateCache({
          objectName: 'lesson',
          query: GET_TOPIC,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })
  return {
    changeLessonOrderMutation,
    loading,
    error,
  }
}
