import React, { ReactElement } from 'react'
import { CenteredText, Whiteboard } from './styled-components'
import Presentation from 'components/common/PresentationIcon/Presentation'

const SplashScreen = ({
  title,
  subTitle,
  createButton = false,
  marginTop = 0,
  minHeight = '75vh',
  height = 'auto',
  contentMaxWidth = '100%',
  textMarginTop = '0px',
  isSearchOrFilter = false,
}: {
  title?: string
  subTitle?: string
  createButton?: (() => ReactElement) | false
  marginTop?: number
  minHeight?: string
  height?: string
  contentMaxWidth?: string
  textMarginTop?: string
  isSearchOrFilter?: boolean
}): ReactElement => {
  return (
    <Whiteboard
      style={{
        marginTop,
        minHeight,
        height,
        justifyContent: isSearchOrFilter ? 'flex-start' : 'center',
      }}
    >
      <CenteredText
        style={{ maxWidth: contentMaxWidth }}
        marginTop={textMarginTop}
      >
        <Presentation />
        {title && <h1>{title}</h1>}
        {subTitle && <h4>{subTitle}</h4>}
        {createButton && <div>{createButton()}</div>}
      </CenteredText>
    </Whiteboard>
  )
}

export default SplashScreen
