import {
  ApolloQueryResult,
  OperationVariables,
  QueryResult,
} from '@apollo/client'
import { IAttachment } from 'interfaces/common'
import { TranslationFunction } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { ChangeEvent, Dispatch, FormEvent, SetStateAction } from 'react'

export interface IFetchMoreVariables {
  searchValue?: string
  currentPage?: number
  perPage?: number
}
export interface IBookItem {
  setIsSearchType?: Dispatch<SetStateAction<boolean>>
  book: IBook
}

export interface IBook {
  _id: string
  id: string
  title: string
  description: string
  coverImage: IAttachment
  contentDocument: IAttachment
  companyId: string
  groupId: string
  setIsSearchType: Dispatch<SetStateAction<boolean>>
}

export interface IBookDto {
  title: string
  description: string
  coverImage: IAttachment | null
  contentDocument: IAttachment | null
  companyId: string | null
}

export interface IAddBookProps {
  companyId?: string
}
export interface IFetchBookProps {
  bookId?: string
}
export interface IFetchBooksByCompanyIdProps {
  companyId?: string
  groupId?: string
}

export interface IDeleteBookResponse {
  deleteBook: (bookId: string) => void
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface IFetchBookResponse {
  getBookById: IBook
}

export interface IUseLazyFetchBookResponse {
  getBookById: (
    bookId: string,
  ) => Promise<QueryResult<IFetchBookResponse, OperationVariables>>
  book: IBook | null
  loading: boolean
  error: string
}

export interface IUseFetchBookResponse {
  book: IBook | null
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<IBook>>
}

export interface IAddBookResponse {
  addBook: (variables: IBookDto) => void
  books: IBook[] | null
  loading: boolean
  error: string
}
export interface IEditBookResponse {
  editBook: (variables: IHandleEditBookProps) => void
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface IHandleEditBookProps {
  bookId?: string
  updateBookDto: IBookDto
}

export interface IUseFetchBooksProps {
  searchValue?: string
  currentPage?: number
  perPage?: number
  companyId?: string
}

export interface IBooksResponse {
  data: IBook[] | null
  totalPages: number
}

export interface IUseFetchBooksResponse {
  books: IBooksResponse
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<IBook>>
  fetchMore: (variables?: IFetchMoreVariables) => void
}

export enum GetBooksByGroupFiltersByEnums {
  IN_GROUP = 'IN_GROUP',
  NOT_IN_GROUP = 'NOT_IN_GROUP',
}

export interface IUseFetchBooksByGroupProps {
  searchValue?: string
  currentPage?: number
  filterBy: GetBooksByGroupFiltersByEnums
  perPage?: number
  groupId?: string
}

export interface IBookToGroupDto {
  bookIds?: string[]
  groupId?: string
}

export interface IAddBookToGroupResponse {
  addBook: (variables: IBookToGroupDto) => void
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface IRemoveBookFromGroupResponse {
  removeBooksFromGroup: (removeBookDto: IBookToGroupDto) => Promise<void>
  books: IBook[] | null
  loading: boolean
  error: string
}

export interface IBookItemExtended extends IBookItem {
  selected?: boolean
  onSelect?: (id: string) => void
  showCheckbox?: boolean
  enableActions?: boolean
  enableGroupActions?: boolean
  groupId?: string
}
export interface IUseAddBookToGroupReturnType {
  t: TranslationFunction
  onSearchSubmit: (e: FormEvent<HTMLFormElement>) => void
  searchValue: string
  handleSearchChange: (e: ChangeEvent<HTMLInputElement>) => void
  selectAllItem: () => void
  isAllSelected: boolean
  books: IBooksResponse
  handleButtonClick: () => Promise<void>
  selectedItems: string[]
  handleSelectItem: (id: string) => void
}
