import React from 'react'
import { Action } from './styled-components'

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import { TFunction } from 'interfaces/TFunction'
import { ISkillTestVideo } from './SkillTestVideos.interface'

type ActionHandler = (action: string, item: ISkillTestVideo) => void

interface ActionConfig {
  hide?: boolean
  render: (item: ISkillTestVideo) => JSX.Element
}

interface IModuleItem {
  moduleId: string
  name: string
}

interface ModuleActionConfig {
  hide?: boolean
  render: (item: IModuleItem) => JSX.Element
}

export const actionConfig = (
  t: TFunction,
  actionHandler?: ActionHandler,
  canModify?: boolean,
): ActionConfig[] => {
  const action = [
    {
      hide: !canModify,
      render: (item: ISkillTestVideo) => (
        <Action onClick={(): void => actionHandler?.('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !canModify,
      render: (item: ISkillTestVideo) => (
        <Action onClick={(): void => actionHandler?.('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.delete')}</span>
        </Action>
      ),
    },
  ]

  return action.filter((i: ActionConfig) => !i.hide)
}

export const questionActionsConfig = (
  handleCreateQuestionClick: (moduleId: string, name: string) => void,
  handleLinkQuestionToModuleClick: (moduleId: string, name: string) => void,
  t: TFunction,
): ModuleActionConfig[] => {
  const action = [
    {
      render: (item: IModuleItem) => (
        <Action
          onClick={(): void =>
            handleCreateQuestionClick(item.moduleId, item.name)
          }
        >
          <AddCircleRoundedIcon />
          <span>{t('questions_layout.create_question')}</span>
        </Action>
      ),
    },
    {
      render: (item: IModuleItem) => (
        <Action
          onClick={(): void =>
            handleLinkQuestionToModuleClick(item.moduleId, item.name)
          }
        >
          <PlaylistAddIcon />
          <span>{t('questions_layout.link_question_to_module')}</span>
        </Action>
      ),
    },
  ]

  return action
}

export const quizQuestionActionsConfig = (
  t: TFunction,
  canModify?: boolean,
  actionHandler?: ActionHandler,
): ActionConfig[] => {
  const action = [
    {
      hide: false,
      render: (item: ISkillTestVideo) => (
        <Action onClick={(): void => actionHandler?.('view', item)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: canModify,
      render: (item: ISkillTestVideo) => (
        <Action onClick={(): void => actionHandler?.('deleteQuestion', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.remove_question_from_quiz')}</span>
        </Action>
      ),
    },
  ].filter((i: ActionConfig) => !i.hide)

  return action
}
