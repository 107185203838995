import { useMutation, MutationTuple } from '@apollo/client'
import { UPLOAD_FILE_STREAM } from 'gql/group/group.query'
import { IUser } from 'interfaces/users'

interface IUploadFileResponse {
  singleUploadStream: IUser[]
}

interface IUploadFileVariables {
  file: File
}

interface IUploadFileServiceResponse {
  singleUploadStream: MutationTuple<
    IUploadFileResponse,
    IUploadFileVariables
  >[0]
}

const uploadFileService = (): IUploadFileServiceResponse => {
  const [singleUploadStream] = useMutation<
    IUploadFileResponse,
    IUploadFileVariables
  >(UPLOAD_FILE_STREAM)

  return {
    singleUploadStream,
  }
}

export default uploadFileService
