import React from 'react'
import {
  StyledFromGroupContainer,
  CourseDetailsContainer,
  SkilTestDetailsField,
  SkillTestDetailsFieldTitle,
  BadgePlaceholder,
  BadgeField,
} from './styled-components'
import Drawer from 'components/common/Drawer'
import { BadgeEditor } from '../BadgeEditor'
import {
  FormContainer,
  FormGroup,
  FormGroupItem,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormField,
} from 'components/common/Form'
import {
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import ImageCropper from 'components/common/ImageCropper'
import Modal from 'components/common/Modal'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import Uploader from 'components/common/FileUploader/Uploader'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import MyLoader from 'loading/loading'
import {
  DrawerContent,
  SkillTestDetailsField,
} from 'components/modules/styled-components'
import DrawerTips from 'components/common/DrawerTipsComonent/DrawerTips'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import useModuleForm from 'hooks/useModuleForm/useModuleForm'

const EditModuleDrawer = ({
  id,
  onClose,
}: {
  id: string
  onClose: () => void
}): JSX.Element => {
  const {
    generatingBadge,
    handleBadgeEditorClose,
    handleBadgeEnabled,
    handleChange,
    handleFileChange,
    handleSubmit,
    errors,
    handleCloseDrawer,
    touched,
    t,
    values,
    setFieldValue,
    imageLoading,
    renderImage,
    defaultOverview,
    defaultTips,
    drawer,
    cropperOpen,
    finishCrop,
    file,
    setCropperOpened,
  } = useModuleForm({ id, onClose })

  return (
    <>
      {generatingBadge && <MyLoader width={250} />}
      <DrawerHeader>
        <DrawerTitle>{t('modules_layout.edit_module')}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormGroup padding="10px">
              <FormGroupItem>
                <Input
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={`${t('form_fields.name')}*`}
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormGroupItem>

              <FormGroupItem>
                <StyledFromGroupContainer>
                  <CourseDetailsContainer>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Uploader
                        label={t('actions.upload_cover_image')}
                        accept="image/x-png,image/gif,image/jpeg"
                        onFileChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => handleFileChange(e, 'coverImage')}
                        deleteFile={(): void => {
                          setFieldValue('coverImage', null)
                        }}
                        type={FileTypes.IMAGE}
                        inputValue={
                          values.coverImage ? values.coverImage.name : ''
                        }
                        onInputChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          setFieldValue('coverImage.name', e.target.value)
                        }}
                        disabled={!values.coverImage}
                        uploadedFile={values.coverImage}
                        fileExtension={
                          (values.coverImage &&
                            `(${values.coverImage.link.split('.').pop()})`) ||
                          ''
                        }
                        loading={imageLoading === 'coverImage'}
                        id="coverImage"
                      />
                      {errors.coverImage && (
                        <span style={{ color: '#f44336' }}>
                          <>{errors.coverImage}</>
                        </span>
                      )}
                    </div>
                  </CourseDetailsContainer>
                </StyledFromGroupContainer>
              </FormGroupItem>

              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('skillTestVideoEnabled', e?.target?.checked)
                  }}
                  label={t('general.skill_test_videos')}
                  name="skillTestVideoEnabled"
                  value={values.skillTestVideoEnabled}
                />
              </FormField>
              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue(
                      'skillTestVideoCriteriaEnabled',
                      e?.target?.checked,
                    )
                  }}
                  label={t('general.stvcriterias')}
                  name="skillTestVideoCriteriaEnabled"
                  value={values.skillTestVideoCriteriaEnabled}
                />
              </FormField>
              <FormField width="100%" style={{ textAlign: 'right' }}>
                <CustomSwitcher
                  onChange={handleBadgeEnabled}
                  label={t('general.badge_enabled')}
                  name="badgeEnabled"
                  value={values.badgeEnabled}
                />
              </FormField>

              {values.badgeEnabled && (
                <BadgeField>
                  {(values.badgeData?.url && renderImage()) || (
                    <BadgePlaceholder />
                  )}
                </BadgeField>
              )}

              {values.skillTestVideoEnabled && (
                <FormField width="100%" style={{ textAlign: 'right' }}>
                  <CustomSwitcher
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFieldValue('skillTestVideoDetails', e?.target?.checked)
                    }}
                    label={t('general.skill_test_video_texts')}
                    name="skillTestVideoDetails"
                    value={values.skillTestVideoDetails}
                  />
                </FormField>
              )}

              {values.skillTestVideoEnabled && values.skillTestVideoDetails && (
                <SkilTestDetailsField hidden={!values.skillTestVideoEnabled}>
                  <SkillTestDetailsFieldTitle>
                    Skill Test Video Overview
                  </SkillTestDetailsFieldTitle>
                  <TextareaAutosize
                    title="Skill Test Video Overview"
                    name="skillTestVideoDetails.overview"
                    aria-label="minimum height"
                    minRows={2}
                    value={defaultOverview}
                    style={{
                      width: '100%',
                      font: '16px Roboto, Helvetica, Arial, sans-serif ',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      padding: '10px',
                    }}
                    onChange={handleChange}
                  />
                </SkilTestDetailsField>
              )}

              {values.skillTestVideoEnabled && values.skillTestVideoDetails && (
                <SkillTestDetailsField hidden={!values.skillTestVideoEnabled}>
                  <SkillTestDetailsFieldTitle>
                    Skill Test Video Tips
                  </SkillTestDetailsFieldTitle>

                  <DrawerTips
                    tips={defaultTips}
                    rememberText="Remember, in the next step you can choose who you wish to send your skill test video for review."
                  />
                </SkillTestDetailsField>
              )}
            </FormGroup>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />

              <Button
                text={t('actions.save')}
                type="small"
                btnType="submit"
                background="#06C68F"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>

      <Modal
        isOpened={cropperOpen}
        onClose={(): void => setCropperOpened(!cropperOpen)}
      >
        <ImageCropper
          file={file}
          hideCropper={(): void => setCropperOpened(false)}
          getCroppedFile={finishCrop}
        />
      </Modal>
      <Drawer opened={drawer.badgeEditor} totalWidth="80vw">
        <BadgeEditor
          id={drawer?.args?.id?.toString() || ''}
          closeDrawer={handleBadgeEditorClose}
          designId={values.badgeData?.id}
        />
      </Drawer>
    </>
  )
}

export default EditModuleDrawer
