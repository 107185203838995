import Typography from '@mui/material/Typography'
import { Wrapper } from './styled-components'
import React from 'react'
import strCutter from 'utils/strCutter'
import { formatDuration } from 'helpers/formatDuration'
import { Action } from 'components/common/GridV2'
import Tooltip from '@mui/material/Tooltip'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as PlayButton } from 'assets/play-icon.svg'
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { ReactComponent as PublicIcon } from 'assets/locked.svg'
import {
  IAction,
  IActionHandlerType,
  ICourse,
  ICourseListConfigReturn,
  IListColumnConfigItem,
} from './courseList.interface'
import { TranslationFunction } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'

export const courseListConfig = (
  t: TranslationFunction,
): ICourseListConfigReturn[] => {
  const grid = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper
          // onClick={() => handelClick(item.id)}
          style={{ cursor: 'pointer' }}
        >
          {item.name.length > 30 ? (
            <Tooltip title={item.name}>
              <div>{strCutter(item.name, 30)}</div>
            </Tooltip>
          ) : (
            <div>{item.name}</div>
          )}
        </Wrapper>
      ),
    },
    {
      id: 'status',
      label: t('general.status'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <StatusIndicator
          status={
            GridStatusEnums[
              item.state.toUpperCase() as keyof typeof GridStatusEnums
            ]
          }
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'numOfModules',
      label: t('general.modules'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper>{item.numberOfModules}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'numberOfLessons',
      label: t('general.lessons'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper>{item.numberOfLessons}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'duration',
      label: t('general.duration'),
      render: (item: IListColumnConfigItem): JSX.Element => {
        return <Wrapper>{formatDuration(item.duration)}</Wrapper>
      },
      numeric: false,
      disablePadding: true,
    },
  ]
  return grid
}

export const courseListActions = (
  handleClick: IActionHandlerType,
  t: TranslationFunction,
  permission: IPermissionsBool,
  activeTab: number,
): IAction[] => {
  const actions = [
    {
      render: (item: ICourse): JSX.Element => {
        if (!permission.publishCourse || activeTab === 0) {
          return <React.Fragment />
        }

        return (
          <Action
            disabled={item.finished !== 3}
            onClick={(): void => {
              if (item.finished === 3) {
                handleClick(
                  'publishedUnPublished',
                  item.id,
                  item.state === 'unpublished' ? 'published' : 'unpublished',
                )
              }
            }}
          >
            <Tooltip
              title={
                item.state === 'unpublished'
                  ? t('actions.publish')
                  : t('actions.unpublish')
              }
              arrow
            >
              <PublicIcon />
            </Tooltip>
          </Action>
        )
      },
    },
    {
      hide: !permission.editCourse,
      render: (item: ICourse) => (
        <Action onClick={(): void => handleClick('editCourse', item)}>
          <Tooltip title={t('actions.edit')} arrow>
            <EditRoundedIcon />
          </Tooltip>
        </Action>
      ),
    },
    {
      render: (): JSX.Element => {
        return (
          <Action>
            <DownloadIcon />
            <Typography>{t('actions.download_syllabus')}</Typography>
          </Action>
        )
      },
    },
    {
      render: (item: ICourse): JSX.Element => {
        return (
          <Action
            onClick={(): void => handleClick('playIntro', item)}
            disabled={!item.video}
          >
            <PlayButton />
            <Typography>{t('actions.play_intro')}</Typography>
          </Action>
        )
      },
    },
    {
      hide: !permission.deleteCourse,
      render: (item: ICourse) => (
        <Action onClick={(): void => handleClick('deleteCourse', item.id)}>
          <DeleteIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]

  return actions
}

export const actionConfig = (
  handleClick: IActionHandlerType,
  permission: IPermissionsBool,
  t: TranslationFunction,
): IAction[] => [
  {
    render: (item: ICourse): JSX.Element => {
      return !item.isBought ? (
        <Action
          className="edit"
          onClick={(): void => handleClick('editCourse', item)}
        >
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ) : (
        <React.Fragment />
      )
    },
  },
  {
    render: (): JSX.Element => {
      return (
        <Action>
          <DownloadIcon />
          <Typography>{t('actions.download_syllabus')}</Typography>
        </Action>
      )
    },
  },
  {
    render: (item: ICourse): JSX.Element => {
      return (
        <Action
          onClick={(): void => handleClick('playIntro', item)}
          disabled={!item.video}
        >
          <PlayButton />
          <Typography>{t('actions.play_intro')}</Typography>
        </Action>
      )
    },
  },
  {
    hide: !permission.deleteCourse,
    render: (item: ICourse): JSX.Element => (
      <Action
        className="delete"
        onClick={(): void => handleClick('deleteCourse', item.id)}
      >
        <DeleteIcon />
        <DeleteSpan>{t('actions.delete')}</DeleteSpan>
      </Action>
    ),
  },
]
