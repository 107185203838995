import React, { useState, useEffect, memo, ReactElement } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import MyLoader from 'loading/loading'
import {
  getSelectedCompanyFromLocalStorage,
  setSelectedCompanyInLocalStorage,
} from 'helpers/localStorage'
import { useUserValue } from 'context/UserContext'
import { GET_CURRENT_USER } from 'gql/users.query'
import {
  SET_CURRENT_COMPANY,
  FETCH_CURRENT_USER,
  CURRENT_USER_PERMISSION,
  SET_SELECTED_COMPANY,
} from 'store/types'
import { useQuery } from '@apollo/client'
import { Outlet } from 'react-router-dom'
import DrawerLayout from 'components/DrawerLayout'

import FirstLogin from 'components/FirstLogin'

import { setUserPermission } from 'utils/permission'
import { Root, StyledMain, StyledModal } from './styled-components'

const Main = (): ReactElement => {
  const [open, setOpen] = useState(true)
  // const []
  const [state, dispatch] = useUserValue()
  const { data, loading, error } = useQuery(GET_CURRENT_USER)

  const toggleDrawer = (): void => {
    setOpen(!open)
  }

  useEffect(() => {
    if (data && data.currentUserForAdmin) {
      if (data.currentUserForAdmin.companyId) {
        const companyObj = {
          id: data.currentUserForAdmin.companyId.id,
          name: data.currentUserForAdmin.companyId.name,
        }
        dispatch({
          type: SET_SELECTED_COMPANY,
          payload: companyObj,
        })
        setSelectedCompanyInLocalStorage(companyObj)
        // refetch({ id: data.currentUser.companyId.id })
        dispatch({
          type: SET_CURRENT_COMPANY,
          payload: data.currentUserForAdmin.companyId,
        })
      } else {
        // for suada users
        const selectedCompanyFromLocalStorage =
          getSelectedCompanyFromLocalStorage()
        if (selectedCompanyFromLocalStorage) {
          dispatch({
            type: SET_SELECTED_COMPANY,
            payload: selectedCompanyFromLocalStorage,
          })
        }
      }
      dispatch({
        type: FETCH_CURRENT_USER,
        payload: data.currentUserForAdmin,
      })

      dispatch({
        type: CURRENT_USER_PERMISSION,
        payload: setUserPermission(
          data.currentUserForAdmin,
          data.currentUserForAdmin.companyId ? 'companyUser' : 'user',
        ),
      })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      localStorage.clear()
      window.location.href = '/login'
    }
  }, [error])

  if (!Object.keys(state.currentUser).length || loading) return <MyLoader />
  if (error || !data) return <Root>error...</Root>

  return (
    <Root>
      <Header currentUser={data.currentUserForAdmin} open={open} />
      <Sidebar
        currentUser={data.currentUserForAdmin}
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <StyledMain $isSidebarOpened={open}>
        <DndProvider backend={HTML5Backend}>
          <div />
          <div style={{ padding: '30px', position: 'relative' }}>
            <Outlet />
          </div>
        </DndProvider>
      </StyledMain>
      <DrawerLayout />
      {data.currentUserForAdmin && (
        <StyledModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={data.currentUserForAdmin.requestPasswordChange}
          onClose={(): void => undefined}
          closeAfterTransition
        >
          <div>
            <FirstLogin />
          </div>
        </StyledModal>
      )}
    </Root>
  )
}

export default memo(Main)
