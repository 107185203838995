import gql from 'graphql-tag'

export const GET_COMPANIES = gql`
  query GetCompanies($currentPage: Int, $perPage: Int, $filter: CompanyFilter) {
    companies(currentPage: $currentPage, perPage: $perPage, filter: $filter) {
      currentPage
      totalPages
      totalCount
      data {
        id
        name
        companyEmail
        address
        phone
        companyActivity {
          label
          id
        }
        keyContactName
        status
        createDate
        internalCoursesCount
        suadaCoursesCount
        superAdminsCount
        groupAdminsCount
        studentsCount
        internalCoachesCount
        assignedCoachesCount
        groupsCount
        avatar
        avatarThumbnail
        platformService
        color
        finished
      }
    }
  }
`

export const GET_COMPANY_OPTIONS = gql`
  query GetCompanyOptions($searchValue: String) {
    getCompanyOptions(searchValue: $searchValue) {
      id
      name
    }
  }
`

export const TOGGLE_COMPANY_SETTINGS = gql`
  mutation ToggleCompanySettings(
    $companyId: String!
    $field: ToggleCompanySettingsFields
    $value: ToggleCompanySettingsValues
    $boolValue: Boolean
  ) {
    toggleCompanySettings(
      companyId: $companyId
      field: $field
      value: $value
      boolValue: $boolValue
    ) {
      id
    }
  }
`

export const GET_COMPANY = gql`
  query GetCompany($id: String) {
    company(id: $id) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      exploreCourses
      showLeaderboardBy
      showNewsFeedBy
      note
      country
      # managers {
      #   id
      #   firstName
      #   lastName
      #   email
      #   status
      # }
      avatar
      avatarThumbnail
      avatarAttachment {
        name
        fileType
        link
      }
      postalCode
      city
      numberOfUsers
      color
      createDate
      internalCoursesCount
      suadaCoursesCount
      superAdminsCount
      groupAdminsCount
      studentsCount
      internalCoachesCount
      assignedCoachesCount
      groupsCount
      finished
      phoneFields {
        code
        dialCode
      }
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($ids: [String!]) {
    deleteCompany(ids: $ids) {
      id
      companyEmail
    }
  }
`

export const CHECK_COMPANY_EMAIL = gql`
  mutation CheckCompanyEmail($email: String!) {
    checkEmail(email: $email)
  }
`

export const REMOVE_COMPANY_AVATAR = gql`
  mutation RemoveCompanyAvatar($avatar: String!) {
    removeCompanyAvatar(avatar: $avatar)
  }
`

export const ADD_COMPANY = gql`
  mutation AddCompany($input: CompanyInput, $coursesIds: [String]) {
    addCompany(input: $input, coursesIds: $coursesIds) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      # managers {
      #   id
      #   firstName
      #   lastName
      #   email
      #   status
      # }
      avatar
      avatarThumbnail
      postalCode
      city
      numberOfUsers
      phoneFields {
        code
        dialCode
      }
    }
  }
`

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: String!, $input: CompanyInput) {
    updateCompany(id: $id, input: $input) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      # managers {
      #   id
      #   firstName
      #   lastName
      #   email
      #   status
      # }
      avatar
      avatarThumbnail
      postalCode
      city
      numberOfUsers
      exploreCourses
      showLeaderboardBy
      showNewsFeedBy
      phoneFields {
        code
        dialCode
      }
    }
  }
`

export const CHANGE_COMPANY_STATUS = gql`
  mutation ChangeCompanyStatus($ids: [String]!, $action: String!) {
    changeCompanyStatus(ids: $ids, action: $action) {
      id
      name
      companyEmail
      address
      phone
      # companyActivity {
      #   label
      #   id
      # }
      status
    }
  }
`

export const CHANGE_COMPANY_PLATFORM_SERVICE = gql`
  mutation ChangeCompanyPlatformService(
    $id: String!
    $platformService: Boolean!
  ) {
    changeCompanyPlatformService(id: $id, platformService: $platformService) {
      id
      name
      companyEmail
      address
      phone
      companyActivity {
        label
        id
      }
      status
      keyContactName
      platformService
      note
      country
      avatar
      avatarThumbnail
      postalCode
      city
      numberOfUsers
      color
      createDate
    }
  }
`

export const ADD_REMOVE_COURSE_FROM_COMPANY = gql`
  mutation AddRemoveCourseFromCompany(
    $companyId: String!
    $coursesIds: [String]
  ) {
    addRemoveCourseFromCompany(companyId: $companyId, coursesIds: $coursesIds) {
      message
    }
  }
`
export const ADD_MANAGER = gql`
  mutation AddManager($input: ManagerInput, $companyId: String) {
    addManager(input: $input, companyId: $companyId) {
      id
    }
  }
`
export const EDIT_MANAGER = gql`
  mutation EditManager(
    $companyId: String
    $userId: String
    $input: ManagerInput
  ) {
    editManager(companyId: $companyId, userId: $userId, input: $input) {
      id
    }
  }
`

export const GET_COMPANIES_BY_PURCHASED_COURSE = gql`
  query GetCompaniesByPurchasedCourse($courseId: String!) {
    getCompaniesByPurchasedCourses(courseId: $courseId) {
      id
      name
    }
  }
`
export const SET_COMPANY_STATE = gql`
  mutation SetCompanyState($companyId: String, $state: Int) {
    setCompanyState(companyId: $companyId, state: $state) {
      id
      name
    }
  }
`

export const UPDATE_COMPANY_AVATAR = gql`
  mutation UpdateCompanyAvatar(
    $id: String!
    $action: ProfileAction
    $avatar: String
  ) {
    updateCompanyAvatar(id: $id, action: $action, avatar: $avatar) {
      id
      avatar
      avatarThumbnail
    }
  }
`
