import { Action } from 'components/common/GridV2'
import React from 'react'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import { ReactComponent as UnlockIcon } from 'assets/unlock.svg'
import { ReactComponent as TrashCanRed } from 'assets/trash-can-red.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'
import { IQuiz } from 'pages/quizzes/quiz.interface'

// Update Permissions type to allow undefined
interface IPermissions {
  editGroup?: boolean
  viewGroup?: boolean
  deleteGroup?: boolean
}

interface IActionConfigItem {
  hide: boolean
  render: (item: IQuiz) => JSX.Element
}

export const actionConfig = (
  actionHandler: (action: string, item: IQuiz) => void,
  permissions: IPermissions,
  t: TFunction,
  setIsSearchType?: (value: boolean) => void,
): IActionConfigItem[] => {
  const action: IActionConfigItem[] = [
    {
      hide: !permissions.editGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => actionHandler('edit', item)}
          className="edit"
        >
          <EditIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.viewGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => actionHandler('publish', item)}
          className={item.published ? 'unpublish' : 'publish'}
        >
          <UnlockIcon />
          <span>
            {item.published ? t('actions.unpublish') : t('actions.publish')}
          </span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteGroup,
      render: (item: IQuiz) => (
        <Action
          onClick={(): void => {
            actionHandler('delete', item)
            if (setIsSearchType) {
              setIsSearchType(false)
            }
          }}
          className="delete"
        >
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IActionConfigItem) => !i.hide)
}
