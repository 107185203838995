import {
  LazyQueryExecFunction,
  OperationVariables,
  useLazyQuery,
  useSubscription,
} from '@apollo/client'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useEffect } from 'react'

import {
  GET_NOTIFICATIONS,
  NOTIFICATION_LISTENER,
} from 'gql/notifications/notifications.query'
import {
  useSetNotificationStats,
  userReadNotification,
  useDeleteNotifications,
} from 'hooks/notifications/useNotifications'

import { useTranslation } from 'react-i18next'
import {
  INotification,
  NotificationMenuProps,
} from 'pages/notifications/NotificationMenu/notificationMenu.interface'
import { getUserRole } from 'utils/utils'
import { ITFunction } from 'components/common/Tfunction/Tfunction.interface'

const useNotificationMenuLogic = ({
  notificationBadgeRefetch,
  onClose,
}: NotificationMenuProps): {
  readNotification: (
    ids: string[],
    action: string,
    fetchNotificationData: () => void,
  ) => Promise<void>
  fetchNotificationData: LazyQueryExecFunction<
    OperationVariables,
    OperationVariables
  >
  deleteNotifications: (
    ids: string[],
    action: string,
    fetchNotificationData: () => void,
  ) => Promise<void>
  notificationList: INotification[]
  renderTitle: (notification: INotification) => string
  handleOpenNotification: () => void
  t: ITFunction
} => {
  const { t } = useTranslation()
  const [fetchNotificationData, { data: getNotificationsResponse }] =
    useLazyQuery(GET_NOTIFICATIONS)
  const { data: notificationListener } = useSubscription(NOTIFICATION_LISTENER)
  const { setNotificationViewStatus } = useSetNotificationStats()
  const { readNotification } = userReadNotification()
  const { deleteNotifications } = useDeleteNotifications()

  useEffect(() => {
    fetchNotificationData({ variables: { skip: 0, count: 10 } })
  }, [notificationListener])

  useEffect(() => {
    if (
      getNotificationsResponse &&
      getNotificationsResponse.getNotifications.data
    ) {
      const unViewNotifications =
        getNotificationsResponse.getNotifications.data.filter(
          (notification: INotification) => !notification.view,
        )

      if (unViewNotifications.length > 0) {
        setNotificationViewStatus(
          unViewNotifications.map(
            (notification: INotification) => notification.id,
          ),
          notificationBadgeRefetch,
        )
      }
    }
  }, [getNotificationsResponse])

  const handleOpenNotification = (): void => {
    onClose()
    DrawerEventEmitter.emit('openDrawer', 'notification', true)
  }

  const renderTitle = (notification: INotification): string => {
    if (notification.objectName === 'Rejection') {
      return notification.actionName === 'Post'
        ? `${notification.actionName} By`
        : `${notification.actionName}`
    } else {
      const owner = notification.owner
        ? notification.owner.companyId
          ? 'Company'
          : 'Suada'
        : ''
      return `${owner} ${getUserRole(
        notification.owner ? notification.owner.role : '',
      )}`
    }
  }

  const notificationList: INotification[] =
    (getNotificationsResponse &&
      getNotificationsResponse.getNotifications.data) ||
    []

  return {
    readNotification,
    fetchNotificationData,
    deleteNotifications,
    notificationList,
    renderTitle,
    handleOpenNotification,
    t,
  }
}

export default useNotificationMenuLogic
