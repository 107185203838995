import styled from 'styled-components'

// gridConfig

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: circle;
`
export const ListItem = styled.li`
  font-size: 12px;
`
export const ErrorContainer = styled.div<{ error: boolean }>`
  cursor: pointer;
  color: ${({ error }: { error: boolean }): string =>
    error ? 'red' : 'rgba(0, 0, 0, 0.27)'};
`

// Step1

export const TemplateButton = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-bottom: 20px;
  div {
    color: #555;
    padding: 0;
    line-height: 40px;
    width: 300px;
    margin: auto;
    display: block;
    border: 2px solid #555;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &:focus {
      color: lighten(#555, 20%);
      border-color: lighten(#555, 20%);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    .rotate {
      transform: rotate(180deg);
    }
    span {
      padding: 0 10px;
    }
  }
`

export const StyledInput = styled.input`
  cursor: pointer;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
`

export const GridFirstName = styled.div<{ disabledCheck?: boolean }>`
  cursor: pointer;
`
