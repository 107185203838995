import { ChangeEvent, useEffect } from 'react'
import { useFormik } from 'formik'
import { useQuery } from '@apollo/client'
import { t } from 'i18next'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { TestSchema } from 'helpers/validationSchemas'
import useAddTestService from 'hooks/tests/useAddTestService'
import useUpdateTestService from 'hooks/tests/useUpdateTestService'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { ITag } from 'pages/tests/filterTestDrawer/filter.interface'
import { IAttachment, ITagsInterface } from 'interfaces/common'
import { GET_TAGS } from 'gql/skilss.query'
import { GET_TEST } from 'gql/tests/tests.query'
import {
  ITestFormData,
  IUseTestLogicProps,
  IUseTestLogicReturn,
} from './useTestLogic.interface'

const useTestLogic = ({
  onSuccess,
  onClose,
  id,
}: IUseTestLogicProps): IUseTestLogicReturn => {
  const [state] = useUserValue()
  const { fireSwal } = useSwal()
  const {
    file,
    cropperOpened,
    setCropperOpened,
    handleCropSave,
    handleImageChange,
    imageLoading,
  } = useUploadImage()

  const formData: ITestFormData = {
    name: '',
    description: '',
    time: 0,
    quizParticipationType: 'mandatory',
    passRate: 0,
    tags: [],
    randomization: false,
    randomizationNumber: 0,
    attachment: null as IAttachment | null,
  }

  const { addTest, data: newTest, addTestLoading } = useAddTestService()
  const { updateTest } = useUpdateTestService()

  const { handleSubmit, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      initialValues: formData,
      validationSchema: TestSchema,
      onSubmit(values) {
        const companyId = state.selectedCompany?.id || null
        const formData = removeTypeNameFromObject({ ...values })
        if (values.tags)
          formData.tags = values.tags.map((i: ITag) => ({ label: i.label }))
        if (id) {
          updateTest(id, formData)
        } else {
          addTest(formData, companyId as string)
          onSuccess && onSuccess()
        }
        onClose()
      },
    })

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    let value: string | number = e.target.value

    if (name === 'time') value = parseFloat(value)
    else if (name === 'quizParticipationType')
      value = value === 'mandatory' ? value : 'optional'
    else if (name === 'passRate' || name === 'randomizationNumber')
      value = parseInt(value)

    setFieldValue(name, value)
  }

  const handleRandomizationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    let value
    if (e.target.value === 'true') {
      value = true
      setFieldValue('randomizationNumber', questions?.length)
    } else {
      value = false
      delete values.randomizationNumber
    }
    setFieldValue('randomization', value)
  }

  useEffect(() => {
    if (newTest) {
      onClose()
    }
  }, [newTest])

  const handleCloseDrawer = (): void => {
    if (tagList?.length === 0) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        confirmText: 'Yes, Cancel!',
        onConfirm: () => onClose(),
      })
    }
  }

  const { data: tags } = useQuery(GET_TAGS, {
    variables: {
      companyId: state.selectedCompany?.id,
    },
  })

  const { data, loading } = useQuery(GET_TEST, {
    variables: {
      testId: id,
      updateTestAssessmentId: state.selectedCompany?.id || null,
    },
  })

  useEffect(() => {
    if (id && data && data.getTestAssessmentById) {
      const dataClone: ITestFormData = {} as ITestFormData
      for (const [key] of Object.entries(formData)) {
        dataClone[key] = data.getTestAssessmentById[key]
      }
      dataClone.tags =
        data.getTestAssessmentById.tags?.map(({ label }: ITag) => ({
          label,
          value: label,
        })) || []
      setValues(dataClone)
    }
  }, [data])

  const tagList =
    tags?.getAllTags?.map(({ label }: ITagsInterface) => ({
      label,
      value: label,
    })) || []
  const questions = data?.getTestAssessmentById?.questions || []

  return {
    questions,
    handleSubmit,
    handleChange,
    values,
    errors,
    handleRandomizationChange,
    touched,
    setFieldValue,
    setValues,
    loading,
    tagList,
    handleImageChange,
    cropperOpened,
    setCropperOpened,
    file,
    imageLoading,
    handleCropSave,
    handleCloseDrawer,
    addTestLoading,
  }
}

export default useTestLogic
