import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GET_GROUP } from 'gql/group/group.query'
import { ADD_STUDENT } from 'gql/users.query'
import { IAddUserParams } from './addUserService'
import { IUser } from 'interfaces/users'

interface IAddStudentServiceResponse {
  addStudentMutation: MutationTuple<IAddStudentResponse, IAddUserParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IAddStudentResponse {
  addStudent: IUser
}

const addStudentService = (companyId: string): IAddStudentServiceResponse => {
  const [addStudentMutation, { loading, error }] = useMutation<
    IAddStudentResponse,
    IAddUserParams
  >(
    ADD_STUDENT,
    companyId
      ? {
          refetchQueries: [
            {
              query: GET_GROUP,
              variables: {
                filter: {
                  company: {
                    type: LowerCaseFilterableFieldType.EXACT,
                    value: companyId,
                  },
                },
              },
            },
          ],
        }
      : {},
  )

  return {
    addStudentMutation,
    loading,
    error,
  }
}

export default addStudentService
