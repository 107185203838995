import { useData } from 'context/DataContext'
import { ITopic } from 'pages/modules/ModulesList.interface'
import {
  SET_CURRENT_TOPIC,
  REMOVE_CURRENT_TOPIC,
  TOGGLE_DRAWER_CONFIRM,
} from 'store/types'

interface IActionReturnType {
  setCurrentTopic: (topic: ITopic) => void
  removeCurrentTopic: () => void
  toggleDrawerConfirm: (confirm: boolean, text: string) => void
}

export const useAction = (): IActionReturnType => {
  const [, dispatch] = useData()
  const toggleDrawerConfirm = (confirm: boolean, text: string): void => {
    dispatch({
      type: TOGGLE_DRAWER_CONFIRM,
      payload: {
        confirm,
        text,
      },
    })
  }

  const setCurrentTopic = (topic: ITopic): void => {
    dispatch({
      type: SET_CURRENT_TOPIC,
      payload: topic,
    })
  }

  const removeCurrentTopic = (): void => {
    dispatch({
      type: REMOVE_CURRENT_TOPIC,
      payload: null,
    })
  }

  return {
    setCurrentTopic,
    removeCurrentTopic,
    toggleDrawerConfirm,
  }
}
