import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import deleteLessonService, {
  IDeleteLessonParams,
} from 'services/lessons/deleteLessonService'

const useDeleteLessonService = (
  params: IDeleteLessonParams,
): {
  loading: boolean
  deleteLesson: (ids: string[], callback?: () => void) => Promise<void>
} => {
  const { t } = useTranslation()
  const { deleteLessonMutation, loading } = deleteLessonService(params)
  const { setSnackbar } = useSnackbarAlert()

  const deleteLesson = async (
    ids: string[],
    callback?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteLessonMutation({
      variables: {
        ids,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      if (callback) callback()
      message = `${
        ids.length > 1 ? t('general.lessons') : t('general.lesson')
      } ${t('messages.successfully')} ${t('general.deleted')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    loading,
    deleteLesson,
  }
}

export default useDeleteLessonService
