import React, { ReactElement } from 'react'
import { Tag } from './Tag'
import {
  CenteredParagraph,
  CenteredParagraphContainer,
  TagsContainer,
} from './styled-components'
import { ITagsProps } from './tags.interface'
import { useTranslation } from 'react-i18next'

const MoreTags = {
  label: '...',
}

const NoTag = {
  label: 'No Tags',
}

const Tags = ({
  tags,
  type,
  toolTip = true,
  redirectInDetails,
  gridStyle,
}: ITagsProps): ReactElement => {
  const { t } = useTranslation()

  if (!tags?.length)
    return type === 'courses' ? (
      <CenteredParagraphContainer>
        {' '}
        <CenteredParagraph>
          {t('modules_layout.no_courses')}{' '}
        </CenteredParagraph>{' '}
      </CenteredParagraphContainer>
    ) : (
      <Tag tag={NoTag} />
    )

  const visibleTags =
    type === 'courseInformation'
      ? tags
      : type === 'question'
      ? gridStyle === 'list'
        ? tags.slice(0, 6)
        : tags.slice(0, 2)
      : tags.slice(0, 5)
  const hiddenTags =
    type === 'question'
      ? gridStyle === 'list'
        ? tags.length > 6
          ? tags.slice(6)
          : []
        : tags.length > 2
        ? tags.slice(2)
        : []
      : tags.length > 5
      ? tags.slice(5)
      : []

  return (
    <TagsContainer>
      {visibleTags.map((tag, index) => (
        <Tag
          key={index}
          tag={{ ...tag, label: tag.label || tag.name }}
          type={type}
          redirectInDetails={(): void =>
            redirectInDetails ? redirectInDetails(index) : undefined
          }
        />
      ))}
      {hiddenTags.length > 0 && toolTip && (
        <Tag
          tag={MoreTags}
          tooltip={hiddenTags.map((tag) => tag.label || tag.name).join(', ')}
          type={type}
        />
      )}
    </TagsContainer>
  )
}

export default Tags
