import { useTranslation } from 'react-i18next'
import useEditGroup from 'hooks/group/useEditGroup'
import {
  IAssignCoachReturnType,
  IValuesType,
} from 'hooks/course/addCourseDrawer/assignCoach/AssignCoach.interface'
import {
  useAvailableTutorsService,
  useGroupsDetailsService,
} from 'services/course/assigncoach/assignCoachService'
import { IOptionType } from 'pages/coaches/AddCoach/addCoach.interface'

const useAssignCoach = (
  courseId: string,
  values: IValuesType,
): IAssignCoachReturnType => {
  const { t } = useTranslation()
  const { assignCoach } = useEditGroup()

  const { data: groupsData, loading: groupsLoading } =
    useGroupsDetailsService(courseId)
  const { data: tutorsData, loading: tutorsLoading } =
    useAvailableTutorsService(courseId)

  const groups = (groupsData && groupsData.getGroupsDetails) || []
  const courseCoachesMapped = tutorsData?.getAvailableTutors?.map((i) => ({
    label: `${i.firstName} ${i.lastName}`,
    value: i.id,
  }))

  const coaches = (courseCoachesMapped || values.coaches) as IOptionType[]

  return {
    t,
    assignCoach,
    loading: groupsLoading,
    tutorsLoading,
    groups,
    coaches,
  }
}

export default useAssignCoach
