import { useLazyQuery } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DateRangePicker from 'components/common/DateRangePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import RangeSlider from 'components/common/RangeSlider'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  filterGenderOptions,
  statuses,
} from 'components/UsersLayout/staticData'
import { useFormik } from 'formik'
import { GET_COMPANIES } from 'gql/companies.query'
import { FilterUserSchema } from 'helpers/validationSchemas'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import {
  IFilterSuperAdminsProps,
  ISuperAdminsFilterFormValues,
  ISuperAdminsFilterOptions,
} from './filterModerators.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { useUserValue } from 'context/UserContext'

const FilterModerators = ({
  closeDrawer,
  filterLoading,
  setFilterOptions,
  filter,
  filterOptions,
}: IFilterSuperAdminsProps): JSX.Element => {
  const { t } = useTranslation()
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [state] = useUserValue()
  const isCompanySelected = Boolean(state?.selectedCompany?.id)

  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  setFilterRole('SUPER_ADMINS')
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: (filterOptions as ISuperAdminsFilterOptions) || {},
    validationSchema: FilterUserSchema,
    onSubmit(values) {
      const data: ISuperAdminsFilterOptions = { ...values }
      const formValues: ISuperAdminsFilterFormValues = {}

      if (values.firstName) {
        formValues.firstName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.firstName,
        }
      }

      if (values.lastName) {
        formValues.lastName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.lastName,
        }
      }

      if (values.note) {
        formValues.note = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.note,
        }
      }

      if (values.location) {
        formValues.location = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.location,
        }
      }

      if (data?.status?.value && data?.status?.value !== 'all') {
        formValues.status = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: data?.status?.value,
        }
      }

      if (values.company && values.company.length > 0) {
        const companyIds = values.company.map((i: { value: string | null }) =>
          i.value === '-1' ? (i.value = null) : i.value,
        )
        const query = { companyId: { $in: companyIds } }
        formValues.query = {
          type: LowerCaseFilterableFieldType.QUERY,
          value: JSON.stringify(query),
        }
      }

      if (
        values.age &&
        values.age.length === 2 &&
        values.age.some((item) => item !== 0)
      ) {
        formValues.age = {
          type: LowerCaseFilterableFieldType.RANGE,
          value: `${values.age[0]}-${values.age[1]}`,
        }
      }

      if (values?.gender?.value && values?.gender?.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values?.gender?.value,
        }
      }

      const safeCreateDate = createDate || [undefined, undefined]

      safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
      safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

      const isSameDate =
        safeCreateDate[0] === safeCreateDate[1] &&
        safeCreateDate[0] !== undefined

      if (!safeCreateDate[1] && safeCreateDate[0] !== undefined)
        safeCreateDate[1] = new Date()

      const formattedData = isSameDate
        ? safeCreateDate[0]
        : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
            safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
          }`

      if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
        formValues.createDate = {
          type: isSameDate
            ? LowerCaseFilterableFieldType.EXACT
            : LowerCaseFilterableFieldType.DATE_RANGE,
          value: formattedData as string,
        }
      }

      filter(formValues)
      setFilterOptions(values as IFilterQueryType)
    },
  })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'SUPER_ADMINS') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions as ISuperAdminsFilterOptions)
    }
  }, [filterOptions, setValues])

  const loadDataOptions = (e: string, action: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e

      if (action === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: UpperCaseFilterableFieldType.MATCH,
                value,
              },
            },
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, action: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e, action)
    }
  }

  const handleRangeChange = (range: number[] | number): void => {
    setFieldValue('age', range)
  }

  const [companyOptions, setCompanyOptions] = useState([])

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  return (
    <>
      {filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.first_name')}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  placeholder={t('user_details.first_name')}
                  size="small"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.last_name')}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  placeholder={t('user_details.last_name')}
                  size="small"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>

              <FormField style={{ position: 'relative' }} width="48%">
                <Title>{t('general.status')}</Title>
                <SelectField
                  options={statuses}
                  value={values.status}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('status', e) as Promise<void>
                  }
                />
              </FormField>
              {!isCompanySelected && (
                <FormField width="48%" style={{ position: 'relative' }}>
                  <Title>{t('groups_layout.select_company')}</Title>

                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string): void =>
                      handleSelectChange(e, 'company')
                    }
                    onChange={(e: string): Promise<void> =>
                      setFieldValue('company', e) as Promise<void>
                    }
                    data={companyOptions}
                    value={values.company}
                    label={t('form_fields.type_to_search_company')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    iconDataKey="icon"
                  />
                </FormField>
              )}

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('user_details.gender')}</Title>
                <SelectField
                  placeholder={t('user_details.gender')}
                  options={filterGenderOptions}
                  value={values.gender}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('gender', e) as Promise<void>
                  }
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('form_fields.location')}
                  placeholder={t('form_fields.location')}
                  size="small"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width={isCompanySelected ? '48%' : '100%'}>
                <TextInput
                  title={t('user_details.note')}
                  placeholder={t('user_details.note')}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('general.create_date')}</Title>
                <DateRangePicker
                  values={createDate}
                  setCreateDate={setCreateDate}
                />
              </FormField>

              <FormField width="100%">
                <Title>{t('form_fields.age_range')}</Title>
                <RangeSlider
                  value={values.age}
                  onRangeChange={handleRangeChange}
                  style={{ color: '#08C694' }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterModerators
