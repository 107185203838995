import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserValue } from 'context/UserContext'
import { useSwal, VerticalSwalButtonClasses } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import AddCircleIcon from '@mui/icons-material/Add'
import { actionConfig, columnConfig } from 'components/UsersLayout/gridConfig'
import { PER_PAGE, useLazyFetchUsers } from 'hooks/users/useUsers'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'
import { Button } from 'components/common/Button'
import { FilterModerators } from 'pages/moderators'
import { FilterAdmins } from 'pages/admins'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { RolesCapitalized, roles } from 'utils/permission'
import { ReactComponent as Users } from 'components/common/Button/icons/user-multiple.svg'
import { IUser, IUserCompany } from 'interfaces/users'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import formatRoleForTranslation, { RoleFormats } from 'helpers/formatRole'

import {
  IUsersProps,
  IUsersSelectAllItems,
  IParams,
  IUseUsersLayoutReturn,
} from './useUsersLayout.interface'
import {
  IFilterQueryType,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import { useDebouncedSearch } from 'hooks/helpers/useHelperSearch/useHelperSearch'
import {
  useSelectAllItem,
  useSelectItem,
} from 'hooks/helpers/useHelperSelect/useHelperSelect'
import { useOpenDrawer } from 'hooks/helpers/useOpenDrawer/useOpenDrawer'
import { useHelperDeleteUser } from 'hooks/helpers/useHelperDeleteUser/useHelperDeleteUser'
import { useHelperModalClose } from 'hooks/helpers/useHelperModalClose/useHelperModalClose'
import { useHelperChangeUserStatus } from 'hooks/helpers/useHelperChangeUserStatus/useHelperChangeUserStatus'
import { useHelperUsersActions } from 'hooks/helpers/useHelperUsersActions/useHelperUsersActions'

const useUsersLayout = ({
  role,
  addText,
  addUserDrawer,
  editUserDrawer,
  groupId,
}: IUsersProps): IUseUsersLayoutReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [defaultFilter, setDefaultFilter] = useState<IFilterQueryType>({})
  const companyId = state.selectedCompany?.id || null

  useEffect(() => {
    let filter = {}
    if (groupId) {
      filter = {
        group: {
          nestedField: 'groupId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: [groupId],
        },
      }
    } else if (state.selectedCompany?.id) {
      filter = {
        companyId: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: state.selectedCompany.id,
        },
      }
    }
    setDefaultFilter(filter)
  }, [state, groupId])

  const { fetchUsers, users, loading } = useLazyFetchUsers(role)

  useEffect(() => {
    const canBeFetched =
      (companyId && defaultFilter.companyId) || (!companyId && defaultFilter)
    if (canBeFetched)
      fetchUsers({
        role,
        filter: {
          ...defaultFilter,
        },
        currentPage: 1,
        perPage: PER_PAGE,
      })
  }, [defaultFilter, companyId])

  const { deleteUser, deleteUserLoading } = useUserDeleteService()
  const { updateUserPassword } = useResendUserService({
    role,
    defaultFilter,
  })
  const { updateUserStatusLoading } = useUpdateStatusUserService({
    role,
    defaultFilter,
  })
  const { exportData } = useExportDataService()

  const { fireSwal } = useSwal()
  const navigate = useNavigate()

  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const allSelectedItems = Object.values(selectedItem)?.flat()

  const [selectAll, setSelectAll] = useState<IUsersSelectAllItems>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [filterQuery, setFilterQuery] =
    useState<IFilterQueryType>(defaultFilter)

  useEffect(() => {
    setSelectedItem({})
    setSelectAll({})
    setFilterQuery(defaultFilter)
    setCurrentPage(1)
    setIsFiltered(false)
  }, [state.selectedCompany])

  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })

  const { sendPushNotifications } = useSendPushNotifications()

  const handleModalClose = useHelperModalClose({
    setPushNotificationModal,
    setMessageData,
  })

  const handlePushNotificationSend = async (): Promise<false | undefined> => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const selectItem = useSelectItem({
    setSelectAll,
    setSelectedItem,
    selectedItem,
    selectAll,
    currentPage,
  })

  const selectAllItem = useSelectAllItem({
    data: users,
    selectAll,
    currentPage,
    setSelectedItem,
    setSelectAll,
    selectedItem,
  })

  const { openAddDrawer } = useOpenDrawer({
    fetchData: fetchUsers,
    perPage,
    defaultFilter,
    setSelectedItem,
    drawerName: addUserDrawer,
  })
  const handleClick = (user: IUser): void => {
    navigate(`/profile/${user.id}`)
  }

  const confirmResendPassword = (id?: string): void => {
    updateUserPassword(id)
  }

  const { confirmChangeStatus } = useHelperChangeUserStatus({
    defaultFilter,
    ROLE: role,
    allSelectedItems,
    setSelectedItem,
  })

  const { confirmDeleteMany } = useHelperDeleteUser({
    defaultFilter,
    refetch: fetchUsers,
    perPage,
    currentPage,
    filteredByCompany: companyId as string,
    selectedItem,
    setSelectedItem,
  })

  const { handleEditClick } = useOpenDrawer({
    fetchData: fetchUsers,
    perPage,
    defaultFilter,
    setSelectedItem,
    drawerName: editUserDrawer,
  })

  const handleDeleteClick = (user: IUser): void => {
    let params: IParams = {
      title: '',
      confirmText: '',
      onConfirm: () => {
        if (!user.id) return
        deleteUser([user.id], role, () => {
          fetchUsers({
            role,
            filter: {
              ...defaultFilter,
              ...filterQuery,
            },
          })
        })
      },
    }

    if (role === 'ADMIN' && user.group?.length !== 0) {
      params.title = t('popups.can_not_delete_admin')
      params.confirmText = t('popups.go_to_groups')
    } else {
      params.title = `${t('popups.delete_single')} ${t(
        formatRoleForTranslation(role),
      )}?`
      params.confirmText = t('popups.confirm_delete')
    }
    fireSwal(params)
  }

  const handleActivityClick = (userId?: string): void => {
    const url = `/profile/${userId}/activity`
    window.open(url, '_blank')
  }

  const handleResendPasswordClick = (userId?: string): void => {
    fireSwal({
      title: `${t('popups.resend_password')} ${t(
        formatRoleForTranslation(role),
      )}?`,
      onConfirm: () => {
        confirmResendPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handleChangeStatusClick = (userId?: string, status?: string): void => {
    if (status === 'ACTIVE') {
      fireSwal({
        title: `${t('popups.suspend_single')} ${t(
          formatRoleForTranslation(role),
        )}?`,
        onConfirm: () => {
          if (!userId) return
          confirmChangeStatus(userId, 'SUSPENDED')
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      fireSwal({
        title: `${t('popups.activate_single')} ${t(
          formatRoleForTranslation(role),
        )}?`,
        onConfirm: () => {
          if (!userId) return
          confirmChangeStatus(userId, 'ACTIVE')
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  const handleDeleteAllClick = (): void => {
    // if user is admin checking if user has active groups to prevent deletion
    if (role === 'ADMIN') {
      const selectedUsers = selectedItem[currentPage].map(
        (selectedItem: string) => {
          const usersToDelete = users.data.filter(
            (user: IUser) => user.id === selectedItem,
          )
          return usersToDelete[0]
        },
      )

      if (selectedUsers.length) {
        const selectedUsersWithGroup = selectedUsers.filter(
          (user: IUser) => user.group?.length !== 0,
        )

        if (selectedUsersWithGroup.length) {
          fireSwal({
            title: t('popups.can_not_delete_admin_many'),
            onConfirm: () => {
              navigate('/groups')
            },
            confirmText: t('popups.go_to_groups'),
          })

          return
        }
      }
    }
    fireSwal({
      title: `${t('popups.delete_many')} ${t(
        formatRoleForTranslation(role, RoleFormats.PLURAL),
      )}?`,
      onConfirm: () => confirmDeleteMany(role),
      confirmText: t('popups.confirm_delete'),
    })
  }

  const { handleSearchChange, searchValue, isSearchType } = useDebouncedSearch({
    fetchFunction: fetchUsers,
    defaultFilter,
    currentPage,
    perPage,
    role,
  })

  const handleExportData = (): void => {
    if (!allSelectedItems.length) return

    if (allSelectedItems.length === users?.totalCount) {
      exportData(role, allSelectedItems, null, (link: string) => {
        window.open(link, '_blank')
      })
    } else {
      fireSwal({
        icon: 'question',
        title: t(`popups.download_${role.toLowerCase()}`),
        showCancelButton: true,
        showConfirmButton: true,
        customClass: VerticalSwalButtonClasses,
        confirmText: `${t('general.all')} ${t(
          `navigation.${role.toLowerCase()}s`,
        )}`,
        denyText: `${t('general.selected')} ${t(
          `navigation.${role.toLowerCase()}s`,
        )}`,
        onConfirm: () => {
          exportData(role, [], companyId, (link: string) => {
            window.open(link, '_blank')
          })
        },
        onDeny: () => {
          exportData(role, allSelectedItems, null, (link: string) => {
            window.open(link, '_blank')
          })
        },
      })
    }
  }

  let field: RolesCapitalized = RolesCapitalized.STUDENT

  switch (role) {
    case roles.super_admin:
      field = RolesCapitalized.SUPER_ADMIN
      break
    case roles.student:
      field = RolesCapitalized.STUDENT
      break
    case roles.coach:
      field = RolesCapitalized.COACH
      break
    case roles.student:
      field = RolesCapitalized.ADMIN
      break
    default:
      break
  }

  // let handleChangeQualificationClick: any = changeQualification || undefined

  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)

  const label =
    role === roles.super_admin
      ? t('user_details.super_admin_details')
      : role === roles.admin
      ? t('user_details.admin_details')
      : ''

  const config = columnConfig(role, selectItem, t, label)

  const [userId, setUserId] = useState<string | undefined>('')

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleResendPasswordClick,
    handleChangeStatusClick,
    state.userPermission,
    field,
    t,
    setResetPasswordOpen,
    setUserId,
  )

  const { multiActions } = useHelperUsersActions({
    handleDeleteAllClick,
    field,
    state,
    ROLE: role,
    defaultFilter,
    setSelectedItem,
    handleExportData,
    setPushNotificationModal,
    allSelectedItems,
  })

  const filter = (filters: IFilterQueryType): void => {
    const newFilterQuery = JSON.stringify(filters) === '{}' ? {} : filters
    fetchUsers({
      role,
      filter: { ...defaultFilter, ...newFilterQuery },
      currentPage: 1,
      perPage,
    })
    if (users) {
      setFilterDrawerOpened(false)
      setResetVisible(true)
      setFilterQuery(newFilterQuery)
      setSelectedItem({})
    }
    setIsFiltered(JSON.stringify(newFilterQuery) !== '{}')
  }

  const resetFilter = (): void => {
    setFilterQuery(defaultFilter)
    setFilterOptions(null)
    setSelectedItem({})
    setSelectAll({})
    setIsFiltered(false)
    setResetVisible(false)
    fetchUsers({
      role,
      filter: defaultFilter,
      currentPage: 1,
      perPage,
    })
    setCurrentPage(1)
  }
  const handlePaginationClick = (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): void => {
    if (newPage !== undefined) {
      fetchUsers({
        filter: { ...filterQuery, ...defaultFilter },
        currentPage: newPage,
        perPage,
      })
      setCurrentPage(newPage)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    fetchUsers({
      role,
      filter: { ...filterQuery, ...defaultFilter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const [filterOptions, setFilterOptions] = useState<IFilterQueryType | null>(
    null,
  )

  const renderFilterComponent = (): React.JSX.Element | undefined => {
    if (role === 'SUPER_ADMIN') {
      return (
        <FilterModerators
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
        />
      )
    }

    if (role === 'ADMIN') {
      return (
        <FilterAdmins
          role="ADMIN"
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filter={filter}
          filterLoading={loading}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          hasSearchByCompany
          manualCompanyId={(state?.currentUser?.companyId as IUserCompany)?.id}
          useCompanyId={
            !!state?.currentUser?.companyId &&
            state.currentUser.companyId !== null
          }
        />
      )
    }
  }

  const createButton = (): React.JSX.Element => (
    <Button
      text={addText}
      color="secondary"
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )

  const breadCrumbData = [
    {
      label: 'Users',
      icon: <Users />,
    },
    {
      label:
        role === roles.super_admin
          ? t('general.super_admin')
          : role === roles.admin
          ? t('general.admins')
          : '',
    },
  ]

  return {
    breadCrumbData,
    filterDrawerOpened,
    renderFilterComponent,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    setFilterDrawerOpened,
    isFiltered,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoading,
    resetVisible,
    handleClick,
    config,
    users,
    actions,
    selectAllItem,
    selectItem,
    t,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    userId,
    setResetPasswordOpen,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    state,
    companyId,
    filterOptions,
    filter,
    loading,
    setFilterOptions,
    defaultFilter,
    isSearchType,
    resetFilter,
  }
}

export default useUsersLayout
