// @deprecated
/* eslint-disable */

import React, { useState, useEffect, ReactElement } from 'react'
import { WizardContainer } from './styled-components'
import { useLazyQuery } from '@apollo/client'
import { useParams, useNavigate } from 'react-router-dom'
import WizardStepper from './WizardStepper/WizardStepper'
import CompanyDetails from './CompanyDetails/CompanyDetails'
import PurchasedCourses from './PurchasedCourses/PurchasedCourses'
import CompanySuperAdmins from './CompanySuperAdmins/CompanySuperAdmins'
import GroupAdmins from './GroupAdmins/GroupAdmins'
import CompanyGroup from './CompanyGroup/CompanyGroup'
import CompanyStudents from './CompanyStudents/CompanyStudents'
import AssignCoach from './AssignCoach/AssignCoach'
import { GET_USERS } from 'gql/users.query'
import { GET_COMPANY } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { GET_GROUP } from 'gql/group/group.query'
import { useSwal } from 'hooks/useSwal'
import useDeleteCompanyService from 'hooks/companies/useCompanyDeleteService'
import { useTranslation } from 'react-i18next'
import { setSelectedCompanyInLocalStorage } from 'helpers/localStorage'
import { SET_SELECTED_COMPANY } from 'store/types'
import { useUserValue } from 'context/UserContext'

const CompanyWizardLayout = (): ReactElement => {
  const { t } = useTranslation()
  const [step, setStep] = useState<number>(0)
  const [completedSteps, setCompletedSteps] = useState<number[]>([])
  const [state, dispatch] = useUserValue()

  const { fireSwal } = useSwal()
  const { id } = useParams()
  const navigate = useNavigate()
  const [data, setData] = useState<any>({
    companyData: null,
    managers: [],
    selectedCourses: [],
    admins: [],
    groups: [],
    students: [],
  })

  const [fetchAdmins, { data: admins }] = useLazyQuery(GET_USERS)
  const [fetchStudent, { data: students }] = useLazyQuery(GET_USERS)
  const [fetchCompany, { data: companyDetails, loading }] =
    useLazyQuery(GET_COMPANY)
  const [fetchCourses, { data: courses }] = useLazyQuery(GET_ALL_COURSE)
  const [fetchGroups, { data: groups }] = useLazyQuery(GET_GROUP)
  const { deleteCompany } = useDeleteCompanyService()

  useEffect(() => {
    dispatch({
      type: SET_SELECTED_COMPANY,
      payload: null,
    })
    setSelectedCompanyInLocalStorage(null)
    return () => {
      localStorage.removeItem('companyStep')
    }
  }, [])

  const fetchCompanyData = (companyId: string): void => {
    fetchCompany({
      variables: {
        id: companyId,
      },
    })

    fetchAdmins({
      variables: {
        role: 'ADMIN',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        currentPage: 1,
        perPage: 10,
      },
    })

    fetchStudent({
      variables: {
        role: 'STUDENT',
        filter: {
          companyId: {
            type: 'exact',
            value: companyId,
          },
        },
        currentPage: 1,
        perPage: 10,
      },
    })

    fetchCourses({
      variables: {
        filter: {
          isBought: {
            type: 'EXACT',
            value: true,
          },
          companyId: {
            type: 'EXACT',
            value: companyId,
          },
        },
      },
    })

    fetchGroups({
      variables: {
        filter: {
          company: {
            type: 'exact',
            value: companyId,
          },
        },
        currentPage: 1,
        perPage: 0,
      },
    })
  }

  useEffect(() => {
    if (id) {
      fetchCompanyData(id)
    }
  }, [])

  useEffect(() => {
    if (admins && admins.usersByRole) {
      setData((prev: any) => ({
        ...prev,
        admins: admins.usersByRole.data,
      }))
    }
  }, [admins])

  useEffect(() => {
    if (students && students.usersByRole) {
      setData((prev: any) => ({
        ...prev,
        students: students.usersByRole,
      }))
    }
  }, [students])

  useEffect(() => {
    if (companyDetails && companyDetails.company) {
      setData((prev: any) => ({ ...prev, companyData: companyDetails.company }))

      const step = localStorage.getItem('companyStep')
      setStep(step ? 0 : companyDetails.company.finished)
      setCompletedSteps((x: any) => {
        return step
          ? [0]
          : [...Array(companyDetails.company.finished)].map((_, i) => i)
      })
    }
  }, [companyDetails])

  useEffect(() => {
    setCompletedSteps((x: number[]) => {
      const newArr = [...Array(step)].map((_, i) => i)
      if (newArr.indexOf(Math.max(...x)) > -1 || x.length === 0) {
        return [...[...Array(step)].map((_, i) => i)]
      }
      return [...x]
    })
  }, [step])

  useEffect(() => {
    if (courses && courses.getAllCourse) {
      setData((prev: any) => ({
        ...prev,
        selectedCourses: courses.getAllCourse.data.map(
          (i: any) => i.originalId,
        ),
      }))
    }
  }, [courses])

  useEffect(() => {
    if (groups && groups.getAllGroups) {
      setData((prev: any) => ({
        ...prev,
        groups: groups.getAllGroups.data,
      }))
    }
  }, [groups])

  const handleCancelCompany = () => {
    const company: any = { ...data.companyData }
    if (company && company.id) {
      fireSwal({
        title: t('popups.cancel_company_create'),
        onConfirm: () =>
          deleteCompany([company.id], () => navigate('/Companies')),
        confirmText: t('general.yes'),
      })
    } else {
      fireSwal({
        title: t('popups.cancel_company_create'),
        onConfirm: () => navigate('/Companies'),
        confirmText: t('general.yes'),
      })
    }
  }

  const getStepContent = (stepIndex: number): React.JSX.Element | undefined => {
    switch (stepIndex) {
      case 0:
        return (
          <CompanyDetails
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )

      case 1:
        return (
          <CompanySuperAdmins
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 2:
        return (
          <PurchasedCourses
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 3:
        return (
          <GroupAdmins
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 4:
        return (
          <CompanyGroup
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 5:
        return (
          <CompanyStudents
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )
      case 6:
        return (
          <AssignCoach
            changeStep={setStep}
            companyData={data}
            setData={setData}
            onCancel={handleCancelCompany}
            t={t}
          />
        )

      default:
        navigate('/Companies')
        return
    }
  }

  return (
    <>
      <WizardStepper
        step={step}
        setStep={setStep}
        completedSteps={completedSteps}
        t={t}
      />

      <WizardContainer>
        {!loading ? getStepContent(step) : 'Loading...'}
      </WizardContainer>
    </>
  )
}

export default CompanyWizardLayout
