import { useMutation, MutationTuple } from '@apollo/client'
import { EXTRACT_USERS_WITH_GROUPS } from 'gql/group/group.query'
import { IUser } from 'interfaces/users'

interface IExtractUsersWithGroupsResponse {
  extractUsersWithGroups: IUser[]
}

interface IExtractUsersWithGroupsVariables {
  file: File
}

interface IExtractUsersWithGroupsServiceResponse {
  extractUsersWithGroups: MutationTuple<
    IExtractUsersWithGroupsResponse,
    IExtractUsersWithGroupsVariables
  >[0]
}

const extractUsersWithGroupsService =
  (): IExtractUsersWithGroupsServiceResponse => {
    const [extractUsersWithGroups] = useMutation<
      IExtractUsersWithGroupsResponse,
      IExtractUsersWithGroupsVariables
    >(EXTRACT_USERS_WITH_GROUPS)

    return {
      extractUsersWithGroups,
    }
  }

export default extractUsersWithGroupsService
