import { NoValueWrapper } from 'components/common/Grid/styled-components'
import React from 'react'
import { GridItemWrapper, StyledScoreSpan } from './styled.components'
import {
  IGridColumnConfig,
  IGridItem,
  IQTestItem,
  IQuizItem,
} from './courseQuizzes.interface'

export const quizColumnConfig = (
  t: (key: string) => string,
): IGridColumnConfig<IQuizItem>[] => {
  const grid = [
    {
      id: 'module',
      numeric: false,
      disablePadding: true,
      label: `${t('general.module')}`,
      render: (item: IQuizItem) => (
        <GridItemWrapper>
          {item.module ? (
            item.module.name
          ) : (
            <NoValueWrapper>N/A</NoValueWrapper>
          )}
        </GridItemWrapper>
      ),
    },

    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.quiz_name')}`,
      render: (item: IQuizItem) => (
        <GridItemWrapper>
          {item.name || <NoValueWrapper>N/A</NoValueWrapper>}
        </GridItemWrapper>
      ),
    },
    {
      id: 'score',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.quiz_result')}`,
      render: (item: IQuizItem) => (
        <GridItemWrapper>
          {item.score ? (
            <StyledScoreSpan passed={item.passed}>
              {item.score}%
            </StyledScoreSpan>
          ) : (
            <NoValueWrapper>N/A</NoValueWrapper>
          )}
        </GridItemWrapper>
      ),
    },
  ]

  return grid.filter((i: IGridItem) => !i.hide)
}

export const iqTestColumnConfig = (
  t: (key: string) => string,
): IGridColumnConfig<IQTestItem>[] => {
  const grid = [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: true,
      label: `${t('user_tabs.iq_type')}`,
      render: (item: IQTestItem) => (
        <GridItemWrapper>{`${item.IQTestType}`}</GridItemWrapper>
      ),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.iq_name')}`,
      render: (item: IQTestItem) => (
        <GridItemWrapper>{item.name}</GridItemWrapper>
      ),
    },
    {
      id: 'score',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.iq_result')}`,
      render: (item: IQTestItem) => (
        <GridItemWrapper>
          {item.score === null ? (
            <NoValueWrapper>N/A</NoValueWrapper>
          ) : (
            <StyledScoreSpan passed={item.passed}>
              {item.score}%
            </StyledScoreSpan>
          )}
        </GridItemWrapper>
      ),
    },
  ]

  return grid.filter((i: IGridItem) => !i.hide)
}
