import React, { ReactElement } from 'react'
import {
  BadgeField,
  BadgePlaceholder,
  DrawerContent,
  SkillTestDetailsField,
  SkillTestDetailsFieldTitle,
  StyledUploadLabel,
} from './styled-components'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/Close'
import {
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import Uploader from 'components/common/FileUploaderV2'
import CustomSwitcher from 'components/common/Switcher/CustomSwitcher'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Drawer from 'components/common/Drawer'
import { BadgeEditor } from 'pages/modules/BadgeEditor'
import MyLoader from 'loading/loading'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { IModuleProps } from './AddModule.interface'
import DrawerTips from 'components/common/DrawerTipsComonent/DrawerTips'
import useModuleForm from 'hooks/useModuleForm/useModuleForm'

const AddModule = ({ title, data, drawerName }: IModuleProps): ReactElement => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    drawer,
    cropperOpen,
    file,
    imageType,
    generatingBadge,
    imageLoading,
    addModuleLoading,
    handleCropSave,
    handleBadgeEnabled,
    handleImageChange,
    deleteImage,
    handleCloseDrawer,
    handleBadgeEditorClose,
    renderImage,
    disabledButton,
    defaultOverview,
    defaultTips,
    cropperModalToggle,
  } = useModuleForm({ data, drawerName })

  return (
    <>
      {addModuleLoading && <MyLoader width={250} />}
      {generatingBadge && <MyLoader width={250} />}
      <DrawerHeader>
        <DrawerTitle>{title}</DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={68}>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              {data.name && (
                <FormField width="100%" space={30}>
                  <TextInput
                    title={`${t('modules_layout.course_name')}*`}
                    placeholder="Course"
                    size="small"
                    name="course"
                    type="text"
                    value={data.name}
                    disabled
                  />
                </FormField>
              )}
              <FormField width="100%" space={30}>
                <TextInput
                  title={`${t('modules_layout.module_name')}*`}
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  placeholder={t('form_fields.name')}
                  size="small"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                />
              </FormField>

              <FormField width="100%">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Uploader
                    label={
                      <>
                        <StyledUploadLabel>
                          {t('actions.upload_cover_image')}
                        </StyledUploadLabel>
                      </>
                    }
                    accept="image/x-png,image/gif,image/jpeg"
                    onFileChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => handleImageChange(e, 'coverImage')}
                    deleteFile={(): void => deleteImage('coverImage')}
                    inputValue={values.coverImage ? values.coverImage.name : ''}
                    onInputChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFieldValue('coverImage.name', e.target.value)
                    }}
                    type={FileTypes.IMAGE}
                    disabled={!values.coverImage}
                    uploadedFile={values.coverImage}
                    // fileExtension={
                    //   (values.coverImage &&
                    //     `(${values.coverImage.link.split('.').pop()})`) ||
                    //   ''
                    // }
                    loading={imageLoading === 'coverImage'}
                    id="image"
                  />
                  {touched.coverImage && errors.coverImage && (
                    <span style={{ color: '#f44336' }}>
                      <>{errors.coverImage}</>
                    </span>
                  )}
                </div>
              </FormField>

              <FormField width="100%" style={{ textAlign: 'right' }}>
                <CustomSwitcher
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue('skillTestVideoEnabled', e?.target?.checked)
                  }}
                  label={t('general.skill_test_videos')}
                  name="skillTestVideoEnabled"
                  value={values.skillTestVideoEnabled}
                />
              </FormField>

              <FormField width="100%">
                <CustomSwitcher
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setFieldValue(
                      'skillTestVideoCriteriaEnabled',
                      e?.target?.checked,
                    )
                  }}
                  label={t('general.criterias')}
                  name="skillTestVideoCriteriaEnabled"
                  value={values.skillTestVideoCriteriaEnabled}
                />
              </FormField>

              <FormField width="100%" style={{ textAlign: 'right' }}>
                <CustomSwitcher
                  onChange={handleBadgeEnabled}
                  label={t('general.badge_enabled')}
                  name="badgeEnabled"
                  value={values.badgeEnabled}
                />
              </FormField>

              {values.badgeEnabled && (
                <BadgeField>
                  {(values.badgeData?.url && renderImage()) || (
                    <BadgePlaceholder />
                  )}
                </BadgeField>
              )}
              {values.skillTestVideoEnabled && (
                <FormField width="100%" style={{ textAlign: 'right' }}>
                  <CustomSwitcher
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFieldValue('skillTestVideoDetails', e?.target?.checked)
                    }}
                    label={t('general.skill_test_video_texts')}
                    name="skillTestVideoDetails"
                    value={values.skillTestVideoDetails}
                  />
                </FormField>
              )}

              {/* when skillTestVideos Switch is on,show skill test video fields */}
              {values.skillTestVideoEnabled && values.skillTestVideoDetails && (
                <SkillTestDetailsField hidden={!values.skillTestVideoEnabled}>
                  <SkillTestDetailsFieldTitle>
                    {t('general.skill_test_video_overview')}
                  </SkillTestDetailsFieldTitle>
                  <TextareaAutosize
                    title="Skill Test Video Overview"
                    name="skillTestVideoDetails"
                    aria-label="minimum height"
                    minRows={2}
                    value={defaultOverview}
                    readOnly
                    style={{
                      width: '100%',
                      font: '16px Roboto, Helvetica, Arial, sans-serif ',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      padding: '10px',
                    }}
                  />
                </SkillTestDetailsField>
              )}

              {values.skillTestVideoEnabled && values.skillTestVideoDetails && (
                <SkillTestDetailsField hidden={!values.skillTestVideoEnabled}>
                  <SkillTestDetailsFieldTitle>
                    Skill Test Video Tips
                  </SkillTestDetailsFieldTitle>

                  <DrawerTips
                    tips={defaultTips}
                    rememberText="Remember, in the next step you can choose who you wish to send your skill test video for review."
                  />
                </SkillTestDetailsField>
              )}

              <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
                <ImageCropper
                  file={file}
                  hideCropper={cropperModalToggle}
                  getCroppedFile={(_: unknown, file): void =>
                    handleCropSave(imageType, file)
                  }
                />
              </Modal>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                btnType="submit"
                isDisabled={disabledButton}
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
      <Drawer opened={drawer.badgeEditor} totalWidth="80vw">
        <BadgeEditor
          id={drawer?.args?.id?.toString() || ''}
          designId={values?.badgeData?.id}
          closeDrawer={handleBadgeEditorClose}
        />
      </Drawer>
    </>
  )
}

export default AddModule
