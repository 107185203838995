import styled from 'styled-components'

export const StyledWrapper = styled.div`
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

export const StyledInnerWrapper = styled.div`
  max-width: 100%;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 1rem;
  padding: 16px 0;
`

export const StyledResponseText = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 1rem;
  background-color: #fff;
  border-radius: 4px;
  max-width: 1000px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 1rem;
`

export const StyledPlaceholder = styled.div`
  text-align: center;
  color: #aaa;
  font-style: italic;
  margin: 20px 0;

  p {
    margin: 10px 0;
  }
`
