import React, { ReactElement } from 'react'
import {
  Wrapper,
  Container,
  PreviewBox,
  Content,
  PreviewAudioIcon,
  Title,
  ImageBox,
  Answer,
  ActionsContainer,
} from './styled-components'
import strCutter from 'utils/strCutter'

import CheckBox from '@mui/material/Checkbox'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import { TFunction } from 'interfaces/TFunction'
import { IAudio } from '../ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

interface Params {
  backgroundColor: string
  question: string
  image: string
  audio: IAudio
  answer: string
  t: TFunction
}

const Preview = ({
  backgroundColor,
  question,
  image,
  audio,
  answer,
  t,
}: Params): ReactElement => {
  const audioColor: 'primary' | 'disabled' = audio ? 'primary' : 'disabled'
  return (
    <Container>
      <PreviewBox background={backgroundColor}>
        <Wrapper>
          <CheckBox disabled />
          <ActionsContainer>
            <PreviewAudioIcon>
              <VolumeUpIcon color={audioColor} />
            </PreviewAudioIcon>
            <MoreVertRoundedIcon color="disabled" />
          </ActionsContainer>
        </Wrapper>
        <Content>
          <Title>{strCutter(question, 50) || t('general.question')}</Title>
          <ImageBox className={`${image ? '' : 'noImage'}`}>
            {image ? <img src={image} /> : 'Cover Image'}
          </ImageBox>
          <Answer>
            {strCutter(answer, 120) || t('question_details.answer')}
          </Answer>
        </Content>
      </PreviewBox>
    </Container>
  )
}

export default Preview
