import styled from 'styled-components'

export const Action = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  opacity: ${({ disabled }: { disabled?: boolean }): string =>
    disabled ? '0.5' : '1'};
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const CompanyCoursesWrapper = styled.div`
  padding-top: 20px;

  .responsive-cards {
    @media (max-width: 1630px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

export const StatusCircle = styled.div`
  margin-right: 5px;
  margin-top: -3px;
  border-radius: 50%;
  width: 12px;
  height: 12px;
`

export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const GroupItem = styled.div`
  position: relative;
  &:hover > span {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const GroupTitle = styled.span`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;

  &.active {
    color: green;

    div {
      background: green;
    }
  }

  &.suspended {
    color: red;

    div {
      background: red;
    }
  }

  &.pending {
    color: rgb(207, 207, 9);

    div {
      background: rgb(207, 207, 9);
    }
  }

  &.draft {
    color: rgba(0, 0, 0, 0.5);

    div {
      background: rgba(0, 0, 0, 0.5);
    }
  }
`
