import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/material/styles'
import DateRangePicker from '@wojtekmaj/react-datetimerange-picker'
import { Placeholder, Wrapper } from './styled-components'

const PREFIX = 'DateRangePickerWithInput'

const classes = {
  calendar: `${PREFIX}-calendar`,
}

const StyledWrapper = styled(Wrapper)<{
  hasError?: boolean
}>(({ hasError }) => ({
  [`& .${classes.calendar}`]: {
    height: 40,
    zIndex: 2,
    width: '100%',
    '&> div': {
      border: !!hasError
        ? ' 1px solid #f44336 !important'
        : ' 1px solid rgba(0, 0, 0, 0.23) !important',
      borderRadius: 3,
      textAlign: 'center',
    },
  },
}))

const DateRangePickerWithInput: React.FC<{
  values: [Date | undefined, Date | undefined]
  placeholder?: string
  setCreateDate: Dispatch<
    SetStateAction<[Date | undefined, Date | undefined]>
  > | void
}> = ({ values, placeholder, setCreateDate }) => {
  return (
    <StyledWrapper>
      <Placeholder>{placeholder}</Placeholder>
      <DateRangePicker
        key={0}
        onChange={setCreateDate as () => void}
        value={values}
        format={'dd-MM-y'}
        className={[classes.calendar]}
        monthPlaceholder={'MM'}
        dayPlaceholder={'DD'}
        yearPlaceholder={'YYYY'}
        showLeadingZeros={false}
      />
    </StyledWrapper>
  )
}

export default DateRangePickerWithInput
