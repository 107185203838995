import React, { useState, useRef, useEffect } from 'react'
import _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { filterOption } from 'pages/modules/staticData'
import {
  FilterDrawerProps,
  IFilterData,
  QueryType,
} from 'pages/modules/FilterDrawer/filterDrawer.interface'
import {
  IUseFilterDrawerReturnType,
  UseFilerDrawerHandleFieldChangeReturnType,
} from './useFilterDrawer.interface'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'

const DEFAULT_RANGE = [0, 500]

const useFilterDrawer = (
  props: FilterDrawerProps,
): IUseFilterDrawerReturnType => {
  const ref = useRef<NodeJS.Timeout | null>(null)
  const [range, setRange] = useState(DEFAULT_RANGE)
  const [filterData, setFilterData] = useState<IFilterData>(filterOption)

  const [loadData, { loading, data }] = useLazyQuery(GET_ALL_COURSE)

  useEffect(() => {
    if (_.isEmpty(props.filterData)) return
    setFilterData(props.filterData as IFilterData)
    const { range } = props.filterData
    if (range) setRange(range as number[])
  }, [])

  const handleFieldChange = (
    field: string,
  ): UseFilerDrawerHandleFieldChangeReturnType => {
    return (e) => {
      setFilterData({
        ...filterData,
        [field]: e as ISelectDropDownOption[],
      })
    }
  }
  const loadDataOptions = (e: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      loadData({
        variables: {
          filter: {
            name: {
              type: UpperCaseFilterableFieldType.MATCH,
              value,
            },
            originalId: {
              type: UpperCaseFilterableFieldType.ARRAY_IN,
              value: [null],
            },
          },
        },
      })
    }, 300)

  const handleSelectChange = (e: string): void => {
    if (ref.current) {
      clearTimeout(ref.current as NodeJS.Timeout)
    }
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }

  // Update filterData with the new range value
  useEffect(() => {
    props.setFilterData((prevData) => ({
      ...prevData,
      range,
    }))
  }, [range])

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (
      JSON.stringify(filterOption) === JSON.stringify(filterData) &&
      JSON.stringify(range) === JSON.stringify(DEFAULT_RANGE) &&
      _.isEmpty(props.filterData)
    )
      return props.onClose()

    const query: QueryType = {}

    const { courses, ...args } = filterData

    for (const key in args) {
      const arg = args[key]
      if ((arg as string) === 'range') continue
      if (
        arg &&
        typeof arg === 'object' &&
        'value' in arg &&
        arg.value !== 'all'
      ) {
        query[key] = arg.value === 'no' ? null : { $ne: null }
      }
    }

    if (range[0] > 0 || range[1] < 500) {
      query.duration = { $gte: range[0] * 60, $lte: range[1] * 60 }
    }

    if (courses && Array.isArray(courses) && courses.length > 0) {
      query['courses.courseId'] = {
        $in: (courses as ISelectDropDownOption[]).map((i) => i.value),
      }
    }

    // this line has some glitch in the code
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.setFilterQuery(query as any)
    props.refetchData(
      props.companyId
        ? {
            filter: {
              query: {
                type: LowerCaseFilterableFieldType.QUERY,
                value: JSON.stringify(query),
              },
              companyId: {
                type: LowerCaseFilterableFieldType.EXACT,
                value: props.companyId,
              },
            },
            currentPage: 1,
            perPage: props.perPage,
          }
        : {
            filter: {
              query: {
                type: LowerCaseFilterableFieldType.QUERY,
                value: JSON.stringify(query),
              },
            },
            currentPage: 1,
            perPage: props.perPage,
          },
    )

    filterData.range = range

    props.setFilterData(filterData)
    if (props.setFiltered) props.setFiltered(true)
    props.onClose()
  }

  const { t } = props

  return {
    onFormSubmit,
    handleSelectChange,
    handleFieldChange,
    data,
    filterData,
    loading,
    t,
    range,
    setRange,
  }
}

export default useFilterDrawer
