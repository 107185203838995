import { useMutation } from '@apollo/client'
import { ADD_BOOK, GET_BOOKS_BY_COMPANY_ID } from 'gql/books/books.query'
import {
  IAddBookResponse,
  IBookDto,
  IFetchBooksByCompanyIdProps,
} from './bookServices.interface'
import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { SnackbarVariants } from 'interfaces/common'

export const useAddBookService = ({
  companyId,
}: IFetchBooksByCompanyIdProps): IAddBookResponse => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  // mutation initialization
  const [addBook, { data, error, loading }] = useMutation(ADD_BOOK, {
    refetchQueries: [
      {
        query: GET_BOOKS_BY_COMPANY_ID,
        variables: {
          companyId,
          searchValue: '',
          currentPage: 1,
          perPage: 12,
        },
      },
    ],
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  // mutationUsage
  const handleAddBook = async (createBookDto: IBookDto): Promise<void> => {
    try {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      const { errors } = await addBook({ variables: { createBookDto } })
      if (errors) {
        message = errors[0].message
        variant = SnackbarVariants.ERROR
      } else {
        message = t('books_layout.book_added')
        variant = SnackbarVariants.SUCCESS
      }

      setSnackbar({ message, variant })
    } catch (error) {
      console.log(SnackbarVariants.ERROR, error)
    }
  }
  return {
    addBook: (createBookDto: IBookDto) => handleAddBook(createBookDto),
    books: (data && data.createBook) || [],
    loading,
    error: errorMessage,
  }
}
