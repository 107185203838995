import {
  ApolloQueryResult,
  FetchMoreQueryOptions,
  useQuery,
} from '@apollo/client'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

import { GET_COURSES_OPTIONS } from 'gql/course/course.query'

interface IUseCoursesOptionsServiceResponse {
  courses?: IGetAllCourseOptionsServiceResponse
  loading: boolean
  error: string
  refetch: () => void
  fetchMore: (
    options: FetchMoreQueryOptions<
      IGetCoursesOptionsVariables,
      IGetCourseOptionsResponse
    >,
  ) => Promise<ApolloQueryResult<IGetCourseOptionsResponse>>
}

interface ICourseOption {
  id: string
  name: string
  originalId: string
}

interface IGetAllCourseOptionsServiceResponse {
  currentPage: number
  totalPages: number
  data: ICourseOption[]
}

interface IGetCourseOptionsResponse {
  getAllCourseOptions: IGetAllCourseOptionsServiceResponse
}

interface IGetCoursesOptionsVariables {
  filter: IFilterQueryType
  currentPage: number
  perPage: number
}

export const useFetchCoursesOptions = (
  filter: IFilterQueryType,
  currentPage = 1,
  perPage: number = 0,
): IUseCoursesOptionsServiceResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery<
    IGetCourseOptionsResponse,
    IGetCoursesOptionsVariables
  >(GET_COURSES_OPTIONS, {
    variables: { filter, currentPage, perPage },
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    courses: data && data.getAllCourseOptions,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}
