import { ApolloError, useQuery } from '@apollo/client'
import { GET_ASSISTANT_FILES } from 'gql/assistant/file/file.query'

export interface IFile {
  _id: string
  id: string
  description: string
  contentDocument: {
    name: string
    fileType: string
    size: string
    link: string
    thumbnail: string
  }
}

interface AssistantFilesResponse {
  files: IFile[]
  loading: boolean
  refetch: () => void
  error?: ApolloError
}

export const getAssistantFiles = (
  assistantId: string,
): AssistantFilesResponse => {
  const {
    data: courseData,
    loading,
    refetch,
    error,
  } = useQuery(GET_ASSISTANT_FILES, {
    variables: { assistantId },
  })

  return {
    files: courseData?.getAssistantFiles || [],
    loading,
    refetch,
    error,
  }
}
