import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import {
  EDIT_COURSE,
  COURSE_PUBLISHED_UN_PUBLISHED,
  DELETE_COURSE_MODULE,
  CHANGE_COURSE_STEP,
  TOGGLE_AI_ASSISTANT,
  RE_TRAIN_AI_ASSISTANT,
} from 'gql/course/course.query'

import { ADD_COURSE_MODULE, GET_MODULES } from 'gql/modules.query'
import { GET_QUIZ_BY_TYPE } from 'gql/quiz/quiz.query'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { ICourse, ICourseBriefData } from 'pages/courses/courseList.interface'
import { IModule } from 'pages/modules/ModulesList.interface'
import { INewCourse } from 'components/courseDetail/interfaces'
import { IUseAddCourseModulesParams } from 'hooks/course/useEditCourse'

interface useEditCourseParams {
  courseId: string
}

interface ICoursePublishedServiceResponse {
  coursePublishedMutation: MutationTuple<
    ICoursePublishedResponse,
    ICoursePublishedVariables
  >[0]
}

interface ICoursePublishedResponse {
  coursePublished: ICourse
}

interface ICoursePublishedVariables {
  courseId: string[]
  action: string
}

export const coursePublishedService = (): ICoursePublishedServiceResponse => {
  const [coursePublishedMutation] = useMutation<
    ICoursePublishedResponse,
    ICoursePublishedVariables
  >(COURSE_PUBLISHED_UN_PUBLISHED)
  return {
    coursePublishedMutation,
  }
}

interface IEditCourseServiceResponse {
  editCourseMutation: MutationTuple<
    IEditCourseResponse,
    IEditCourseVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IEditCourseResponse {
  editCourse: ICourse
}

interface IEditCourseVariables {
  id: string
  input: INewCourse
}

export const editCourseService = (
  params: useEditCourseParams,
): IEditCourseServiceResponse => {
  const [editCourseMutation, { loading, error }] = useMutation<
    IEditCourseResponse,
    IEditCourseVariables
  >(EDIT_COURSE, {
    refetchQueries: [
      {
        query: GET_QUIZ_BY_TYPE,
        variables: {
          actionId: params.courseId,
          type: 'course',
          currentPage: 1,
          perPage: 12,
          filter: {
            IQTestType: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: 'initial',
            },
          },
        },
      },
    ],
  })
  return {
    editCourseMutation,
    loading,
    error,
  }
}

interface IAddCourseModulesServiceResponse {
  addCourseModulesMutation: MutationTuple<
    IAddCourseModulesResponse,
    IAddCourseModulesVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IAddCourseModulesResponse {
  addModulesToCourse: IModule
}

interface IAddCourseModulesVariables {
  courseData: ICourseBriefData
  moduleIds: string[]
}

export const addCourseModulesService = (
  params: IUseAddCourseModulesParams,
): IAddCourseModulesServiceResponse => {
  const [addCourseModulesMutation, { loading, error }] = useMutation<
    IAddCourseModulesResponse,
    IAddCourseModulesVariables
  >(ADD_COURSE_MODULE, {
    update(cache, { data }) {
      if (data?.addModulesToCourse) {
        const document = data.addModulesToCourse
        updateCache({
          objectName: 'allModules',
          query: GET_MODULES,
          cache,
          document,
          action: 'addMany',
          params,
        })
      }
    },
  })

  return {
    addCourseModulesMutation,
    loading,
    error,
  }
}

interface IDeleteCourseModuleServiceResponse {
  deleteCourseModuleMutation: MutationTuple<
    IDeleteCourseModuleResponse,
    IDeleteCourseModuleVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteCourseModuleResponse {
  deleteModuleInCourse: IModule
}

interface IDeleteCourseModuleVariables {
  courseId: string
  moduleIds: string[]
}

export const deleteCourseModuleService = (
  params: IUseAddCourseModulesParams,
): IDeleteCourseModuleServiceResponse => {
  const [deleteCourseModuleMutation, { loading, error }] = useMutation<
    IDeleteCourseModuleResponse,
    IDeleteCourseModuleVariables
  >(DELETE_COURSE_MODULE, {
    update(cache, { data }) {
      if (data?.deleteModuleInCourse) {
        const document = data.deleteModuleInCourse
        updateCache({
          objectName: 'allModules',
          query: GET_MODULES,
          cache,
          document,
          action: 'deleteMany',
          params,
        })
      }
    },
  })

  return {
    deleteCourseModuleMutation,
    loading,
    error,
  }
}

interface IChangeCourseStepServiceResponse {
  changeCourseStepMutation: MutationTuple<
    IChangeCourseStepResponse,
    IChangeCourseStepVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IChangeCourseStepResponse {
  changeCourseStep: ICourse
}

interface IChangeCourseStepVariables {
  courseId: string
  step: number
}

export const changeCourseStepService = (): IChangeCourseStepServiceResponse => {
  const [changeCourseStepMutation, { loading, error }] = useMutation<
    IChangeCourseStepResponse,
    IChangeCourseStepVariables
  >(CHANGE_COURSE_STEP)
  return {
    changeCourseStepMutation,
    loading,
    error,
  }
}

interface IToggleAIAssistantServiceResponse {
  toggleAIAssistantMutation: MutationTuple<
    IToggleAIAssistantResponse,
    IToggleAIAssistantVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IToggleAIAssistantResponse {
  toggleAIAssistant: string
}

interface IToggleAIAssistantVariables {
  courseId: string
  isAIAssistantEnabled: boolean
}

export const toggleAIAssistantService =
  (): IToggleAIAssistantServiceResponse => {
    const [toggleAIAssistantMutation, { loading, error }] = useMutation<
      IToggleAIAssistantResponse,
      IToggleAIAssistantVariables
    >(TOGGLE_AI_ASSISTANT)
    return {
      toggleAIAssistantMutation,
      loading,
      error,
    }
  }

interface IReTrainAIAssistantServiceResponse {
  reTrainAIAssistantMutation: MutationTuple<
    IReTrainAIAssistantResponse,
    IReTrainAIAssistantVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IReTrainAIAssistantResponse {
  reTrainAIAssistant: boolean
}

interface IReTrainAIAssistantVariables {
  assistantId: string
}

export const reTrainAIAssistantService =
  (): IReTrainAIAssistantServiceResponse => {
    const [reTrainAIAssistantMutation, { loading, error }] = useMutation<
      IReTrainAIAssistantResponse,
      IReTrainAIAssistantVariables
    >(RE_TRAIN_AI_ASSISTANT)
    return {
      reTrainAIAssistantMutation,
      loading,
      error,
    }
  }
