import { gql } from 'graphql.macro'

export const GET_ASSISTANT_MESSAGES = gql`
  query GetAssistantMessages(
    $threadId: String!
    $filter: IAssistantMessagesFilter
  ) {
    getAssistantMessages(threadId: $threadId, filter: $filter) {
      _id
      role
      content
      createdAt
    }
  }
`
