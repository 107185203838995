import React, { ReactElement } from 'react'
import {
  Container,
  Header,
  FormFooter,
  FormButtons,
  StyledFlex,
  InputWrapper,
  GridHeader,
  QuestionsContainer,
} from './styled-components'
import IconButton from '@mui/material/IconButton'
import FormControlLabel from '@mui/material/FormControlLabel'

import {
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { FormGroupWrapper } from 'components/common/Form/Form'
import { Button } from 'components/common/Button'
import Pagination from 'components/common/Pagination'
import useLinkQuestionsDrawerToTestServices from 'hooks/tests/TestDetails/QuestionsLayout/useLinkQuestionsDrawerToTestServices'
import QuestionItem from '../QuestionsItem/QuestionItem'
import { NoDataDiv } from '../../addGroupDrawer/toolbar/styled-components'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CheckBox from 'components/common/Checkbox'
import TextInput from 'components/common/TextInput/TextInput'
import ReactSelect from 'react-select'
import { QuestionTypes } from 'pages/questions/staticData'
import { DrawerHeader } from 'pages/tests/addTestDrawer/styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { Typography } from '@mui/material'
import { IQuestion } from 'interfaces/questions'
import MyLoader from 'loading/loading'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'

interface Params {
  testAssessmentId: string
  closeDrawer: () => void
  handleCloseDrawer: () => void
  questionsToNotInclude?: string[]
  manualCompanyId?: string
}

const LinkQuestionsToTestAssessmentDrawer = ({
  testAssessmentId,
  closeDrawer,
  handleCloseDrawer,
  questionsToNotInclude,
  manualCompanyId,
}: Params): ReactElement => {
  const {
    t,
    data,
    questionsLoading,
    selectItem,
    selectAllItem,
    isAllSelected,
    selectedItems,
    perPage,
    handleSearchSubmit,
    handleSearchChange,
    handleSubmit,
    filterData,
    handlePaginationClick,
    handlePerPageChange,
    tagList,
    handleTypeChange,
    handleTagChange,
    searchValue,
  } = useLinkQuestionsDrawerToTestServices({
    manualCompanyId,
    testAssessmentId,
    questionsToNotInclude,
    handleCloseDrawer,
  })

  const questionList = (data && data.getQuestions.data) || []

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('tests_layout.link_questions_to_test')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <Container>
          <FormGroupWrapper>
            <Header>
              <DrawerTitle>{t('actions.filters')}</DrawerTitle>
              <StyledFlex>
                <InputWrapper>
                  <ReactSelect
                    name="type"
                    onChange={handleTypeChange}
                    options={QuestionTypes}
                    value={filterData.type as ISelectDropDownOption}
                    placeholder={t('form_fields.select_type')}
                    isClearable
                  />
                </InputWrapper>
                <InputWrapper>
                  <form onSubmit={handleSearchSubmit}>
                    <TextInput
                      label="Search"
                      type="text"
                      size="small"
                      value={searchValue}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type="submit">
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </InputWrapper>
              </StyledFlex>
              <ReactSelect
                name="tags"
                onChange={handleTagChange}
                options={tagList}
                value={filterData.tags as ISelectDropDownOption[]}
                isMulti
                placeholder={t('form_fields.select_tags')}
              />
            </Header>
            <GridHeader>
              <Typography fontSize={18} fontWeight={600}>
                {t('general.questions')}
              </Typography>
              <FormControlLabel
                control={
                  <CheckBox
                    checked={isAllSelected}
                    onChange={selectAllItem}
                    color="primary"
                  />
                }
                label={
                  <Typography fontSize={16} fontWeight={600}>
                    {t('general.select_all')}
                  </Typography>
                }
                labelPlacement="start"
              />
            </GridHeader>
            <QuestionsContainer>
              {questionList?.length ? (
                questionList.map((question: IQuestion) => (
                  <QuestionItem
                    key={question.id}
                    isChecked={selectedItems.includes(question.id)}
                    item={question}
                    selectItem={selectItem}
                  />
                ))
              ) : questionsLoading ? (
                <MyLoader width={250} />
              ) : (
                <NoDataDiv>
                  <p>{t('general.no_data')}</p>
                </NoDataDiv>
              )}
            </QuestionsContainer>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.add')}
                type="small"
                background="#06C68F"
                onClick={handleSubmit}
                isDisabled={!selectedItems.length}
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
            <Pagination
              currentPage={data ? data.getQuestions.currentPage : 1}
              totalPages={data ? data.getQuestions.totalPages : 0}
              handleChange={handlePaginationClick}
              perPage={perPage}
              handlePerPageChange={handlePerPageChange}
            />
          </FormFooter>
        </Container>
      </DrawerContent>
    </>
  )
}

export default LinkQuestionsToTestAssessmentDrawer
