import { useCompanyOptionsService } from 'services/companies/getCompanyOptions'
import { ICompanyOption } from 'services/companies/companyServices.interface'
import { useUserValue } from 'context/UserContext'
import { SET_SELECTED_COMPANY } from 'store/types'
import { setSelectedCompanyInLocalStorage } from 'helpers/localStorage'
import { TFunction, useTranslation } from 'react-i18next'
import { useState, MouseEvent, useEffect } from 'react'

const useCompanySwitcher = (): {
  t: TFunction<'translation', undefined>
  selectedCompany: ICompanyOption | null
  anchorEl: Element | null
  selected: boolean
  open: boolean
  searchValue: string
  filteredCompanies: ICompanyOption[]
  handleClick: (event: MouseEvent<HTMLSpanElement>) => void
  handleClose: () => void
  handleRemoveCompanyState: (e: MouseEvent<HTMLParagraphElement>) => void
  handleCompanySelect: (company: ICompanyOption) => void
  setSearchValue: (value: string) => void
} => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [selectedCompany, setSelectedCompany] = useState<ICompanyOption | null>(
    null,
  )
  const [state, dispatch] = useUserValue()
  const [searchValue, setSearchValue] = useState<string>('')

  const handleClick = (event: MouseEvent<HTMLSpanElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (state) {
      setSelectedCompany(state.selectedCompany || null)
    }
  }, [state])

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleRemoveCompanyState = (
    e: MouseEvent<HTMLParagraphElement>,
  ): void => {
    e.stopPropagation()
    dispatch({
      type: SET_SELECTED_COMPANY,
      payload: null,
    })
    setSelectedCompanyInLocalStorage(null)
    setSelectedCompany(null)
    handleClose()
  }

  const handleCompanySelect = (company: ICompanyOption): void => {
    dispatch({
      type: SET_SELECTED_COMPANY,
      payload: {
        id: company.id,
        name: company.name,
      },
    })
    setSelectedCompanyInLocalStorage({
      id: company.id,
      name: company.name,
    })
    setSelectedCompany(company)
    handleClose()
    setSearchValue('')
  }

  const open = Boolean(anchorEl)
  const selected = Boolean(selectedCompany)
  const { data: companies } = useCompanyOptionsService()

  const filteredCompanies =
    companies?.filter((company) =>
      company.name.toLowerCase().includes(searchValue.toLowerCase()),
    ) || []
  return {
    t,
    selectedCompany,
    anchorEl,
    selected,
    open,
    searchValue,
    filteredCompanies,
    handleClick,
    handleClose,
    handleRemoveCompanyState,
    handleCompanySelect,
    setSearchValue,
  }
}

export default useCompanySwitcher
