import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { SEND_PUSH_NOTIFICATIONS } from 'gql/group/group.query'
import { IMessageResponse } from 'interfaces/common'

interface ISendPushNotificationsResponse {
  sendPushNotifications: IMessageResponse
}

interface ISendPushNotificationsVariables {
  recipientIds: string[]
  messageData: ICustomMessageData
}

export interface ICustomMessageData {
  heading: string
  body: string
}

interface ISendPushNotificationsServiceResponse {
  sendPushNotificationsMutation: MutationTuple<
    ISendPushNotificationsResponse,
    ISendPushNotificationsVariables
  >[0]
  error?: ApolloError
  data?: ISendPushNotificationsResponse | null
  loading: boolean
}

const sendPushNotificationsService =
  (): ISendPushNotificationsServiceResponse => {
    const [sendPushNotificationsMutation, { error, data, loading }] =
      useMutation<
        ISendPushNotificationsResponse,
        ISendPushNotificationsVariables
      >(SEND_PUSH_NOTIFICATIONS)

    return {
      sendPushNotificationsMutation,
      error,
      data,
      loading,
    }
  }

export default sendPushNotificationsService
