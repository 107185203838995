import React, { ReactElement } from 'react'
import {
  RadioButtonContainer,
  TextContainer,
  TutorAvatar,
  TutorItemWrapper,
} from './styled-component'
import AvatarIcon from 'assets/profile.svg'
import { IUser } from 'interfaces/users'
import { RadioButton } from 'components/common'

const TutorItem = ({
  selectedTutor,
  selectTutor,
  tutor,
}: {
  selectedTutor?: string
  selectTutor: (id?: string) => void
  tutor: IUser
}): ReactElement => {
  const active = selectedTutor === tutor.id
  return (
    <TutorItemWrapper
      active={active}
      onClick={(): void => selectTutor(tutor.id)}
    >
      <TutorAvatar src={tutor.avatarThumbnail || tutor.avatar || AvatarIcon} />
      <TextContainer>
        <p>{`${tutor.firstName} ${tutor.lastName}`}</p>
        <span>{tutor.email}</span>
      </TextContainer>
      <RadioButtonContainer>
        <RadioButton value={tutor.id} checked={active} />
      </RadioButtonContainer>
    </TutorItemWrapper>
  )
}

export default TutorItem
