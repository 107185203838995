import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { ADD_USER } from 'gql/users.query'
import { IUser } from 'interfaces/users'

interface IAddUserServiceResponse {
  addUserMutation: MutationTuple<IAddUserResponse, IAddUserParams>[0]
  loading: boolean
  error?: ApolloError
}

export interface IAddUserParams {
  input: IUser
  companyId?: string
}

export interface IAddUserResponse {
  addUser: IUser
}

const addUserService = (): IAddUserServiceResponse => {
  const [addUserMutation, { loading, error }] = useMutation<
    IAddUserResponse,
    IAddUserParams
  >(ADD_USER)

  return {
    addUserMutation,
    loading,
    error,
  }
}

export default addUserService
