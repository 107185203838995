import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import { GET_MODULE } from 'gql/modules.query'
import { ADD_TOPIC } from 'gql/topics.query'
import { ILessonOrderServiceProps } from './editTopicService'
import { IModule, ITopic } from 'pages/modules/ModulesList.interface'

interface IAddTopicServiceResponse {
  addTopicMutation: MutationTuple<IAddTopicResponse, IAddTopicParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IAddTopicResponse {
  addTopic: IModule
}

interface IAddTopicParams {
  moduleId: string
  input: ITopic
}

const addTopicService = (
  params: ILessonOrderServiceProps,
): IAddTopicServiceResponse => {
  const [addTopicMutation, { loading, error }] = useMutation<
    IAddTopicResponse,
    IAddTopicParams
  >(ADD_TOPIC, {
    update(cache, { data }) {
      if (data?.addTopic) {
        const document = data.addTopic
        updateCache({
          objectName: 'getModule',
          query: GET_MODULE,
          cache,
          document,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    addTopicMutation,
    loading,
    error,
  }
}

export default addTopicService
