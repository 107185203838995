import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  .DraftEditor-root {
    // border: 1px solid transparent;
    padding: 10px;
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    transition: border 0.2s ease;
  }

  // &.show .DraftEditor-root {
  //   border: 1px solid rgba(0, 0, 0, 0.2);
  // }

  .anchor_input {
    padding: 10px;
    outline: none;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);

    &--invalid {
      border: 1px solid red;
    }
  }

  & a {
    color: #5e93c5 !important;
  }
`

export const ContentContainer = styled.div<{
  $contentHeight?: string
}>`
  position: relative;
  .DraftEditor-root {
    height: ${({ $contentHeight }): string => $contentHeight || 'auto'};
  }
  cursor: text;
`

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  position: relative;
  margin-bottom: 10px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    transform: translateY(-50%);
    width: 144px;
    height: 2px;
    border-radius: 20px;
    background-color: rgba(2, 191, 131, 0.5);
  }
  padding: 5px 10px;
`

export const EditorContainer = styled.div<{
  $hasControls?: boolean
}>`
  &.show {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  ul,
  ul li {
    list-style-type: disc !important;
    padding-left: 40px;
  }
  ol,
  ol li {
    list-style-type: decimal !important;
    padding-left: 40px;
  }

  blockquote {
    margin: 0;
    padding: 10px 20px;
    background: #f9f9f9;
  }
`

export const EditorActions = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  > div:not(:last-child) {
    margin-right: 12px;
  }
`

export const EditorActionsEditButton = styled.div`
  position: relative;

  &:hover > div {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`

export const EditorActionsEditText = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: 2;
  text-align: center;
  padding: 5px 8px;
  bottom: -35px;
  left: 50%;
  color: #333;
  font-size: 12px;
  transform: translateX(-50%) scale(0);
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0.5px 12px rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s ease, transform 0.2s ease;
`
export const EditorEditActions = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  > div:not(:last-child) {
    margin-right: 6px;
  }

  &.show {
    opacity: 1;
    pointer-events: auto;
  }
`

export const ScrollView = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 10%,
    rgb(250, 250, 250)
  );
  color: #333;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  p {
    margin: 0 0 5px 0 !important;
  }
`

export const ToolbarControls = styled.div`
  // border-top: 1px solid rgba(0, 0, 0, 0.2);
  // border-right: 1px solid rgba(0, 0, 0, 0.2);
  // border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  padding: 10px;
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 10px;
  }

  button {
    cursor: pointer;
    display: inline-block;
    background: transparent;
    border: 1px solid #dedede;
    border-radius: 5px;
    font-style: normal;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.2s ease, border 0.2s ease;

    &:hover {
      background: #f4f4f4;
    }

    &.draftJsToolbar__active__3qcpF {
      background: #f4f4f4;
    }
  }
`
export const DefaultText = styled.div`
  padding-left: 10px;
  opacity: 0.5;
  font-style: italic;
  margin: 0px;
  font-size: 16px;
`
