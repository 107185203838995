import Switch from '@mui/material/Switch'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import BusinessIcon from '@mui/icons-material/Business'
import EmailIcon from '@mui/icons-material/Email'
import EventIcon from '@mui/icons-material/Event'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PublicIcon from '@mui/icons-material/Public'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import { Button } from 'components/common/Button'
import { EditableAvatar } from 'components/common/EditableAvatar/EditableAvatar'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { useUserValue } from 'context/UserContext'
import 'cropperjs/dist/cropper.css'
import { DrawerEventEmitter } from 'helpers/drawer'
import useDeleteCompanyService from 'hooks/companies/useCompanyDeleteService'
import { useUpdateCompanyAvatar } from 'hooks/companies/useEditCompanyAvatarService'
import useCompaniesService from 'hooks/useCompaniesService'
import { useSwal } from 'hooks/useSwal'
import moment from 'moment'
import { actionConfig } from 'pages/companies/gridConfig'
import React, { RefObject } from 'react'
import { useNavigate } from 'react-router'
import {
  StyledWrapper,
  StyledButtonContainer,
  SetupsContainer,
  StyledActionsContainer,
} from './styled-components'
import strCutter from 'utils/strCutter'
import {
  CompanyContent,
  Content,
  CompanyTitle,
  Container,
  ContainerContent,
  ContainerHeader,
  ContenGrid,
  ContenGridItem,
  ContinueBtn,
  Header,
  NoValueWrapper,
  PlatformServiceSwitcher,
  PlatformServiceTitle,
  Wrapper,
  WhiteBg,
} from './styled-components'
import { ICompanyInformationParams } from './types'
import DomainIcon from './icons/domain.svg'
import { ReactCropperElement } from 'react-cropper'

const CompanyInformation = ({
  company,
  t,
}: ICompanyInformationParams): JSX.Element => {
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  if (!company)
    return (
      <>
        {' '}
        {fireSwal({
          text: 'Something went wrong',
          width: '40%',
        })}
      </>
    )

  const [state] = useUserValue()
  const { changeCompanyPlatformService } = useCompaniesService()

  const { deleteCompany } = useDeleteCompanyService()

  const handleChange = (): void => {
    fireSwal({
      text: 'When Platform Service is enabled Company can create their own Courses and other learning materials. When Platform Service is disabled Company can only use Purchased Courses and can not create their own learning materials.',
      title: 'Change Status?',
      confirmText: 'Change Status',
      width: '40%',
      onConfirm: () => {
        fireSwal({
          title: `${
            company.platformService ? 'Disable' : 'Enable'
          } Platform Service Status`,
          text: `To  ${
            company.platformService ? 'Disable' : 'Enable'
          } Platform Service write down the Company Name and then click “Change”`,
          input: 'text',
          inputConfirmValue: company.name,
          inputPlaceholder: 'Company Name...',
          confirmText: 'Change',
          inputValidationMessage: 'Company Name is not Correct',
          onConfirm: () =>
            changeCompanyPlatformService(company.id, !company.platformService),
        })
      },
    })
  }

  const confirmDelete = async (ids: string[]): Promise<void> => {
    deleteCompany(ids, () => navigate('/companies'))
  }

  const handleEditClick = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'companiesEdit', true, {
      id: company.id,
      data: () => undefined,
    })
  }

  const handleDeleteClick = (): void => {
    const params = {
      title: `${t('popups.are_you_sure')} ${t('actions.delete')} ${t(
        'popups.this',
      )} ${t('general.company')}?`,
      onConfirm: (): void => {
        confirmDelete([company.id])
      },
      confirmText: 'Yes, delete!',
    }
    fireSwal(params)
  }

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    state.userPermission,
    t,
  )

  const { updateAvatar, hasResponseReceived } = useUpdateCompanyAvatar()

  const finishCrop = (
    action: string,
    crudFunction: (isFinished: boolean) => void,
    cropper: RefObject<ReactCropperElement>,
  ): void => {
    let cropdFile = null
    if (action === 'update') {
      action = 'ADD'
      cropdFile = cropper?.current?.cropper?.getCroppedCanvas().toDataURL()
    } else if (action === 'delete') {
      action = 'DELETE'
    }
    updateAvatar(company.id, action, cropdFile, crudFunction)
  }

  const specialCompanyId = '6000146e583c862f2b822f74'

  return (
    <>
      <Header color={company.id === specialCompanyId ? '#06c68f' : '#343a40'}>
        <Wrapper justify="between">
          <Wrapper>
            <EditableAvatar
              img={company.avatar || DomainIcon}
              finishCrop={finishCrop}
              isRectangular={true}
              hasResponseReceived={hasResponseReceived}
              isForEverySize={true}
            />
            <CompanyContent>
              <CompanyTitle>{strCutter(company.name, 35)} </CompanyTitle>
              <Wrapper align="center">
                <SettingsApplicationsIcon style={{ color: '#06C68F' }} />
                <PlatformServiceTitle>
                  {t('companies_layout.platform_service')}
                  {state.currentUser.companyId && (
                    <span
                      style={{
                        color: company.platformService ? 'green' : 'red',
                        marginLeft: '7px',
                      }}
                    >
                      {company.platformService
                        ? t('general.enabled')
                        : t('general.disabled')}
                    </span>
                  )}
                </PlatformServiceTitle>
              </Wrapper>
              {!state.currentUser.companyId && (
                <Wrapper align="center">
                  <PlatformServiceSwitcher>
                    <span style={{ color: '#b3b3b3' }}>
                      {t('general.disabled')}
                    </span>
                    <Switch
                      checked={company.platformService}
                      onChange={handleChange}
                      name="platformServiceOn"
                      style={{ color: '#06C68F' }}
                    />
                    <span className="enabled">{t('general.enabled')}</span>
                  </PlatformServiceSwitcher>
                </Wrapper>
              )}
              {!state.currentUser.companyId && (
                <Button
                  text="Analytics"
                  size="small"
                  onClick={(): void => {
                    navigate('/analytics/' + company.id)
                  }}
                  background="#06C68F"
                />
              )}
            </CompanyContent>
          </Wrapper>

          <StyledWrapper>
            {company.finished !== 7 && (
              <StyledButtonContainer>
                <SetupsContainer>{company.finished + 1}/7</SetupsContainer>
                <span>Steps Done</span>
                <ContinueBtn
                  onClick={(): void =>
                    navigate(`/create-company/${company.id}`)
                  }
                >
                  {t('companies_layout.continue_setup')}
                </ContinueBtn>
              </StyledButtonContainer>
            )}{' '}
            <StyledActionsContainer>
              <GridActionMenu
                actionConfig={actions}
                row={company}
                icon={
                  <Button
                    text={t('general.actions')}
                    size="small"
                    onClick={(): void => undefined}
                    icon={<ArrowDropDownIcon />}
                    iconPosition="right"
                    background="#06C68F"
                  />
                }
              />
            </StyledActionsContainer>
          </StyledWrapper>
        </Wrapper>
      </Header>

      <Content>
        {company.note && (
          <Container>
            <ContainerHeader>Note</ContainerHeader>
            <div style={{ marginTop: '8px', paddingLeft: '5px' }}>
              {company.note}
            </div>
          </Container>
        )}

        <Container>
          <ContainerHeader>
            {t('company_details.company_details')}
          </ContainerHeader>
          <ContainerContent>
            <ContenGrid>
              <ContenGridItem>
                <EventIcon style={{ color: '#06C68F' }} />
                <strong>{t('general.create_date')}:</strong>{' '}
                {moment(parseInt(company.createDate)).format('DD-MM-YYYY')}
              </ContenGridItem>
              <ContenGridItem>
                <span className="material-icons" style={{ color: '#06C68F' }}>
                  groups
                </span>
                <strong>{t('company_details.number_of_users')}:</strong>{' '}
                {company.numberOfUsers || 0}
              </ContenGridItem>
              <ContenGridItem>
                <MarkunreadMailboxIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.postal_code')}:</strong>{' '}
                {company.postalCode || <NoValueWrapper>N/A</NoValueWrapper>}
              </ContenGridItem>
              <ContenGridItem>
                <PersonIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.key_contact_name')}:</strong>{' '}
                {strCutter(company.keyContactName, 25)}
              </ContenGridItem>
              <ContenGridItem>
                <BusinessIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.industry')} :</strong>{' '}
                {company.companyActivity ? (
                  strCutter(company.companyActivity.label, 25)
                ) : (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem>
                <LocationOnIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.address')}:</strong>{' '}
                {strCutter(company.address, 25) || (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem>
                <EmailIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.key_contact_email')}:</strong>{' '}
                {strCutter(company.companyEmail, 30)}
              </ContenGridItem>
              <ContenGridItem>
                <PublicIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.country')} :</strong>{' '}
                {strCutter(company.country, 25) || (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem>
                <PhoneIphoneIcon style={{ color: '#06C68F' }} />
                <strong>{t('form_fields.phone')}:</strong>{' '}
                {company.phoneFields &&
                company.phoneFields.code &&
                company.phone ? (
                  `${company.phoneFields.code} ${company.phone}`
                ) : (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
              <ContenGridItem>
                <LocationCityIcon style={{ color: '#06C68F' }} />
                <strong>{t('company_details.city')} :</strong>{' '}
                {strCutter(company.city, 25) || (
                  <NoValueWrapper>N/A</NoValueWrapper>
                )}
              </ContenGridItem>
            </ContenGrid>
          </ContainerContent>
        </Container>
      </Content>
      <WhiteBg />
    </>
  )
}
export default CompanyInformation
