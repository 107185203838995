import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as DeleteForeverRoundedIcon } from 'assets/trash-can-red.svg'
import { Action } from 'components/common/GridV2'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'
import {
  IActionHandler,
  IActionItem,
  IGridColumn,
  IGridItem,
} from './gridConfig.interface'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { IColumnItem } from './ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

export const columnConfig = (): IGridColumn[] => {
  const grid: IGridColumn[] = [
    {
      id: 'isChecked',
      render: (item: IColumnItem) => <Checkbox checked={item.isChecked} />,
      numeric: false,
      disablePadding: true,
    },

    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      render: (item: IColumnItem) => <div>{item.name}</div>,
    },
    {
      numeric: false,
      disablePadding: true,
      label: 'Lessons',
      id: 'lessons',
      render: (item: IColumnItem) => <div>{item.lessons.length}</div>,
    },
  ]

  return grid
}

export const nestedColumConfig = (): IGridItem[] => {
  const grid: IGridItem[] = [
    {
      numeric: false,
      disablePadding: true,
      label: 'Name',
      id: 'name',
      render: (item: IColumnItem) => <div>{item.name}</div>,
    },
  ]

  return grid
}

export const actionConfig = (
  actionHandler: IActionHandler,
  permissions: IPermissionsBool,
  t: TFunction<'translation', undefined>,
): IActionItem[] => {
  const action: IActionItem[] = [
    {
      hide: !permissions.editGroup,
      render: (item: IColumnItem) => (
        <Action
          onClick={(): void | (() => void) =>
            actionHandler('edit', item, 'edit')
          }
          className="edit"
        >
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteGroup,
      render: (item: IColumnItem) => (
        <Action
          onClick={(): void | (() => void) =>
            actionHandler('delete', item, 'delete')
          }
          className="delete"
        >
          <DeleteForeverRoundedIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IActionItem) => !i.hide)
}
