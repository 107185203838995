import { useQuery } from '@apollo/client'
import { useUserValue } from 'context/UserContext'
import { GET_QUESTIONS } from 'gql/questions.query'
import { useAddQuestionsToTestAssessment } from 'hooks/questions/useAddQuestionsToTestAssessment'
import { IUseLinkQuestionsFilterServices } from './testQuestions.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { GET_TAGS } from 'gql/skilss.query'
import { ValueType } from 'react-select'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PER_PAGE } from 'hooks/questions/useQuestions'
import { ITag } from 'pages/tests/filterTestDrawer/filter.interface'
import getQuestionIdsService from 'services/questions/getQuestionIdsService'
import { SelectChangeEvent } from '@mui/material'
import {
  IQuestionsFilterData,
  IQuestionsFilterOption,
} from 'hooks/questions/useQuestionsLayout'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import { INestedFormValue } from 'pages/coaches/FilterCoaches/filterCoaches.interface'
import { ITags } from 'components/common/Tags/tags.interface'
import { IUseLinkQuestionsDrawerToTestServicesReturn } from './useLinkQuestionsDrawerToTestService.interface'

const useLinkQuestionsDrawerToTestServices = ({
  manualCompanyId,
  testAssessmentId,
  questionsToNotInclude,
  handleCloseDrawer,
}: IUseLinkQuestionsFilterServices): IUseLinkQuestionsDrawerToTestServicesReturn => {
  const { t } = useTranslation()
  const [userState] = useUserValue()
  const [state] = useUserValue()
  const [filterData, setFilterData] = useState<IQuestionsFilterData>({})
  const [filterOptions, setFilterOptions] = useState<IQuestionsFilterOption>(
    {} as IQuestionsFilterOption,
  )
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isAllSelected, setIsAllSelected] = useState(false)

  const defaultFilters = {
    companyId: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: manualCompanyId || state.selectedCompany?.id,
    },
    _id: {
      type: LowerCaseFilterableFieldType.ARRAY_NOT_IN,
      value: questionsToNotInclude,
    },
  }
  const {
    data,
    loading: questionsLoading,
    refetch: refetchCurrentQuestions,
  } = useQuery(GET_QUESTIONS, {
    variables: {
      type: '',
      id: '',
      filter: defaultFilters,
      currentPage,
      perPage,
    },
  })

  const { questionIds, loading: questionIdsLoading } = getQuestionIdsService({
    ...defaultFilters,
    ...filterOptions,
    question: { type: LowerCaseFilterableFieldType.MATCH, value: searchValue },
  })

  const { addQuestionsToTestAssessment } =
    useAddQuestionsToTestAssessment(testAssessmentId)

  const { data: tag } = useQuery(GET_TAGS, {
    variables: {
      companyId: userState.selectedCompany?.id,
    },
  })

  const tagList =
    (tag &&
      tag.getAllTags.map(({ label, id }: ITag) => ({ label, value: id }))) ||
    []

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItems.indexOf(id)
    if (selectedIndex === -1) {
      if (selectedItems.length + 1 === questionIds.length)
        setIsAllSelected(true)
      setSelectedItems((prev) => [...prev, id])
      return
    }

    setIsAllSelected(false)
    setSelectedItems((prev) =>
      prev.filter((selectedGroupId) => selectedGroupId !== id),
    )
  }

  const selectAllItem = async (): Promise<void> => {
    if (!isAllSelected) {
      setSelectedItems(questionIds || [])
      setIsAllSelected(true)
      return
    }

    setSelectedItems([])
    setIsAllSelected(false)
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    resetSelectedItems(perPage)
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: {
        ...defaultFilters,
        ...filterOptions,
        question: { type: 'match', value: searchValue },
      },
    })
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target

    setSearchValue(value)
    if (e.target.value === '') {
      resetSelectedItems()
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: defaultFilters,
      })
    }
  }

  const handleSubmit = (): void => {
    addQuestionsToTestAssessment(testAssessmentId, selectedItems, () => {
      handleCloseDrawer()
    })
  }

  const handleTypeChange = (
    value: ValueType<ISelectDropDownOption, false>,
  ): void => {
    const type = value as ISelectDropDownOption
    resetSelectedItems(perPage)
    setFilterData({
      ...filterData,
      type,
    })
    setFilterOptions((prevFilterOptions) => ({
      ...prevFilterOptions,
      type:
        type &&
        ({
          type: LowerCaseFilterableFieldType.EXACT,
          value: type.value,
          nestedField: 'value',
        } as INestedFormValue),
    }))
  }

  const handleTagChange = (
    value: ValueType<ISelectDropDownOption, true>,
  ): void => {
    const tags = value as ITags[]
    resetSelectedItems(perPage)
    setFilterData({
      ...filterData,
      tags,
    })
    setFilterOptions((prevFilterOptions) => ({
      ...prevFilterOptions,
      tags:
        tags &&
        ({
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: tags.map((tag) => tag.label as string),
          nestedField: 'label',
        } as INestedFormValue),
    }))
  }

  const handlePaginationClick = (
    _: ChangeEvent<unknown>,
    value: number,
  ): void => {
    if (value !== currentPage) {
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: {
          ...defaultFilters,
          ...filterOptions,
          question: { type: 'match', value: searchValue },
        },
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handlePerPageChange = (event: SelectChangeEvent<number>): void => {
    const eventNum = parseInt(event.target.value as string)
    setPerPage(eventNum)
    refetchCurrentQuestions({
      type: '',
      id: '',
      filter: {
        ...defaultFilters,
        ...filterOptions,
        question: { type: 'match', value: searchValue },
      },
      currentPage: 1,
      perPage: eventNum,
    })
  }

  const resetSelectedItems = (perPage = PER_PAGE): void => {
    setSelectedItems([])
    setIsAllSelected(false)
    setCurrentPage(1)
    setPerPage(perPage)
  }

  useEffect(() => {
    if (filterOptions) {
      refetchCurrentQuestions({
        type: '',
        id: '',
        filter: {
          ...defaultFilters,
          ...filterOptions,
          question: { type: 'match', value: searchValue },
        },
        currentPage,
        perPage,
      })
    }
  }, [filterOptions])

  return {
    t,
    data,
    filterData,
    selectItem,
    selectAllItem,
    selectedItems,
    perPage,
    questionsLoading,
    questionIdsLoading,
    isAllSelected,
    currentPage,
    searchValue,
    setSearchValue,
    handleSearchSubmit,
    handleSearchChange,
    handleSubmit,
    handlePaginationClick,
    handlePerPageChange,
    tagList,
    handleTagChange,
    handleTypeChange,
  }
}

export default useLinkQuestionsDrawerToTestServices
