import React, { useState, useEffect, ReactElement } from 'react'
import _ from 'lodash'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useTranslation } from 'react-i18next'

import { useSwal } from 'hooks/useSwal'
import { useData } from 'context/DataContext'
import { useAction } from 'store/actions'

import Drawer from 'components/common/Drawer'

import { EditGroupDrawer } from 'pages/group'
import { BadgeEditor } from 'pages/modules/BadgeEditor'
import { CompaniesEdit } from 'pages/companies'
import { AddStudent, EditStudent } from 'pages/students'
import { AddCoach, EditCoach } from 'pages/coaches'
import { AddAdmin, EditAdmin } from 'pages/admins'
import { AddModerator, EditModerator } from 'pages/moderators'
import { AddModule } from 'components/modules'
import {
  AddCourseDrawer,
  EditCourseDrawer,
  AddCourseModuleDrawer,
  AssignTutorDrawer,
} from 'pages/courses'
import AddTestDrawer from 'pages/tests/addTestDrawer/AddTestDrawer'
import { EditModuleDrawer } from 'pages/modules'
import { AddLessonDrawer, EditLessonDrawer } from 'pages/lessons'
import { CreateQuestionDrawer, EditQuestionDrawer } from 'pages/questions'
import {
  AddQuizDrawer,
  EditQuizDrawer,
  AddQuestionsToQuiz,
} from 'pages/quizzes'
import { NotificationLayout } from 'pages/notifications'
import {
  AddFlashCardDrawer,
  EditFlashCardDrawer,
} from 'pages/modules/FlashCards'
import {
  AddSkillTestVideoDrawer,
  EditSkillTestVideoDrawer,
} from 'pages/modules/ModuleDetail/SkillTestVideos'
import {
  AddEditSkillTestVideoCriteriaDrawer,
  ViewSkillTestVideoCriteriasDrawer,
} from 'pages/modules/SkillTestVideoCriterias'
import EditTestDrawer from 'pages/tests/editTestDrawer/EditTestDrawer'
import { useDeleteLessonAssets } from 'hooks/lessons/useDeleteLessonAssets'
import AddStudentsInNewGroup from 'pages/group/AddGroupDrawer/AddStudentsInNewGroup'
import EditBookDrawer from 'pages/books/EditBookDrawer/EditBookDrawer'
import AddBookToGroup from 'pages/group/GroupBooks/AddBookToGroupDrawer/addBookToGroup'
import AddCompanyDrawer from 'pages/companies/CompaniesAdd/AddCompanyDrawer'
import { ITestDrawerData } from 'pages/tests/addTestDrawer/addTestDrawer.interface'
import { IDrawerData } from 'pages/admins/AddAdmin/addAdmin.interface'
import { role } from 'hooks/useStudentsForm/useStudentsForm.interface'
import { IAddCourseDrawerDataProp } from 'hooks/course/addCourseDrawer/addCourseDrawer.interface'
import {
  IAddSkillTestVideoCriteriaDrawerDataProp,
  ISkillTestVideoReviewCriteria,
} from 'pages/modules/SkillTestVideoCriterias/skillTestVideoCriteria.interface'
import { IModuleData } from 'components/modules/AddModule.interface'
import { IAddLessonDrawerDataProp } from 'pages/lessons/AddLessonDrawer/addLessonDrawer.interface'
import { ILesson } from 'interfaces/modules'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IAddQuizDrawerDataProp } from 'pages/quizzes/AddQuizDrawer/addQuizDrawer.interface'
import { SkillTestVideoData } from 'pages/modules/ModuleDetail/SkillTestVideos/EditSkillTestVideoDrawer/EditSkillTestVideoDrawer'
import {
  AddQuestionsToQuizType,
  IAddQuestionsToQuizDataProp,
} from 'pages/quizzes/EditQuizDrawer/AddQuestionsToQuiz'
import { IAddCourseModuleDrawerDataProp } from 'pages/courses/CourseDetail/AddCourseModuleDrawer/AddCourseModuleDrawer'
import { IAddModeratorDataProp } from 'pages/moderators/AddModerator/AddModerator'
import { IGroup } from 'interfaces/groups'
import { ICourse } from 'pages/courses/courseList.interface'
import { IEditQuizData } from 'pages/quizzes/EditQuizDrawer/editQuizDrawer.interface'
import { IFlashCard } from 'hooks/modules/FlashCardsLayout/useModuleDetailContent.interface'
import { IAddStudentDrawerDataProp } from 'pages/students/AddStudent/addStudent.interface'

interface IArgs {
  id: string
  role: string
  data: object | object[]
  type: string
  companyId?: string
  bookId?: string
  groupData?: IGroup
  courseData?: ICourse
  onSuccess?: () => void
}

const DrawerLayout = (): ReactElement => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const { fireSwal } = useSwal()
  const { handleDeleteLessonAssets } = useDeleteLessonAssets()
  const [args, setArgs] = useState<IArgs>({
    id: '',
    role: '',
    data: {},
    type: '',
  })

  const [drawer, setDrawer] = useState({
    groupInformation: false,
    companyAdd: false,
    companiesAdd: false,
    companiesEdit: false,
    addModerator: false,
    addQuestions: false,
    addGroup: false,
    addAdmin: false,
    addTest: false,
    editTest: false,
    addCoach: false,
    addBookToGroup: false,
    addStudent: false,
    editStudent: false,
    editCoach: false,
    editAdmin: false,
    editModerator: false,
    filterCoach: false,
    addEditSkillTestVideoCriteria: false,
    badgeEditor: false,
    addCourses: false,
    editCourse: false,
    addModule: false,
    addCourseModule: false,
    editModuleDrawer: false,
    addTopic: false,
    editTopic: false,
    addLesson: false,
    editLesson: false,
    createQuestion: false,
    editQuestion: false,
    linkQuestionsToModule: false,
    addQuiz: false,
    editQuiz: false,
    AddQuestionsToQuiz: false,
    notification: false,
    editBook: false,
    addFlashCard: false,
    editFlashCard: false,
    rejectionDetails: false,
    addSkillTestVideo: false,
    editSkillTestVideo: false,
    viewSkillTestVideoCriterias: false,
    addStudentInGroup: false,
    assignTutor: false,
  })

  const handleDrawerEvent = (
    field: string,
    event: boolean,
    args?: IArgs,
  ): void => {
    if (args) {
      setArgs(args)
    }
    setDrawer({
      ...drawer,
      [field]: event,
    })
  }

  useEffect((): void => {
    const isEmpty = _.values(state.formData).every(_.isEmpty)
    if (!isEmpty) {
      const { type, values, compareTo, drawer } = state.formData
      const isEqual = _.isEqual(values, compareTo)
      const isEmpty = _.values(values).every(_.isEmpty)

      let lessonVideoId = ''
      if (
        (drawer === 'addLesson' || drawer === 'editLesson') &&
        state?.formData?.isVideoUpdated
      ) {
        lessonVideoId = values?.video?.id
      }

      if (type === 'add' && isEmpty) {
        dispatch({
          type: 'CLEAR_FORMDATA',
          payload: true,
        })
        handleDrawerEvent(drawer, false)
      }

      if (type === 'add' && !isEmpty) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: (): void => {
            if (drawer === 'addLesson' && state?.formData?.isVideoUpdated) {
              handleDeleteLessonAssets(lessonVideoId)
            }
            handleDrawerEvent(drawer, false)
            toggleDrawerConfirm(false, '')
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          onClose: (): void => {
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          confirmText: t('popups.confirm_cancel'),
          cancelText: t('general.no'),
        })
      }

      if (type === 'edit' && isEqual) {
        dispatch({
          type: 'CLEAR_FORMDATA',
          payload: true,
        })
        handleDrawerEvent(drawer, false)
      }

      if (type === 'edit' && !isEqual) {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: (): void => {
            if (drawer === 'editLesson' && state?.formData?.isVideoUpdated) {
              handleDeleteLessonAssets(lessonVideoId)
            }
            handleDrawerEvent(drawer, false)
            toggleDrawerConfirm(false, '')
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          onClose: (): void => {
            dispatch({
              type: 'CLEAR_FORMDATA',
              payload: true,
            })
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }
    }
  }, [state.formData])

  useEffect((): void => {
    DrawerEventEmitter.addListener('openDrawer', handleDrawerEvent)
  }, [])

  return (
    <>
      {/* {drawers.map((drawerItem: Props) => (
        <Drawer
          opened={drawer[drawerItem.drawer]}
          toggleDrawer={():void => handleDrawerEvent(drawerItem.name, false)}
          totalWidth={drawerItem.width}
        >
          {drawerItem.component}
        </Drawer>
      ))} */}
      <Drawer opened={drawer.groupInformation} totalWidth="700px">
        <EditGroupDrawer
          id={args.id}
          onClose={(): void => handleDrawerEvent('groupInformation', false)}
          companyId={args.companyId as string}
        />
      </Drawer>
      <Drawer opened={drawer.addStudentInGroup} totalWidth="700px">
        <AddStudentsInNewGroup
          groupData={args.groupData as IGroup}
          closeDrawer={(): void =>
            handleDrawerEvent('addStudentInGroup', false)
          }
        />
      </Drawer>
      <Drawer opened={drawer.assignTutor} totalWidth="700px">
        <AssignTutorDrawer
          groupData={args.groupData as IGroup}
          courseData={args.courseData as ICourse}
          closeDrawer={(): void => handleDrawerEvent('assignTutor', false)}
        />
      </Drawer>
      <Drawer opened={drawer.addBookToGroup} totalWidth="700px">
        <AddBookToGroup
          groupData={args.groupData as IGroup}
          closeDrawer={(): void => handleDrawerEvent('addBookToGroup', false)}
        />
      </Drawer>
      <Drawer opened={drawer.badgeEditor} totalWidth="80vw">
        <BadgeEditor
          id={args.id}
          closeDrawer={(): void => handleDrawerEvent('badgeEditor', false)}
        />
      </Drawer>

      <Drawer opened={drawer.editBook}>
        <EditBookDrawer
          onClose={(): void => handleDrawerEvent('editBook', false)}
          companyId={args.companyId as string}
          bookId={args.bookId}
        />
      </Drawer>
      <Drawer opened={drawer.companiesEdit} totalWidth="600px">
        <CompaniesEdit companyId={args.id} />
      </Drawer>
      <Drawer opened={drawer.companiesAdd} totalWidth="600px">
        <AddCompanyDrawer />
      </Drawer>
      <Drawer opened={drawer.addModerator} totalWidth="600px">
        <AddModerator
          title={t('users_layout.add_super_admin')}
          role="SUPER_ADMIN"
          drawerName="addModerator"
          drawerData={args.data as IAddModeratorDataProp}
        />
      </Drawer>
      <Drawer opened={drawer.addAdmin} totalWidth="600px">
        <AddAdmin
          drawerData={args.data as IDrawerData}
          onClose={(): void => handleDrawerEvent('addAdmin', false)}
        />
      </Drawer>
      <Drawer opened={drawer.addTest} totalWidth="600px">
        <AddTestDrawer
          title={t('tests_layout.new_test')}
          drawerName="addTest"
          drawerData={args.data as ITestDrawerData}
          onClose={(): void => handleDrawerEvent('addTest', false)}
          handleUploadFile={(): void => undefined}
          onSuccess={args.onSuccess as () => void}
        />
      </Drawer>
      {/* <Drawer
        opened={drawer.addQuestions}
        
        totalWidth="600px"
      >
        <AddQuestionsDrawer
          title="Add Questions"
          drawerName="addQuestions"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addQuestions', false)}
          handleUploadFile={handleToggleDrawer}
        />
      </Drawer>
      <Drawer
        opened={drawer.addQuestions}
        
        totalWidth="600px"
      >
        <AddGroupsDrawer
          title="Add Groups"
          drawerName="addGroup"
          drawerData={args.data}
          onClose={(): void => handleDrawerEvent('addGroup', false)}
          handleUploadFile={handleToggleDrawer}
        /> */}
      {/* </Drawer> */}
      <Drawer opened={drawer.editTest} totalWidth="600px">
        <EditTestDrawer
          id={args.id}
          onClose={(): void => handleDrawerEvent('editTest', false)}
        />
      </Drawer>
      <Drawer opened={drawer.addStudent} totalWidth="600px">
        <AddStudent
          title={t('users_layout.add_student')}
          role="STUDENT"
          drawerName="addStudent"
          drawerData={args.data as IAddStudentDrawerDataProp}
          onClose={(): void => handleDrawerEvent('addStudent', false)}
        />
      </Drawer>
      <Drawer opened={drawer.addCoach} totalWidth="600px">
        <AddCoach
          title="Add Tutor"
          role="COACH"
          drawerName="addCoach"
          drawerData={args.data as object}
          onClose={(): void => handleDrawerEvent('addCoach', false)}
        />
      </Drawer>
      <Drawer opened={drawer.editStudent} totalWidth="600px">
        <EditStudent id={args.id} role={args.role as role} />
      </Drawer>
      <Drawer opened={drawer.editCoach} totalWidth="600px">
        <EditCoach id={args.id} role={args.role} />
      </Drawer>
      <Drawer opened={drawer.editAdmin} totalWidth="600px">
        <EditAdmin id={args.id} role={args.role} />
      </Drawer>
      <Drawer opened={drawer.editModerator} totalWidth="600px">
        <EditModerator
          title={t('users_layout.edit_super_admin')}
          id={args.id}
          role={args.role}
          drawerName="editModerator"
        />
      </Drawer>
      {/* <Drawer
        opened={drawer.filterCoach}
        toggleDrawer={(): void => handleDrawerEvent('filterCoach', false)}
        totalWidth="600px"
      >
        <FilterCoaches id={args.id} role={args.role} />
      </Drawer> */}
      <Drawer opened={drawer.addCourses} totalWidth="700px">
        <AddCourseDrawer
          onClose={(): void => handleDrawerEvent('addCourses', false)}
          data={args.data as IAddCourseDrawerDataProp}
        />
      </Drawer>
      <Drawer opened={drawer.addEditSkillTestVideoCriteria} totalWidth="600px">
        <AddEditSkillTestVideoCriteriaDrawer
          title={t('skillTestVideoCriteria_layout.addEdit')}
          data={args.data as IAddSkillTestVideoCriteriaDrawerDataProp}
          onClose={(): void =>
            handleDrawerEvent('addEditSkillTestVideoCriteria', false)
          }
        />
      </Drawer>
      <Drawer opened={drawer.viewSkillTestVideoCriterias} totalWidth="600px">
        <ViewSkillTestVideoCriteriasDrawer
          title="View Skill Test Video Criterias"
          criterias={args.data as ISkillTestVideoReviewCriteria[]}
          onClose={(): void =>
            handleDrawerEvent('viewSkillTestVideoCriterias', false)
          }
        />
      </Drawer>
      <Drawer opened={drawer.editCourse} totalWidth="700px">
        <EditCourseDrawer id={args.id} />
      </Drawer>
      <Drawer opened={drawer.addModule} totalWidth="600px">
        <AddModule
          title={t('modules_layout.create_module')}
          data={args.data as IModuleData}
          drawerName="addModule"
        />
      </Drawer>
      <Drawer opened={drawer.addCourseModule} totalWidth="800px">
        <AddCourseModuleDrawer
          title={t('actions.add_modules_to_course')}
          drawerName="addCourseModule"
          data={args.data as IAddCourseModuleDrawerDataProp}
        />
      </Drawer>

      <Drawer opened={drawer.editModuleDrawer} totalWidth="600px">
        <EditModuleDrawer
          onClose={(): void => handleDrawerEvent('editModuleDrawer', false)}
          id={args.id}
        />
      </Drawer>

      <Drawer opened={drawer.addLesson} totalWidth="600px">
        <AddLessonDrawer
          onClose={(): void => handleDrawerEvent('addLesson', false)}
          id={args.id}
          data={args.data as IAddLessonDrawerDataProp}
        />
      </Drawer>

      <Drawer opened={drawer.editLesson} totalWidth="600px">
        <EditLessonDrawer
          onClose={(): void => handleDrawerEvent('editLesson', false)}
          moduleId={args.id}
          lesson={args.data as ILesson}
        />
      </Drawer>
      <Drawer opened={drawer.createQuestion} totalWidth="700px">
        <CreateQuestionDrawer
          title={t('questions_layout.create_question')}
          currentModule={args.data as IModule}
        />
      </Drawer>
      <Drawer opened={drawer.editQuestion} totalWidth="700px">
        <EditQuestionDrawer
          title={t('questions_layout.edit_question')}
          currentModule={args.data as IModule}
          questionId={args.id}
        />
      </Drawer>
      <Drawer opened={drawer.addQuiz} totalWidth="592px">
        <AddQuizDrawer
          onClose={(): void => handleDrawerEvent('addQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data as IAddQuizDrawerDataProp}
        />
      </Drawer>

      <Drawer opened={drawer.editQuiz} totalWidth="592px">
        <EditQuizDrawer
          onClose={(): void => handleDrawerEvent('editQuiz', false)}
          id={args.id}
          type={args.type}
          data={args.data as IEditQuizData}
        />
      </Drawer>

      <Drawer opened={drawer.AddQuestionsToQuiz} totalWidth="800px">
        <AddQuestionsToQuiz
          onClose={(): void => handleDrawerEvent('AddQuestionsToQuiz', false)}
          id={args.id}
          type={args.type as AddQuestionsToQuizType}
          data={args.data as IAddQuestionsToQuizDataProp}
        />
      </Drawer>

      <Drawer
        opened={drawer.notification}
        toggleDrawer={(): void => handleDrawerEvent('notification', false)}
        totalWidth="700px"
      >
        <NotificationLayout
          onClose={(): void => handleDrawerEvent('notification', false)}
        />
      </Drawer>
      <Drawer opened={drawer.addFlashCard} totalWidth="700px">
        <AddFlashCardDrawer
          onClose={(): void => handleDrawerEvent('addFlashCard', false)}
          data={args.data as IFlashCard}
        />
      </Drawer>
      <Drawer opened={drawer.editFlashCard} totalWidth="700px">
        <EditFlashCardDrawer
          onClose={(): void => handleDrawerEvent('editFlashCard', false)}
          data={args.data as IFlashCard}
        />
      </Drawer>
      <Drawer opened={drawer.addSkillTestVideo} totalWidth="700px">
        <AddSkillTestVideoDrawer
          onClose={(): void => handleDrawerEvent('addSkillTestVideo', false)}
          data={args.data as { moduleId: string }}
        />
      </Drawer>
      <Drawer opened={drawer.editSkillTestVideo} totalWidth="700px">
        <EditSkillTestVideoDrawer
          onClose={(): void => handleDrawerEvent('editSkillTestVideo', false)}
          data={args.data as SkillTestVideoData}
        />
      </Drawer>
    </>
  )
}

export default DrawerLayout
