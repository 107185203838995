import { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { GET_QUIZ_BY_TYPE } from 'gql/quiz/quiz.query'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import useDeleteQuizService from 'hooks/quizzes/useDeleteQuizService'
import {
  IFilterData,
  IUseQuizLayoutProps,
  IUseQuizLayoutReturn,
  QuizValue,
} from 'pages/quizzes/quiz.interface'
import {
  ISelectAll,
  ISelectedItem,
} from 'pages/tests/testLayout/testLayout.interface'
import { PER_PAGE as perPage } from 'hooks/modules/useModules'
import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DrawerEventEmitter } from 'helpers/drawer'
import { actionConfig } from 'pages/quizzes/gridConfig'
import React from 'react'
import { Button } from 'components/common'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { IUserCompany } from 'interfaces/users'
import { checkIfFiltered } from 'utils/FilterIconHandler'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

const useQuizLayout = ({
  type,
  id,
  IQTest,
  fromQuestions,
  setOpenCreateQuizDrawer,
  canCreate,
  companyCourse,
}: IUseQuizLayoutProps): IUseQuizLayoutReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const companyId =
    (state?.currentUser?.companyId as IUserCompany)?.id ||
    state?.selectedCompany?.id ||
    null
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [searchText, setSearchText] = useState('')
  const stateRef = useRef(state)
  const [filterData, setFilterData] = useState<IFilterData>({
    filterOptions: null,
    values: null,
  })
  const [filterDrawer, setFilterDrawer] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isFiltered, setIsFiltered] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const [
    fetchQuizByType,
    { data, refetch: refetchQuizByType, loading: quizListLoading },
  ] = useLazyQuery(GET_QUIZ_BY_TYPE)
  const { deleteQuiz } = useDeleteQuizService()

  const fromList = !!(type && id)
  const defaultFilter = companyId
    ? {
        companyId: {
          type: UpperCaseFilterableFieldType.EXACT,
          value: companyId,
        },
      }
    : {}

  const runQuery = (): void => {
    if (!state) return
    setSelectedItem({})
    if (fromList) {
      fetchQuizByType({
        variables: {
          type: type.toLowerCase(),
          actionId: id,
          currentPage,
          perPage,
          filter: {
            ...filterData.filterOptions,
            IQTestType: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: IQTest ? 'initial' : null,
            },
          },
        },
      })
    } else {
      fetchQuizByType({
        variables: {
          type: '',
          actionId: '',
          currentPage,
          perPage,
          filter: {
            ...filterData.filterOptions,
            ...defaultFilter,
          },
        },
      })
    }
  }

  useEffect(() => {
    if (quizListLoading) {
      setInitialLoad(true)
    } else {
      setInitialLoad(false)
    }
  }, [quizListLoading])

  useEffect(() => {
    runQuery()
  }, [type, id, filterData, state])

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const openAddQuizDrawer = (): void => {
    refetchQuizByType &&
      DrawerEventEmitter.emit('openDrawer', 'addQuiz', true, {
        type: type,
        id: id,
        data: {
          name: name,
          quizzes: quizList,
          companyId: companyId,
          refetch: () => refetchQuizByType({}),
          IQTest: IQTest,
        },
      })
  }
  const quizList = fromList
    ? (data && data.getQuizzesByType) || []
    : (data && data.getQuizzesByType) || []

  const deleteQuizzes = (ids: string[]): void => {
    const title: string = IQTest
      ? ids.length > 1
        ? t('popups.delete_iq_test_many')
        : t('popups.delete_iq_test_single')
      : ids.length > 1
      ? t('popups.delete_quiz_many')
      : t('popups.delete_quiz_single')

    fireSwal({
      title,
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => deleteQuiz(ids, runQuery, type),
    })
  }

  const handlePaginationClick = (
    _: ChangeEvent<unknown>,
    value: number,
  ): void => {
    if (value !== currentPage) {
      refetchQuizByType({
        currentPage: value,
        perPage,
      })
      setCurrentPage(value)
    }
  }

  const handleEditDrawer = (val: QuizValue): void => {
    DrawerEventEmitter.emit('openDrawer', 'editQuiz', true, {
      type: type || '',
      id: id || '',
      data: {
        quizId: val.id,
        quizzes: quizList,
        IQTest: IQTest,
        companyId: companyId,
      },
    })
  }

  const actionHandlers = (
    action: string,
    value: QuizValue,
  ): Promise<void> | void | (() => void) => {
    switch (action) {
      case 'view':
        return navigate(`/quizzes/${value.id}`)
      case 'edit':
        return handleEditDrawer(value)
      case 'delete':
        return deleteQuizzes([value.id])
      default:
        return () => console.log('1')
    }
  }

  useEffect(() => {
    stateRef.current = state
  }, [state])

  useEffect(() => {
    const filtered = checkIfFiltered(
      filterData.filterOptions as IFilterQueryType,
    )
    setIsFiltered(filtered)
  }, [filterData])

  const debouncedSearch = useRef(
    debounce((searchText: string) => {
      const currentSelectedCompanyId = stateRef.current.selectedCompany?.id
      const searchValue = {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      }
      setCurrentPage(1)
      refetchQuizByType({
        filter: {
          ...filterData.filterOptions,
          name: searchValue,
          companyId: {
            type: UpperCaseFilterableFieldType.EXACT,
            value: currentSelectedCompanyId,
          },
        },
      })
      setIsSearchType(searchText !== '')
    }, 500),
  ).current

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSearchType(true)
      const newSearchText = e.target.value
      setSearchText(newSearchText)
      debouncedSearch(newSearchText)
    },
    [debouncedSearch],
  )

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()

    if (!searchText.trim()) {
      return
    }

    const filter = {
      name: {
        type: UpperCaseFilterableFieldType.MATCH,
        value: searchText,
      },
      companyId: {
        type: UpperCaseFilterableFieldType.EXACT,
        value: companyId,
      },
      ...filterData.filterOptions,
    }

    refetchQuizByType({
      filter,
      currentPage: 1,
      perPage,
    })
  }

  useEffect(() => {
    if (fromQuestions) {
      openAddQuizDrawer()
    }

    return (): void => {
      setOpenCreateQuizDrawer && setOpenCreateQuizDrawer(false)
    }
  }, [fromQuestions])

  const currentUser = state.currentUser

  const actions = actionConfig(t, actionHandlers, state.userPermission)
  const createIqTestBtnDisabled =
    IQTest && quizList.data && quizList.data.length > 0 ? true : false
  const createButtonDisabled = !(canCreate > 0) || createIqTestBtnDisabled

  const CreateAssessmentButton = (): React.JSX.Element | null =>
    companyCourse && currentUser.companyId ? null : (
      <Tooltip
        title={
          createButtonDisabled && !IQTest
            ? t('quizzes_layout.create_quiz_tooltip')
            : ''
        }
        arrow
      >
        <span>
          <Button
            text={
              IQTest
                ? t('quizzes_layout.create_iq_test')
                : t('quizzes_layout.create_quiz')
            }
            color="secondary"
            onClick={openAddQuizDrawer}
            isDisabled={createButtonDisabled}
            icon={<AddCircleRoundedIcon />}
            background="#06C68F"
          />
        </span>
      </Tooltip>
    )
  const createButton = (): React.JSX.Element => <CreateAssessmentButton />

  return {
    state,
    companyId,
    searchText,
    setSearchText,
    filterData,
    setFilterData,
    filterDrawer,
    setFilterDrawer,
    selectedItem,
    setSelectedItem,
    selectAll,
    setSelectAll,
    currentPage,
    setCurrentPage,
    fetchQuizByType,
    data,
    refetchQuizByType,
    deleteQuiz,
    selectItem,
    handleSearchChange,
    onSearchSubmit,
    createButton,
    t,
    handlePaginationClick,
    quizList,
    quizListLoading,
    actions,
    fromList,
    deleteQuizzes,
    currentUser,
    CreateAssessmentButton,
    createIqTestBtnDisabled,
    isSearchType,
    isFiltered,
    initialLoad,
  }
}

export default useQuizLayout
