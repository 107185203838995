import React, { ReactElement } from 'react'
import { Button } from 'components/common/Button'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/DrawerLayout/styled-components'
import { FormButtons, FormFooter } from 'components/common/Form'

import { DrawerWrapper } from './styled-components'
import DrawerInformation from './DrawerInformation/DrawerInformation'
import DrawerFilter from './DrawerFilter/DrawerFilter'
import TutorsList from './TutorsList/TutorsList'
import {
  IAssignTutorDrawerProps,
  useAssignTutorDrawer,
} from 'hooks/course/assignTutor'
import MyLoader from 'loading/loading'

const AssignTutorDrawer = ({
  groupData,
  courseData,
  closeDrawer,
}: IAssignTutorDrawerProps): ReactElement => {
  const {
    t,
    groupName,
    courseName,
    courseId,
    searchValue,
    handleSearchChange,
    handleAssignTutor,
    selectedTutor,
    assignTutorLoading,
    selectTutor,
  } = useAssignTutorDrawer({
    groupData,
    courseData,
    closeDrawer,
  })
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('groups_layout.assign_tutor')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent headerHeight={138}>
        {assignTutorLoading && <MyLoader width={200} />}
        <DrawerWrapper>
          <DrawerInformation groupName={groupName} courseName={courseName} />
          <DrawerFilter
            searchValue={searchValue}
            handleSearchChange={handleSearchChange}
          />
          <TutorsList
            courseId={courseId}
            searchValue={searchValue}
            selectedTutor={selectedTutor}
            selectTutor={selectTutor}
          />
        </DrawerWrapper>
      </DrawerContent>
      <FormFooter>
        <FormButtons>
          <Button
            text={t('actions.save')}
            color="secondary"
            type="small"
            onClick={handleAssignTutor}
            background="#06C68F"
          />
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={closeDrawer}
            background="#E0E1E2"
            textColor="#414141"
          />
        </FormButtons>
      </FormFooter>
    </>
  )
}

export default AssignTutorDrawer
