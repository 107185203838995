import gql from 'graphql-tag'

export const ADD_GROUP = gql`
  mutation AddGroup($input: GroupInput, $students: [String]) {
    addGroup(input: $input, students: $students) {
      name
      note
      location
      id
      status
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
      courses {
        courseId
        name
        coach {
          name
          coachId
        }
      }
      coach {
        name
        coachId
      }
      company {
        name
        id
      }
    }
  }
`
export const EDIT_GROUP = gql`
  mutation EditGroup($id: String!, $input: GroupInput) {
    editGroup(id: $id, input: $input) {
      name
      note
      location
      id
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
    }
  }
`

export const GET_GROUPS_BY_TEST_ASSESSMENT_ID = gql`
  query GetGroupsByTestAssessmentId(
    $testAssessmentId: String!
    $filter: GroupFilter
    $currentPage: Int
    $perPage: Int
  ) {
    getGroupsByTestAssessmentId(
      testAssessmentId: $testAssessmentId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      data {
        id
        name
        status
        coach {
          id
          name
          coachId
          avatar
        }
        admin {
          id
          firstName
          email
          lastName
        }
        company {
          name
          id
        }
        numberOfStudents
        courses {
          name
        }
      }
      totalCount
      currentPage
    }
  }
`

export const GET_GROUP_OPTIONS_BY_TEST_ASSESSMENT_ID = gql`
  query GetGroupsByTestAssessmentId(
    $testAssessmentId: String!
    $filter: GroupFilter
    $currentPage: Int
    $perPage: Int
  ) {
    getGroupsByTestAssessmentId(
      testAssessmentId: $testAssessmentId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      data {
        id
        name
      }
    }
  }
`

export const GET_GROUP = gql`
  query GetGroup($filter: GroupFilter, $currentPage: Int, $perPage: Int) {
    getAllGroups(
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalCount
      data {
        name
        note
        location
        id
        numberOfStudents
        status
        createDate
        admin {
          firstName
          lastName
          email
          id
          avatar
        }
        courses {
          courseId
          name
          coach {
            name
            coachId
            avatar
          }
        }
        coach {
          name
          coachId
          avatar
          id
        }
        company {
          name
          id
        }
      }
    }
  }
`
export const GET_AVAILABLE_GROUP_IDS_FOR_TEST = gql`
  query GetGroup($testAssessmentId: String!, $filter: GroupFilter) {
    getAvailableGroupIdsForTestAssessment(
      testAssessmentId: $testAssessmentId
      filter: $filter
    )
  }
`

export const GET_AVAILABLE_GROUPS_FOR_TEST = gql`
  query GetGroup(
    $testAssessmentId: String!
    $filter: GroupFilter
    $currentPage: Int
    $perPage: Int
  ) {
    getAvailableGroupsForTestAssessment(
      testAssessmentId: $testAssessmentId
      filter: $filter
      currentPage: $currentPage
      perPage: $perPage
    ) {
      currentPage
      totalCount
      data {
        name
        note
        location
        id
        numberOfStudents
        status
        createDate
        admin {
          firstName
          lastName
          email
          id
          avatar
        }
        courses {
          courseId
          name
          coach {
            name
            coachId
          }
        }
        coach {
          name
          coachId
          avatar
          id
        }
        company {
          name
          id
        }
      }
    }
  }
`

export const GET_GROUPS_DETAILS = gql`
  query GetGroupDetails($courseId: String!) {
    getGroupsDetails(courseId: $courseId) {
      name
      id
      courses {
        courseId
        name
        coach {
          name
          coachId
        }
      }
    }
  }
`

export const GET_GROUP_BY_ID = gql`
  query GetGroupById($id: String) {
    getGroupById(id: $id) {
      name
      note
      location
      id
      numberOfStudents
      status
      testAssessments
      isNewsFeedAvailable
      createDate
      company {
        id
        avatar
        name
      }
      admin {
        firstName
        lastName
        email
        id
      }
      courses {
        coach {
          coachId
          id
          name
          avatar
        }
        courseId
        name
        state
      }
    }
  }
`

export const GET_GROUPS_BY_IDS = gql`
  query GetGroupsByIds($ids: [String]) {
    getGroupsByIds(ids: $ids) {
      groups {
        name
        note
        location
        id
        numberOfStudents
        status
        createDate
        company {
          id
          avatar
          name
        }
        admin {
          firstName
          lastName
          email
          id
        }
      }
      numberOfStudents
    }
  }
`

export const ADD_STUDENTS_IN_GROUP = gql`
  mutation AddStudentInGroup(
    $groupId: String
    $groupName: String
    $ids: [String]
  ) {
    addStudentsInGroup(groupId: $groupId, groupName: $groupName, ids: $ids) {
      name
      note
      location
      id
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
    }
  }
`

export const MOVE_STUDENTS_IN_GROUP = gql`
  mutation MoveStudentInGroup(
    $groupId: String
    $oldGroupId: String
    $userId: String
  ) {
    moveStudentsInGroup(
      groupId: $groupId
      oldGroupId: $oldGroupId
      userId: $userId
    ) {
      message
    }
  }
`

export const DELETE_STUDENTS_FROM_GROUP = gql`
  mutation DeleteStudentFromGroup($groupId: String, $ids: [String]) {
    deleteStudentsFromGroup(groupId: $groupId, ids: $ids) {
      name
      note
      location
      id
      numberOfStudents
      admin {
        firstName
        lastName
        email
        id
      }
    }
  }
`

export const SEND_PUSH_NOTIFICATIONS = gql`
  mutation SendPushNotificationToUsers(
    $recipientIds: [String]!
    $messageData: MessageData
  ) {
    sendPushNotificationToUsers(
      recipientIds: $recipientIds
      messageData: $messageData
    ) {
      message
    }
  }
`

// export const UPLOAD_FILE = gql`
//   mutation SingleUpload($file: Upload!) {
//     singleUpload(file: $file) {
//       name
//       lastModified
//       lastModifiedDate
//       webkitRelativePath
//       type
//       size
//     }
//   }
// `

export const UPLOAD_FILE_STREAM = gql`
  mutation UploadFileStream($file: Upload) {
    singleUploadStream(file: $file) {
      firstName
      lastName
      email
      phone
      id
      biography
      gender
      note
    }
  }
`
export const EXTRACT_USERS_WITH_GROUPS = gql`
  mutation UploadFileStream($file: Upload) {
    extractUsersWithGroups(file: $file) {
      id
      firstName
      lastName
      email
      groups
    }
  }
`

export const INSERT_GROUPS_WITH_COURSES = gql`
  mutation InsertGroupsWithCourses(
    $groups: [ExtractGroupsWithCoursesInput]
    $companyId: String
  ) {
    insertGroupsWithCourses(groups: $groups, companyId: $companyId) {
      message
      error
      id
      name
      adminFirstName
      adminLastName
      adminEmail
      tutorFirstName
      tutorLastName
      tutorEmail
      courses
    }
  }
`
export const INSERT_GROUPS_WITH_TESTS = gql`
  mutation InsertGroupsWithCourses(
    $groups: [ExtractGroupsWithTestsInput]
    $companyId: String
  ) {
    insertGroupsWithTests(groups: $groups, companyId: $companyId) {
      message
      error
      id
      name
      adminFirstName
      adminLastName
      adminEmail
      tests
    }
  }
`

export const EXTRACT_GROUPS_WITH_COURSES = gql`
  mutation UploadFileStream($file: Upload) {
    extractGroupsWithCourses(file: $file) {
      id
      name
      adminFirstName
      adminLastName
      adminEmail
      tutorFirstName
      tutorLastName
      tutorEmail
      courses
    }
  }
`

export const EXTRACT_GROUPS_WITH_TESTS = gql`
  mutation UploadFileStream($file: Upload) {
    extractGroupsWithTests(file: $file) {
      id
      name
      adminFirstName
      adminLastName
      adminEmail
      tests
    }
  }
`

export const DELETE_GROUP = gql`
  mutation DeleteGroup($id: [String]) {
    deleteGroup(id: $id) {
      message
    }
  }
`

export const ASSIGN_TUTOR = gql`
  mutation AssignTutor($groupId: String, $tutorId: String, $courseId: String) {
    assignTutorToGroup(
      groupId: $groupId
      tutorId: $tutorId
      courseId: $courseId
    ) {
      name
      id
      admin {
        firstName
        lastName
        email
        id
      }
      courses {
        courseId
        name
        coach {
          name
          coachId
        }
      }
      coach {
        name
        coachId
      }
    }
  }
`

export const ADD_REMOVE_GROUP_FROM_COURSE = gql`
  mutation AddRemoveGroupFromCourse($courseId: String, $groupIds: [String]) {
    asyncAddRemoveGroupFromCourse(courseId: $courseId, groupIds: $groupIds) {
      id
    }
  }
`

export const ADD_GROUPS_TO_COURSE = gql`
  mutation AddGroupsToCourse($courseId: String, $groupIds: [String]) {
    addGroupsToCourse(courseId: $courseId, groupIds: $groupIds) {
      message
    }
  }
`

export const REMOVE_GROUPS_FROM_COURSE = gql`
  mutation RemoveGroupsFromCourse($courseId: String, $groupIds: [String]) {
    removeGroupsFromCourse(courseId: $courseId, groupIds: $groupIds) {
      message
    }
  }
`

export const GET_GROUP_NAME_BY_ID = gql`
  query GetGroupNameById($id: String) {
    getGroupById(id: $id) {
      name
      id
    }
  }
`

export const GET_STUDENTS_QUIZ_RESULTS = gql`
  query GetStudentsQuizResults(
    $courseId: String!
    $groupsIds: [String]
    $skip: Int
    $limit: Int
    $type: String
    $filter: StudentsQuizResultsFilter
  ) {
    getStudentsQuizResults(
      groupsIds: $groupsIds
      courseId: $courseId
      skip: $skip
      limit: $limit
      type: $type
      filter: $filter
    ) {
      totalCount
      currentPage
      data {
        id
        firstName
        lastName
        quizzes {
          id
          name
          score
          passed
          module {
            id
            name
          }
          topic {
            id
            name
          }
          lesson {
            id
            name
          }
          IQTestType
        }
      }
    }
  }
`
