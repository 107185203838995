import React, { useState } from 'react'
import moment from 'moment'
import {
  NotificationContainer,
  NotificationItem,
  ListWrapper,
  CheckboxWrapper,
  ListContentWrapper,
  AvatarContainer,
  NameContainer,
  EventContainer,
  DateContainer,
  TitleContainer,
  UserRole,
} from './styled-components'
import CheckBox from '@mui/material/Checkbox'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Button from '@mui/material/Button'

import { generateFullName, formatDateAgo, getUserRole } from 'utils/utils'
import Drawer from 'components/common/Drawer'
import { RejectionDetailsDrawer } from 'pages/rejections'
import ProfileIcon from 'pages/profile/AdminProfile/icons/profile-picture.svg'
import {
  INotificationList,
  INotificationListProps,
} from './Notification.interface'
import { IUser } from 'interfaces/users'
import { IRejection } from 'interfaces/rejections'

const NotificationList = (props: INotificationListProps): React.JSX.Element => {
  const [rejectionDrawerOpened, setRejectionDrawerOpened] =
    useState<boolean>(false)
  const [rejection, setRejection] = useState<IRejection | null>(null)

  const renderTitle = (notification: INotificationList): string => {
    if (notification.objectName === 'Rejection') {
      return notification.actionName === 'Post'
        ? `${notification.actionName} By`
        : `${notification.actionName}`
    } else {
      const owner = notification.owner
        ? notification.owner.companyId
          ? 'Company'
          : 'Suada'
        : ''
      return `${owner} ${getUserRole(notification.owner?.role || '')}`
    }
  }

  const handleRejectionViewClick = (rejection: IRejection): void => {
    setRejectionDrawerOpened(true)
    setRejection(rejection)
  }

  return (
    <NotificationContainer>
      <Drawer
        opened={rejectionDrawerOpened}
        toggleDrawer={(): void => setRejectionDrawerOpened(false)}
        totalWidth="560px"
      >
        <RejectionDetailsDrawer
          onClose={(): void => setRejectionDrawerOpened(false)}
          data={rejection as IRejection}
        />
      </Drawer>

      {props.notificationList.map((i, index: number) => (
        <NotificationItem
          key={index}
          bcColor={i.state === 'read' ? '#fff' : 'rgba(236,235,235,0.7)'}
        >
          <ListWrapper align="center">
            {i.objectName !== 'Rejection' && (
              <CheckboxWrapper>
                <CheckBox
                  onChange={(): void => props.selectItem(i.id)}
                  checked={props.selectedItem.includes(i.id)}
                />
              </CheckboxWrapper>
            )}

            <AvatarContainer>
              {i.objectName === 'Rejection' ? (
                <ReportProblemIcon />
              ) : (
                i.owner && (
                  <img
                    alt="owner-avatar"
                    src={i.owner?.avatar || ProfileIcon}
                  />
                )
              )}
            </AvatarContainer>

            <ListContentWrapper>
              <div>
                <TitleContainer>
                  <UserRole>{renderTitle(i)}</UserRole>
                  <NameContainer>
                    {generateFullName(i.owner as IUser)}
                  </NameContainer>
                </TitleContainer>

                <EventContainer>
                  <span>{i.message}</span>
                  {i.objectName !== 'Rejection' && (
                    <NameContainer> {i.actionName} </NameContainer>
                  )}
                  {i.additionalText && (
                    <span style={{ marginLeft: '2px' }}>
                      {i.additionalText}
                    </span>
                  )}
                  {i.company && (
                    <NameContainer style={{ marginLeft: '2px' }}>
                      {i.company.name}
                    </NameContainer>
                  )}
                </EventContainer>
              </div>

              <div>
                {i.objectName === 'Rejection' && (
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: '#06C68F',
                      border: '1px solid #06C68F',
                    }}
                    onClick={(): void =>
                      handleRejectionViewClick(i as IRejection)
                    }
                  >
                    View
                  </Button>
                )}

                <DateContainer>
                  {formatDateAgo(
                    moment(parseInt(i.createDate)).format('DD MMM YYYY h:mm A'),
                  )}
                </DateContainer>
              </div>
            </ListContentWrapper>
          </ListWrapper>
        </NotificationItem>
      ))}
    </NotificationContainer>
  )
}

export default NotificationList
