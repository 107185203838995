import { useState } from 'react'
import addStudentsService from 'services/group/addStudentsService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

export interface IUseAddStudentsInGroupReturnType {
  loading: boolean
  addStudentsInGroup: (
    groupId: string,
    groupName: string,
    ids: string[],
    refetch: () => void,
    studentInGroupRefetch?: () => void,
  ) => Promise<void>
  moveStudentsInGroup: (
    groupId: string,
    userId: string,
    refetch: () => void,
    oldGroupId: string,
    studentInGroupRefetch: () => void,
  ) => Promise<void>
}

const useAddStudentsInGroup = (
  companyId: string,
): IUseAddStudentsInGroupReturnType => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)
  const { addStudents, moveStudents } = addStudentsService(companyId)

  const addStudentsInGroup = async (
    groupId: string,
    groupName: string,
    ids: string[],
    refetch: () => void,
    studentInGroupRefetch?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await addStudents({
      variables: { groupId, groupName, ids },
    })

    if (errors && errors.length) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${
      ids.length > 1 ? t('general.students') : t('general.student')
    } ${t('messages.successfully')} ${t('general.added')}`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
    refetch()
    if (studentInGroupRefetch) {
      studentInGroupRefetch()
    }
  }

  const moveStudentsInGroup = async (
    groupId: string,
    userId: string,
    refetch: () => void,
    oldGroupId: string,
    studentInGroupRefetch: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await moveStudents({
      variables: { groupId, oldGroupId, userId },
    })

    if (errors && errors.length) {
      setLoading(false)
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${t('general.student')} ${t('messages.successfully')} ${t(
      'general.moved',
    )}`
    variant = 'success'

    setSnackbar({ message, variant })
    setLoading(false)
    refetch()
    if (studentInGroupRefetch) {
      studentInGroupRefetch()
    }
  }

  return {
    loading,
    addStudentsInGroup,
    moveStudentsInGroup,
  }
}

export default useAddStudentsInGroup
