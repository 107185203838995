import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import Modal from '@mui/material/Modal'

const PREFIX = 'CustomModal'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

interface IModalParams {
  isOpened: boolean
  onClose: (event: object, reason: 'backdropClick' | 'escapeKeyDown') => void
  children: ReactElement
}

const CustomModal = ({
  isOpened,
  onClose,
  children,
}: IModalParams): ReactElement => {
  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={styles.modal}
      open={isOpened}
      onClose={onClose}
      closeAfterTransition
    >
      {children}
    </StyledModal>
  )
}

export default CustomModal
