import { ApolloError, MutationTuple, useMutation } from '@apollo/client'

import { ADD_REMOVE_COURSE_FROM_COMPANY } from 'gql/companies.query'

import { ICompany } from 'pages/companies/CompanyDetails/types'

interface IAddRemoveCourseFromCompanyServiceResponse {
  addRemoveCourseFromCompanyMutation: MutationTuple<
    IAddRemoveCourseFromCompanyResponse,
    IAddRemoveCourseFromCompanyVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IAddRemoveCourseFromCompanyResponse {
  addRemoveCourseFromCompany: ICompany
}

interface IAddRemoveCourseFromCompanyVariables {
  companyId: string
  coursesIds: string[]
}

export const addRemoveCourseFromCompanyService =
  (): IAddRemoveCourseFromCompanyServiceResponse => {
    const [addRemoveCourseFromCompanyMutation, { loading, error }] =
      useMutation<
        IAddRemoveCourseFromCompanyResponse,
        IAddRemoveCourseFromCompanyVariables
      >(ADD_REMOVE_COURSE_FROM_COMPANY)

    return {
      addRemoveCourseFromCompanyMutation,
      loading,
      error,
    }
  }
