import React, { ReactElement } from 'react'
import strCutter from 'utils/strCutter'
import { Tooltip } from '@mui/material'
import { TagContainer } from '../styled-components'
import { ITagProps } from '../tags.interface'

const Tag = ({
  tag,
  tooltip,
  type,
  redirectInDetails,
}: ITagProps): ReactElement => (
  <TagContainer
    onClick={(): void =>
      redirectInDetails && redirectInDetails(Number(tag.id) || 0)
    }
    key={tag.id}
  >
    {(tag.label?.length || 0) > 15 ? (
      <Tooltip title={tag.label}>
        <span>
          {type === 'courses'
            ? strCutter(tag.label, 20)
            : strCutter(tag.label, 7)}
        </span>
      </Tooltip>
    ) : tooltip ? (
      <Tooltip title={tooltip}>
        <span>{tag.label}</span>
      </Tooltip>
    ) : (
      <>{tag.label}</>
    )}
  </TagContainer>
)

export default Tag
