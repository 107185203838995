import styled from 'styled-components'

// DateRangePicker

export const Container = styled.div`
  position: relative;
  width: 100%;
`
export const PickerContainer = styled.div`
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  z-index: 2;
  background: #fff;
`

// DateRangePickerWithInput

export const Placeholder = styled.div`
  position: absolute;
  top: -8px;
  font-size: 12px;
  background: white;
  z-index: 2;
  left: 9px;
`
export const ErrorMessage = styled.div`
  background-color: white;
  padding: 5px 10px;
  border-radius: 3px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
`

export const Wrapper = styled.div`
  position: relative;
  &.error {
    color: #f44336;
  }

  div > p,
  > p {
    position: absolute;
    top: 30px;
    color: #f44336;
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`
