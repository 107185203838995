import { useSnackbar } from 'notistack'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

const useSnackbarAlert = (): {
  setSnackbar: ({ message, variant }: ISnackbarProps) => void
} => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const defaultMessage = t('messages.something_wrong')

  const setSnackbar = ({
    message = defaultMessage,
    variant,
  }: ISnackbarProps): void => {
    enqueueSnackbar(message, { variant, autoHideDuration: 3000 })
  }
  return {
    setSnackbar,
  }
}

export default useSnackbarAlert
