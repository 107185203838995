import React from 'react'

import strCutter from 'utils/strCutter'
import { getFileType } from 'helpers/getFileType'

import { default as CustomTooltip } from '@mui/material/Tooltip'
import {
  FileTitle,
  FileIcon,
  StyledFileContainer,
  classes,
} from './styled-components'
import { IFileWrapperParams } from './fileWrapper.interface'

const FileWrapper = ({
  icon,
  file,
  onClick,
}: IFileWrapperParams): React.ReactElement => {
  const type = file.fileType

  const fileType = getFileType(type)
  return (
    <StyledFileContainer onClick={(): void => onClick(file.link)}>
      <FileIcon>
        {icon ? icon : <img src={`/${fileType}_icon.svg`} alt="File icon" />}
      </FileIcon>

      <CustomTooltip
        title={`${file.name}.${file.fileType}`}
        classes={{
          tooltip: classes.tooltip,
        }}
      >
        <FileTitle>{strCutter(file.name, 20)}</FileTitle>
      </CustomTooltip>
    </StyledFileContainer>
  )
}

export default FileWrapper
