import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { DELETE_QUESTIONS } from 'gql/questions.query'

interface IDeleteQuestionsServiceResponse {
  deleteQuestionsMutation: MutationTuple<
    IDeleteQuestionsResponse,
    IDeleteQuestionsParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteQuestionsResponse {
  deleteQuestions: string
}

interface IDeleteQuestionsParams {
  moduleId: string
  questionIds: string[]
}

const deleteQuestionsService = (): IDeleteQuestionsServiceResponse => {
  const [deleteQuestionsMutation, { loading, error }] = useMutation<
    IDeleteQuestionsResponse,
    IDeleteQuestionsParams
  >(DELETE_QUESTIONS)

  return {
    deleteQuestionsMutation,
    loading,
    error,
  }
}

export default deleteQuestionsService
