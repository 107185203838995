import { useLazyQuery, useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { GET_USER } from 'gql/users.query'
import { GET_COMPANIES } from 'gql/companies.query'
import { UserSchema } from 'helpers/validationSchemas'
import useAddUserService from 'hooks/users/useAddUserService'
import useEditUserService from 'hooks/users/useEditUserService'
import { roles } from 'utils/permission'
import moment from 'moment'
import { genderOptions } from 'components/UsersLayout/staticData'
import {
  IModerator,
  IModeratorsDrawerValues,
  IUseModeratorsDrawerProps,
  IUseModeratorsDrawerReturn,
} from './useModeratorsDrawer.interface'
import { IUser } from 'interfaces/users'
import useCheckUserEmailService from 'hooks/users/checkUserEmail'

const useModeratorsDrawer = ({
  role,
  userId,
  companyId,
  onSuccess,
}: IUseModeratorsDrawerProps): IUseModeratorsDrawerReturn => {
  const [addingCompanyUser, setAddingCompanyUser] = useState(false)
  const [companyOptions, setCompanyOptions] = useState([])
  const [currentModerator, setCurrentModerator] = useState<IModerator | null>(
    null,
  )

  const { data: userData } = useQuery(GET_USER, {
    variables: { id: userId },
    skip: !userId,
  })

  const [getCompanyOptions, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  const { addUser, addUserLoading } = useAddUserService()
  const { checkUserEmail } = useCheckUserEmailService()

  const { updateUser, editUserLoading } = useEditUserService()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    setFieldError,
    setErrors,
  } = useFormik<IModeratorsDrawerValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      consultantOfCompanies: null,
      jobTitle: '',
      location: '',
      gender: null,
      phoneFields: null,
      phoneFieldValue: '',
      birthDate: '',
      biography: '',
      note: '',
      avatar: '',
    },
    validationSchema: UserSchema,
    async onSubmit(values) {
      const formValues = { ...values } as IModeratorsDrawerValues
      delete formValues?.phoneFieldValue

      if (!userId || (userData?.user && userData.user.email !== values.email)) {
        const emailError = await checkUserEmail(values.email)
        if (emailError) {
          setErrors({ email: emailError })
          return
        }
      }

      formValues.gender = formValues.gender ? formValues.gender.value : null
      if (formValues.phoneFields && formValues.phoneFields?.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = null
      }

      if (addingCompanyUser) {
        if (
          !values.consultantOfCompanies ||
          values.consultantOfCompanies.length === 0
        ) {
          setFieldError(
            'consultantOfCompanies',
            'Please select at least one company',
          )
          return
        }

        formValues.consultantOfCompanies = values.consultantOfCompanies.map(
          (item: { value: string }) => item.value,
        )
        formValues.role = roles.consultant
      } else {
        formValues.role = roles.super_admin
      }

      if (userId) {
        updateUser(userId, formValues as IUser, onSuccess)
      } else {
        addUser(formValues as IUser, role, companyId, onSuccess)
      }
    },
  })

  useEffect(() => {
    if (userData) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        phoneFields,
        birthDate,
        biography,
        note,
        avatar,
        consultantOfCompanies,
      } = userData.user

      const userGender = genderOptions.find(
        (option: { value: string }) => option.value === gender,
      )
      const formData = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender: gender
          ? {
              label: userGender?.label,
              value: gender,
            }
          : null,
        phoneFields: phoneFields.dialCode
          ? { label: phoneFields.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialcode || null,
        biography,
        note,
        birthDate: birthDate ? moment(parseInt(birthDate)).format() : null,
        avatar,
      }
      setValues(formData)

      if (consultantOfCompanies?.length) {
        setFieldValue(
          'consultantOfCompanies',
          consultantOfCompanies.map((company: { id: string }) => company.id),
        )
      }
      setCurrentModerator(formData)
    }
  }, [userData])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  const handleAddCompanyUserChange = (e: string): void => {
    if (addingCompanyUser) {
      setFieldValue('consultantOfCompanies', null)
      setAddingCompanyUser(false)
    } else {
      setAddingCompanyUser((prev) => !prev)
      setFieldValue('consultantOfCompanies', e)
      if (!companyData) {
        getCompanyOptions({
          variables: {
            currentPage: 0,
            perPage: 0,
          },
        })
      }
    }
  }

  const onDateChange = (date: string): void => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    userLoading: userId ? editUserLoading : addUserLoading,
    companyOptions,
    companyLoading,
    addingCompanyUser,
    setAddingCompanyUser,
    handleAddCompanyUserChange,
    onDateChange,
    getCompanyOptions,
    currentModerator,
    companyData,
  }
}

export default useModeratorsDrawer
