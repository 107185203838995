import React from 'react'
import { useFetchTaskCommentsReplies } from 'services/tasks/getTaskCommentsRepliesService'
import {
  StyledCommentContainer,
  styles,
  StyledCommentsContainer,
  StyledRoleContainer,
  StyledUserContainer,
  StyledUserDetailsContainer,
  AttachmentContainer,
} from './styled-component'
import moment from 'moment'
import { ReactComponent as AttachmentIcon } from 'assets/document-attachment.svg'
import { Link } from 'react-router-dom'
import AvatarIcon from 'assets/profile.svg'
import { ITaskComment, ITaskReplies } from './TaskComments.interface'
import { IAttachment } from 'interfaces/common'

const TaskReplies = ({ taskId, id }: ITaskReplies): JSX.Element => {
  const { taskCommentsReplies } = useFetchTaskCommentsReplies(taskId, id)
  const commentsData = taskCommentsReplies?.data

  const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  const mappedCommentsData = commentsData?.map((comment: ITaskComment) => {
    const { author, createDate, description, attachments } = comment
    const { firstName, lastName, avatar, role } = author

    return (
      <StyledCommentsContainer key={comment.id}>
        <StyledUserContainer>
          {' '}
          <StyledUserDetailsContainer>
            {avatar ? (
              <img style={styles.avatar} src={avatar} alt="avatarIcon" />
            ) : (
              <img style={styles.avatar} src={AvatarIcon} alt="avatarIcon" />
            )}

            <span style={styles.name}>
              {firstName} {lastName}
            </span>
            <StyledRoleContainer>
              {capitalizeFirstLetter(role)}
            </StyledRoleContainer>
          </StyledUserDetailsContainer>
          <span style={styles.time}>{moment(createDate).format(' HH:mm')}</span>
        </StyledUserContainer>
        <StyledCommentContainer>{description} </StyledCommentContainer>

        <>
          {attachments.map((attachment: IAttachment) => {
            return (
              <AttachmentContainer key={attachment._id}>
                <AttachmentIcon style={styles.fileIcon} />
                <Link
                  to={attachment.link || '#'}
                  style={styles.fileTitle}
                  target="_blank"
                  rel="noreferrer"
                >
                  {attachment.name}
                </Link>
              </AttachmentContainer>
            )
          })}
        </>

        <TaskReplies taskId={comment.taskId} id={comment.id} />
      </StyledCommentsContainer>
    )
  })

  return <>{mappedCommentsData}</>
}

export default TaskReplies
