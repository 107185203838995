import { useQuery } from '@apollo/client'
import { GET_BOOKS_BY_GROUP } from 'gql/books/books.query'
import {
  IUseFetchBooksByGroupProps,
  IUseFetchBooksResponse,
} from './bookServices.interface'

export const useFetchBooksByGroup = ({
  searchValue,
  currentPage = 1,
  perPage = 12,
  filterBy,
  groupId,
}: IUseFetchBooksByGroupProps): IUseFetchBooksResponse => {
  const { data, loading, error, refetch, fetchMore } = useQuery(
    GET_BOOKS_BY_GROUP,
    {
      variables: {
        searchValue,
        currentPage,
        perPage,
        filterBy,
        groupId,
      },
      skip: !groupId,
    },
  )
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    books: (data && data.getBooksByGroup) || [],
    loading,
    error: errorMessage,
    refetch,
    fetchMore: (variables) => fetchMore({ variables }),
  }
}
