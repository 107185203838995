import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { UPDATE_STUDENT } from 'gql/users.query'
import { IUser } from 'interfaces/users'

interface IUpdateStudentServiceResponse {
  updateStudentMutation: MutationTuple<
    IUpdateStudentResponse,
    IUpdateStudentParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IUpdateStudentParams {
  id: string
  input: IUser
}

interface IUpdateStudentResponse {
  updateStudent: IUser
}

const updateStudentService = (): IUpdateStudentServiceResponse => {
  const [updateStudentMutation, { loading, error }] = useMutation<
    IUpdateStudentResponse,
    IUpdateStudentParams
  >(UPDATE_STUDENT, {})

  return {
    updateStudentMutation,
    loading,
    error,
  }
}

export default updateStudentService
