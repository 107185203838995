import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import deleteQuizService from 'services/quizzes/deleteQuizService'

const useDeleteQuizService = (): {
  deleteQuiz: (ids: string[], fun: () => void, type?: string) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  const { deleteQuizMutation, loading } = deleteQuizService()

  const deleteQuiz = async (
    ids: string[],
    fun: () => void,
    type?: string,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteQuizMutation({
      variables: {
        ids,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${
        ids.length > 1 ? t('general.quizzes') : t('general.quiz')
      } ${t('messages.successfully')} ${t('general.deleted')}`

      if (type === 'course') {
        message = `${
          ids.length > 1 ? t('general.iq_tests') : t('general.iq_test')
        } ${t('messages.successfully')} ${t('general.deleted')}`
      }

      variant = 'success'
      fun()
    }

    setSnackbar({ message, variant })
  }
  return {
    deleteQuiz,
    loading,
  }
}

export default useDeleteQuizService
