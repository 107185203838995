import React, { ChangeEvent, useEffect, useState } from 'react'
import { useUserValue } from 'context/UserContext'
import { useSwal, VerticalSwalButtonClasses } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import { actionConfig, columnConfig } from 'components/UsersLayout/gridConfig'
import { PER_PAGE } from 'hooks/users/useUsers'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'
import { useExportDataService } from 'hooks/helpers/useHelpersService'
import { useLazyFetchStudents } from 'hooks/users/useFetchStudents'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { ReactComponent as Users } from 'components/common/Button/icons/user-multiple.svg'
import { IUser } from 'interfaces/users'
import { useNavigate } from 'react-router-dom'
import {
  IStudentsSelectAllItems,
  IUseStudentsLayoutReturn,
} from './useStudentsLayout.interface'
import { RolesCapitalized } from 'utils/permission'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import {
  IFilterQueryType,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import { useDebouncedSearch } from 'hooks/helpers/useHelperSearch/useHelperSearch'
import {
  useSelectAllItem,
  useSelectItem,
} from 'hooks/helpers/useHelperSelect/useHelperSelect'
import { useOpenDrawer } from 'hooks/helpers/useOpenDrawer/useOpenDrawer'
import { useHelperModalClose } from 'hooks/helpers/useHelperModalClose/useHelperModalClose'
import { useHelperDeleteUser } from 'hooks/helpers/useHelperDeleteUser/useHelperDeleteUser'
import { useHelperChangeUserStatus } from 'hooks/helpers/useHelperChangeUserStatus/useHelperChangeUserStatus'
import { useHelperUsersActions } from 'hooks/helpers/useHelperUsersActions/useHelperUsersActions'

const useStudentsLayout = ({
  groupId,
  courseId,
}: {
  groupId?: string
  courseId?: string
}): IUseStudentsLayoutReturn => {
  const ROLE = 'STUDENT'

  const { t } = useTranslation()
  const [state] = useUserValue()
  const [defaultFilter, setDefaultFilter] = useState<IFilterQueryType>({})

  const companyId = state.selectedCompany?.id || null

  useEffect(() => {
    let filter = {}
    if (groupId) {
      filter = {
        group: {
          nestedField: 'groupId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: [groupId],
        },
      }
    } else if (courseId) {
      filter = {
        courses: {
          nestedField: 'courseId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: [courseId],
        },
      }
    } else if (state.selectedCompany?.id) {
      filter = {
        companyId: {
          type: LowerCaseFilterableFieldType.EXACT,
          value: state.selectedCompany.id,
        },
      }
    }
    setDefaultFilter(filter)
  }, [state, groupId, courseId])

  const { users, loading, fetchStudents } = useLazyFetchStudents()

  useEffect(() => {
    const canBeFetched =
      (companyId && defaultFilter.companyId) || (!companyId && defaultFilter)
    if (canBeFetched) {
      fetchStudents({
        filter: {
          ...defaultFilter,
        },
        currentPage: 1,
        perPage: PER_PAGE,
      })
    }
  }, [defaultFilter, companyId])

  const { deleteUserLoading } = useUserDeleteService()
  const { updateUserPassword } = useResendUserService({
    role: ROLE,
    defaultFilter,
  })
  const { updateUserStatusLoading } = useUpdateStatusUserService({
    role: ROLE,
    defaultFilter,
  })
  const { exportData } = useExportDataService()

  const { fireSwal } = useSwal()
  const navigate = useNavigate()

  const [selectedItem, setSelectedItem] = useState<ISelectedItem>({})
  const allSelectedItems = Object.values(selectedItem)?.flat()
  const [selectAll, setSelectAll] = useState<IStudentsSelectAllItems>({})
  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)
  const [resetVisible, setResetVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [filterQuery, setFilterQuery] =
    useState<IFilterQueryType>(defaultFilter)

  useEffect(() => {
    setSelectedItem({})
    setSelectAll({})
    setFilterQuery(defaultFilter)
    setCurrentPage(1)
    setIsFiltered(false)
  }, [state.selectedCompany])

  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })

  const { sendPushNotifications } = useSendPushNotifications()
  const handleModalClose = useHelperModalClose({
    setPushNotificationModal,
    setMessageData,
  })

  const handlePushNotificationSend = async (): Promise<false | undefined> => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const selectItem = useSelectItem({
    setSelectAll,
    setSelectedItem,
    selectedItem,
    selectAll,
    currentPage,
  })

  const selectAllItem = useSelectAllItem({
    data: users,
    selectAll,
    currentPage,
    setSelectedItem,
    setSelectAll,
    selectedItem,
  })

  const { openAddDrawer } = useOpenDrawer({
    drawerName: 'addStudent',
    fetchData: fetchStudents,
    perPage,
    defaultFilter,
    setSelectedItem,
  })

  const handleClick = (user: IUser): void => {
    navigate(`/profile/${user.id}`)
  }

  const { confirmDelete, confirmDeleteMany } = useHelperDeleteUser({
    ROLE,
    defaultFilter,
    refetch: fetchStudents,
    perPage,
    currentPage,
    filteredByCompany: companyId as string,
    selectedItem,
    setSelectedItem,
  })

  const confirmResendPassword = (id: string): void => {
    updateUserPassword(id)
  }

  const { confirmChangeStatus } = useHelperChangeUserStatus({
    defaultFilter,
    ROLE,
    allSelectedItems,
    setSelectedItem,
  })

  const { handleEditClick } = useOpenDrawer({
    fetchData: fetchStudents,
    perPage,
    defaultFilter,
    setSelectedItem,
    drawerName: 'editStudent',
  })

  const handleDeleteAllClick = (): void => {
    const roleText = 'Students'
    fireSwal({
      title: `Are you sure you want to delete selected ${roleText}?`,
      onConfirm: () => confirmDeleteMany(),
      confirmText: 'Yes, delete!',
    })
  }

  const handleDeleteClick = (user: IUser): void => {
    const params = {
      title: `${t('popups.delete_single')} ${t(
        `general.${ROLE.toLocaleLowerCase()}`,
      )}?`,
      onConfirm: (): void => {
        if (user.id) confirmDelete([user.id])
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleActivityClick = (userId?: string): void => {
    const url = `/profile/${userId}/activity`
    window.open(url, '_blank')
  }

  const handleResendPasswordClick = (userId?: string): void => {
    fireSwal({
      title: `${t('popups.resend_password')} ${t(
        `general.${ROLE.toLocaleLowerCase()}`,
      )}?`,

      onConfirm: () => {
        if (!userId) return
        confirmResendPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handleChangeStatusClick = (userId?: string, status?: string): void => {
    if (status === 'ACTIVE') {
      fireSwal({
        title: `${t('popups.suspend_single')} ${t(
          `general.${ROLE.toLocaleLowerCase()}`,
        )}?`,
        onConfirm: () => {
          if (!userId) return
          confirmChangeStatus(userId, 'SUSPENDED')
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      fireSwal({
        title: `${t('popups.activate_single')} ${t(
          `general.${ROLE.toLocaleLowerCase()}`,
        )}?`,
        onConfirm: () => {
          if (!userId) return
          confirmChangeStatus(userId, 'ACTIVE')
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  const { handleSearchChange, searchValue, isSearchType } = useDebouncedSearch({
    fetchFunction: fetchStudents,
    defaultFilter,
    currentPage,
    perPage,
  })

  const handleExportData = (): void => {
    if (!allSelectedItems.length) return

    if (allSelectedItems.length === users?.totalCount) {
      exportData(ROLE, allSelectedItems, null, (link: string) => {
        window.open(link, '_blank')
      })
    } else {
      fireSwal({
        icon: 'question',
        title: t('popups.download_students'),
        showCancelButton: true,
        showConfirmButton: true,
        customClass: VerticalSwalButtonClasses,
        confirmText: `${t('general.all')} ${t('analytics.students')}`,
        denyText: `${t('general.selected')} ${t('analytics.students')}`,
        onConfirm: () => {
          exportData(ROLE, [], companyId, (link: string) => {
            window.open(link, '_blank')
          })
        },
        onDeny: () => {
          exportData(ROLE, allSelectedItems, null, (link: string) => {
            window.open(link, '_blank')
          })
        },
      })
    }
  }

  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)

  const label = t('user_details.student_details')

  const config = columnConfig(ROLE, selectItem, t, label)

  const [userId, setUserId] = useState<string | undefined>('')
  const field = RolesCapitalized.STUDENT

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleResendPasswordClick,
    handleChangeStatusClick,
    state.userPermission,
    field,
    t,
    setResetPasswordOpen,
    setUserId,
  )

  const { multiActions } = useHelperUsersActions({
    handleDeleteAllClick,
    field,
    state,
    ROLE,
    defaultFilter,
    setSelectedItem,
    handleExportData,
    setPushNotificationModal,
    allSelectedItems,
  })

  const filter = (filters: IFilterQueryType): void => {
    fetchStudents({
      filter: { ...filters, ...defaultFilter },
      currentPage: 1,
      perPage,
    })

    if (users) {
      setFilterDrawerOpened(false)
      setResetVisible(true)
      setFilterQuery(filters)
      setSelectedItem({})
    }

    setIsFiltered(JSON.stringify(filters) !== '{}')
  }

  const resetFilter = (): void => {
    setFilterQuery(defaultFilter)
    setFilterOptions(null)
    setSelectedItem({})
    setSelectAll({})
    setIsFiltered(false)
    setResetVisible(false)
    fetchStudents({
      filter: defaultFilter,
      currentPage: 1,
      perPage,
    })
    setCurrentPage(1)
  }

  const handlePaginationClick = (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): void => {
    if (newPage !== undefined) {
      fetchStudents({
        filter: { ...filterQuery, ...defaultFilter },
        currentPage: newPage,
        perPage,
      })
      setCurrentPage(newPage)
    }
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    fetchStudents({
      filter: { ...filterQuery, ...defaultFilter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
    setSelectedItem({})
  }

  const addText = 'Add Student'

  const createButton = (): React.JSX.Element => (
    <Button
      text={addText}
      color="secondary"
      onClick={openAddDrawer}
      icon={<AddCircleIcon />}
      background="#06C68F"
    />
  )

  const breadCrumbData = [
    {
      label: 'Users',
      icon: <Users />,
    },
    {
      label: 'Students',
    },
  ]

  const [filterOptions, setFilterOptions] = useState<IFilterQueryType | null>(
    null,
  )

  return {
    breadCrumbData,
    t,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    setFilterDrawerOpened,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoading,
    users,
    resetVisible,
    handleClick,
    config,
    actions,
    selectAllItem,
    selectItem,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    setResetPasswordOpen,
    userId,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    filterDrawerOpened,
    ROLE,
    filter,
    state,
    loading,
    setFilterOptions,
    filterOptions,
    setIsFiltered,
    isFiltered,
    companyId,
    isSearchType,
    resetFilter,
  }
}

export default useStudentsLayout
