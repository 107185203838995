import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import {
  IUseTestGroupDetailsServices,
  IUseTestGroupDetailsServicesReturn,
} from './groupDetails.interface'
import { IGroup } from 'interfaces/groups'
import { useSwal } from 'hooks/useSwal'

import { columnConfig } from 'pages/group/gridConfigV2'
import { actionConfig } from './gridConfig'
import { ISelectAll } from 'pages/tests/testLayout/testLayout.interface'
import { useUserValue } from 'context/UserContext'
import useAddTestAssessmentToGroupsService from 'services/tests/addTestAssessmentToGroups'
import useDeleteTestAssessmentFromGroupsService from 'services/tests/deleteTestAssessmentFromGroups'
import { ISelectPage } from 'components/common/FilterInterface/filter.interface'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'

const useTestGroupDetailsServices = ({
  testAssessmentId,
  refetchGroups,
  groups,
}: IUseTestGroupDetailsServices): IUseTestGroupDetailsServicesReturn => {
  // states
  const [selectedItem, setSelectedItem] = useState<ISelectPage>({})
  const [state] = useUserValue()
  const [addGroupDrawer, setAddGroupDrawer] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [selectAll, setSelectAll] = useState<ISelectAll>({})
  const [filterDrawer, setFilterDrawer] = useState<IGroupFilterDrawerOptions>({
    opened: false,
    filterOptions: null,
    filterValues: null,
  })
  const [searchText, setSearchText] = useState<string>('')

  // router
  const navigate = useNavigate()
  const { fireSwal } = useSwal()
  const { t } = useTranslation()

  // apollo hooks
  const { addTestAssessmentToGroup } = useAddTestAssessmentToGroupsService()
  const { deleteTestAssessmentFromGroup } =
    useDeleteTestAssessmentFromGroupsService()

  const handleResetClick = (): void => {
    setFilterDrawer((prev: IGroupFilterDrawerOptions) => ({
      ...prev,
      filterOptions: null,
      filterValues: null,
    }))
    setSearchText('')
    refetchGroups({
      currentPage,
      perPage,
      filter: null,
      testAssessmentId,
    })
  }

  const handleAddGroupsToCourse = (groupIds: string[]): void => {
    addTestAssessmentToGroup({
      testAssessmentId,
      groupIds: groupIds,
    }).then(() => {
      refetchGroups({
        testAssessmentId,
      })
      setAddGroupDrawer(false)
    })
  }

  const handleDeleteGroups = (groupIds: string[]): void => {
    fireSwal({
      title:
        groupIds.length > 1
          ? t('popups.delete_groups_from_test')
          : t('popups.delete_group_from_test'),
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => {
        deleteTestAssessmentFromGroup({
          testAssessmentId: testAssessmentId,
          groupIds,
        }).then(() => {
          refetchGroups({
            testAssessmentId,
          })
          setSelectedItem({})
          setSelectAll({})
        })
      },
    })
  }

  const selectItem = (id: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = groups?.length
        ? groups.map((n: IGroup) => n.id as string)
        : []
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  // Search Submit Handler
  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()

    const searchValue = { type: 'match', value: searchText }
    setCurrentPage(1)
    refetchGroups({
      filter: {
        name: searchValue,
      },
      currentPage: 1,
      perPage,
    })
  }

  const handleClick = (group: IGroup): void => {
    navigate(`/group/${group.id}`)
  }

  const config = columnConfig(handleClick, selectItem, t, true)
  const actions = actionConfig(handleDeleteGroups, t)

  const selectedItemsOnPage = selectedItem[currentPage] || []

  const handleChangeGroupRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetchGroups({
      filter: filterDrawer.filterOptions,
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const handleGroupsPaginationClick = (
    _: ChangeEvent<unknown> | null,
    newPage: number,
  ): void => {
    setCurrentPage(newPage + 1)
    refetchGroups({
      filter: filterDrawer.filterOptions,
      currentPage: newPage + 1,
      perPage,
    })
  }

  return {
    handleAddGroupsToCourse,
    handleDeleteGroups,
    selectItem,
    selectAllItem,
    config,
    state,
    actions,
    onSearchSubmit,
    handleGroupsPaginationClick,
    handleChangeGroupRowsPerPage,
    setAddGroupDrawer,
    selectedItem,
    setSelectedItem,
    selectAll,
    setSelectAll,
    currentPage,
    setCurrentPage,
    perPage,
    selectedItemsOnPage,
    searchText,
    setSearchText,
    filterDrawer,
    setFilterDrawer,
    addGroupDrawer,
    handleClick,
    handleResetClick,
  }
}

export default useTestGroupDetailsServices
