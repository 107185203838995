import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router'
import update from 'immutability-helper'
import { Button } from 'components/common/Button'
import { DrawerEventEmitter } from 'helpers/drawer'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { filterTopics } from 'pages/topics/FilterDrawer'
import { useUserValue } from 'context/UserContext'
import { useSwal } from 'hooks/useSwal'
import { useChangeTopicsOrder, useEditTopic } from 'hooks/topics/useEditTopic'
import useDeleteLessonService from 'hooks/lessons/useDeleteLessonService'
import { useDeleteTopic } from 'hooks/topics/useDeleteTopic'
import {
  IColumnItem,
  IFilterData,
  IHandleLessonClick,
  IHandleSearchSubmit,
  ILoadTopicList,
  IMediaData,
  IModalData,
  IModuleTopicsGridV2Props,
} from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import {
  actionConfig,
  columnConfig,
  nestedActions,
  nestedColumConfig,
} from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/gridConfig'
import { IUseModuleTopicsGridV2 } from './useModuleTopicGridV2.interface'
import { ITopic } from 'pages/modules/ModulesList.interface'

const filterOptions = {
  name: '',
  numberOfLessons: [0, 0],
  duration: [0, 0],
  attachment: '',
  lessons: [],
}

const useModuleTopicsGridV2 = ({
  moduleId,
  data,
  refetch,
  canModify,
  routerState,
  t,
}: IModuleTopicsGridV2Props): IUseModuleTopicsGridV2 => {
  const modalData: IModalData = {
    modal: false,
    url: '',
    field: '',
    subtitle: null,
  }
  const { fireSwal } = useSwal()
  const navigate = useNavigate()
  const [mediaModal, setMediaModal] = useState(modalData)
  const [state] = useUserValue()

  const [searchValue, setSearchValue] = useState<string>('')
  const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false)
  const [filtered, setFiltered] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<IFilterData>(filterOptions)
  const [filteredData, setFilteredData] = useState<ITopic[] | null>(null)
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [collapsedItems, setCollapsedItems] = useState<string[]>([])
  const { deleteTopic } = useDeleteTopic()
  const { deleteLesson } = useDeleteLessonService({
    moduleId,
  })
  const { changeTopicsOrder } = useChangeTopicsOrder({
    moduleId,
    filter: {},
  })
  const { editTopic } = useEditTopic({
    topicId: '',
  })

  const [cards, setCards] = useState<ITopic[]>([])
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]

      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const selectItem = (id: string): void => {
    if (selectedItem.includes(id)) {
      setSelectedItem(selectedItem.filter((item) => item !== id))
    } else {
      setSelectedItem([...selectedItem, id])
    }

    setSelectAll(false)
  }

  const handleDeleteTopic = (topicId: string): void => {
    const params = {
      title: t('popups.delete_topic_single'),
      onConfirm: (): Promise<void | false> =>
        deleteTopic(moduleId, [topicId], () => refetch()),

      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const selectAllItem = (): void => {
    if (!selectAll) {
      const newArr: string[] = data.map((n) => n.id) as string[]
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const getTopicList = (): { name: string; id: string }[] =>
    data.map((i) => ({ name: i.name as string, id: i.id as string }))

  const handleDrawerOpen = (field: string): void => {
    const loadTopicList: ILoadTopicList = {}

    if (field === 'addLesson') {
      loadTopicList.topicList = getTopicList()
    }

    DrawerEventEmitter.emit('openDrawer', field, true, {
      id: moduleId,
      data: { id: '', ...loadTopicList, refetch: () => refetch() },
    })
  }

  const handleAddLessonClick = (e: IHandleLessonClick): void => {
    DrawerEventEmitter.emit('openDrawer', 'addLesson', true, {
      id: moduleId,
      data: { name: e.name, id: e.id, refetch: () => refetch() },
    })
  }

  const confirmDelete = (ids: string[]): void => {
    deleteLesson(ids, () => refetch())
    setSelectedItem([])
  }

  const handleDeleteClick = (ids: string[]): void => {
    const isMultiple = ids.length > 1 ? true : false
    const mainText: string = isMultiple
      ? t('popups.delete_lesson_many')
      : t('popups.delete_lesson_single')
    fireSwal({
      title: `${mainText}`,
      confirmText: t('popups.confirm_delete'),
      onConfirm: () => confirmDelete(ids),
    })
  }

  const handleLessonDelete = (lessonId: string): void => {
    const params = {
      title: t('popups.delete_lesson_single'),
      onConfirm: (): Promise<void> => deleteLesson([lessonId], () => refetch()),
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }
  const onTopicEdit = (
    topicId: string,
    name: string,
    callBack: () => void,
  ): void => {
    editTopic(topicId, { name }, callBack)
  }

  const config = columnConfig(t, onTopicEdit, canModify)

  const onCollapseClick = (id: string): void => {
    setCollapsedItems((prevItems) => {
      if (prevItems.includes(id)) {
        return prevItems.filter((item) => item !== id)
      } else {
        return [...prevItems, id]
      }
    })
  }

  const actions = actionConfig(
    handleAddLessonClick,
    handleDeleteTopic,
    state.userPermission,
    onCollapseClick,
    collapsedItems,
    t,
  )

  const handleOpenMediaModal = (data: IMediaData, field?: string): void => {
    if (mediaModal.modal) {
      setMediaModal(modalData)
      return
    }
    setMediaModal({
      modal: true,
      url:
        field === 'playAudio'
          ? (data?.audio?.link as string)
          : (data?.video?.links[0].url as string),
      subtitle: field === 'playAudio' ? null : (data.subtitle as string),
      field,
    })
  }

  const filter = (filterOptions: IFilterData): void => {
    const filtered = filterTopics(filterOptions, cards)
    setFilteredData(filtered)
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const filterOptions = {
      name: searchValue,
    }
    filter(filterOptions as IFilterData)
  }

  const handleSearchChange = (e: IHandleSearchSubmit): void => {
    setSearchValue(e.target.value)
    if (e.target.value === '') setFilteredData(null)
  }

  const handleResetClick = (): void => {
    setFilteredData(null)
    setFiltered(false)
    setSearchValue('')
    setFilterData(filterOptions)
  }

  const nestedEvents = (
    val: IColumnItem | string,
    field: string,
  ): void | Window | null => {
    switch (field) {
      case 'playVideo':
        return handleOpenMediaModal(
          {
            audio: (val as IColumnItem).audio,
            video: (val as IColumnItem).video,
            subtitle: Array.isArray((val as IColumnItem).subtitle)
              ? ((val as IColumnItem).subtitle as string[]).join(' ')
              : ((val as IColumnItem).subtitle as string),
          },
          field,
        )
      case 'playAudio':
        return handleOpenMediaModal(
          {
            audio: (val as IColumnItem).audio,
            video: (val as IColumnItem).video,
            subtitle: Array.isArray((val as IColumnItem).subtitle)
              ? ((val as IColumnItem).subtitle as string[]).join(' ')
              : ((val as IColumnItem).subtitle as string),
          },
          field,
        )
      case 'view':
        return navigate(`/lessons/${(val as IColumnItem).id}`, {
          state: routerState,
        })
      case 'edit':
        return DrawerEventEmitter.emit('openDrawer', 'editLesson', true, {
          id: moduleId,
          data: { id: (val as IColumnItem).id },
        })
      case 'delete':
        return handleLessonDelete((val as IColumnItem).id as string)
      case 'download':
        return window.open(val as string, '_blank')
      default:
        return
    }
  }

  const nestedConfig = nestedColumConfig(nestedEvents, t, selectItem, canModify)
  const nestedAction = nestedActions(
    nestedEvents,
    state.userPermission,
    canModify,
    t,
  )

  useEffect(() => {
    if (data) {
      const topicList = data.filter((item) =>
        item.name?.toLowerCase().includes(searchValue.toLowerCase()),
      )
      setCards(topicList)
    }
  }, [data, searchValue])

  const createButton = (): React.JSX.Element => (
    <>
      {state.userPermission.createLesson && canModify && (
        <Button
          text={t('lessons_layout.create_lesson')}
          size="small"
          color="secondary"
          onClick={(): void => handleDrawerOpen('addLesson')}
          icon={<AddCircleRoundedIcon />}
          background="#06C68F"
        />
      )}
    </>
  )

  return {
    cards,
    createButton,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    filter,
    setFiltered,
    state,
    handleDrawerOpen,
    selectedItem,
    handleDeleteClick,
    filtered,
    handleResetClick,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    config,
    filteredData,
    actions,
    nestedConfig,
    selectAllItem,
    changeTopicsOrder,
    moveCard,
    nestedAction,
    setCollapsedItems,
    collapsedItems,
    onCollapseClick,
    mediaModal,
    handleOpenMediaModal,
  }
}

export default useModuleTopicsGridV2
