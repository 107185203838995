import { MutationTuple, useMutation } from '@apollo/client'

import { INSERT_STUDENTS_WITH_GROUPS } from 'gql/users.query'
import { IImportStudentsWithGroupsInput, IUser } from 'interfaces/users'

interface IImportStudentsWithGroupsServiceResponse {
  insertStudentsWithGroups: MutationTuple<
    IImportStudentsWithGroupsResponse,
    IInsertStudentsWithGroupsParams
  >[0]
  loading: boolean
}

interface IInsertStudentsWithGroupsParams {
  students: IImportStudentsWithGroupsInput[]
  companyId: string
}

interface IImportStudentsWithGroupsResponse {
  insertStudentsWithGroups: IUser[]
}

const importStudentsWithGroupsService =
  (): IImportStudentsWithGroupsServiceResponse => {
    const [insertStudentsWithGroups, { loading }] = useMutation<
      IImportStudentsWithGroupsResponse,
      IInsertStudentsWithGroupsParams
    >(INSERT_STUDENTS_WITH_GROUPS)

    return {
      insertStudentsWithGroups,
      loading,
    }
  }

export default importStudentsWithGroupsService
