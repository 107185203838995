import { useState } from 'react'
import {
  editTopicService,
  changeTopicsOrderService,
  changeLessonOrderService,
  ILessonOrderServiceProps,
} from 'services/topics/editTopicService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { ITopic } from 'pages/modules/ModulesList.interface'

export const useEditTopic = (params: {
  topicId: string
}): {
  editTopic: (topicId: string, input: ITopic, callBack?: () => void) => void
  editTopicLoading: boolean
  newTopic: ITopic | null
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [newTopic, setNewTopic] = useState<ITopic | null>(null)
  const [error, setError] = useState('')
  const { editTopicMutation, loading } = editTopicService(params)

  const editTopic = async (
    topicId: string,
    input: ITopic,
    callBack?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await editTopicMutation({
      variables: {
        topicId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else if (data) {
      setNewTopic(data.editTopic)
      message = t('messages.changes_saved')
      variant = 'success'
      if (callBack) callBack()
    }

    setSnackbar({ message, variant })
  }

  return {
    editTopic,
    editTopicLoading: loading,
    newTopic,
    error,
  }
}

export const useChangeTopicsOrder = (
  params: ILessonOrderServiceProps,
): {
  changeTopicsOrder: (draggedId: string, droppedId: string) => Promise<void>
  changeTopicsOrderLoading: boolean
  error: string
} => {
  const [error, setError] = useState('')
  const { changeTopicsOrderMutation, loading } =
    changeTopicsOrderService(params)

  const changeTopicsOrder = async (
    draggedId: string,
    droppedId: string,
  ): Promise<void> => {
    const { errors } = await changeTopicsOrderMutation({
      variables: {
        draggedId,
        droppedId,
      },
    })

    if (errors) {
      setError(errors[0].message)
      return
    }
  }

  return {
    changeTopicsOrder,
    changeTopicsOrderLoading: loading,
    error,
  }
}

export const useChangeLessonsOrder = (
  params: ILessonOrderServiceProps,
): {
  changeLessonsOrder: (draggedId: string, droppedId: string) => void
  changeLessonsOrderLoading: boolean
  error: string
} => {
  const [error, setError] = useState('')
  const { changeLessonOrderMutation, loading } =
    changeLessonOrderService(params)

  const changeLessonsOrder = async (
    draggedId: string,
    droppedId: string,
  ): Promise<void> => {
    const { errors } = await changeLessonOrderMutation({
      variables: {
        draggedId,
        droppedId,
      },
    })

    if (errors) {
      setError(errors[0].message)
      return
    }
  }

  return {
    changeLessonsOrder,
    changeLessonsOrderLoading: loading,
    error,
  }
}
