import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  StyledFromGroupContainer,
  StyledFormGroupWrapper,
  ItemWrapper,
  StyledFormGroupTitle,
} from './styled-components'

import { FormGroupItem } from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import HelpIcon from '@mui/icons-material/Help'
import CreatableSelect from 'react-select/creatable'
import { IQTestTypes } from 'pages/quizzes/staticData'
import { GET_TAGS } from 'gql/skilss.query'
import { useTranslation } from 'react-i18next'
import { IStep1Props } from './addQuizDrawer.interface'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { Title } from 'components/DrawerLayout/styled-components'

const Step1 = ({
  values,
  companyId,
  addedIQTestType,
  setFieldValue,
  IQTest,
  errors,
  touched,
  handleChange,
  type,
  handleQuizParticipationTypeChange,
  handleBlur,
}: IStep1Props): React.JSX.Element => {
  const { data } = useQuery(GET_TAGS, {
    variables: { companyId: companyId },
  })

  const tagList =
    (data &&
      data.getAllTags.map((i: { label: string; id: string }) => ({
        label: i.label,
        value: i.id,
      }))) ||
    []

  let IQTestTypeValue = values.position as string

  if (addedIQTestType) {
    IQTestTypeValue =
      addedIQTestType === 'initial'
        ? IQTestTypes[1].value
        : IQTestTypes[0].value
  }

  useEffect(() => {
    if (addedIQTestType) {
      setFieldValue('IQTestType', IQTestTypeValue)
    }
  }, [addedIQTestType])

  useEffect(() => {
    if (IQTest) {
      setFieldValue('quizParticipationType', 'optional')
    }
  }, [IQTest])

  const { t } = useTranslation()
  return (
    <>
      <FormGroupItem>
        <Title>{`${t('assessment_drawer.assessment_name')}*`}</Title>
        <Input
          error={!!errors.name}
          errorMessage={errors.name ? errors.name : ''}
          touched={touched.name}
          placeholder={t('general.name')}
          name="name"
          size="small"
          type="text"
          fullWidth
          value={values.name}
          onChange={handleChange}
        />
      </FormGroupItem>

      <FormGroupItem>
        <Title>{t('assessment_drawer.select_tags')}</Title>
        <CreatableSelect
          label={`${t('form_fields.select')} ${t('general.group')}`}
          name="group"
          isMulti
          onChange={(value): void =>
            setFieldValue('tags', value ? [...value] : [])
          }
          options={tagList}
          value={values.tags}
          placeholder={t('assessment_drawer.select_tags')}
        />
      </FormGroupItem>

      <FormGroupItem>
        <Title>{t('assessment_drawer.quiz_overview')}</Title>
        <TextEditor
          isVisible={true}
          hasControls={false}
          contentHeight="200px"
          handleChange={(editorState: string): void => {
            setFieldValue('description', editorState)
          }}
          canEdit={true}
          defaultText={
            type === 'course'
              ? t('quiz_details.iq_test_overview')
              : t('quiz_details.quiz_overview')
          }
        />
      </FormGroupItem>

      <FormGroupItem>
        <Title>{t('assessment_drawer.time_in_minutes')}</Title>
        <Input
          error={!!errors.time}
          errorMessage={errors.time ? errors.time : ''}
          touched={touched.time}
          placeholder={t('assessment_drawer.e.g._20')}
          name="time"
          size="small"
          type="number"
          fullWidth
          value={values.time}
          onChange={handleChange}
        />
      </FormGroupItem>

      {/* Commented timeType for time */}
      {/* <FormGroupItem>
        <RadioGroup
          name="timeType"
          value={values.timeType}
          onChange={handleChange}
        >
          <StyledFromGroupContainer>
            <StyledFormGroupWrapper>
              {t('quiz_details.time_type')}
              {values.time && '*'}
            </StyledFormGroupWrapper>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value="estimated"
                  control={<Radio color="secondary" />}
                  label={t('general.estimated')}
                  disabled={!values.time}
                />
              </ItemWrapper>

              <ItemWrapper>
                <FormControlLabel
                  value="limited"
                  control={<Radio color="secondary" />}
                  label={t('general.limited')}
                  disabled={!values.time}
                />
              </ItemWrapper>

              <HelpButtonContainer>
                <Tooltip
                  title={
                    values.timeType &&
                    (values.timeType === 'estimated' ? (
                      <span style={{ fontSize: '14px' }}>
                        {t('general.estimated_helper')}
                      </span>
                    ) : (
                      <span style={{ fontSize: '14px' }}>
                        {t('general.limited_helper')}
                      </span>
                    ))
                  }
                  arrow
                >
                  <HelpIcon color="secondary" style={{ color: '#06C68F' }} />
                </Tooltip>
              </HelpButtonContainer>
            </StyledFormGroupWrapper>
            {touched.timeType && errors.timeType && (
              <ErrorMessage>{errors.timeType}</ErrorMessage>
            )}
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem> */}

      {!IQTest && (
        <>
          <FormGroupItem>
            <RadioGroup
              name="quizParticipationType"
              value={values.quizParticipationType}
              onChange={(e): void =>
                handleQuizParticipationTypeChange(e.target.value)
              }
            >
              <StyledFromGroupContainer>
                <StyledFormGroupTitle>
                  <Title>
                    {type === 'course'
                      ? `${t('general.iq_test')} ${t(
                          'quiz_details.participation',
                        )} ${t('general.type')}*`
                      : `${t('general.quiz')} ${t(
                          'quiz_details.participation',
                        )} ${t('general.type')}*`}
                  </Title>
                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {values.quizParticipationType &&
                        values.quizParticipationType === 'mandatory'
                          ? t('general.mandatory_helper')
                          : t('general.optional_helper')}
                      </span>
                    }
                    arrow
                  >
                    <HelpIcon color="secondary" style={{ color: '#06C68F' }} />
                  </Tooltip>
                </StyledFormGroupTitle>
                <StyledFormGroupWrapper>
                  <ItemWrapper>
                    <FormControlLabel
                      value="optional"
                      control={<Radio color="secondary" />}
                      label={t('general.optional')}
                      disabled={false}
                    />
                  </ItemWrapper>
                  <ItemWrapper>
                    <FormControlLabel
                      value="mandatory"
                      control={<Radio color="secondary" />}
                      label={t('general.mandatory')}
                      disabled={false}
                    />
                  </ItemWrapper>
                </StyledFormGroupWrapper>
              </StyledFromGroupContainer>
            </RadioGroup>
          </FormGroupItem>
          <FormGroupItem>
            <Title>{t('assessment_drawer.pass_rate')}</Title>
            <Input
              error={!!errors.passRate}
              errorMessage={errors.passRate ? errors.passRate : ''}
              touched={touched.passRate}
              placeholder={`${t('quiz_details.pass_rate')} ( % )${
                values.quizParticipationType !== 'optional' ? '*' : ''
              }`}
              name="passRate"
              size="small"
              type="number"
              fullWidth
              value={values.passRate}
              onChange={handleChange}
              disabled={values.quizParticipationType === 'optional'}
              onBlur={handleBlur}
            />
          </FormGroupItem>
        </>
      )}

      {/* isShowAnswersTurnedOn component */}
      <FormGroupItem>
        <RadioGroup
          name="isShowAnswersTurnedOn"
          value={values.isShowAnswersTurnedOn}
          onChange={(): void =>
            setFieldValue(
              'isShowAnswersTurnedOn',
              !values.isShowAnswersTurnedOn,
            )
          }
        >
          <StyledFromGroupContainer>
            <StyledFormGroupTitle>
              {' '}
              <Title>{t('quiz_details.isShowAnswersTurnedOn')}</Title>
              <Tooltip
                title={
                  <span style={{ fontSize: '14px' }}>
                    {t('quiz_details.isShowAnswersTurnedOn_helper')}
                  </span>
                }
                arrow
              >
                <HelpIcon color="secondary" style={{ color: '#06C68F' }} />
              </Tooltip>
            </StyledFormGroupTitle>

            <StyledFormGroupWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value={true}
                  control={<Radio color="secondary" />}
                  label={t('general.yes')}
                  // disabled={!values.randomization}
                />
              </ItemWrapper>
              <ItemWrapper>
                <FormControlLabel
                  value={false}
                  control={<Radio color="secondary" />}
                  label={t('general.no')}
                  // disabled={!values.randomization}
                />
              </ItemWrapper>
            </StyledFormGroupWrapper>
            {/* {touched.randomization && errors.randomization && (
              <ErrorMessage>{errors.randomization}</ErrorMessage>
            )} */}
          </StyledFromGroupContainer>
        </RadioGroup>
      </FormGroupItem>
    </>
  )
}

export default Step1
