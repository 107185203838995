import React, { useState, useEffect, FormEvent } from 'react'
import { useQuery } from '@apollo/client'

import { filterOptions } from 'pages/quizzes/staticData'
import { GET_TAGS } from 'gql/skilss.query'

import {
  FilterDropdownProps,
  FilterTestDrawerProps,
  ITag,
} from 'pages/tests/filterTestDrawer/filter.interface'

import {
  UpperCaseFilterableFieldType,
  LowerCaseFilterableFieldType,
} from 'enums/filterEnum'
import { IQuizzesFilterType } from 'pages/quizzes/FilterDrawer/filterDrawer.interface'
import { ITags } from 'components/common/Tags/tags.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { ITFunction } from 'components/common/Tfunction/Tfunction.interface'
import { IFilterData } from 'pages/quizzes/quiz.interface'

interface IUseFilterTestLogicReturn {
  filterDataValues: IQuizzesFilterType
  passRate: number[]
  tagList: { label: string; value: string }[]
  handleFieldChange: (
    e: React.ChangeEvent<HTMLInputElement> | FilterDropdownProps,
    field: string,
    action?: string,
  ) => void
  onFormSubmit: (e: FormEvent<HTMLFormElement>) => void
  t: ITFunction
  setFilterData: React.Dispatch<React.SetStateAction<IFilterData>>
  onClose: () => void
  setPassRate: (rate: number[]) => void
}

const useFilterTestLogic = ({
  onClose,
  setFilterData,
  filterData,
  companyId,
  setCurrentPage,
  t,
}: FilterTestDrawerProps): IUseFilterTestLogicReturn => {
  const [filterDataValues, setFilterDataValues] =
    useState<IQuizzesFilterType>(filterOptions)
  const [passRate, setPassRate] = useState([0, 100])

  const { data: tagsData } = useQuery(GET_TAGS, {
    variables: {
      companyId,
    },
  })

  const tagList =
    (tagsData &&
      tagsData.getAllTags.map((tag: ITags) => ({
        label: tag.label,
        value: tag.id,
      }))) ||
    []

  useEffect(() => {
    if (filterData?.values) {
      setFilterDataValues(filterData.values)
      setPassRate(filterData.values.passRate as number[])
    }
  }, [filterData])

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement> | FilterDropdownProps,
    field: string,
    action?: string,
  ): void => {
    if (action && action === LowerCaseFilterableFieldType.DROPDOWN) {
      setFilterDataValues({
        ...filterDataValues,
        [field]: e,
      })
    } else {
      const event = e as React.ChangeEvent<HTMLInputElement>
      setFilterDataValues({
        ...filterDataValues,
        [field]: event.target.value,
      })
    }
  }

  const onFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const { name, quizParticipationType, timeType, tags } = filterDataValues
    const values: IFilterQueryType = {}

    if (name)
      values.name = {
        type: UpperCaseFilterableFieldType.MATCH,
        value: name,
      }
    else delete values.name

    if (
      quizParticipationType.value.toUpperCase() !==
      UpperCaseFilterableFieldType.ALL
    ) {
      values.quizParticipationType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: quizParticipationType.value.toUpperCase(),
      }
    } else delete values.quizParticipationType

    if (timeType.value.toUpperCase() !== UpperCaseFilterableFieldType.ALL)
      values.timeType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: timeType.value.toUpperCase(),
      }
    else delete values.timeType

    if (tags)
      values.tags = {
        type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: tags.map((tag: ITag) => tag.label) as string[],
        nestedField: UpperCaseFilterableFieldType.LABEL,
      }
    else delete values.tags

    if (passRate[0] === 0 && passRate[1] === 300) delete values.passRate
    else
      values.passRate = {
        type: UpperCaseFilterableFieldType.RANGE,
        min: passRate[0],
        max: passRate[1],
      }

    setFilterData({
      filterOptions: {
        ...values,
      } as IFilterQueryType,
      values: { ...filterDataValues, passRate },
    })

    setCurrentPage(1)

    onClose()
  }
  return {
    filterDataValues,
    passRate,
    tagList,
    handleFieldChange,
    onFormSubmit,
    t,
    setFilterData,
    onClose,
    setPassRate,
  }
}
export default useFilterTestLogic
