import React, { ReactElement } from 'react'
import { Container, ErrorMessage } from './styled-components'
import Select, { ValueType } from 'react-select'
import { IFilterOptionsType } from '../FilterInterface/filter.interface'
interface IDataItem {
  name?: string
  id?: string | number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any // Allow additional unknown fields
}

interface ISelectAsyncProps {
  data: IDataItem[]
  error?: boolean
  touched?: boolean
  errorMessage?: string
  isMulti?: boolean
  value: IFilterOptionsType | IFilterOptionsType[] | object
  onChange: (
    selectedOption: IFilterOptionsType | IFilterOptionsType[] | null | string,
  ) => void
  onInputChange: (inputValue: string) => void
  label: string
  loading: boolean
  disabled?: boolean
  isClearable?: boolean
}

const SelectAsync = (props: ISelectAsyncProps): ReactElement => {
  const newData =
    props?.data &&
    props.data.length > 0 &&
    (props.data.map((i) => ({
      label: i.name,
      value: i.id,
    })) as IFilterOptionsType[])

  const handleChange = (
    value: ValueType<IFilterOptionsType, boolean>,
  ): void => {
    props.onChange(value as IFilterOptionsType | IFilterOptionsType[] | null)
  }

  const handleInputChange = (inputValue: string): void => {
    props.onInputChange(inputValue)
  }

  return (
    <Container error={!!(props.error && props.touched)}>
      <Select
        isLoading={props.loading}
        isMulti={props.isMulti}
        options={newData || []}
        value={props.value}
        onChange={handleChange}
        onInputChange={handleInputChange}
        placeholder={props.label}
        isDisabled={props.disabled}
        isClearable={props.isClearable}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>{props.errorMessage}</ErrorMessage>
      )}
    </Container>
  )
}

export default SelectAsync
