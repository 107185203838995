import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import config from 'config'
import { useAssistantMessages } from 'services/assistant/assistant'
import { useQuery } from '@apollo/client'
import { GET_COURSE } from 'gql/course/course.query'
import { useGetCurrentUser } from 'services/users/getCurrentUser'
import { IFile } from 'services/assistant/file/getAssistantFiles'
import { TFunction } from 'react-i18next'

interface IKeyValuePair {
  [key: string]: string
}

export interface IAIAssistantReturnType {
  files: IFile[]
  createButton: () => ReactElement
  t: TFunction<'translation', undefined>
  setAddFileDrawerOpened: Dispatch<SetStateAction<boolean>>
  addFileDrawerOpened: boolean
  assistantId: string
  handleDelete: (_id: string) => void
}

export interface IMessage {
  _id: string
  content: string
  role: string
  createdAt: number
  __typename: string
}

interface IAIAssistantChatReturnType {
  response: string
  responseLoading: boolean
  message: string
  messagesLoading: boolean
  setMessage: (message: string) => void
  messages: IMessage[]
  userMessageStyles: IKeyValuePair
  assistantMessageStyles: IKeyValuePair
  scrollRef: React.RefObject<HTMLDivElement>
}

const useAIAssistantChat = (): IAIAssistantChatReturnType => {
  const { id: courseId } = useParams()
  const { data: courseData } = useQuery(GET_COURSE, {
    variables: { courseId },
  })

  const [message, setMessage] = useState('')
  const [response, setResponse] = useState('')
  const [responseLoading, setResponseLoading] = useState(false)
  const { currentUser, refetch } = useGetCurrentUser()

  const course = courseData?.getCourseById
  const threadId = currentUser?.assistant
    ? (currentUser.assistant as Record<string, string>)[course?.assistantId]
    : ''

  const {
    messages,
    loading: messagesLoading,
    refetch: refetchMessages,
  } = useAssistantMessages({
    threadId: threadId,
    filter: {
      limit: 10,
    },
  })

  const scrollRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = (
    behavior: 'auto' | 'instant' | 'smooth' | undefined = 'smooth',
  ): void => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: behavior,
      })
    }
    window.scroll({ top: document.body.scrollHeight, behavior: behavior })
  }

  // Scroll to bottom when new messages are loaded, or when a new response is received in chunks
  useEffect(() => {
    if (!response && messages.length > 0) scrollToBottom()
    if (response && !message) scrollToBottom('instant')
  }, [messages, response, message, messagesLoading])

  useEffect(() => {
    scrollToBottom()
  }, [])

  const handleSendMessage = async (): Promise<void> => {
    setResponseLoading(true)

    try {
      const responseStream = await fetch(
        `${config.APIURL}/assistant/create-run`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify({
            prompt: message,
            assistantId: course?.assistantId,
            userId: String(currentUser.id),
          }),
        },
      )

      setMessage('') // Clear input field

      if (!responseStream.body) {
        throw new Error('ReadableStream not supported in this browser.')
      }

      const reader = responseStream.body.getReader()
      const decoder = new TextDecoder()

      let firstIteration = true
      let done = false
      setResponse('') // Clear previous responses
      while (!done) {
        if (firstIteration) {
          refetchMessages({
            threadId: threadId,
            filter: {
              limit: 10,
            },
          })
          scrollToBottom()
          firstIteration = false
        }
        const { value, done: streamDone } = await reader.read()
        done = streamDone
        // refetch the current user to update the assistant thread if the stream is done and thread id was not provided
        if (streamDone && !threadId && refetch) {
          refetch()
        }
        const chunk = decoder.decode(value, { stream: true })
        setResponseLoading(false)
        setResponse((prev) => prev + chunk)
      }
    } catch (error) {
      console.error('Error:', error)
      setResponseLoading(false)
      setResponse('Error processing your request')
    }
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault() // Prevent default behavior such as form submission
        if (message.trim()) {
          handleSendMessage()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [message]) // Add message as a dependency

  const userMessageStyles = {
    alignSelf: 'flex-end',
    backgroundColor: '#009CE5',
    color: '#fff',
  }

  const assistantMessageStyles = {
    alignSelf: 'flex-start',
    backgroundColor: '#E9E9E9',
    color: '#000',
  }

  return {
    messages,
    response,
    responseLoading,
    message,
    messagesLoading,
    setMessage,
    userMessageStyles,
    assistantMessageStyles,
    scrollRef,
  }
}

export default useAIAssistantChat
