import { useLazyQuery } from '@apollo/client'
import { GET_TOPICS } from 'gql/topics.query'
import { IGetTopicsServiceReturn } from './getTopicsService.interface'

export const getTopicsService = (): IGetTopicsServiceReturn => {
  const [loadTopics, { loading: getTopicsLoading, data }] =
    useLazyQuery(GET_TOPICS)

  const topicsList = data?.getTopics

  return {
    loadTopics,
    getTopicsLoading,
    topicsList,
  }
}
