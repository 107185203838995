import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import deleteGroupService from 'services/group/deleteGroupService'

export interface IUseDeleteGroupReturnType {
  deleteGroupServ: (id: string[], refetch: () => void) => Promise<void>
}
const useDeleteGroup = (): IUseDeleteGroupReturnType => {
  const { t } = useTranslation()
  const { deleteGroup } = deleteGroupService()
  const { setSnackbar } = useSnackbarAlert()

  const deleteGroupServ = async (
    id: string[],
    refetch: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await deleteGroup({
      variables: { id },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
      return
    }

    message = `${id.length > 1 ? t('general.groups') : t('general.group')} ${t(
      'messages.successfully',
    )} ${t('general.deleted')}`
    variant = 'success'

    setSnackbar({ message, variant })
    refetch()
  }

  return {
    deleteGroupServ,
  }
}

export default useDeleteGroup
