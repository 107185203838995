import { NoValueWrapper } from 'components/common/Grid/styled-components'
import React from 'react'
import {
  GridITemWrapper,
  SentForReview,
  CriteriaButton,
} from './styled-components'
import strCutter from 'utils/strCutter'
import { ReactComponent as Crown } from '../icons/crown.svg'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Tooltip } from '@mui/material'
import moment from 'moment'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { generateFullName } from 'utils/utils'
import { TFunction } from 'interfaces/TFunction'
import { ISkillTestVideo } from 'interfaces/SkillTestVideo/SkillTestVideo.interface'

interface Reviewer {
  id: string
  rate?: string
  firstName: string
  lastName: string
  role: string
}

export const getStatus = (status: string): string => {
  if (status === 'DECLINED') {
    return 'Try Again'
  } else {
    const convertedStatus =
      `${status[0].toUpperCase()}` + `${status.slice(1).toLowerCase()}`
    return convertedStatus
  }
}

interface GridItem {
  id: string
  numeric: boolean
  disablePadding: boolean
  label: string
  render: (item: ISkillTestVideo) => React.JSX.Element
  hide?: boolean
}

export const columnConfig = (
  t: TFunction,
  onClick?: React.Dispatch<React.SetStateAction<boolean>>,
  setVideo?: React.Dispatch<React.SetStateAction<ISkillTestVideo | null>>,
  onCriteriasClick?: (item: ISkillTestVideo['criterias']) => void,
  isCriteriasPresented?: boolean,
): GridItem[] => {
  const grid: GridItem[] = [
    {
      id: 'module',
      numeric: false,
      disablePadding: true,
      label: `${t('general.module')}`,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <GridITemWrapper
          style={{ cursor: 'pointer' }}
          onClick={(): void => {
            if (onClick && setVideo) {
              onClick(true)
              setVideo(item)
            }
          }}
        >
          {item?.video?.thumbnail && (
            <div style={{ backgroundImage: `URL(${item.video.thumbnail})` }}>
              <PlayCircleOutlineIcon style={{ color: '#06C68F' }} />
            </div>
          )}
          <span>{item.module.name}</span>
        </GridITemWrapper>
      ),
    },
    {
      id: 'version',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.version')}`,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <GridITemWrapper style={{ width: 77 }}>{`${t('user_tabs.version')} ${
          item.version
        }`}</GridITemWrapper>
      ),
    },
    {
      id: 'sentForReview',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.sentForReview')}`,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <SentForReview>
          {item.sentForReview.map((p: Reviewer, i: number) => (
            <div key={p.id}>
              <Tooltip title={p.rate || 'Not reviewed'}>
                <span>
                  {strCutter(
                    generateFullName({
                      firstName: p.firstName,
                      lastName: p.lastName,
                    }),
                    30,
                  )}

                  {p.role === 'COACH' && (
                    <Crown
                      style={{
                        verticalAlign: 'super',
                      }}
                    />
                  )}
                </span>
              </Tooltip>
              {item.sentForReview.length > 1 &&
                i !== item.sentForReview.length - 1 &&
                ', '}
            </div>
          ))}

          {item.sentForReview.length === 0 && (
            <NoValueWrapper>N/A</NoValueWrapper>
          )}
        </SentForReview>
      ),
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <StatusIndicator
          status={getStatus(item.status).toUpperCase() as GridStatusEnums}
        />
      ),
    },
    {
      id: 'receivedStars',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.receivedStars')}`,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <GridITemWrapper style={{ justifyContent: 'center', width: 90 }}>
          {item.averageRating || (
            <NoValueWrapper style={{ marginLeft: 13 }}>N/A</NoValueWrapper>
          )}
        </GridITemWrapper>
      ),
    },
    {
      id: 'passedCriterias',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.passedCriterias')}`,
      hide: !isCriteriasPresented,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <GridITemWrapper style={{ justifyContent: 'center', width: 90 }}>
          {(item.criterias?.length && (
            <CriteriaButton
              onClick={(): void | undefined =>
                onCriteriasClick && onCriteriasClick(item.criterias)
              }
            >
              {` ${
                item.criterias.filter((i: { checked: boolean }) => i.checked)
                  ?.length || 0
              }/${item.criterias?.length || 0}`}
            </CriteriaButton>
          )) || <NoValueWrapper style={{ marginLeft: 13 }}>N/A</NoValueWrapper>}
        </GridITemWrapper>
      ),
    },
    {
      id: 'createDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: ISkillTestVideo): React.JSX.Element => (
        <GridITemWrapper>
          {moment(parseInt(item.createDate)).format('DD-MM-YYYY') || (
            <NoValueWrapper>N/A</NoValueWrapper>
          )}
        </GridITemWrapper>
      ),
    },
  ]

  return grid.filter((i: GridItem) => !i.hide)
}
