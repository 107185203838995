import { useMutation, MutationTuple } from '@apollo/client'
import { INSERT_GROUPS_WITH_TESTS } from 'gql/group/group.query'
import { IExtractGroupsWithTestsMutationResponse } from './uploadGroupsWithCoursesService'
import { IInsertGroupsWithTestsInput } from 'interfaces/groups'

interface IImportGroupsWithTestsResponse {
  insertGroupsWithTests: IExtractGroupsWithTestsMutationResponse[]
}

interface IImportGroupsWithTestsVariables {
  groups: IInsertGroupsWithTestsInput[]
  companyId: string
}

interface IImportGroupsWithTestsServiceResponse {
  insertGroupsWithTests: MutationTuple<
    IImportGroupsWithTestsResponse,
    IImportGroupsWithTestsVariables
  >[0]
  loading: boolean
}

const importGroupsWithTestsService =
  (): IImportGroupsWithTestsServiceResponse => {
    const [insertGroupsWithTests, { loading }] = useMutation<
      IImportGroupsWithTestsResponse,
      IImportGroupsWithTestsVariables
    >(INSERT_GROUPS_WITH_TESTS)

    return {
      insertGroupsWithTests,
      loading,
    }
  }

export default importGroupsWithTestsService
