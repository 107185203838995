import useSnackbarAlert from 'hooks/useSnackbar'
import { IGroup, IInsertGroupsWithCoursesInput } from 'interfaces/groups'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import importGroupsWithCoursesService from 'services/group/importGroupsWithCoursesService'

export interface IUseImportGroupsWithCoursesReturnType {
  importGroupsWithCourses: (
    values: IInsertGroupsWithCoursesInput[],
    companyId: string,
    callBack?: (groupsData: IGroup[] | null) => void,
  ) => Promise<void>
  loading: boolean
}

const useImportGroupsWithCourses =
  (): IUseImportGroupsWithCoursesReturnType => {
    const { t } = useTranslation()
    const { insertGroupsWithCourses, loading } =
      importGroupsWithCoursesService()
    const { setSnackbar } = useSnackbarAlert()

    const importGroupsWithCourses = async (
      values: IInsertGroupsWithCoursesInput[],
      companyId: string,
      callBack?: (groupsData: IGroup[] | null) => void,
    ): Promise<void> => {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }
      const { errors, data } = await insertGroupsWithCourses({
        variables: {
          groups: values,
          companyId,
        },
      })

      let groupsData = null

      if (errors) {
        message = errors[0].message
        variant = 'error'
      } else if (data) {
        message = `${t('general.groups')} ${t('messages.edited')}`
        variant = 'success'
        groupsData = data.insertGroupsWithCourses
      }

      if (callBack) callBack(groupsData as IGroup[])

      setSnackbar({ message, variant })
    }

    return {
      importGroupsWithCourses,
      loading,
    }
  }

export default useImportGroupsWithCourses
