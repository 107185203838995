import React, { ReactElement } from 'react'
import { ImageContainer } from './styled-components'
import AddMore from 'assets/add-more.png'

const Presentation = (): ReactElement => {
  return (
    <ImageContainer>
      <img src={AddMore} alt="Add More" />
    </ImageContainer>
  )
}

export default Presentation
