import { useState } from 'react'
import { useFetchAnalytics } from 'hooks/analytics/useAnalytics'
import { useTranslation } from 'react-i18next'
import {
  ISummary,
  IUseCoursesAnalyticsReturn,
} from './useCourseAnalytics.interface'
import { getConfig } from 'pages/analytics/Courses/courseConfig'
import { IAnalyticsCourseOption } from './useAnalyticsData.interface'
import { ICourseItem } from 'pages/analytics/Courses/coursesAnalytics.interface'
import { useGetCoursesAnalytics } from './useGetCoursesAnalytics'

const useCoursesAnalytics = (
  companyId: string,
  selectedCourse: IAnalyticsCourseOption | null,
): IUseCoursesAnalyticsReturn => {
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()
  const config = getConfig(t)

  const filter = {
    companyId,
  }

  const {
    data: initialCoursesData,
    error: coursesError,
    loading: coursesLoading,
  } = useGetCoursesAnalytics({ filter })

  // Calculate sums
  const summary: ISummary = (
    Array.isArray(initialCoursesData) ? initialCoursesData : []
  ).reduce<ISummary>(
    (acc, curr) => {
      acc.groupsCount += curr.groupsCount
      acc.numberOfStudents += curr.numberOfStudents
      acc.numberOfSkillTestVideos += curr.numberOfSkillTestVideos
      acc.numberOfNotes += curr.numberOfNotes
      acc.numberOfLikes += curr.numberOfLikes
      acc.numberOfComments += curr.numberOfComments
      acc.numberOfPosts += curr.numberOfPosts
      acc.passedQuizzesCount += curr.passedQuizzesCount
      acc.studentsCountWhoCompletedCourse +=
        curr.studentsCountWhoCompletedCourse
      return acc
    },
    {
      id: 'summary',
      name: 'Totals',
      state: '',
      groupsCount: 0, // Use a separate field for total groups count
      numberOfStudents: 0,
      numberOfSkillTestVideos: 0,
      numberOfNotes: 0,
      numberOfLikes: 0,
      numberOfComments: 0,
      numberOfPosts: 0,
      passedQuizzesCount: 0,
      studentsCountWhoCompletedCourse: 0,
    },
  )

  const courses = [summary as unknown as ICourseItem].concat(
    Array.isArray(initialCoursesData) ? initialCoursesData : [],
  )

  const { analytics, loading } = useFetchAnalytics(
    selectedCourse?.value,
    companyId,
  )

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault()
    setSearchValue(e.target.value)
  }

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
  }

  return {
    t,
    config,
    searchValue,
    courses,
    analytics,
    loading,
    coursesError,
    coursesLoading,
    handleSearchChange,
    handleSearchSubmit,
  }
}

export default useCoursesAnalytics
