import { useQuery } from '@apollo/client'
import { GET_USER } from 'gql/users.query'
import {
  IGetUserByIdReturn,
  IGetUserByIdReturnService,
  IGetUserByIdServiceVariable,
} from './getUserById.interface'

export const getUserByIdService = (id: string): IGetUserByIdReturnService => {
  const { data, loading, error } = useQuery<
    IGetUserByIdReturn,
    IGetUserByIdServiceVariable
  >(GET_USER, {
    variables: { id },
    skip: !id,
  })

  return {
    data: data?.user,
    loading,
    error,
  }
}
