import { useMutation, MutationTuple } from '@apollo/client'
import { DELETE_LESSON } from 'gql/lesson/lesson.query'
import { GET_MODULE } from 'gql/modules.query'
import { IMessageResponse } from 'interfaces/common'

// Interfaces for deleteLessonService
interface IDeleteLessonMutationResponse {
  deleteLesson: IMessageResponse
}

export interface IDeleteLessonParams {
  moduleId: string
}

export interface IDeleteLessonMutationVariables {
  ids: string[]
}

interface IDeleteLessonServiceResponse {
  deleteLessonMutation: MutationTuple<
    IDeleteLessonMutationResponse,
    IDeleteLessonMutationVariables
  >[0]
  loading: boolean
}

const deleteLessonService = (
  variables: IDeleteLessonParams,
): IDeleteLessonServiceResponse => {
  const [deleteLessonMutation, { loading }] = useMutation<
    IDeleteLessonMutationResponse,
    IDeleteLessonMutationVariables
  >(DELETE_LESSON, {
    refetchQueries: [
      {
        query: GET_MODULE,
        variables,
      },
    ],
  })

  return {
    deleteLessonMutation,
    loading,
  }
}

export default deleteLessonService
