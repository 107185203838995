import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { useTranslation } from 'react-i18next'

import {
  editQuizService,
  addRemoveQuestionsService,
} from 'services/quizzes/editQuizService'

export const useEditQuizService = (): {
  editQuiz: (quizId: string, input: IQuiz, fun: () => void) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()

  const { editQuizMutation, loading } = editQuizService()

  const editQuiz = async (
    quizId: string,
    input: IQuiz,
    fun: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await editQuizMutation({
      variables: { quizId, input },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
      fun()
    }

    setSnackbar({ message, variant })
  }
  return {
    editQuiz,
    loading,
  }
}

export const useAddRemoveQuestions = (): {
  addRemoveQuestions: (
    action: string,
    ids: string[],
    quizId: string,
    fun?: () => void,
  ) => Promise<void>
} => {
  const { setSnackbar } = useSnackbarAlert()

  const { questionsActionMutation } = addRemoveQuestionsService()

  const addRemoveQuestions = async (
    action: string,
    ids: string[],
    quizId: string,
    fun?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await questionsActionMutation({
      variables: {
        quizId,
        action,
        questionIds: ids,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `The Changes have been saved`
      variant = 'success'
      if (fun) fun()
    }

    setSnackbar({ message, variant })
  }

  return {
    addRemoveQuestions,
  }
}
