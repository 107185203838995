import styled, { keyframes } from 'styled-components'
import { IFlexWrapperProps } from './tasksDrawer.interface'

// AddTestDrawer
export const Container = styled.div`
  box-sizing: border-box;
  height: 100%;

  & > .MuiRadio-colorSecondary.Mui-checked {
    color: #0660c6 !important;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #0660c6 !important;
  }

  .MuiCheckbox-colorPrimary.Mui-checked svg {
    color: #0660c6 !important;
  }
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 550;
  padding-top: 0px;
  margin-bottom: 2px;
`

// Header
export const DrawerHeader = styled.header`
  padding: 10px 20px;
  background: #ffffff;
  position: sticky;
  z-index: 2;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
`

export const DrawerHeaderSteps = styled.header`
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

export const DrawerTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
`

export const DrawerStepsTitle = styled.h2`
  font-size: 16px font-weight normal;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
`
export const RandomQuestions = styled.p`
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0;
`

export const FormFooter = styled.footer`
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  height: 60px;
`

export const FormButtons = styled.div`
  align-self: flex-end;
  display: flex;
  justify-content: space-between;

  div:not(:last-child) {
    margin-right: 20px;
  }
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  svg {
    font-size: 1rem;
    margin-right: 6px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const DrawerContent = styled.div`
  position: relative;
  height: calc(100% - 137px);
  overflow-y: auto;
`

export const HelperLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    margin-bottom: 5px;
  }
`

export const StyledSelectFieldContainer = styled.div`
  display: flex;
  gap: 10px;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }


`

export const FlexWrapper = styled.div<IFlexWrapperProps>`
  display: flex;
  flex-direction: column;
  animation: ${(props): string =>
    props.animate ? `${fadeIn} 0.5s` : `${fadeOut} 0.5s`};
`
