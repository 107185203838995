import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'

import createLessonService from 'services/lessons/createLessonService'
import { ILesson } from 'interfaces/modules'

const useCreateLessonService = (): {
  createLesson: (
    input: ILesson,
    fun: () => void,
    onSuccess?: () => void,
  ) => Promise<void>
  loading: boolean
} => {
  const { t } = useTranslation()
  const { createLessonMutation } = createLessonService()
  const { setSnackbar } = useSnackbarAlert()
  const [loading, setLoading] = useState(false)

  const createLesson = async (
    input: ILesson,
    fun: () => void,
    onSuccess?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const topicId = input.topic as string
    delete input.topic

    const { errors } = await createLessonMutation({
      variables: {
        topicId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      onSuccess && onSuccess()

      message = `${t('general.lesson')} ${t('messages.successfully')} ${t(
        'general.created',
      )}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    fun()
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }
  return {
    createLesson,
    loading,
  }
}

export default useCreateLessonService
