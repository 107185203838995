import React, { ReactElement } from 'react'
import { Container } from './styled-components'
import Badge from '@mui/material/Badge'
import { ReactComponent as NotificationsIcon } from 'assets/notification.svg'
import { NotificationBadgeProps } from './notificationBadge.interface'

const NotificationBadge = ({
  numberOfNewNotifications,
  open,
}: NotificationBadgeProps): ReactElement => {
  return (
    <Container>
      <Badge
        badgeContent={numberOfNewNotifications}
        color="secondary"
        onClick={open}
      >
        <NotificationsIcon />
      </Badge>
    </Container>
  )
}

export default NotificationBadge
