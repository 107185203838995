import React from 'react'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { Action } from './styled-components'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import ViewCompactIcon from '@mui/icons-material/ViewCompact'
import { TFunction } from 'interfaces/TFunction'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { ICompany } from 'pages/companies/CompanyDetails/types'

interface IItemProps {
  name: string
  video: string
  status: string
  isChecked?: boolean
  id: string
  level?: string
  coursePrivacy: string
  coachType: string
  coach: string
  duration: string
  studentsEnrolled: string
  rating: string
  numberOfGroups: string
  numberOfStudents: string
  attachment?: boolean
  companyId: string | ICompany
}

type ActionHandler = (event: string, field: IItemProps) => void

interface ActionItem {
  hide?: boolean
  render: (item: IItemProps) => JSX.Element
}

export const actionConfig = (
  actionHandler: ActionHandler,
  permissions: IPermissionsBool,
  canModify: boolean,
  t: TFunction,
): ActionItem[] => {
  const actions: ActionItem[] = [
    {
      hide: !permissions.viewModule,
      render: (item: IItemProps) => (
        <Action onClick={(): void => actionHandler('view', item)}>
          <ViewCompactIcon />
          <span>{t('actions.view')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.editModule || canModify,
      render: (item: IItemProps) => (
        <Action onClick={(): void => actionHandler('edit', item)}>
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.editCourse || canModify,
      render: (item: IItemProps) => (
        <Action onClick={(): void => actionHandler('delete', item)}>
          <DeleteForeverRoundedIcon />
          <span>{t('actions.remove_from_course')}</span>
        </Action>
      ),
    },
    {
      render: (item: IItemProps) => (
        <Action
          onClick={(): void => actionHandler('pdf', item)}
          className={item.attachment ? '' : 'disabled'}
        >
          <CloudDownloadIcon />
          <span>{t('actions.download_attachment')}</span>
        </Action>
      ),
    },
  ]
  return actions.filter((i: ActionItem) => !i.hide)
}

type ModuleClickHandler = (id: string, name: string, company: ICompany) => void

interface ModuleActionItem {
  render: (item: IItemProps) => JSX.Element
}

export const moduleActionsConfig = (
  t: TFunction,
  handleCreateModuleClick?: ModuleClickHandler,
  handleAddModuleClick?: ModuleClickHandler,
): ModuleActionItem[] => {
  const action: ModuleActionItem[] = [
    {
      render: (item: IItemProps) => (
        <Action
          onClick={(): void =>
            handleCreateModuleClick &&
            handleCreateModuleClick(
              item.id,
              item.name,
              item.companyId as ICompany,
            )
          }
        >
          <AddCircleRoundedIcon />
          <span>{t('modules_layout.create_module')}</span>
        </Action>
      ),
    },
    {
      render: (item: IItemProps) => (
        <Action
          onClick={(): void =>
            handleAddModuleClick &&
            handleAddModuleClick(item.id, item.name, item.companyId as ICompany)
          }
        >
          <PlaylistAddIcon />
          <span>{t('course_details.add_module_to_the_course')}</span>
        </Action>
      ),
    },
  ]
  return action
}
