import React from 'react'
import { TriggerEnum } from './tasksDrawer.interface'
import { FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Select from 'react-select'
import {
  Container,
  DrawerContent,
  DrawerHeader,
  FlexWrapper,
  StyledSelectFieldContainer,
  Title,
} from './styled-component'
import makeAnimated from 'react-select/animated'
import { FormTitle } from 'pages/users/styled-components'
import {
  FormField,
  FormFields,
  FormButtons,
  FormFooter,
} from 'components/common/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { StyledUploadLabel } from 'pages/tests/editTestDrawer/styled-components'
import Uploader from 'components/common/FileUploaderV2'
import { Button } from 'components/common/Button'
import { useTranslation } from 'react-i18next'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { ItemWrapper } from 'components/styled-components'
import { SelectField } from 'components/common/SelectField'
import Checkbox from 'components/common/Checkbox'

import { useTaskDrawer } from 'hooks/tasks/useTaskDrawer'
import { IUpdateTasksDrawerProps } from './tasksDrawer.interface'

const animatedComponents = makeAnimated()

const TasksDrawer = ({
  taskId,
  setTaskDrawerOpen,
  companyId,
  onTaskAdded,
  mappedGroups,
  mappedCourses,
  groupsLoading,
  coursesLoading,
  setTaskId,
}: IUpdateTasksDrawerProps): React.ReactElement => {
  const { t } = useTranslation()

  const {
    handleFileChange,
    handleSubmit,
    courseSelected,
    setCourseSelected,
    uploadedFile,
    setUploadedFile,
    taskTitle,
    setTaskTitle,
    taskDescription,
    setTaskDescription,
    onModuleSelected,
    setOnModuleSelected,
    setSelectedGroups,
    courseConditionSelected,
    setCourseConditionSelected,
    moduleConditionSelected,
    setModuleConditionSelected,
    radioValue,
    isCheckboxChecked,
    setIsCheckboxChecked,
    selectedRadio,
    setSelectedRadio,
    mappedModules,
    handleRadioChange,
    handleCloseDrawer,
    selectedGroups,
    isUpdating,
    acceptedTypes,
    validationErrors,
  } = useTaskDrawer(
    companyId,
    setTaskDrawerOpen,
    onTaskAdded,
    taskId,
    setTaskId,
  )

  return (
    <>
      <Container>
        <DrawerHeader>
          <FormTitle>
            {' '}
            {isUpdating
              ? t('tasks_layout.update_task')
              : t('tasks_layout.add_task')}{' '}
          </FormTitle>
          <IconButton
            onClick={(): void => {
              setTaskDrawerOpen(false)
              setTaskId(null)
            }}
          >
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <DrawerContent>
          <FormFields>
            <FormField>
              <Title>
                {t('tasks_layout.task')} {t('form_fields.name')}
              </Title>
              <TextInput
                label={''}
                size="small"
                placeholder={`${t('form_fields.name')}`}
                name="name"
                type="text"
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setTaskTitle(e.target.value)
                }}
                value={taskTitle}
              />
              {validationErrors.taskTitle && (
                <span style={{ color: 'red' }}>
                  {t('tasks_layout.required_field_name')}
                </span>
              )}
            </FormField>

            <FormField>
              <Title>{t('general.overview')}</Title>
              <TextInput
                label=""
                placeholder="Type note here"
                size="small"
                name="description"
                type="text"
                fullWidth={true}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setTaskDescription(e.target.value)
                }}
                multiline
                rows="4"
                value={taskDescription}
              />
              {validationErrors.taskDescription && (
                <span style={{ color: 'red' }}>
                  {t('tasks_layout.required_field_description')}
                </span>
              )}
            </FormField>
            <FormField>
              <Uploader
                id="pdf"
                type={FileTypes.FILE}
                placeHolder={t('actions.file_upload_format1')}
                accept={acceptedTypes.join(', ')}
                label={
                  <>
                    <StyledUploadLabel>
                      {t('actions.file_upload_format1')}
                    </StyledUploadLabel>
                  </>
                }
                inputValue={uploadedFile ? (uploadedFile.name as string) : ''}
                uploadedFile={uploadedFile}
                deleteFile={(): void => setUploadedFile(null)}
                onFileChange={handleFileChange}
              />
            </FormField>
            <FormField>
              <Title>{t('tasks_layout.select_groups')} </Title>
              <Select
              data-testid="select-groups"
                isLoading={groupsLoading}
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={(selectedOptions): void => {
                  setSelectedGroups(
                    selectedOptions
                      ? selectedOptions.map(
                          (option: { label: string; value: string }) => ({
                            label: option.label,
                            value: option.value,
                          }),
                        )
                      : [],
                  )
                }}
                isMulti
                options={mappedGroups}
                value={selectedGroups}
                placeholder={t('tasks_layout.select_groups')}
              />
              {validationErrors.selectedGroups && (
                <span style={{ color: 'red' }}>
                  {t('tasks_layout.required_field_groups')}
                </span>
              )}
            </FormField>
            <FormField>
              <RadioGroup
                name="moreOptions"
                value={radioValue}
                onChange={handleRadioChange}
              >
                {process.env.REACT_APP_STAGE === 'development' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCheckboxChecked}
                        onChange={(): void =>
                          setIsCheckboxChecked(!isCheckboxChecked)
                        }
                        name="certificateIncluded"
                      />
                    }
                    label={t('tasks_layout.more_options')}
                  />
                )}

                {isCheckboxChecked ? (
                  <FlexWrapper animate={isCheckboxChecked}>
                    <ItemWrapper>
                      <FormControlLabel
                        value="onCourse"
                        control={<Radio color="primary" />}
                        label={t('general.onCourse')}
                        onClick={(): void => setSelectedRadio('onCourse')}
                      />
                    </ItemWrapper>
                    {selectedRadio === 'onCourse' && (
                      <StyledSelectFieldContainer>
                        <SelectField
                          options={[
                            {
                              value: TriggerEnum.BEFORE_COURSE,
                              label: 'Before start ',
                            },
                            {
                              value: TriggerEnum.AFTER_COURSE,
                              label: 'After finish',
                            },
                          ]}
                          onChange={(value: {
                            value: string
                            label: string
                          }): void =>
                            setCourseConditionSelected(
                              value
                                ? {
                                    value: value.value,
                                    label: value.label || '',
                                  }
                                : null,
                            )
                          }
                          value={courseConditionSelected}
                        />
                        <SelectField
                          isLoading={coursesLoading}
                          options={mappedCourses}
                          onChange={(value): void => setCourseSelected(value)}
                          value={courseSelected}
                        />
                      </StyledSelectFieldContainer>
                    )}

                    <ItemWrapper>
                      <FormControlLabel
                        value="onModule"
                        control={<Radio color="primary" />}
                        label={t('general.onModule')}
                        onClick={(): void => setSelectedRadio('onModule')}
                      />
                    </ItemWrapper>
                    {selectedRadio === 'onModule' && (
                      <StyledSelectFieldContainer>
                        <SelectField
                          options={[
                            {
                              value: TriggerEnum.BEFORE_MODULE,
                              label: 'Before start',
                            },
                            {
                              value: TriggerEnum.AFTER_MODULE,
                              label: 'After finish',
                            },
                          ]}
                          onChange={(value: {
                            value: string
                            label: string
                          }): void =>
                            setModuleConditionSelected(
                              value
                                ? {
                                    value: value.value,
                                    label: value.label || '',
                                  }
                                : null,
                            )
                          }
                          value={moduleConditionSelected}
                        />
                        <SelectField
                          options={mappedModules}
                          onChange={(
                            value: { value: string; label: string } | null,
                          ): void => setOnModuleSelected(value)}
                          value={onModuleSelected}
                        />
                      </StyledSelectFieldContainer>
                    )}
                  </FlexWrapper>
                ) : (
                  <></>
                )}
              </RadioGroup>
            </FormField>
          </FormFields>
        </DrawerContent>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.save')}
              type="small"
              onClick={handleSubmit}
              background="#06C68F"
            />
            <Button
              text={t('actions.cancel')}
              type="small"
              onClick={handleCloseDrawer}
              background="#E0E1E2"
              textColor="#414141"
            />
          </FormButtons>
        </FormFooter>
      </Container>
    </>
  )
}

export default TasksDrawer
