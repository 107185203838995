import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { EDIT_FLASHCARD } from 'gql/flashcards.query'
import { IFlashCard } from 'hooks/modules/FlashCardsLayout/useModuleDetailContent.interface'

interface IEditFlashCardServiceResponse {
  editFlashCardMutation: MutationTuple<
    IEditFlashCardResponse,
    IEditFlashCardParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IEditFlashCardResponse {
  editFlashCard: IFlashCard
}

interface IEditFlashCardParams {
  id: string
  input: IFlashCard
}

const editFlashCardService = (): IEditFlashCardServiceResponse => {
  const [editFlashCardMutation, { loading, error }] = useMutation<
    IEditFlashCardResponse,
    IEditFlashCardParams
  >(EDIT_FLASHCARD)

  return {
    editFlashCardMutation,
    loading,
    error,
  }
}

export default editFlashCardService
