import { useLazyQuery } from '@apollo/client'
import IconButton from '@mui/material/IconButton'
import { Button } from 'components/common/Button'
import DateRangePicker from 'components/common/DateRangePicker'
import {
  FormButtons,
  FormContainer,
  FormField,
  FormFields,
  FormFooter,
  FormGroupWrapper,
} from 'components/common/Form/Form'
import Loader from 'components/common/Loader'
import RangeSlider from 'components/common/RangeSlider'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import TextInput from 'components/common/TextInput/TextInput'
import {
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  filterGenderOptions,
  IStudentsFilterOptions,
  statuses,
  studentsFilterOptions,
} from 'components/UsersLayout/staticData'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
// import SelectField from 'components/common/Select/Select'
import { GET_COMPANIES } from 'gql/companies.query'
import { GET_ALL_COURSE } from 'gql/course/course.query'
import { GET_GROUP } from 'gql/group/group.query'
import { FilterStudentsSchema } from 'helpers/validationSchemas'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { roles } from 'utils/permission'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'
import { useFilterContext } from 'context/FilterContext'
import { useFilterRoleContext } from 'context/FilterRoleContext'
import {
  IFilterStudentsFormValues,
  IFilterStudentsProps,
  IStudentsGroupFilter,
} from './filterStudents.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

const FilterStudents = ({
  closeDrawer,
  filterLoading,
  manualCompanyId,
  useCompanyId,
  setFilterOptions,
  filterOptions,
  filter,
  defaultFilter,
  refetchStudents,
}: IFilterStudentsProps): JSX.Element => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const isCompanySelected = Boolean(state?.selectedCompany?.id)
  const ref = useRef<NodeJS.Timeout | null>(null)
  const { createDate, setCreateDate } = useFilterContext()
  const { filterRole, setFilterRole } = useFilterRoleContext()

  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUP)
  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  const [fetchCourse, { data: courseData, loading: courseLoading }] =
    useLazyQuery(GET_ALL_COURSE)

  setFilterRole('STUDENTS')

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: studentsFilterOptions,
    validationSchema: FilterStudentsSchema,
    onSubmit(values) {
      const formValues: IFilterStudentsFormValues = {}

      if (values.firstName) {
        formValues.firstName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.firstName,
        }
      }
      if (values.lastName) {
        formValues.lastName = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.lastName,
        }
      }
      if (values.note) {
        formValues.note = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.note,
        }
      }
      if (values.location) {
        formValues.location = {
          type: LowerCaseFilterableFieldType.MATCH,
          value: values.location,
        }
      }

      if (values?.gender?.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values?.gender?.value,
        }
      }

      if (values.group) {
        const searchedGroupIds = [
          ...values.group.map((gr: { value: string }) => gr.value),
        ]

        const groups =
          searchedGroupIds && defaultFilter?.group?.value
            ? [...searchedGroupIds, ...defaultFilter.group.value]
            : searchedGroupIds

        formValues.group = {
          nestedField: 'groupId',
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_ALL,
          value: groups,
        }
      }

      if (values.course) {
        formValues.courses = {
          type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: values.course.map((i) => i.value.id),
          nestedField: 'courseId',
        }
      }

      if (values?.status?.value !== 'all') {
        formValues.status = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values?.status?.value,
        }
      }

      if (values.company) {
        const companyIds = values.company.map((i: { value: string | null }) =>
          i.value === '-1' ? (i.value = null) : i.value,
        )
        const query = { companyId: { $in: companyIds } }
        // formValues.companyId = {
        //   type: 'arrayIn',
        //   value: companyIds,
        // }
        formValues.query = {
          type: LowerCaseFilterableFieldType.QUERY,
          value: JSON.stringify(query),
        }
      }

      const isAllZero = values?.age?.every((item) => item === 0)

      if (!isAllZero) {
        formValues.age = {
          type: LowerCaseFilterableFieldType.RANGE,
          value: `${values?.age?.[0]}-${values?.age?.[1]}`,
        }
      }

      // formValues.query = {
      //   type: 'query',
      //   value: JSON.stringify(queryString),
      // }

      if (values?.gender?.value !== 'all') {
        formValues.gender = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: values?.gender?.value,
        }
      }

      //if user wants to clear the date, we should send undefined
      const safeCreateDate = createDate || [undefined, undefined]

      safeCreateDate[0] = safeCreateDate[0] && new Date(safeCreateDate[0])
      safeCreateDate[1] = safeCreateDate[1] && new Date(safeCreateDate[1])

      const isSameDate =
        safeCreateDate[0] === safeCreateDate[1] &&
        safeCreateDate[0] !== undefined

      if (!safeCreateDate[1] && safeCreateDate[0] !== undefined)
        safeCreateDate[1] = new Date()

      const formattedData = isSameDate
        ? safeCreateDate[0]
        : `${safeCreateDate[0] ? safeCreateDate[0].toISOString() : ''}${'-'}${
            safeCreateDate[1] ? safeCreateDate[1].toISOString() : ''
          }`

      if (safeCreateDate[0] !== undefined || safeCreateDate[1] !== undefined) {
        formValues.createDate = {
          type: isSameDate
            ? LowerCaseFilterableFieldType.EXACT
            : LowerCaseFilterableFieldType.DATE_RANGE,
          value: formattedData as string,
        }
      }
      if (refetchStudents && defaultFilter) {
        refetchStudents({
          role: roles.student,
          filter: { ...defaultFilter, ...formValues },
          currentPage: 1,
          perPage: 10,
        })
        closeDrawer()
      }

      filter(formValues)
      setFilterOptions(values as IFilterQueryType)
    },
  })

  //clear createDate value when we switch tabs
  useEffect(() => {
    if (createDate && filterRole != 'STUDENTS') {
      setCreateDate([undefined, undefined])
    }
  }, [filterRole])

  useEffect(() => {
    if (filterOptions) {
      setValues(filterOptions as IStudentsFilterOptions)
    }
  }, [])

  useEffect(() => {
    setFilterOptions(values as IFilterQueryType)
  }, [values])

  const handleRangeChange = (range: number | number[]): void => {
    setFieldValue('age', range)
  }

  const currentLocation = useLocation()
  const inOnCompanyPage = currentLocation.pathname.indexOf('companies') > -1

  const loadDataOptions = (e: string, field: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: UpperCaseFilterableFieldType.MATCH,
                value,
              },
            },
          },
        })
      } else if (field === 'group') {
        let filter: IStudentsGroupFilter = {
          name: {
            type: LowerCaseFilterableFieldType.MATCH,
            value,
          },
        }
        if (useCompanyId) {
          filter = {
            ...filter,
            company: {
              type: LowerCaseFilterableFieldType.EXACT,
              value: manualCompanyId
                ? manualCompanyId
                : state.selectedCompany?.id,
            },
          }
        }
        fetchGroup({
          variables: {
            filter,
            perPage: 0,
          },
        })
      } else {
        let filter: IStudentsGroupFilter = {
          name: { type: UpperCaseFilterableFieldType.MATCH, value },
        }

        if (!inOnCompanyPage && !useCompanyId) {
          filter = {
            ...filter,
            originalId: {
              type: UpperCaseFilterableFieldType.ARRAY_IN,
              value: [null],
            },
          }
        }
        if (useCompanyId) {
          filter = {
            ...filter,
            companyId: {
              type: UpperCaseFilterableFieldType.EXACT,
              value: manualCompanyId
                ? manualCompanyId
                : state.selectedCompany?.id,
            },
          }
        }
        fetchCourse({
          variables: {
            filter,
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const [companyOptions, setCompanyOptions] = useState([])

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  return (
    <>
      {filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.first_name')}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  placeholder={t('user_details.first_name')}
                  size="small"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.last_name')}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  placeholder={t('user_details.last_name')}
                  size="small"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('general.status')}</Title>
                <SelectField
                  options={statuses}
                  value={values.status}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('status', e) as Promise<void>
                  }
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_group')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void =>
                    handleSelectChange(e, 'group')
                  }
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('group', e) as Promise<void>
                  }
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_groups')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_course')}</Title>

                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void =>
                    handleSelectChange(e, 'course')
                  }
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('course', e) as Promise<void>
                  }
                  data={(courseData && courseData.getAllCourse.data) || []}
                  value={values.course}
                  label={t('form_fields.type_to_search_courses')}
                  loading={courseLoading}
                  labelDataKey="name"
                />
              </FormField>
              {!isCompanySelected && (
                <FormField width="48%" style={{ position: 'relative' }}>
                  <Title>{t('groups_layout.select_company')}</Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string): void =>
                      handleSelectChange(e, 'company')
                    }
                    onChange={(e: string): Promise<void> =>
                      setFieldValue('company', e) as Promise<void>
                    }
                    data={companyOptions}
                    value={values.company}
                    label={t('form_fields.type_to_search_companies')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    iconDataKey="icon"
                  />
                </FormField>
              )}

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('user_details.gender')}</Title>
                <SelectField
                  placeholder={t('user_details.gender')}
                  options={filterGenderOptions}
                  value={values.gender}
                  onChange={(e: string): Promise<void> =>
                    setFieldValue('gender', e) as Promise<void>
                  }
                />
              </FormField>

              <FormField width="48%">
                <TextInput
                  title={t('form_fields.location')}
                  placeholder={t('form_fields.location')}
                  size="small"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width={isCompanySelected ? '48%' : '100%'}>
                <TextInput
                  title={t('user_details.note')}
                  placeholder={t('user_details.note')}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('general.create_date')}</Title>
                <DateRangePicker
                  values={createDate}
                  setCreateDate={setCreateDate}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('form_fields.age_range')}</Title>
                <RangeSlider
                  value={values.age}
                  onRangeChange={handleRangeChange}
                  style={{ color: '#08C694' }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterStudents
