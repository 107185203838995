import { useMutation, MutationTuple } from '@apollo/client'
import { EXTRACT_GROUPS_WITH_TESTS } from 'gql/group/group.query'
import { IGroup } from 'interfaces/groups'

interface IExtractGroupsWithTestsResponse {
  extractGroupsWithTests: IGroup[]
}

interface IExtractGroupsWithTestsVariables {
  file: File
}

interface IExtractGroupsWithTestsServiceResponse {
  extractGroupsWithTests: MutationTuple<
    IExtractGroupsWithTestsResponse,
    IExtractGroupsWithTestsVariables
  >[0]
}

const extractGroupsWithTestsService =
  (): IExtractGroupsWithTestsServiceResponse => {
    const [extractGroupsWithTests] = useMutation<
      IExtractGroupsWithTestsResponse,
      IExtractGroupsWithTestsVariables
    >(EXTRACT_GROUPS_WITH_TESTS)

    return {
      extractGroupsWithTests,
    }
  }

export default extractGroupsWithTestsService
