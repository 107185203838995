import React, { ReactElement } from 'react'
import { styled } from '@mui/material/styles'
import { LoaderWrapper, LoaderCircle } from './styled-components'
import CircularProgress from '@mui/material/CircularProgress'

const PREFIX = 'Loader'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledLoaderWrapper = styled(LoaderWrapper)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}))

interface ILoaderProps {
  width?: number | string
  height?: number | string
  withBackground?: boolean
}

const Loader = ({
  width = '40px',
  height = '40px',
  withBackground = false,
}: ILoaderProps): ReactElement => {
  return (
    <StyledLoaderWrapper
      className={`${withBackground ? 'withBackground' : ''}`}
    >
      <LoaderCircle>
        <CircularProgress style={{ width, height }} />
      </LoaderCircle>
    </StyledLoaderWrapper>
  )
}

export default Loader
