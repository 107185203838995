import React from 'react'
import { InfoWrapper, Subtitle, Wrapper } from '../styled-components'
import { t } from 'i18next'
import useAllUserProfile from 'hooks/users/useAllUserProfile'
import {
  TextWrapper,
  DetailsHeader,
  InformationBackground,
  ProfileBackground,
  StyledFlex,
  CenteredText,
} from '../styled-components'
import { Tooltip } from '@mui/material'
import strCutter from 'utils/strCutter'
import { StatusWrapper } from 'components/UsersLayout/styled-components'
import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'
import { IUserInfoRow } from 'hooks/users/staticData'

export const WrapperComponent = ({
  item,
  index,
}: {
  item: IUserInfoRow
  index?: number
}): React.JSX.Element => {
  const text = strCutter(item.info, 30)
  const shouldRenderTooltip = item.info.length > 30

  return (
    <Wrapper key={index}>
      <Subtitle>{t(item.subtitle)}</Subtitle>
      {shouldRenderTooltip ? (
        <Tooltip title={t(item.info)} placement="bottom">
          <InfoWrapper>{text}</InfoWrapper>
        </Tooltip>
      ) : (
        <InfoWrapper>{text}</InfoWrapper>
      )}
    </Wrapper>
  )
}

export const StatusWrapperComponent = (): React.JSX.Element => {
  const { profileData } = useAllUserProfile()
  return (
    <Wrapper>
      <Subtitle>{t('general.status')}</Subtitle>
      <InfoWrapper>
        <StatusWrapper
          className={`${profileData.status?.toLowerCase()}`}
          style={{ fontSize: 16, fontWeight: 500, width: '100%' }}
        >
          {profileData.status}
        </StatusWrapper>
      </InfoWrapper>
    </Wrapper>
  )
}

const UserDetailsComponent = ({
  profileData,
}: {
  profileData: IPersonalInformation
}): React.JSX.Element => {
  return (
    <StyledFlex>
      <ProfileBackground>
        <DetailsHeader>{t('user_details.biography')}</DetailsHeader>
        <TextWrapper>
          {profileData.biography ? (
            profileData.biography
          ) : (
            <CenteredText>{t('general.no_info')}</CenteredText>
          )}
        </TextWrapper>
      </ProfileBackground>
      <InformationBackground>
        <DetailsHeader>{t('form_fields.note')}</DetailsHeader>
        <TextWrapper>
          {profileData.note ? (
            profileData.note
          ) : (
            <CenteredText>{t('general.no_info')}</CenteredText>
          )}
        </TextWrapper>
      </InformationBackground>
    </StyledFlex>
  )
}

export default UserDetailsComponent
