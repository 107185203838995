import { useQuery } from '@apollo/client'
import { GET_USER } from 'gql/users.query'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useUpdateProfileAvatar } from 'hooks/users/useEditProfileService'
import useUpdateStatusUserService from 'hooks/users/useUpdateStatusUserService'
import useResendUserService from 'hooks/users/useUpdateUserPasswordService'
import { useSwal } from 'hooks/useSwal'
import {
  ChangeEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import useUserDeleteService from 'hooks/users/useDeleteUserService'
import { activities, roles } from 'utils/permission'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'

import { ReactComponent as GroupIcon } from 'components/common/Button/icons/user-multiple.svg'

import { getActions, getInfoItemsRow } from './staticData'
import React from 'react'
import { actionConfig } from './gridConfig/gridConfig'
import useOutsideClick from 'helpers/useOutsideClick'
import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'
import { ReactCropperElement } from 'react-cropper'
import { IGroup } from 'interfaces/groups'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

interface ISwalParams {
  title: string
  onConfirm: () => void
  confirmText: string
}

interface IUseAllUserProfileReturn {
  profileData: IPersonalInformation
  breadcrumbData: Array<{ label: string; icon?: JSX.Element; link?: string }>
  isResetPasswordOpen: boolean
  setResetPasswordOpen: React.Dispatch<React.SetStateAction<boolean>>
  hasResponseReceived: boolean
  finishCrop: (
    action: string,
    crudFunction: (isFinished: boolean) => void,
    cropper: RefObject<ReactCropperElement> | null,
  ) => void
  isCompany: boolean
  id: string | undefined
  infoItemsRow: ReturnType<typeof getInfoItemsRow>
  actions: ReturnType<typeof getActions>
  status: string
  actionsRow: ReturnType<typeof actionConfig>
  handleAvatarChange: (e: ChangeEvent<HTMLInputElement>) => void
  avatarRef: React.RefObject<HTMLInputElement>
  cropperOpen: boolean
  styles: {
    modal: { display: string; alignItems: string; justifyContent: string }
  }
  setCropperOpen: React.Dispatch<React.SetStateAction<boolean>>
  cropper: React.RefObject<ReactCropperElement>
  file: string | null
  cropperModalToggle: () => void
  onSave: () => void
  loading: boolean
}

const useAllUserProfile = (): IUseAllUserProfileReturn => {
  const cropper = useRef<ReactCropperElement>(null)
  const avatarRef = useRef(null)
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()
  const { fireSwal } = useSwal()
  const navigate = useNavigate()
  const [isResetPasswordOpen, setResetPasswordOpen] = useState(false)
  const [avatarMenu, setAvatarMenu] = useState(false)
  const [cropperOpen, setCropperOpen] = useState(false)
  const [file, setFile] = useState<string | null>(null)
  const ref: React.RefObject<HTMLDivElement> =
    useRef() as React.RefObject<HTMLDivElement>
  const [loading, setLoading] = useState(false)
  const [loadingForDelete, setLoadingForDelete] = useState(false)

  const { data } = useQuery(GET_USER, {
    variables: { id },
  })
  const user = data?.user || {}
  const defaultFilter: IFilterQueryType = {}

  if (user?.companyId) {
    defaultFilter.companyId = {
      type: LowerCaseFilterableFieldType.EXACT,
      value: user?.companyId,
    }
  }

  const { updateUserStatus } = useUpdateStatusUserService({
    role: user?.role,
    defaultFilter,
  })

  const { deleteUser } = useUserDeleteService()

  const { updateUserPassword } = useResendUserService({
    role: user?.role,
    defaultFilter,
  })

  const [editUserDrawer, setEditUserDrawer] = useState<{
    role: string
    drawerName: string
  }>({} as { role: string; drawerName: string })

  const [profileData, setProfileData] = useState<IPersonalInformation>({
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    companyId: {
      id: '',
      name: '',
    },
    createDate: new Date(),
    birthDate: new Date(),
    consultantOfCompanies: [],
    gender: 'prefer not to say',
    location: '',
    phone: '',
    isTutor: false,
    jobTitle: '',
    biography: '',
    note: '',
    status: '',
    id: '',
    age: 0,
    phoneFields: null,
    phoneFieldValue: null,
    avatar: '',
    group: [],
  })

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      const localData = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        role: user?.role,
        companyId: user?.companyId,
        createDate: user?.createDate,
        birthDate: user?.birthDate,
        gender: user?.gender,
        location: user?.location,
        phone: user?.phone,
        jobTitle: user?.jobTitle,
        biography: user?.biography,
        note: user?.note,
        consultantOfCompanies: user?.consultantOfCompanies,
        status: user?.status,
        id: user?.id,
        age: user?.age,
        phoneFields: user?.phoneFields,
        phoneFieldValue: user?.phoneFields ? user?.phoneFields.code : null,
        avatar: user?.avatar,
        avatarThumbnail: user?.avatarThumbnail,
        group: user?.group ? user?.group.map((g: IGroup) => g.groupId) : [],
        course: user?.courses,
        isTutor: user?.isTutor,
      }
      setProfileData(localData)
      const roleDrawerMap = {
        [roles.super_admin]: 'editModerator',
        [roles.admin]: 'editAdmin',
        [roles.coach]: 'editCoach',
        [roles.student]: 'editStudent',
        [roles.consultant]: 'editModerator',
      }

      let drawerName = roleDrawerMap[user?.role] || ''

      setEditUserDrawer({
        role: user?.role,
        drawerName,
      })
    }
  }, [user])

  const handleEditClick = (): void => {
    DrawerEventEmitter.emit('openDrawer', editUserDrawer.drawerName, true, {
      id,
      role: editUserDrawer.role,
    })
  }

  const handleActivityClick = (userId: string[]): void => {
    const url = `/profile/${userId}/activity`
    window.open(url, '_blank')
  }

  const isCompany = !!user?.companyId?.name

  const { updateAvatar, hasResponseReceived } = useUpdateProfileAvatar()

  const finishCrop = (
    action: string,
    crudFunction: (isFinished: boolean) => void,
    cropper: RefObject<ReactCropperElement> | null,
  ): void => {
    let croppedFile = null
    if (action === 'update') {
      croppedFile = (cropper as RefObject<ReactCropperElement>).current?.cropper
        .getCroppedCanvas()
        .toDataURL()
    }
    if (id) updateAvatar(croppedFile as string, id, action, crudFunction)
  }

  const confirmDelete = async (userId: string[]): Promise<void> => {
    if (!user || !user.role) return
    const role = user.role.toLowerCase()
    const rolePath: { [key: string]: string } = {
      consultant: '/users/super-admins',
      super_admin: '/users/super-admins',
      coach: '/users/tutors',
    }
    const path = rolePath[role] || `/users/${role}s`
    deleteUser(userId, user.role, () => navigate(path))
  }

  const confirmChangeStatus = (userId: string, status: string): void => {
    updateUserStatus([userId], status)
  }

  const handleDeleteClick = (user: IPersonalInformation): void => {
    const params: ISwalParams =
      profileData.role === roles.admin
        ? {
            title: t('popups.can_not_delete_admin'),
            onConfirm: () => navigate('/groups'),
            confirmText: t('popups.go_to_groups'),
          }
        : {
            title: `${t('popups.delete_single')} ${user?.role?.toLowerCase()}?`,
            onConfirm: () => confirmDelete([user?.id as string]),
            confirmText: t('popups.confirm_delete'),
          }

    fireSwal(params)
  }

  const confirmResendPassword = (id: string): void => {
    updateUserPassword(id)
  }

  const handleResendPasswordClick = (userId: string): void => {
    fireSwal({
      title: `${t('popups.resend_password')} ${
        profileData.role === roles.super_admin
          ? t('general.super_admin')
          : t(
              `general.${
                profileData?.role && profileData.role.toLocaleLowerCase()
              }`,
            )
      }?`,
      onConfirm: () => {
        confirmResendPassword(userId)
      },
      confirmText: t('popups.confirm_resend_password'),
    })
  }

  const handleChangeStatusClick = (userId: string, status: string): void => {
    if (status === activities.active && profileData?.role) {
      fireSwal({
        title: `${t('popups.suspend_single')} ${
          profileData.role === roles.super_admin
            ? t('general.super_admin')
            : t(`general.${profileData.role.toLocaleLowerCase()}`)
        }?`,
        onConfirm: () => {
          confirmChangeStatus(userId, activities.suspended)
        },
        confirmText: t('popups.confirm_suspend'),
      })
    } else {
      fireSwal({
        title: `${t('popups.activate_single')} ${
          profileData.role === roles.super_admin
            ? t('general.super_admin')
            : t(
                `general.${
                  profileData.role && profileData.role.toLocaleLowerCase()
                }`,
              )
        }?`,
        onConfirm: () => {
          confirmChangeStatus(userId, activities.active)
        },
        confirmText: t('popups.confirm_activate'),
      })
    }
  }

  useEffect(() => {
    loadingForDelete && setLoadingForDelete(!hasResponseReceived)
  }, [hasResponseReceived, loading, loadingForDelete])

  useOutsideClick(ref, () => {
    if (avatarMenu) setAvatarMenu(false)
  })

  const handleAvatarChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target
    if (!files) return
    const reader = new FileReader()
    setLoading(false)

    reader.onload = (): void => {
      const img: HTMLImageElement = new Image() as HTMLImageElement
      if (typeof reader.result === 'string') {
        img.src = reader.result
        setFile(reader.result as string)
      }
      setCropperOpen(true)
      setAvatarMenu(false)
    }
    reader.readAsDataURL(files[0])
  }

  const crudFunction = (): void => {
    setCropperOpen(false)
    setAvatarMenu(false)
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const onSave = useCallback(() => {
    setLoading(true)
    finishCrop('update', crudFunction, cropper)
    setFile(null)
  }, [])

  const handleAvatarDelete = (): void => {
    fireSwal({
      title: `${t('popups.delete_avatar')} `,
      onConfirm: () => {
        setLoadingForDelete(true)
        finishCrop('delete', () => undefined, null)
        setFile(null)
      },
      confirmText: t('popups.confirm_delete'),
    })
  }

  const roleLabels: { [key: string]: string } = {
    ADMIN: 'Admins',
    CONSULTANT: 'Consultants',
    SUPER_ADMIN: 'Super Admins',
    COACH: 'Tutors',
    STUDENT: 'Students',
  }

  let roleLabel = roleLabels[profileData.role || ''] || ''

  const breadcrumbData = [
    { label: 'Users', icon: <GroupIcon /> },
    {
      label: roleLabel,
      link: ['Super Admins', 'Consultants'].includes(roleLabel)
        ? '/users/super-admins'
        : `/users/${roleLabel.toLowerCase()}`,
    },
    {
      label: `${profileData.firstName} ${profileData.lastName}`,
    },
  ]

  const infoItemsRow = getInfoItemsRow(profileData, isCompany)
  const actions = getActions(
    profileData,
    handleEditClick,
    handleDeleteClick,
    handleActivityClick,
    handleChangeStatusClick,
  )

  const actionsRow = actionConfig(
    handleAvatarDelete,
    setResetPasswordOpen,
    handleResendPasswordClick,
    profileData,
  )

  const styles = {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }

  return {
    profileData,
    breadcrumbData,
    isResetPasswordOpen,
    setResetPasswordOpen,
    hasResponseReceived,
    finishCrop,
    isCompany,
    id,
    infoItemsRow,
    actions,
    status: profileData.status as string,
    actionsRow,
    handleAvatarChange,
    avatarRef,
    cropperOpen,
    styles,
    setCropperOpen,
    cropper,
    file,
    cropperModalToggle,
    onSave,
    loading,
  }
}

export default useAllUserProfile
