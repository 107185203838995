import { ISnackbarProps } from 'interfaces/snackbar'
import useSnackbarAlert from 'hooks/useSnackbar'
// import { useStateValue } from "context/StateContext"
import { useTranslation } from 'react-i18next'

import uploadFileService from 'services/group/uploadFileService'
import { IUser } from 'interfaces/users'

const useUploadFileStream = (): {
  uploadFileStream: (file: File, cb: (users: IUser[]) => void) => Promise<void>
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const { singleUploadStream } = uploadFileService()

  const uploadFileStream = async (
    file: File,
    cb: (users: IUser[]) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors, data } = await singleUploadStream({
      variables: {
        file,
      },
    })

    if (errors && errors.length) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else if (data) {
      message = t('messages.extracted_users')
      variant = 'success'

      cb(data.singleUploadStream)
    }

    setSnackbar({ message, variant })
  }

  return {
    uploadFileStream,
  }
}

export default useUploadFileStream
