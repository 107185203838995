import { useQuery } from '@apollo/client'
import AppBar from '@mui/material/AppBar'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { activeTabClassName, TabNavigation } from 'components/common/Tabs/Tabs'
import { GET_PORTFOLIO, GET_USER_Short_Info } from 'gql/users.query'
import { Container } from 'pages/companies/CompanyDetails/styled-components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import GroupsByUsers from './groups'
import IQTestsResult from './iqTests'
import QuizesByUser from './quizes'
import SkillTestVideos from './skillTestVideos'
import { IUser } from 'interfaces/users'
import { ICourse } from 'pages/courses/courseList.interface'
import { IUserProgress } from 'pages/analytics/Students/StudentsAnalytics.interface'

const styles = {
  bar: {
    background: '#fff',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,.2)',
  },
  btn: {
    width: '20%',
    color: '#212121',
    opacity: 1,
  },
}

const UserTabs: React.FC<{
  currentTab: number
  handleTabChange: React.Dispatch<React.SetStateAction<number>>
  courseInfo: ICourse
  userId?: string
  setUserData?: React.Dispatch<React.SetStateAction<IUser | null>>
  setUserProgress?: React.Dispatch<React.SetStateAction<IUserProgress | null>>
}> = ({
  currentTab,
  handleTabChange,
  courseInfo,
  userId: currentUserId,
  setUserData,
  setUserProgress,
}) => {
  const { t } = useTranslation()
  const { userId: userIdParam } = useParams<{ userId: string }>()
  const userId = currentUserId || userIdParam

  const { data } = useQuery(GET_PORTFOLIO, {
    variables: {
      id:
        courseInfo?.isBought && courseInfo?.originalId
          ? courseInfo.originalId
          : courseInfo?.id,
      userId,
    },
  })

  const { data: userData } = useQuery(GET_USER_Short_Info, {
    variables: {
      id: userId,
    },
  })

  const firstName = userData?.user?.firstName

  const groups =
    userData?.user?.group &&
    userData.user.group.map((g: { groupId: string }) => g.groupId)

  const courseId =
    courseInfo.isBought && courseInfo.originalId
      ? [courseInfo.originalId]
      : [courseInfo?.id]

  useEffect(() => {
    if (userData && setUserData) {
      setUserData(userData.user)
    }
    if (data && setUserProgress) {
      setUserProgress(
        (prev) =>
          ({
            ...prev,
            progress: data.getPortfolioForAnalytics.courseDetails?.progress,
          } as IUserProgress),
      )
    }
  }, [userData, data])

  return (
    <Container>
      <TabNavigation transparent>
        <AppBar position="static" style={styles.bar}>
          <Tabs
            value={currentTab}
            onChange={(_: React.ChangeEvent<object>, value: number): void =>
              handleTabChange(value)
            }
            aria-label="simple tabs example"
          >
            <Tab
              label={t('user_tabs.skill_test_performance')}
              {...activeTabClassName(0)}
              style={styles.btn}
            />
            <Tab
              label={t('user_tabs.quiz_performance')}
              {...activeTabClassName(1)}
              style={styles.btn}
            />
            <Tab
              label={t('user_tabs.iq_performance')}
              {...activeTabClassName(2)}
              style={styles.btn}
            />
            <Tab
              label={t('user_tabs.groups')}
              {...activeTabClassName(3)}
              style={styles.btn}
            />
            {/* <Tab
              label={t('user_tabs.students')}
              {...activeTabClassName(4)}
              style={styles.btn}
            /> */}
          </Tabs>
        </AppBar>
      </TabNavigation>
      {currentTab === 0 && courseId && (
        <SkillTestVideos
          courseId={courseId[0]}
          firstName={firstName}
          userId={userId}
          setUserProgress={setUserProgress}
        />
      )}

      {currentTab === 1 && data?.getPortfolioForAnalytics?.quizzes && (
        <QuizesByUser
          quizResult={data.getPortfolioForAnalytics.quizzes}
          firstName={firstName}
        />
      )}

      {currentTab === 2 && data?.getPortfolioForAnalytics?.iqTests && (
        <IQTestsResult
          testResult={data.getPortfolioForAnalytics.iqTests}
          firstName={firstName}
        />
      )}

      {currentTab === 3 && (
        <GroupsByUsers
          groupIds={groups}
          courses={[courseInfo?.id]}
          firstName={firstName}
          handleTabChange={handleTabChange}
          setGroupsByCourse={(): void => undefined}
        />
      )}
    </Container>
  )
}

export default UserTabs
