import i18n from 'i18n/index'
import { ICourse } from 'pages/courses/courseList.interface'

interface IUsersFilterOptions {
  firstName?: string
  lastName?: string
  status?: {
    value: string
    label: string
  }
  company?: Array<{ value: string; label?: string }>
  age?: number[]
  gender?: { value: string; label: string }
  location?: string
  note?: string
  createDate?: [Date | null, Date | null]
}
export interface IOption {
  value: string
  label: string
}
interface IFilterGenderOption {
  value: string | null
  label: string
}

export interface IAdminsFilterOptions extends IUsersFilterOptions {
  group?: Array<{ value: string; label: string }> | Array<{ value: string }>
}

interface ICoachesFilterOptions extends IUsersFilterOptions {
  group?: Array<{ value: string; label: string }> | Array<{ value: string }>
  course?: Array<{ value: string; label: string }>
}

export interface IStudentsFilterOptions extends IUsersFilterOptions {
  group?: Array<{ value: string; label: string }> | Array<{ value: string }>
  course?: Array<{ value: ICourse; label: string }>
}

export interface IValidationObject {
  label: string
  value: string
  error: boolean
}

export let genderOptions: IOption[] = []
export let filterGenderOptions: IFilterGenderOption[] = []

export let statuses: IOption[] = []
export let filterOptions: IUsersFilterOptions = {
  firstName: '',
  lastName: '',
  status: { value: 'all', label: 'All' },
  gender: { label: 'All', value: 'all' },
  age: [0, 0],
  createDate: [null, null],
  company: undefined,
  location: '',
  note: '',
}

export let adminsFilterOptions: IAdminsFilterOptions = {
  ...filterOptions,
  group: undefined,
}

export let coachesFilterOptions: ICoachesFilterOptions = {
  ...filterOptions,
  group: undefined,
  course: undefined,
}

export let studentsFilterOptions: IStudentsFilterOptions = {
  ...filterOptions,
  group: undefined,
  course: undefined,
}

export let validations: IValidationObject[] = []

function translate(): void {
  genderOptions = [
    { value: 'male', label: i18n.t('user_details.male') },
    { value: 'female', label: i18n.t('user_details.female') },
    { value: 'prefer not to say', label: i18n.t('user_details.not_say') },
  ]

  filterGenderOptions = [
    { value: 'all', label: i18n.t('general.all') },
    { value: 'male', label: i18n.t('user_details.male') },
    { value: 'female', label: i18n.t('user_details.female') },
    { value: 'prefer not to say', label: i18n.t('user_details.not_say') },
    { value: null, label: i18n.t('user_details.not_specified') },
  ]

  statuses = [
    { value: 'all', label: i18n.t('general.all') },
    { value: 'DRAFT', label: i18n.t('general.draft') },
    { value: 'PENDING', label: i18n.t('general.pending') },
    { value: 'ACTIVE', label: i18n.t('general.active') },
    { value: 'SUSPENDED', label: i18n.t('general.suspended') },
  ]

  filterOptions = {
    firstName: '',
    lastName: '',
    status: { value: 'all', label: i18n.t('general.all') },
    gender: { label: 'All', value: 'all' },
    age: [0, 0],
    createDate: [null, null],
    company: undefined,
    location: '',
    note: '',
  }

  adminsFilterOptions = { ...adminsFilterOptions, ...filterOptions }
  coachesFilterOptions = {
    ...coachesFilterOptions,
    ...filterOptions,
  }
  studentsFilterOptions = {
    ...studentsFilterOptions,
    ...filterOptions,
    gender: { label: i18n.t('general.all'), value: 'all' },
  }

  validations = [
    {
      label: `${i18n.t('validations.use_at_least')} 8 ${i18n.t(
        'validations.character',
      )}`,
      value: 'length',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.uppercase',
      )} ${i18n.t('validations.character')}`,
      value: 'uppercase',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.lowercase',
      )} ${i18n.t('validations.character')}`,
      value: 'lowercase',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.number',
      )}`,
      value: 'digit',
      error: true,
    },
    {
      label: `${i18n.t('validations.use_at_least')} 1 ${i18n.t(
        'validations.special',
      )} ${i18n.t('validations.character')}(., @ # $ % ^ & *)`,
      value: 'special',
      error: true,
    },
  ]
}

i18n.on('languageChanged init', () => {
  translate()
})
