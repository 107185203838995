import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { updateCache } from 'services/updateCache'
import { CREATE_COURSE, GET_ALL_COURSE } from 'gql/course/course.query'
import { ICourse } from 'pages/courses/courseList.interface'
import { IAddCourseDrawerDefaultQuery } from 'hooks/course/addCourseDrawer/addCourseDrawer.interface'

interface ICreateCourseServiceResponse {
  createCourse: MutationTuple<ICreateCourseResponse, ICreateCourseVariables>[0]
  loading: boolean
  error?: ApolloError
}

export interface ICreateCourseResponse {
  createCourse: ICourse
}

export interface ICreateCourseVariables {
  input?: ICourse
  filter?: IAddCourseDrawerDefaultQuery
  groupIds?: string[]
}

const createCourseService = (
  params: ICreateCourseVariables,
): ICreateCourseServiceResponse => {
  const [createCourseMutation, { loading, error }] = useMutation<
    ICreateCourseResponse,
    ICreateCourseVariables
  >(CREATE_COURSE, {
    update(cache, { data }) {
      if (data?.createCourse) {
        const document = data.createCourse
        updateCache({
          objectName: 'getAllCourse',
          query: GET_ALL_COURSE,
          cache,
          document,
          action: 'addToTopSingle',
          params,
          nestedFindObjectName: 'data',
        })
      }
    },
  })

  return {
    createCourse: createCourseMutation,
    loading,
    error,
  }
}

export default createCourseService
