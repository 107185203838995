/* eslint-disable @typescript-eslint/no-explicit-any */
function removeKeys<T extends Record<string, any>>(
  obj: T,
  keys: string[],
): Partial<T> {
  const newObj = { ...obj }
  keys.forEach((key) => {
    delete newObj[key]
  })
  return newObj
}

export default removeKeys
