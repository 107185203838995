import styled from 'styled-components'

export const Container = styled.div<{ error: boolean }>`
  width: 100%;
  position: relative;
  .css-yk16xz-control {
    border-color: ${({ error }): string =>
      error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'};
    outline: none;
    &:hover {
      border-color: ${({ error }): string =>
        error ? '#f44336' : 'rgba(0, 0, 0, 0.23)'};
    }
  }
  .css-1wa3eu0-placeholder {
    color: ${({ error }): string =>
      error ? '#f44336' : 'rgba(0, 0, 0, 0.54) '};
  }
`

export const ErrorMessage = styled.div`
  position: absolute;
  color: #f44336;
  bottom: -20px;
  left: 6px;
  font-size: 0.75rem;
`
