import React, { ReactElement } from 'react'
import { Action } from 'components/common/GridV2'
import strCutter from 'utils/strCutter'
import Checkbox from 'components/common/Checkbox'
import moment from 'moment'
import { ReactComponent as TrashCanRed } from 'assets/trash-can-red.svg'
import { ReactComponent as EditIcon } from 'assets/edit.svg'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import IconButton from '@mui/material/IconButton'
import AvatarIcon from 'assets/profile.svg'

import Tooltip from '@mui/material/Tooltip'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import {
  CheckBoxAndAvatarContainer,
  GroupItem,
  GroupTitle,
} from 'pages/companyWizard/CompanyStudents/styled-components'
import { ReactComponent as AssignTutorSvg } from 'assets/assign-tutor.svg'
import { ReactComponent as ModifyStudentsSvg } from 'assets/add-student.svg'
import SchoolIcon from '@mui/icons-material/School'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { TFunction } from 'interfaces/TFunction'
import { IGroup } from 'interfaces/groups'
import { ActionTypes } from './editCourse.interrface'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import {
  StudentDetailsContainer,
  StudentDetailsTextContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'

interface GridItem {
  id: string
  render: (item: IGroup) => JSX.Element
  numeric: boolean
  disablePadding: boolean
  label?: string
}

export const columnConfig = (
  handelClick: (item: IGroup) => void,
  selectItem: (id: string) => void,
  t: TFunction<'translation', undefined>,
  selectedCourseId?: string,
): GridItem[] => {
  const grid: GridItem[] = [
    {
      id: 'isChecked',
      render: (item: IGroup) => (
        <Checkbox
          checked={item.isChecked}
          onChange={(): void => selectItem(item.id as string)}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IGroup) => (
        <div
          onClick={(): void => handelClick(item)}
          style={{ cursor: 'pointer' }}
        >
          <Tooltip
            title={
              <>
                <div>{item.name}</div>
              </>
            }
          >
            <span
              style={{
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: 5,
              }}
            >
              {strCutter(item.name, 7)}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: t('general.admin'),
      render: (item: IGroup): ReactElement => {
        return (
          <div>
            {item.admin ? item.admin.map((i) => i.firstName).join(', ') : 'N/A'}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item: IGroup) => (
        <StatusIndicator status={item.status as GridStatusEnums} />
      ),
    },

    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: t('general.students'),
      render: (item: IGroup) => <div>{item.numberOfStudents || 0}</div>,
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item: IGroup): ReactElement => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(2, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {courses.map(
                (course, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map((exGroup, i: number) => (
                        <div key={i}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'tutor',
      numeric: false,
      disablePadding: false,
      label: `${t('general.coach')}`,
      render: (item: IGroup): ReactElement => {
        const tutor = item.courses?.find(
          (course) => course.courseId === selectedCourseId,
        )?.coach

        return (
          <div>
            {tutor ? (
              <StudentDetailsContainer>
                <CheckBoxAndAvatarContainer style={{ marginLeft: 10 }}>
                  <img src={tutor.avatar || AvatarIcon} />
                </CheckBoxAndAvatarContainer>
                <StudentDetailsTextContainer>
                  <div>{tutor.name}</div>
                </StudentDetailsTextContainer>
              </StudentDetailsContainer>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      id: 'created',
      numeric: true,
      disablePadding: false,
      label: t('general.create_date'),
      render: (item: IGroup) => (
        <div>{moment(item.createDate).format('DD-MM-YYYY')}</div>
      ),
    },
  ]

  return grid
}

interface ActionItem {
  render: (item: IGroup) => React.JSX.Element
}

export const actionConfig = (
  handleClick: (item: IGroup | string | string[], action: ActionTypes) => void,
  t: TFunction<'translation', undefined>,
): ActionItem[] => {
  const action: ActionItem[] = [
    {
      render: (item: IGroup): React.JSX.Element => (
        <Action
          className="assign-tutor"
          onClick={(): void => handleClick(item, ActionTypes.ASSIGN_TUTOR)}
        >
          <Tooltip title={t('groups_layout.assign_tutor')} arrow>
            <AssignTutorSvg />
          </Tooltip>
        </Action>
      ),
    },
    {
      render: (item: IGroup): React.JSX.Element => (
        <Action
          className="modify-students"
          onClick={(): void => handleClick(item, ActionTypes.MODIFY_STUDENTS)}
        >
          <Tooltip title={t('groups_layout.modify_students')} arrow>
            <ModifyStudentsSvg />
          </Tooltip>
        </Action>
      ),
    },
    {
      render: (item: IGroup): React.JSX.Element => (
        <Action
          className="edit-group"
          onClick={(): void => handleClick(item.id as string, ActionTypes.EDIT)}
        >
          <EditIcon />
          <span>{t('groups_layout.edit_group')}</span>
        </Action>
      ),
    },
    {
      render: (item: IGroup): React.JSX.Element => (
        <Action
          className="delete-group"
          onClick={(): void =>
            handleClick([item.id as string], ActionTypes.DELETE)
          }
        >
          <TrashCanRed />
          <DeleteSpan>
            {t('courses_layout.delete_group_from_course')}
          </DeleteSpan>
        </Action>
      ),
    },
  ]
  return action
}

interface ActionItem {
  render: (item: IGroup) => React.JSX.Element
}

export const groupActionConfig = (
  handleClick: (action: string) => void,
  t: TFunction,
): ActionItem[] => {
  const action: ActionItem[] = [
    {
      render: () => (
        <Action
          className="create-group"
          onClick={(): void => handleClick('createGroup')}
        >
          <AddCircleRoundedIcon />
          <span>
            {t('actions.create')} {t('general.group')}
          </span>
        </Action>
      ),
    },
    {
      render: () => (
        <Action
          className="add-group"
          onClick={(): void => handleClick('addGroup')}
        >
          <PlaylistAddIcon />
          <span>{t('actions.add_groups_to_course')}</span>
        </Action>
      ),
    },
  ]

  return action
}
