import React, { ChangeEvent, FormEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AvatarIcon from 'assets/profile.svg'
import { useGetStudentsQuizResults } from 'hooks/group/useGetStudentsQuizResults'
import { quizColumnConfig, iqTestColumnConfig } from './gridConfig'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import {
  SearchInputWrapper,
  GridWrapper,
  StyledDetailContainer,
  CustomAvatarStyles,
  StyledContainer,
} from './styled.components'
import Grid from 'components/common/GridV2'
import TablePagination from 'components/common/Pagination/TablePagination'

import TextInput from 'components/common/TextInput/TextInput'
import { IStudent } from './courseQuizzes.interface'
import { CenterParagraph } from 'pages/analytics/Groups/styled-components'

const PREFIX = 'CourseQuizzes'

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
}

interface PropTypes {
  groupId: string | undefined
  groupsIds: string[] | undefined
  courseId: string
  type: 'quiz' | 'iq_test'
}

const CourseQuizzes = ({
  groupId,
  groupsIds,
  courseId,
  type,
}: PropTypes): ReactElement => {
  const { t } = useTranslation()
  const [perPage, setPerPage] = useState(10)
  const { data, loading, refetch } = useGetStudentsQuizResults({
    groupId,
    groupsIds,
    courseId,
    type,
    filter: {},
    skip: 1,
    limit: perPage,
  })
  const [searchValue, setSearchValue] = useState<string>('')
  const config = type === 'quiz' ? quizColumnConfig(t) : iqTestColumnConfig(t)
  const studentList = data?.data || []

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email'],
      },
    }
    let filter = {}
    if (searchValue !== '') {
      filter = { ...formValue }
    } else {
      filter = {}
    }

    refetch({
      groupId: groupId || '',
      courseId,
      skip: 1,
      limit: perPage,
      type,
      filter,
    })
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value)
    if (e.currentTarget.value === '') {
      refetch({
        groupId: groupId || '',
        courseId,
        skip: 1,
        limit: perPage,
        type,
        filter: {},
      })
    }
  }

  const handlePaginationClick = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    refetch({
      groupId: groupId || '',
      courseId,
      skip: newPage + 1,
      limit: perPage,
      type,
      filter: {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email'],
        },
      },
    })
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      groupId: groupId || '',
      courseId,
      skip: 1,
      limit: parseInt(event.target.value),
      type,
      filter: {
        search: {
          type: 'search',
          value: searchValue,
          fields: ['firstName', 'lastName', 'email'],
        },
      },
    })
    setPerPage(parseInt(event.target.value, 10))
  }

  return (
    <StyledContainer classes={classes}>
      <SearchInputWrapper>
        <form onSubmit={handleSearchSubmit}>
          <TextInput
            label={t('analytics.searchForStudent')}
            type="text"
            size="small"
            value={searchValue}
            onChange={handleSearchChange}
            disabled={loading}
            icon={
              <IconButton type="submit">
                <SearchRoundedIcon />
              </IconButton>
            }
          />
        </form>
      </SearchInputWrapper>
      {!studentList.length ? (
        <StyledContainer classes={classes}>
          <CenterParagraph>{t('general.specific_data')}</CenterParagraph>
        </StyledContainer>
      ) : (
        studentList.map((student: IStudent) => (
          <GridWrapper key={student.id}>
            <StyledDetailContainer>
              <img src={AvatarIcon} style={CustomAvatarStyles} alt="" />
              <span>
                {' '}
                {student.firstName} <span> {student.lastName} </span>{' '}
              </span>
            </StyledDetailContainer>

            <Grid
              data={student.quizzes}
              config={config}
              resetVisible={!!false}
            />
          </GridWrapper>
        ))
      )}
      {data && studentList.length > 0 && (
        <TablePagination
          currentPage={studentList ? data.currentPage : 0}
          rowsPerPage={perPage}
          count={studentList ? data.totalCount : 0}
          handleChangePage={handlePaginationClick}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
        />
      )}
    </StyledContainer>
  )
}

export default CourseQuizzes
