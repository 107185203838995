// @deprecated
/* eslint-disable */

import React, { useState, useEffect, ReactElement } from 'react'
import _ from 'lodash'
import {
  ItemWrapper,
  ContentWrapper,
  ItemContainer,
  InputWrapper,
  AvatarContainer,
  InputLabel,
  FooterWrapper,
  ButtonWrapper,
  TextContainer,
} from './styled-components'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { CHECK_COMPANY_EMAIL } from 'gql/companies.query'
import { GET_ALL_INDUSTRY } from 'gql/skilss.query'
import { CompaniesWizardPage1Schema } from 'helpers/validationSchemas'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from 'components/common/Checkbox'
import { FormFields, FormField } from 'components/common/Form/Form'
import TextInput from 'components/common/TextInput/TextInput'
import { SelectField } from 'components/common/SelectField'
import { Button } from 'components/common/Button'
import countries from 'countries'
import Tooltip from '@mui/material/Tooltip'
import HelpIcon from '@mui/icons-material/Help'
import CreatableSelect from 'react-select/creatable'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { PhoneCountryCode } from 'components/common/PhoneCountyCode'

import { Container, Wrapper } from '../styled-components'

import { useUploadImage } from 'hooks/helpers/useHelpersService'
import useAddCompanyService from 'hooks/companies/useAddCompanyService'
import useCompaniesService from 'hooks/useCompaniesService'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import BusinessIcon from '@mui/icons-material/Business'
import { TFunction } from 'interfaces/TFunction'
import { ISelectFilterOption } from 'interfaces/common'

interface ICompanyDetailsProps {
  changeStep: any
  companyData: any
  setData: any
  onCancel: any
  t: TFunction
}

interface IformData {
  name: string
  companyEmail: string
  phone: string
  city: string
  companyActivity: any
  numberOfUsers: string
  country: any
  postalCode: string
  address: string
  exploreCourses: boolean
  platformService: false
  keyContactName: string
  note: string
  avatar: string
  phoneFields: any
  phoneFieldValue: any
}

const formData: IformData = {
  name: '',
  companyEmail: '',
  phone: '',
  city: '',
  companyActivity: null,
  numberOfUsers: '',
  country: null,
  exploreCourses: false,
  postalCode: '',
  address: '',
  platformService: false,
  keyContactName: '',
  note: '',
  avatar: '',
  phoneFields: null,
  phoneFieldValue: null,
}

const CompanyDetails = ({
  changeStep,
  companyData,
  setData,
  onCancel,
  t,
}: ICompanyDetailsProps): ReactElement => {
  const [cropperOpen, setCropperOpen] = useState(false)
  const [avatar, setAvatar] = useState<string | null>(null)
  const navigate = useNavigate()
  const [save, setSave] = useState('saveAsDraft')

  const { data } = useQuery(GET_ALL_INDUSTRY)
  const { uploadImage, imageLoading } = useUploadImage()
  const { addCompany, loading } = useAddCompanyService()
  const [checkEmail] = useMutation(CHECK_COMPANY_EMAIL)
  const { updateCompany, setCompanyState } = useCompaniesService()

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: formData,
    validationSchema: CompaniesWizardPage1Schema,
    async onSubmit(values) {
      try {
        const data: any = { ...values }

        delete data.phoneFieldValue

        if (data.companyActivity) {
          data.companyActivity = [
            {
              label: data.companyActivity.label,
            },
          ]
        }

        if (data.numberOfUsers)
          data.numberOfUsers = data.numberOfUsers.toString()

        if (data.country) data.country = data.country.value

        if (data.phoneFields && data.phoneFields.value) {
          data.phoneFields = {
            dialCode: data.phoneFields.value,
            code: data.phoneFields.label,
          }
        } else {
          data.phoneFields = null
        }

        if (companyData.companyData && companyData.companyData.id) {
          delete data.managers
          updateCompany(companyData.companyData.id, data, (data: any) => {
            const { managers, ...args } = data

            setData((prev: any) => ({
              ...prev,
              companyData: args,
              managers,
            }))
            if (save === 'next') {
              setCompanyState(args.id, 1)
              changeStep(1)
            } else {
              setCompanyState(args.id, 0, 'Success', () =>
                navigate('/Companies'),
              )
            }
          })

          return
        }

        const res = await checkEmail({
          variables: { email: values.companyEmail },
        })

        if (res.errors) {
          errors.companyEmail = res.errors[0].message
          return false
        }

        delete data.managers
        addCompany(data, [], (data: any) => {
          if (data) {
            const { managers = [], ...args } = data
            setData((prev: any) => ({
              ...prev,
              companyData: args,
              managers,
            }))
            setCompanyState(args.id, 0)
            changeStep(1)
          }
        })
      } catch (err) {
        console.log('Error from checkEmail request', err)
      }
    },
  })

  useEffect(() => {
    if (companyData.companyData) {
      const { companyData: company } = companyData
      const data: any = {}
      const { country, companyActivity, ...args } = formData

      for (const key in args) {
        data[key] = company[key]
      }

      data.companyActivity =
        company.companyActivity && company.companyActivity.length > 0
          ? {
              value: company.companyActivity[0].label,
              label: company.companyActivity[0].label,
            }
          : null

      data.country = company.country
        ? {
            value: company.country,
            label: company.country,
          }
        : null

      setValues(data)
    }
  }, [companyData])

  const handleFileChange = (e: any) => {
    try {
      const { files } = e.target
      const reader: any = new FileReader()
      reader.onload = () => {
        const img: any = new Image()
        img.src = reader.result
        setAvatar(reader.result)
      }
      reader.readAsDataURL(files[0])
      setCropperOpen(true)
    } catch (err) {
      console.log('Error from handleFileChange', err)
    }
  }

  const handleUploadAvatar = (field: string, croppedFile: File) => {
    uploadImage(croppedFile, `company/${field}`, (link: string) => {
      setFieldValue(field, link)
      setAvatar(null)
    })
  }

  const onSubmit = (state: string) => {
    setSave(state)
    handleSubmit()
  }

  const activityList =
    (data &&
      data.getAllIndustry.map((i: any) => ({ label: i.label, value: i.id }))) ||
    []

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  return (
    <>
      <Container>
        <Wrapper width="74%">
          <ContentWrapper>
            <FormFields>
              <FormField width="48%">
                <AvatarContainer>
                  <div className="input-button">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      id="avatar"
                    />
                    {imageLoading ? (
                      `${t('general.loading')}*`
                    ) : (
                      <InputLabel htmlFor="avatar">
                        <AddAPhotoIcon fontSize="small" />
                        <span>{t('general.upload')}</span>
                      </InputLabel>
                    )}
                  </div>
                  {values.avatar ? (
                    <img src={values.avatar} />
                  ) : (
                    <BusinessIcon
                      style={{
                        fontSize: '7em',
                        color: '#06C68F',
                      }}
                    />
                  )}
                </AvatarContainer>
              </FormField>

              <FormField width="48%">
                <ItemContainer>
                  <InputWrapper style={{ marginTop: '0px' }}>
                    <TextInput
                      error={errors.name ? true : false}
                      errorMessage={errors.name ? errors.name : ''}
                      touched={touched.name}
                      label={`${t('general.company')} ${t(
                        'form_fields.title',
                      )}*`}
                      name="name"
                      size="small"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <TextInput
                      error={errors.keyContactName ? true : false}
                      errorMessage={
                        errors.keyContactName ? errors.keyContactName : ''
                      }
                      touched={touched.keyContactName}
                      label={`${t('company_details.key_contact_name')}*`}
                      name="keyContactName"
                      size="small"
                      type="text"
                      value={values.keyContactName}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                    />
                  </InputWrapper>

                  <InputWrapper>
                    <TextInput
                      error={errors.companyEmail ? true : false}
                      errorMessage={
                        errors.companyEmail ? errors.companyEmail : ''
                      }
                      touched={touched.companyEmail}
                      label={`${t('company_details.key_contact_email')}*`}
                      name="companyEmail"
                      size="small"
                      type="text"
                      value={values.companyEmail}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                    />
                  </InputWrapper>
                </ItemContainer>
              </FormField>

              <FormField width="48%">
                <PhoneCountryCode
                  label={t('user_details.phone')}
                  selectLabel={t('form_fields.dial_code')}
                  selectValue={values.phoneFields || null}
                  selectChange={(e: ISelectFilterOption | null) =>
                    setFieldValue('phoneFields', e)
                  }
                  inputLabel={t('user_details.phone')}
                  inputValue={values.phone}
                  inputChange={handleChange}
                  error={!!(errors.phone || errors.phoneFields)}
                  errorMessage={(errors.phone || errors.phoneFields) as string}
                  touched={(touched.phone || touched.phoneFields) as boolean}
                />
              </FormField>

              <FormField width="48%" style={{ position: 'relative' }}>
                <CreatableSelect
                  onChange={(e: any) => setFieldValue('companyActivity', e)}
                  options={activityList}
                  value={values.companyActivity}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 2,
                    }),
                  }}
                  placeholder={t('company_details.industry')}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  // error={errors.numberOfUsers ? true : false}
                  // errorMessage={errors.numberOfUsers ? errors.numberOfUsers : ''}
                  // touched={touched.numberOfUsers}
                  label={t('company_details.number_of_users')}
                  name="numberOfUsers"
                  size="small"
                  type="number"
                  value={values.numberOfUsers}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <SelectField
                  placeholder={t('company_details.country')}
                  options={countries}
                  value={values.country}
                  onChange={(e: any) => setFieldValue('country', e)}
                  isClearable
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  // error={errors.city ? true : false}
                  // errorMessage={errors.city ? errors.city : ''}
                  // touched={touched.city}
                  label={t('company_details.city')}
                  name="city"
                  size="small"
                  type="text"
                  value={values.city}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  label={t('company_details.postal_code')}
                  name="postalCode"
                  size="small"
                  type="text"
                  value={values.postalCode}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <TextInput
                  // error={errors.address ? true : false}
                  // errorMessage={errors.address ? errors.address : ''}
                  // touched={touched.address}
                  label={t('company_details.address')}
                  name="address"
                  size="small"
                  type="text"
                  fullWidth={true}
                  value={values.address}
                  onChange={handleChange}
                />
              </FormField>

              <FormField>
                <TextInput
                  // error={errors.note ? true : false}
                  // errorMessage={errors.note ? errors.note : ''}
                  // touched={touched.note}
                  label={t('form_fields.note')}
                  name="note"
                  size="small"
                  type="text"
                  fullWidth
                  multiline
                  rows="3"
                  value={values.note}
                  onChange={handleChange}
                />
              </FormField>

              <FormField
                style={{
                  width: '100%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                }}
              >
                <ItemWrapper>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.platformService}
                        onChange={handleChange}
                        name="platformService"
                        color="primary"
                      />
                    }
                    label={t('companies_layout.platform_service')}
                  />

                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {t('company_details.platform_service_helper')}
                      </span>
                    }
                    arrow
                  >
                    <HelpIcon color="primary" style={{ color: '#06C68F' }} />
                  </Tooltip>
                </ItemWrapper>
                <ItemWrapper>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.exploreCourses}
                        onChange={handleChange}
                        name="exploreCourses"
                        color="primary"
                      />
                    }
                    label={t('companies_layout.explore_courses')}
                  />

                  <Tooltip
                    title={
                      <span style={{ fontSize: '14px' }}>
                        {t('company_details.explore_courses_helper')}
                      </span>
                    }
                    arrow
                  >
                    <HelpIcon color="primary" style={{ color: '#06C68F' }} />
                  </Tooltip>
                </ItemWrapper>
              </FormField>
            </FormFields>
          </ContentWrapper>
        </Wrapper>

        <Wrapper width="calc(26% - 20px)">
          <TextContainer>
            {t('company_wizard_layout.company_details_description')}
          </TextContainer>
        </Wrapper>
      </Container>

      <FooterWrapper>
        <ButtonWrapper>
          <Button
            text={t('actions.cancel')}
            type="small"
            onClick={onCancel}
            background="#E0E1E2"
            textColor="#414141"
          />
        </ButtonWrapper>

        <ButtonWrapper>
          <div style={{ marginRight: '15px', height: '31px' }}>
            <Button
              text={t('actions.save_as_draft')}
              type="small"
              onClick={() => onSubmit('saveAsDraft')}
              textColor="#06C68F"
              background="#f5f5f5"
              outline="1px solid #06C68F"
              isDisabled={loading}
            />
          </div>

          <Button
            text={t('actions.save_and_next')}
            type="small"
            color="secondary"
            onClick={() => onSubmit('next')}
            background="#06C68F"
            isDisabled={loading}
          />
        </ButtonWrapper>
      </FooterWrapper>

      <Modal
        isOpened={cropperOpen}
        onClose={() => setCropperOpen(!cropperOpen)}
      >
        <ImageCropper
          file={{ file: avatar as string }}
          hideCropper={() => setCropperOpen(!cropperOpen)}
          getCroppedFile={(_: any, file: any) =>
            handleUploadAvatar('avatar', file)
          }
        />
      </Modal>
    </>
  )
}

export default CompanyDetails
