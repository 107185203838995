import React, { FormEvent, ReactElement } from 'react'
import 'cropperjs/dist/cropper.css'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import { FormContainer } from 'components/common/Form'
import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import Tab1 from './Tab1'
import Loader from 'components/common/Loader'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { IEditCourseDrawerProps } from 'hooks/course/editCourseDrawer/editCourseDrawer.interface'
import useCourseForm from 'interfaces/courses/useCourseForm/useCourseForm'

const EditCourseDrawer = ({ id }: IEditCourseDrawerProps): ReactElement => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleVideoChange,
    videoLinks,
    setVideoLinks,
    progress,
    setProgress,
    introVideo,
    setIntroVideo,
    setFieldValue,
    isCompanyMode,
    skillList,
    uploadImage,
    uploadFile,
    imageLoading,
    handleUploadFile,
    fileLoading,
    courseData,
    handleButtonClick,
    handleCloseDrawer,
    courseCompanyId,
    loading,
    cropperModalToggle,
    handleCropSave,
    editCourseLoading,
    t,
    cropperOpen,
  } = useCourseForm({
    id,
  })

  return (
    <>
      {loading || editCourseLoading ? (
        <Loader withBackground={Boolean(loading)} />
      ) : (
        courseData && (
          <>
            <div>
              <DrawerHeader>
                <DrawerTitle>{`${t('actions.edit')} ${t(
                  'general.course',
                )}`}</DrawerTitle>
                <IconButton onClick={handleCloseDrawer}>
                  <HighlightOffRoundedIcon />
                </IconButton>
              </DrawerHeader>
            </div>
            <DrawerContent headerHeight={67}>
              <FormContainer
                onSubmit={(e: FormEvent<HTMLFormElement>): void =>
                  e.preventDefault()
                }
              >
                <Tab1
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  handleVideoChange={handleVideoChange}
                  videoLinks={videoLinks}
                  setVideoLinks={setVideoLinks}
                  videoUploadProgress={progress}
                  setProgress={setProgress}
                  introVideo={introVideo}
                  setIntroVideo={setIntroVideo}
                  setFieldValue={setFieldValue}
                  isCompanyMode={isCompanyMode}
                  skillList={skillList}
                  uploadImage={uploadImage}
                  uploadFile={uploadFile}
                  imageLoading={imageLoading}
                  handleUploadFile={handleUploadFile}
                  fileLoading={fileLoading}
                  courseData={courseData}
                  handleButtonClick={handleButtonClick}
                  handleDeleteCourse={handleCloseDrawer}
                  manualCompanyId={courseCompanyId as string}
                  t={t}
                />
              </FormContainer>
            </DrawerContent>
            <Modal isOpened={cropperOpen} onClose={cropperModalToggle}>
              <ImageCropper
                file={''}
                hideCropper={cropperModalToggle}
                getCroppedFile={handleCropSave}
              />
            </Modal>
          </>
        )
      )}
    </>
  )
}

export default EditCourseDrawer
