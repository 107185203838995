import { useQuery } from '@apollo/client'
import { GET_TEST_ANALYTICS } from 'gql/tests/tests.query'
import {
  ITestAnalyticsReturn,
  IUseTestAnalyticsProps,
} from './testAnalytics.interface'

export const useTestAnalytics = ({
  testId,
  userId,
}: IUseTestAnalyticsProps): ITestAnalyticsReturn => {
  const { data, loading, error } = useQuery(GET_TEST_ANALYTICS, {
    variables: { testId, userId },
    skip: !testId || !userId,
  })
  const userData = data?.getTestAnalytics?.user
  const testData = data?.getTestAnalytics?.test

  return { data, userData, testData, loading, error }
}
