import React, { ReactElement } from 'react'
import strCutter from 'utils/strCutter'
import moment from 'moment'

import {
  GroupsWrapper,
  GroupItem,
  CheckBoxAndAvatarContainer,
  GroupTitle,
} from 'components/UsersLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import SchoolIcon from '@mui/icons-material/School'

import { NoValueWrapper } from 'components/common/Grid/styled-components'
import AvatarIcon from 'assets/profile.svg'
import {
  StudentDetailsContainer,
  StudentDetailsTextContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { TFunction } from 'interfaces/TFunction'
import { IUser, IUserCompany } from 'interfaces/users'
import { IAction } from 'components/common/GridV2'

export const columnConfig = (t: TFunction): IAction[] => [
  {
    id: 'Avatar',
    label: `${t('user_details.student_details')}`,
    render: (item: IUser) => (
      <StudentDetailsContainer>
        <CheckBoxAndAvatarContainer style={{ marginLeft: 10 }}>
          <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
        </CheckBoxAndAvatarContainer>
        <StudentDetailsTextContainer>
          <div>
            {item.firstName} {item.lastName}
          </div>
          <div>{item.email}</div>
        </StudentDetailsTextContainer>
      </StudentDetailsContainer>
    ),
    numeric: false,
    disablePadding: true,
    width: '40%',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: `${t('user_details.phone')}`,
    render: (item: IUser): ReactElement => (
      <div>
        {item.phoneFields &&
          item.phoneFields.code &&
          `(${item.phoneFields.code}) `}
        {item.phone}
      </div>
    ),
  },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: `${t('general.status')}`,
    render: (item: IUser) => <StatusIndicator status={item.status} />,
  },
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: `${t('general.groups')}`,
    render: (item: IUser): ReactElement => {
      const groups = item.group || []
      const firstTwoGroups = groups.slice(0, 2)
      const exGroups = groups.slice(2, groups.length)
      return (
        <div>
          {firstTwoGroups.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
          <GroupsWrapper>
            {firstTwoGroups.map(
              (group, index: number) =>
                index < 3 && (
                  <GroupItem key={group.groupId}>
                    <IconButton>
                      <span className="material-icons">groups</span>{' '}
                    </IconButton>
                    <GroupTitle className="group-item-title">
                      {group.name}
                    </GroupTitle>
                  </GroupItem>
                ),
            )}

            {exGroups.length > 0 && (
              <Tooltip
                title={
                  <>
                    {exGroups.map((exGroup) => (
                      <div key={exGroup.groupId}>{exGroup.name}</div>
                    ))}
                  </>
                }
              >
                <span
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                >
                  ...
                </span>
              </Tooltip>
            )}
          </GroupsWrapper>
        </div>
      )
    },
  },
  {
    id: 'courses',
    numeric: false,
    disablePadding: false,
    label: `${t('general.courses')}`,
    render: (item: IUser): ReactElement => {
      const courses = item.courses || []
      const firstTwoCourses = courses.slice(0, 2)
      const exCourse = courses.slice(3, courses.length)
      return (
        <div>
          {firstTwoCourses.length === 0 && <NoValueWrapper>N/A</NoValueWrapper>}
          <GroupsWrapper>
            {courses.map(
              (course, index: number) =>
                index < 3 && (
                  <GroupItem key={course.courseId}>
                    <IconButton>
                      <SchoolIcon fontSize="small" />
                    </IconButton>
                    <GroupTitle className="group-item-title">
                      {course.name}
                    </GroupTitle>
                  </GroupItem>
                ),
            )}

            {exCourse.length > 0 && (
              <Tooltip
                title={
                  <>
                    {exCourse.map((exGroup) => (
                      <div key={exGroup.courseId}> {exGroup.name}</div>
                    ))}
                  </>
                }
              >
                <span
                  style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    marginLeft: 5,
                  }}
                >
                  ...
                </span>
              </Tooltip>
            )}
          </GroupsWrapper>
        </div>
      )
    },
  },
  {
    id: 'companyId',
    numeric: false,
    disablePadding: false,
    label: `${t('user_details.company')}`,
    render: (item: IUser) => (
      <div>
        {item.companyId ? (
          strCutter((item.companyId as IUserCompany).name, 25)
        ) : (
          <>N/A</>
        )}
      </div>
    ),
  },
  {
    id: 'createdDate',
    numeric: false,
    disablePadding: false,
    label: `${t('general.create_date')}`,
    render: (item: IUser) => (
      <>{moment(parseInt(item.createDate as string)).format('DD-MM-YYYY')}</>
    ),
  },
]
