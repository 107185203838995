import React from 'react'
import strCutter from 'utils/strCutter'
import { Props } from './SkillTestVideoItem.interface'
import Tooltip from '@mui/material/Tooltip'
import {
  Card,
  Wrapper,
  Header,
  Title,
  Content,
  Footer,
  VideosCountContainer,
  TipsWrapper,
  TipsList,
  TipsItem,
  TipsDescription,
} from './styled.components'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import useSkillTestVideoItem from 'hooks/modules/SkillTestVideos/useSkillTestVideoItem'
import { useTranslation } from 'react-i18next'

const SkillTestVideoItem = ({
  skillTestVideo,
  actions,
  width,
  isDefault = false,
}: Props): React.JSX.Element => {
  const { t } = useTranslation()
  const { title, description, videosCount, renderText } = useSkillTestVideoItem(
    { skillTestVideo, actions, width, isDefault: isDefault ?? false },
  )

  return (
    <Card width={width}>
      <Header>
        <Wrapper>
          {title.length > 25 ? (
            <Tooltip title={title}>
              <Title>{strCutter(title, 25)}</Title>
            </Tooltip>
          ) : (
            <Title>{title}</Title>
          )}
          {actions && (
            <GridActionMenu actionConfig={actions} row={skillTestVideo} />
          )}
        </Wrapper>
      </Header>
      <Content>
        {!isDefault && description.length > 95 ? (
          <Tooltip title={description}>
            <p>{strCutter(description, 95)}</p>
          </Tooltip>
        ) : (
          description
        )}
        {isDefault && (
          <TipsWrapper>
            <TipsList>
              <TipsItem>{t('tips.keep_it_concise')}</TipsItem>
              <TipsItem>{t('tips.use_your_own_stories')}</TipsItem>
              <TipsItem>{t('tips.get_the_message_across')}</TipsItem>
            </TipsList>
            <TipsDescription>{t('tips.description')}</TipsDescription>
          </TipsWrapper>
        )}
      </Content>
      <Footer>
        <VideosCountContainer>{renderText(videosCount)}</VideosCountContainer>
      </Footer>
    </Card>
  )
}

export default SkillTestVideoItem
