import React, { ReactElement } from 'react'
import { ICommonDrawerProps } from './customDrawer.interface'
import { classes, StyledDrawer } from './styled-components'

const CustomDrawer = ({
  opened,
  children,
  toggleDrawer,
  totalWidth = '630px',
}: ICommonDrawerProps): ReactElement => {
  return (
    <div>
      <StyledDrawer
        anchor="right"
        open={opened}
        onClose={(e: KeyboardEvent): void => toggleDrawer && toggleDrawer(e)}
        classes={{
          paper: classes.drawer,
        }}
        totalWidth={totalWidth}
      >
        {children}
      </StyledDrawer>
    </div>
  )
}

export default CustomDrawer
