import { MutationTuple, useMutation } from '@apollo/client'
import { GET_USERS, UPDATE_USER_STATUS } from 'gql/users.query'
import { IUpdateStatusUserServiceParams } from 'hooks/users/useUpdateStatusUserService'
import { IUser } from 'interfaces/users'
import { updateCache } from 'services/updateCache'

export interface IUpdateUserStatusResponse {
  updateUserStatus: IUser[]
}

export interface IUpdateUserStatusParams {
  ids: string[]
  status: string
}

export interface IUpdateUserStatusServiceReturn {
  updateUserStatusMutation: MutationTuple<
    IUpdateUserStatusResponse,
    IUpdateUserStatusParams
  >[0]
  loading: boolean
  error?: Error
}

const updateUserStatusService = (
  params: IUpdateStatusUserServiceParams,
): IUpdateUserStatusServiceReturn => {
  const [updateUserStatusMutation, { loading, error }] = useMutation<
    IUpdateUserStatusResponse,
    IUpdateUserStatusParams
  >(UPDATE_USER_STATUS, {
    update(cache, { data }) {
      if (data?.updateUserStatus) {
        updateCache({
          objectName: 'usersByRole',
          query: GET_USERS,
          cache,
          document: data.updateUserStatus,
          action: 'update',
          params,
        })
      }
    },
  })

  return {
    updateUserStatusMutation,
    loading,
    error,
  }
}

export default updateUserStatusService
