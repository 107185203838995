// @deprecated
/* eslint-disable */

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import SchoolIcon from '@mui/icons-material/School'
import Checkbox from 'components/common/Checkbox'
import { NoValueWrapper } from 'components/common/Grid/styled-components'
import moment from 'moment'
import React from 'react'
import {
  Action,
  GroupsWrapper,
  GroupItem,
  GroupTitle,
} from './styled-components'
import strCutter from 'utils/strCutter'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { TFunction } from 'interfaces/TFunction'

interface ICompanyGroupItemProps {
  name: string
  description: string
  location: string
  isChecked?: boolean
  id: string
  last?: string
  numberOfStudents?: number | string
  admin?: any
  created: string
  company: any
  courses: any
  status: GridStatusEnums
  companyId: any
}

export const columnConfig = (
  handelClick: any,
  selectItem: any,
  checkbox?: any,
  t?: any,
) => {
  const grid = [
    {
      id: 'isChecked',
      render: (item: ICompanyGroupItemProps) => (
        <div style={{ visibility: !checkbox ? 'hidden' : 'visible' }}>
          <Checkbox
            checked={item.isChecked}
            onChange={() => selectItem(item.id)}
          />
        </div>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: ICompanyGroupItemProps) => (
        <div onClick={() => handelClick(item.id)} style={{ cursor: 'pointer' }}>
          {strCutter(item.name, 30)}
        </div>
      ),
    },
    {
      id: 'admin',
      numeric: true,
      disablePadding: false,
      label: t('general.admin'),
      render: (item: ICompanyGroupItemProps) => {
        return (
          <div>
            {item.admin
              ? item.admin.map((i: any) => i.firstName).join(', ')
              : 'N/A'}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: t('status'),
      render: (item: ICompanyGroupItemProps) => {
        return <StatusIndicator status={item.status} />
      },
    },
    {
      id: 'numberOfStudents',
      numeric: true,
      disablePadding: false,
      label: 'Students',
      render: (item: ICompanyGroupItemProps) => (
        <div>{item.numberOfStudents || 0}</div>
      ),
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: t('general.courses'),
      render: (item: ICompanyGroupItemProps) => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourses = courses.slice(2, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoCourses.map(
                (course: any, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourses.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourses.map((exCourse: any) => (
                        <div key={exCourse.courseId}>{exCourse.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item: ICompanyGroupItemProps) => (
        <div>
          {item.company && item.company.name ? (
            strCutter(item.company.name, 25)
          ) : (
            <>N/A</>
          )}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item: ICompanyGroupItemProps) =>
        item.created ? (
          <>{moment(parseInt(item.created)).format('DD-MM-YYYY')}</>
        ) : (
          <NoValueWrapper>N/A</NoValueWrapper>
        ),
    },
  ]

  return grid
}

export const actionConfig = (handleClick: any, t: TFunction) => {
  const action = [
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('edit', item)}>
          <EditRoundedIcon />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ),
    },
    {
      render: (item: any) => (
        <Action onClick={() => handleClick('delete', item.id)}>
          <DeleteForeverRoundedIcon />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]
  return action.filter((i: any) => !i.hide)
}
