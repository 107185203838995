import React from 'react'
import { Action } from 'components/UsersLayout/styled-components'
import { ReactComponent as DeleteIconRed } from 'assets/trash-can-red.svg'
import { ReactComponent as Reset } from 'assets/reset.svg'
import { ReactComponent as Upload } from 'components/common/Button/icons/upload.svg'
import { t } from 'i18next'
import { ActionCustomFlex } from 'pages/profile/AllUserProfile/UsersProfile/styled-components'
import {
  IGetAction,
  IPersonalInformation,
} from 'pages/profile/AdminProfile/PersonalInformation.interface'
import { ReactComponent as EditIcon } from 'assets/editRounded.svg'
import { IAction } from 'components/common/GridV2'

export const actionConfig = (
  handleAvatarDelete: () => void,
  profileData: IPersonalInformation,
): IAction[] => {
  let action: IAction[] = [
    {
      render: (): JSX.Element => (
        <>
          <Action color="#06C68F">
            <label htmlFor="file">
              <ActionCustomFlex>
                <Upload />
                <span> {t('actions.upload_avatar')}</span>
              </ActionCustomFlex>
            </label>
          </Action>
        </>
      ),
    },
  ]

  if (profileData.avatar) {
    action.push({
      render: (): JSX.Element => (
        <Action color="#FF0000" onClick={(): void => handleAvatarDelete()}>
          <DeleteIconRed />
          <span>{t('actions.delete_avatar')}</span>
        </Action>
      ),
    })
  }

  return action
}

export const getActions = (
  profileData: IPersonalInformation,
  handleEditClick: () => void,
  handleResetPasswordClick: () => void,
): IGetAction[] => [
  {
    icon: <EditIcon />,
    text: t('actions.edit'),
    onClick: handleEditClick,
  },

  {
    icon: <Reset />,
    text: t('actions.change_password'),
    onClick: handleResetPasswordClick,
  },
]
