import React, { ChangeEvent } from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Modal from '@mui/material/Modal'
import FilterListIcon from '@mui/icons-material/FilterList'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SyncIcon from '@mui/icons-material/Sync'
import { Button } from 'components/common/Button'
import { Grid } from 'components/common/Cards/styled-components'
import Drawer from 'components/common/Drawer'
import Pagination from 'components/common/Pagination'
import { Input } from 'components/common/TextInput'
import { ToolbarItem } from 'components/common/Toolbar'
import Player from 'components/common/VideoPlayer/Player'
import CourseList from 'pages/courses/CourseList'
import FilterDrawer from 'pages/courses/FilterDrawer/FilterDrawer'
import { Wrapper } from '../styled-components'
import { CompanyCoursesWrapper } from './styled-components'
import AddPurchasedCourseDrawer from './AddPurchasedCourseDrawer/AddPurchasedCourseDrawer'
import _ from 'lodash'
import { ICompanyCoursesProps } from '../types'
import useCompanyCourses from 'hooks/companies/useCompanyCourses'

const PREFIX = 'CompanyCourses'

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const StyledCompanyCoursesWrapper = styled(CompanyCoursesWrapper)(
  ({ theme }) => ({
    [`& > .${classes.paper}`]: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
)

const CompanyCourses = ({
  variables,
  createBtn,
  addCoachIds,
  defCurrentPage,
  currentTab,
  t,
}: ICompanyCoursesProps): JSX.Element => {
  const {
    actions,
    courses,
    filteredCourses,
    filterData,
    filterIsOpen,
    searchText,
    videoModal,
    addPurchasedCourse,
    boughtCourses,
    courseEditMode,
    refetch,
    setAddPurchasedCourse,
    setFilterData,
    setFilterIsOpen,
    setSearchText,
    setVideoModal,
    handleCreateCourse,
    handlePaginationClick,
    resetFilter,
    filterDrawer,
    onSearchSubmit,
    actionHandler,
    handleClosePurchasedCourseDrawer,
    setCurrentPage,
    coachIds,
    state,
    isSuperAdmin,
  } = useCompanyCourses({
    variables,
    addCoachIds,
    defCurrentPage,
    currentTab,
    t,
  })

  return (
    <StyledCompanyCoursesWrapper>
      <div style={{ marginBottom: 20 }}>
        <Wrapper justify="between" align="center">
          <Wrapper align="center">
            {createBtn && state.currentUser.role !== 'ADMIN' && (
              <Button
                text={t('courses_layout.create_course')}
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={handleCreateCourse}
                isDisabled={!createBtn}
                background="#06C68F"
              />
            )}

            {currentTab === 0 && isSuperAdmin && (
              <Button
                text={t('courses_layout.add_purchased_courses')}
                color="secondary"
                icon={<AddCircleRoundedIcon />}
                onClick={(): void => setAddPurchasedCourse(true)}
                background="#06C68F"
              />
            )}
          </Wrapper>

          <Wrapper>
            <ToolbarItem>
              <IconButton
                disabled={_.isEmpty(filterData.filterOptions) && !searchText}
                onClick={resetFilter}
              >
                <SyncIcon fontSize="small" />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <IconButton onClick={filterDrawer}>
                <FilterListIcon
                  fontSize="medium"
                  style={{ color: '#06C68F' }}
                />
              </IconButton>
            </ToolbarItem>

            <ToolbarItem>
              <form onSubmit={onSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setSearchText(e.target.value)
                  }}
                  icon={
                    <IconButton onClick={onSearchSubmit}>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </Wrapper>
        </Wrapper>
      </div>

      <Grid
        className="card responsive-cards"
        style={{ position: 'relative' }}
        cols={4}
      >
        <CourseList
          isGridView={true}
          actionHandler={actionHandler}
          activeTab={currentTab}
          companyMode={true}
          data={filteredCourses}
          gridStyle="card"
          actions={actions}
        />
      </Grid>
      {courses?.data && courses.data.length === 0 && (
        <div>{t('general.no_courses')}</div>
      )}
      {courses && courses.totalPages > 1 && (
        <div
          style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
        >
          <Pagination
            currentPage={courses ? courses.currentPage : 1}
            totalPages={courses ? courses.totalPages : 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )}
      <Drawer
        opened={filterIsOpen}
        toggleDrawer={(): void => undefined}
        totalWidth="700px"
      >
        <FilterDrawer
          filterData={filterData}
          setFilterData={setFilterData}
          refetchCourse={refetch}
          resetFilter={resetFilter}
          onClose={(): void => setFilterIsOpen(false)}
          isCooursePrivacyHidden={true}
          setCurrentPage={setCurrentPage}
          isOnPurchasedCourses={currentTab === 0}
          coachIds={coachIds}
          t={t}
        />
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={styles.modal}
        open={videoModal.modal}
        onClose={(): void => {
          setVideoModal({
            ...videoModal,
            modal: false,
          })
        }}
        closeAfterTransition
      >
        <Player url={videoModal.data} subtitle={videoModal.subtitle} />
      </Modal>

      <Drawer
        opened={addPurchasedCourse}
        toggleDrawer={(): void => undefined}
        totalWidth="850px"
      >
        <AddPurchasedCourseDrawer
          boughtCourses={boughtCourses}
          handleCloseDrawer={handleClosePurchasedCourseDrawer}
          isEditMode={courseEditMode}
          refetchCourses={refetch}
          hasPurchasedCourses={true}
        />
      </Drawer>
    </StyledCompanyCoursesWrapper>
  )
}

export default CompanyCourses
