import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { EDIT_QUIZ, ADD_REMOVE_QUESTIONS } from 'gql/quiz/quiz.query'
import { IQuestion } from 'interfaces/questions'
import { IQuiz } from 'pages/quizzes/quiz.interface'

interface IEditQuizServiceResponse {
  editQuizMutation: MutationTuple<IEditQuizResponse, IEditQuizParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IEditQuizResponse {
  editQuiz: IQuiz
}

interface IEditQuizParams {
  quizId: string
  input: IQuiz
}

export const editQuizService = (): IEditQuizServiceResponse => {
  const [editQuizMutation, { loading, error }] = useMutation<
    IEditQuizResponse,
    IEditQuizParams
  >(EDIT_QUIZ)

  return {
    editQuizMutation,
    loading,
    error,
  }
}

interface IAddRemoveQuestionsServiceResponse {
  questionsActionMutation: MutationTuple<
    IAddRemoveQuestionsResponse,
    IAddRemoveQuestionsParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IAddRemoveQuestionsResponse {
  addRemoveQuestions: IQuestion
}

interface IAddRemoveQuestionsParams {
  action: string
  questionIds: string[]
  quizId: string
}

export const addRemoveQuestionsService =
  (): IAddRemoveQuestionsServiceResponse => {
    const [questionsActionMutation, { loading, error }] = useMutation<
      IAddRemoveQuestionsResponse,
      IAddRemoveQuestionsParams
    >(ADD_REMOVE_QUESTIONS)

    return {
      questionsActionMutation,
      loading,
      error,
    }
  }
