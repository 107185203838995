import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import SchoolIcon from '@mui/icons-material/School'
import { GroupsWrapper } from 'components/common/DetailsLayout'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-action.svg'
import moment from 'moment'
import { NoValueWrapper } from 'pages/companies/CompanyDetails/styled-components'
import {
  CheckBoxAndAvatarContainer,
  GroupItem,
  GroupTitle,
} from 'pages/companyWizard/CompanyStudents/styled-components'
import React, { ReactElement } from 'react'
import strCutter from 'utils/strCutter'
import AvatarIcon from 'assets/profile.svg'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import {
  StudentDetailsContainer,
  StudentDetailsTextContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import { Action } from 'components/UsersLayout/styled-components'
import Checkbox from 'components/common/Checkbox'
import { IUser, IUserCompany } from 'interfaces/users'
import { TFunction } from 'interfaces/TFunction'
import { IStudentGridConfigAction } from 'hooks/students/addStudentInGroupDrawer/useAddStudentInGroupDrawer.interface'

export const columnConfig = (
  selectItem: (id?: string) => void,
  t: TFunction<'translation', undefined>,
): IStudentGridConfigAction[] => {
  let grid: IStudentGridConfigAction[] = [
    {
      id: 'isChecked',
      render: (item) => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={item.isChecked}
            onChange={(): void => selectItem(item.id)}
          />
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'Avatar',
      label: `${t('user_details.student_details')}`,
      render: (item) => (
        <StudentDetailsContainer>
          <CheckBoxAndAvatarContainer style={{ marginLeft: 10 }}>
            <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
          </CheckBoxAndAvatarContainer>
          <StudentDetailsTextContainer>
            <div>
              {item.firstName} {item.lastName}
            </div>
            <div>{item.email}</div>
          </StudentDetailsTextContainer>
        </StudentDetailsContainer>
      ),
      numeric: false,
      disablePadding: true,
      width: '40%',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item) => <StatusIndicator status={item.status} />,
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: `${t('general.groups')}`,
      render: (item): ReactElement => {
        const groups = item.group || []
        const firstTwoGroups = groups.slice(0, 2)
        const exGroups = groups.slice(2, groups.length)
        return (
          <div>
            {firstTwoGroups.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <span className="material-icons">groups</span>{' '}
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {group.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup) => (
                        <div key={exGroup.groupId}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}`,
      render: (item): ReactElement => {
        const courses = item.courses || []
        const firstTwoCourses = courses.slice(0, 2)
        const exCourse = courses.slice(3, courses.length)
        return (
          <div>
            {firstTwoCourses.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {courses.map(
                (course, index: number) =>
                  index < 3 && (
                    <GroupItem key={course.courseId}>
                      <IconButton>
                        <SchoolIcon fontSize="small" />
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {course.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exCourse.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exCourse.map((exGroup) => (
                        <div key={exGroup.courseId}> {exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item) => (
        <div>
          {item.companyId ? (
            strCutter((item.companyId as IUserCompany).name, 25)
          ) : (
            <>N/A</>
          )}
        </div>
      ),
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.phone')}`,
      render: (item): ReactElement => {
        return (
          <div>
            {item.phoneFields &&
              item.phoneFields.code &&
              `(${item.phoneFields.code}) `}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item) => (
        <>
          {item.createDate
            ? moment(parseInt(item.createDate)).format('DD-MM-YYYY')
            : 'N/A'}
        </>
      ),
    },
  ]

  return grid
}

interface IStudentActionConfig {
  render: (item: IUser) => ReactElement
}

export const actionConfig = (
  handleClick: (id?: string, action?: string, groupId?: string[]) => void,
  t: TFunction<'translation', undefined>,
): IStudentActionConfig[] => {
  const action: IStudentActionConfig[] = [
    {
      render: (item) => (
        <Action
          onClick={(): void =>
            handleClick(
              item.id,
              'move',
              item.group?.map((group) => group.groupId),
            )
          }
        >
          <Tooltip title={t('actions.move_to_another_group')}>
            <DragIndicatorIcon style={{ opacity: 0.8 }} />
          </Tooltip>
        </Action>
      ),
    },
    {
      render: (item) => (
        <Action onClick={(): void => handleClick(item.id, 'delete')}>
          <Tooltip title={t('actions.remove_from_group')}>
            <DeleteIcon />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return action
}
