import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_BOOK_BY_ID } from 'gql/books/books.query'
import {
  IFetchBookProps,
  IFetchBookResponse,
  IUseFetchBookResponse,
  IUseLazyFetchBookResponse,
} from './bookServices.interface'

export const useFetchBook = ({
  bookId,
}: IFetchBookProps): IUseFetchBookResponse => {
  const { data, loading, error, refetch } = useQuery(GET_BOOK_BY_ID, {
    variables: {
      bookId,
    },
    skip: !bookId,
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    book: (data && data.getBookById) || null,
    loading,
    error: errorMessage,
    refetch,
  }
}

export const useLazyFetchBook = (): IUseLazyFetchBookResponse => {
  const [getBookById, { data, loading, error }] =
    useLazyQuery<IFetchBookResponse>(GET_BOOK_BY_ID)
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    getBookById: async (bookId: string) =>
      await getBookById({ variables: { bookId } }),
    book: (data && data.getBookById) || null,
    loading,
    error: errorMessage,
  }
}
