import { UPDATE_TASK_BY_ID } from 'gql/tasks.query'
import { useMutation, MutationTuple } from '@apollo/client'
import { ITask } from './getTasksService.interface'

interface IUpdateTaskResponse {
  data?: {
    updateTask: ITask
  } | null
  loading: boolean
  error: Error | null
}

export const useUpdateTask = (): {
  updateTask: (taskDetails: ITask) => Promise<IUpdateTaskResponse>
  updating: boolean
  updateError: Error | undefined
} => {
  const [
    updateTaskMutation,
    { loading: updating, error: updateError },
  ]: MutationTuple<
    {
      updateTask: ITask
    },
    { input: ITask }
  > = useMutation(UPDATE_TASK_BY_ID)

  const updateTask = async (
    taskDetails: ITask,
  ): Promise<IUpdateTaskResponse> => {
    try {
      const { ...inputDetails } = taskDetails

      const response = await updateTaskMutation({
        variables: {
          input: inputDetails,
        },
      })
      return { data: response.data, loading: updating, error: null }
    } catch (error) {
      console.error('Error updating task:', error)
      return { data: null, loading: false, error: error as Error }
    }
  }

  return { updateTask, updating, updateError }
}
