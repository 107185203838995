import React from 'react'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
} from 'components/DrawerLayout/styled-components'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import { FormContainerDiv, FormGroupWrapper } from 'components/common/Form'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import Drawer from 'components/common/Drawer'
import { IAddGroupInCourseDrawerProps } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import useAddGroupInCourseDrawer from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/useAddGroupInCourseDrawer'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import GroupItem from 'components/common/GroupItem/GroupItem'
import { CheckBoxFlex } from 'pages/tests/testDetails/addGroupDrawer/toolbar/styled-components'
import CheckBox from 'components/common/Checkbox'
import { GroupItemContainer, Wrapper } from './styled-components'
import {
  FormButtons,
  FormFooter,
} from 'pages/tests/testDetails/questionsLayout/linkQuestionsToTestAssessmentDrawer/styled-components'
import Pagination from 'components/common/Pagination'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'

const AddGroupInCourseDrawer = (
  props: IAddGroupInCourseDrawerProps,
): React.JSX.Element => {
  const {
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleCloseDrawer,
    onFormSubmit,
    selectAllItem,
    selectAll,
    currentPage,
    perPage,
    filter,
    refetch,
    t,
    searchText,
    filterDrawer,
    setFilterDrawer,
    handleSearchChange,
    loading,
    groups,
    selectItem,
    selectedItemIds,
  } = useAddGroupInCourseDrawer(props)

  const isAllSelected = !!selectAll[currentPage]
  return (
    <>
      <DrawerHeader>
        <DrawerTitle>
          {t('actions.add')} {t('general.groups')}
        </DrawerTitle>
        <IconButton onClick={handleCloseDrawer}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainerDiv>
          <FormGroupWrapper padding={10}>
            <Wrapper>
              <DrawerTitle>{t('general.groups')}</DrawerTitle>
              <Wrapper>
                <CheckBoxFlex>
                  <DrawerTitle>{t('general.select_all')}</DrawerTitle>
                  <CheckBox onChange={selectAllItem} checked={isAllSelected} />
                </CheckBoxFlex>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton type="submit">
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                  $iconInStart
                />
              </Wrapper>
            </Wrapper>

            {groups && groups.data?.length ? (
              <GroupItemContainer>
                {groups.data.map((group, index: number) => (
                  <GroupItem
                    item={group}
                    key={index}
                    selectItem={selectItem}
                    selectedItem={selectedItemIds}
                    isChecked={selectedItemIds.includes(group.id as string)}
                  />
                ))}
              </GroupItemContainer>
            ) : loading ? (
              <MyLoader width={250} />
            ) : (
              <SplashScreen title={t('general.no_data')} />
            )}

            <Drawer
              opened={!!filterDrawer.opened}
              toggleDrawer={(): void => undefined}
              totalWidth="600px"
            >
              <FilterDrawer
                closeDrawer={(): void =>
                  setFilterDrawer({
                    ...filterDrawer,
                    opened: false,
                  } as IGroupFilterDrawerOptions)
                }
                setFilterOptions={setFilterDrawer}
                refetchGroup={refetch}
                setCurrentPage={(): void => undefined}
                defaultFilter={filter}
                companyId={props.companyId}
                useCompanyId={true}
              />
            </Drawer>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.add')}
                type="small"
                color="secondary"
                onClick={onFormSubmit}
                background="#06C68F"
              />
              <Button
                text={t('actions.close')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
            {groups && groups.totalCount > 0 && (
              <Pagination
                currentPage={groups ? currentPage : 0}
                perPage={perPage}
                totalPages={
                  groups.totalCount
                    ? Math.ceil(groups.totalCount / perPage || 1)
                    : 0
                }
                handleChange={handlePaginationClick}
                handlePerPageChange={handleChangeRowsPerPage}
              />
            )}
          </FormFooter>
        </FormContainerDiv>
      </DrawerContent>
    </>
  )
}

export default AddGroupInCourseDrawer
