import gql from 'graphql-tag'

export const GET_ANALYTICS = gql`
  query GetAnalytics($courseId: String, $companyId: String) {
    getCourseAnalytics(courseId: $courseId, companyId: $companyId) {
      studentsQuantity
      groupsQuantity
      skillTestVideosQuantity
      studentsNotStartedCoursesYetQuantity
      studentsFinishedCoursesQuantity
      studentsInProgressCoursesQuantity
      studentsCompletedQuizzesQuantity
      studentsFailedQuizzesQuantity
      studentsInProgressQuizzesQuantity
    }
  }
`
export const GET_ANALYTICS_QUANTITIES = gql`
  query GetAnalytics($courseId: String, $companyId: String, $groupId: String) {
    getCourseAnalyticsQuantities(
      courseId: $courseId
      companyId: $companyId
      groupId: $groupId
    ) {
      studentsQuantity
      groupsQuantity
      skillTestVideosQuantity
    }
  }
`

export const GET_STATISTICS = gql`
  query UserStatistic($companyId: String) {
    getStatistics(companyId: $companyId) {
      postsLiked
      postsAdded
      notesAdded
      commentsAdded
      skillTestVideosAdded
      # watchedHours
      # timeSpentInApp
      filesOpened
    }
  }
`
