import React from 'react'
import ModuleDetailContent from './ModuleDetailContent/ModuleDetailContent'
import FlashCardsLayout from '../FlashCards'
import SkillTestVideos from './SkillTestVideos'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { QuizLayout } from 'pages/quizzes'
import Breadcrumb from 'components/common/BreadCrumbV2'
import { SkillTestVideoCriterias } from '../SkillTestVideoCriterias/'
import ModuleTopicsGridV2 from './ModuleTopicsGridV2'
import QuestionsList from './QuestionList/QuestionList'
import useModuleDetail from 'hooks/modules/useModuleDetail'
import { styles } from '../styled-components'
import { ITopic } from '../ModulesList.interface'

const ModuleDetail = (): React.JSX.Element => {
  const {
    breadCrumbData,
    moduleData,
    refetch,
    canModify,
    value,
    handleTabChange,
    t,
    loading,
    routerState,
    currentModule,
    setOpenCreateQuizDrawer,
    openCreateQuizDrawer,
    moduleId,
  } = useModuleDetail()

  if (loading) return <div>Loading...</div>

  return (
    <div>
      <Breadcrumb data={breadCrumbData} />
      {moduleData && (
        <>
          <ModuleDetailContent
            moduleItem={moduleData}
            refetch={refetch}
            topicsLength={0}
            canRedirect={canModify}
            t={t}
          />
          {(canModify && (
            <>
              <TabNavigation>
                <AppBar position="static" style={styles.bar}>
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      label={`${t('general.lessons')}`}
                      {...activeTabClassName(0)}
                      style={styles.btn}
                    />
                    <Tab
                      label={t('general.questions')}
                      {...activeTabClassName(1)}
                      style={styles.btn}
                    />
                    <Tab
                      label={t('general.quizzes')}
                      {...activeTabClassName(2)}
                      style={styles.btn}
                    />
                    <Tab
                      label={t('general.flash_cards')}
                      {...activeTabClassName(3)}
                      style={styles.btn}
                    />
                    {moduleData.skillTestVideoEnabled && (
                      <Tab
                        label={
                          moduleData.skillTestVideoDetails === 'default'
                            ? 'default skill test videos'
                            : 'skill test videos'
                        }
                        {...activeTabClassName(4)}
                        style={styles.btn}
                      />
                    )}
                    {moduleData.skillTestVideoCriteriaEnabled && (
                      <Tab
                        label={t('general.stvcriterias')}
                        {...activeTabClassName(5)}
                        style={styles.btn}
                      />
                    )}
                  </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} padding={0}>
                  <ModuleTopicsGridV2
                    loadingTopics={loading}
                    data={moduleData.topics as ITopic[]}
                    refetch={refetch}
                    moduleId={moduleData.id}
                    canModify={canModify}
                    routerState={routerState}
                    t={t}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} padding={0}>
                  <QuestionsList
                    topics={moduleData.topics as ITopic[]}
                    fromModule={true}
                    currentModule={currentModule}
                    isDraggable
                    refetchModule={refetch}
                    manualCompanyId={moduleData.companyId}
                    canModify={canModify}
                    handleTabChange={handleTabChange}
                    setOpenCreateQuizDrawer={setOpenCreateQuizDrawer}
                    routerState={routerState}
                  />
                </TabPanel>
                <TabPanel value={value} index={2} padding={0}>
                  <QuizLayout
                    type="module"
                    id={moduleData.id}
                    canCreate={moduleData.numberOfQuestions}
                    name={moduleData.name}
                    isDraggable
                    companyId={moduleData.companyId}
                    companyCourse={!canModify}
                    fromQuestions={openCreateQuizDrawer}
                    setOpenCreateQuizDrawer={setOpenCreateQuizDrawer}
                    routerState={routerState}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} padding={0}>
                  <FlashCardsLayout
                    module={moduleData}
                    companyCourse={!canModify}
                  />
                </TabPanel>
                {moduleData.skillTestVideoEnabled && (
                  <TabPanel value={value} index={4} padding={0}>
                    <SkillTestVideos
                      moduleId={moduleId}
                      moduleName={moduleData.name}
                      skillTestVideoTemplate={moduleData.skillTestVideoDetails}
                      canModify={canModify}
                    />
                  </TabPanel>
                )}
                {moduleData.skillTestVideoCriteriaEnabled && moduleId && (
                  <TabPanel value={value} index={5} padding={0}>
                    <SkillTestVideoCriterias
                      moduleId={moduleId}
                      canModify={canModify}
                    />
                  </TabPanel>
                )}
              </TabNavigation>
            </>
          )) || (
            <TabNavigation>
              <AppBar position="static" style={styles.bar}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label={`${t('general.lessons')}`}
                    {...activeTabClassName(0)}
                    style={styles.btn}
                  />
                  <Tab
                    label={t('general.quizzes')}
                    {...activeTabClassName(1)}
                    style={styles.btn}
                  />
                  <Tab
                    label={t('general.flash_cards')}
                    {...activeTabClassName(2)}
                    style={styles.btn}
                  />
                  {moduleData.skillTestVideoEnabled && (
                    <Tab
                      label={
                        moduleData.skillTestVideoDetails === 'default'
                          ? 'default skill test videos'
                          : 'skill test videos'
                      }
                      {...activeTabClassName(3)}
                      style={styles.btn}
                    />
                  )}
                  {moduleData.skillTestVideoCriteriaEnabled && (
                    <Tab
                      label={t('general.stvcriterias')}
                      {...activeTabClassName(4)}
                      style={styles.btn}
                    />
                  )}
                </Tabs>
              </AppBar>

              <TabPanel color="#f5f6fa" value={value} index={0} padding={0}>
                <ModuleTopicsGridV2
                  loadingTopics={loading}
                  data={moduleData.topics as ITopic[]}
                  refetch={refetch}
                  moduleId={moduleData.id}
                  canModify={canModify}
                  routerState={routerState}
                  t={t}
                />
              </TabPanel>
              <TabPanel value={value} index={1} padding={0}>
                <QuizLayout
                  type="module"
                  id={moduleData.id}
                  canCreate={moduleData.numberOfQuestions}
                  name={moduleData.name}
                  isDraggable
                  companyId={moduleData.companyId}
                  companyCourse={!canModify}
                  fromQuestions={openCreateQuizDrawer}
                  setOpenCreateQuizDrawer={setOpenCreateQuizDrawer}
                  routerState={routerState}
                />
              </TabPanel>
              <TabPanel value={value} index={2} padding={0}>
                <FlashCardsLayout
                  module={moduleData}
                  companyCourse={!canModify}
                />
              </TabPanel>
              {moduleData.skillTestVideoEnabled && (
                <TabPanel value={value} index={3} padding={0}>
                  <SkillTestVideos
                    moduleId={moduleId}
                    moduleName={moduleData.name}
                    skillTestVideoTemplate={moduleData.skillTestVideoDetails}
                    canModify={canModify}
                  />
                </TabPanel>
              )}
              {moduleData.skillTestVideoCriteriaEnabled && moduleId && (
                <TabPanel value={value} index={4} padding={0}>
                  <SkillTestVideoCriterias
                    moduleId={moduleId}
                    canModify={canModify}
                  />
                </TabPanel>
              )}
            </TabNavigation>
          )}
        </>
      )}
    </div>
  )
}

export default ModuleDetail
