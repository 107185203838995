import { useMutation } from '@apollo/client'
import gql from 'graphql-tag'

export const DeleteLessonAssets = gql`
  mutation DeleteLessonAssets($lessonVideoId: String) {
    deleteLessonAssets(lessonVideoId: $lessonVideoId) {
      message
    }
  }
`

interface ILessonsResponse {
  handleDeleteLessonAssets: (lessonVideoId: string) => Promise<void>
}

export const useDeleteLessonAssets = (): ILessonsResponse => {
  const [mutate] = useMutation(DeleteLessonAssets)

  const handleDeleteLessonAssets = async (
    lessonVideoId: string,
  ): Promise<void> => {
    await mutate({
      variables: {
        lessonVideoId,
      },
    })
  }

  return { handleDeleteLessonAssets }
}
