import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateTestService } from 'services/tests/updateTestService'

const useEditTestService = (): {
  updateTest: (
    testId: string,
    input: IQuiz,
    action?: () => void,
  ) => Promise<void>
  error: string
  loading: boolean
} => {
  const { t } = useTranslation()
  const { updateTestMutation, loading } = updateTestService()
  const [error, setError] = useState('')
  const { setSnackbar } = useSnackbarAlert()

  const updateTest = async (
    testId: string,
    input: IQuiz,
    action?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await updateTestMutation({
      variables: {
        testId,
        input,
      },
    })

    if (errors && errors.length) {
      message = errors[0]?.message
        ? errors[0].message
        : t('messages.something_wrong')
      variant = 'error'
      setError(message)
    } else {
      message = t('messages.changes_saved')
      variant = 'success'
    }

    setSnackbar({ message, variant })
    if (action) action()
  }

  return {
    updateTest,
    error,
    loading,
  }
}

export default useEditTestService
