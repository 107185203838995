import Tooltip from '@mui/material/Tooltip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Checkbox from 'components/common/Checkbox'
import React from 'react'
import {
  List,
  ListItem,
  ErrorContainer,
  GridFirstName,
} from './styled-components'
import { IImportGroupsGridItem, itemProps } from './importGroups.interface'
import { TFunction } from 'interfaces/TFunction'
import strCutter from 'utils/strCutter'

export const columnConfig = (
  selectItem: (id: string) => void,
  isImported: boolean,
  t: TFunction<'translation', undefined>,
): IImportGroupsGridItem[] => {
  const grid: IImportGroupsGridItem[] = [
    {
      label: '',
      id: 'isChecked',
      render: (item: itemProps) => (
        <Checkbox
          checked={item.isChecked}
          onChange={(): void => selectItem(item.id)}
          disabled={isImported}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: `${t('group_details.name')}*`,
      render: (item: itemProps) =>
        item.name?.length > 20 ? (
          <Tooltip title={item.name}>
            <GridFirstName>{strCutter(item.name, 20)}</GridFirstName>
          </Tooltip>
        ) : (
          <GridFirstName>{item.name}</GridFirstName>
        ),
    },
    {
      id: 'adminFirstName',
      numeric: false,
      disablePadding: false,
      label: `${t('group_details.admin_first_name')}*`,
      render: (item: itemProps) => <div>{item.adminFirstName}</div>,
    },
    {
      id: 'adminLastName',
      numeric: false,
      disablePadding: false,
      label: `${t('group_details.admin_last_name')}*`,
      render: (item: itemProps) => <div>{item.adminLastName}</div>,
    },
    {
      id: 'adminEmail',
      numeric: false,
      disablePadding: false,
      label: `${t('group_details.admin_email')}*`,
      render: (item: itemProps) => <div>{item.adminEmail}</div>,
    },
    {
      id: 'tutorFirstName',
      numeric: false,
      disablePadding: false,
      label: `${t('group_details.tutor_first_name')}*`,
      render: (item: itemProps) => <div>{item.tutorFirstName}</div>,
    },
    {
      id: 'tutorLastName',
      numeric: false,
      disablePadding: false,
      label: `${t('group_details.tutor_last_name')}*`,
      render: (item: itemProps) => <div>{item.tutorLastName}</div>,
    },
    {
      id: 'tutorEmail',
      numeric: false,
      disablePadding: false,
      label: `${t('group_details.tutor_email')}*`,
      render: (item: itemProps) => <div>{item.tutorEmail}</div>,
    },
    {
      id: 'courses',
      numeric: false,
      disablePadding: false,
      label: `${t('general.courses')}*`,
      render: (item: itemProps): JSX.Element => {
        const courses = item.courses?.join(', ') || ''
        return courses.length > 20 ? (
          <Tooltip title={courses}>{strCutter(courses, 20)}</Tooltip>
        ) : (
          <div>{courses}</div>
        )
      },
    },
  ]

  if (isImported) {
    grid.push({
      id: 'error',
      numeric: false,
      disablePadding: false,
      label: 'Error',
      render: (item: itemProps) => {
        const messages = (item && item.message) || []

        if (!item.error) return <></>

        return (
          <ErrorContainer error={item.error}>
            <Tooltip
              title={
                <List>
                  {messages.map((i: string, index: number) => (
                    <ListItem key={index}>* {i}</ListItem>
                  ))}
                </List>
              }
            >
              <ErrorOutlineIcon color="secondary" />
            </Tooltip>
          </ErrorContainer>
        )
      },
    })
  }

  return grid
}
