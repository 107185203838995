// @deprecated
/* eslint-disable */

import React, { useState } from 'react'
import { ToolbarItemWrapper, Title } from './styled-components'
import Grid from 'components/common/Grid'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSwal } from 'hooks/useSwal'
import useDeleteGroup from 'hooks/group/useDeleteGroup'
import { Button } from 'components/common/Button'

import Tooltip from '@mui/material/Tooltip'
import { columnConfig, actionConfig } from './columnConfig'
import EditGroup from './EditGroup'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const GroupList = ({ groupList, refetch, adminList, companyId, t }: any) => {
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [searchText, setSearchText] = useState('')
  const { fireSwal } = useSwal()
  const { deleteGroupServ } = useDeleteGroup()
  const [checkbox, setCheckbox] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editField, setEditField] = useState<any>(null)

  const handleSelect = (id: never, event: boolean) => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = () => {
    if (!selectAll) {
      const newArr: string[] = groupList.map((n: any) => n.id)
      setSelectedItem(newArr)
      setSelectAll(true)
      return
    }
    setSelectedItem([])
    setSelectAll(false)
  }

  const handleDelete = (ids: string[]) => {
    if (ids.length <= 0) return
    const title: string =
      ids.length === 1
        ? `${t('popups.delete_single')} ${t('general.group')}`
        : `${t('popups.delete_many')} ${t('general.groups')}`
    const params = {
      title,
      onConfirm: () => {
        deleteGroupServ(ids, () => {
          refetch()
          setCheckbox(false)
        })
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleEdit = (field: any) => {
    setEditMode(true)
    setEditField(field)
  }

  const actionClick = (e: string, field: any) => {
    switch (e) {
      case 'edit':
        return handleEdit(field)

      case 'delete':
        return handleDelete([field])

      default:
        break
    }
  }

  const config = columnConfig(() => {}, handleSelect, checkbox, t)
  const actions = actionConfig(actionClick, t)

  const filtered = groupList.filter((i: any) =>
    i.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  return (
    <>
      {editMode && (
        <EditGroup
          item={editField}
          onCancel={() => {
            setEditMode(false)
            setEditField(null)
          }}
          adminList={adminList}
          companyId={companyId}
        />
      )}

      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{t('company_wizard_layout.group_list')}</Title>

            {/* <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  tooltipText: 'Delete',
                  disabled: selectedItem.length < 1,
                  onClick: () => handleDelete(selectedItem),
                  component: <DeleteIcon fontSize="small" />,
                }
              ]}
            /> */}
            <div style={{ marginLeft: '10px' }}>
              {filtered && filtered.length > 0 && (
                <Button
                  text={
                    !checkbox ? t('actions.delete_rows') : t('actions.cancel')
                  }
                  type="small"
                  onClick={() => {
                    if (checkbox) {
                      setSelectedItem([])
                    }
                    setCheckbox(!checkbox)
                  }}
                  background="#06C68F"
                />
              )}
            </div>

            {checkbox && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(selectedItem)}>
                    <DeleteIcon
                      fontSize="small"
                      color={
                        selectedItem.length === 0 ? 'disabled' : 'secondary'
                      }
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>

          <div className="wrapper">
            <ToolbarItem>
              <form onSubmit={(e: any) => e.preventDefault()}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  icon={
                    <IconButton>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>

      <Grid
        data={filtered}
        config={config}
        actionConfig={actions}
        paging
        pageSize={1}
        totalCount={0}
        onSwitchPage={() => console.log('1')}
        currentPage={1}
        onPageSizeChange={() => console.log('1')}
        selectAllItem={selectAllItem}
        selected={selectedItem.length}
        selectedItems={selectedItem}
        disabledSelect={!checkbox}
      />
    </>
  )
}

export default GroupList
