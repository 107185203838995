export enum HandleSwitchChange {
  askPassword = 'askPassword',
  exploreCourses = 'exploreCourses',
  showLeaderboardByGroup = 'showLeaderboardByGroup',
  showNewsFeedByGroup = 'showNewsFeedByGroup',
}

export enum CompanyPolicyEnums {
  COMPANY = 'COMPANY',
  GROUP = 'GROUP',
}

export enum HandleCompanySettingsFields {
  exploreCourses = 'exploreCourses',
  showLeaderboardBy = 'showLeaderboardBy',
  showNewsFeedBy = 'showNewsFeedBy',
}

export interface HandleCompanySettingsFieldsType {
  companyId: string | null
  field?: HandleCompanySettingsFields
  value?: string | boolean
  boolValue?: boolean
}
