import React, { ReactElement } from 'react'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import { ICompanyChartParams } from './types'
import { generateCharData } from './generateChartData'

import {
  Content,
  Container,
  ContainerHeader,
  ContainerContent,
  ChartContainer,
} from './styled-components'
import { useSwal } from 'hooks/useSwal'

const CompanyChart = ({
  company,
  handleTabChange,
  t,
}: ICompanyChartParams): ReactElement => {
  const { fireSwal } = useSwal()
  if (!company)
    return <> {fireSwal({ text: 'Something went wrong', width: '40&' })}</>

  const data = generateCharData(company, t)

  return (
    <Content>
      <Container>
        <ContainerHeader>
          {t('company_details.company_diagram')}
        </ContainerHeader>
        <ContainerContent>
          <ChartContainer>
            <ResponsiveContainer width="100%" height={350}>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                onClick={(e): void => handleTabChange(e.activeTooltipIndex)}
              >
                <CartesianGrid />
                <XAxis dataKey="label" />
                <YAxis allowDecimals={false} />
                <Tooltip />
                <Bar dataKey="quantity" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </ContainerContent>
      </Container>
    </Content>
  )
}

export default CompanyChart
