import React from 'react'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useQuery } from '@apollo/client'
import { Title } from './styled-components'
import { GET_LEADERBOARD } from 'gql/leaderboard/leaderboard.query'
import { useUserValue } from 'context/UserContext'
import { ILeaderBoardUser, IUserCompany } from 'interfaces/users'

const Leaderboard = (): React.JSX.Element => {
  const navigate = useNavigate()
  const userState = useUserValue()
  const userCompany = userState[0]?.currentUser?.companyId as IUserCompany
  const companyId = userCompany?.id
  const { data } = useQuery(GET_LEADERBOARD, {
    variables: {
      companyId,
    },
  })
  const userData = data && data.getLeaderboard
  if (userData) {
    return (
      <>
        <Title>Leaderboard</Title>
        <TableContainer
          style={{
            borderRadius: '14px',
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 45%)',
            padding: '15px 25px',
            backgroundColor: 'white',
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ranking</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="right" style={{ width: '35%' }}>
                  Points
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((row: ILeaderBoardUser) => (
                <TableRow
                  key=""
                  style={{ cursor: 'pointer' }}
                  onClick={(): void => navigate('/profile/' + row.id)}
                >
                  <TableCell style={{ color: '#06c68f', fontSize: '21px' }}>
                    {JSON.stringify(userData.indexOf(row) + 1)}
                  </TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell
                    style={{ color: '#06c68f', fontSize: '21px' }}
                    align="right"
                  >
                    {row.point}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }
  return <div>loading ...</div>
}

export default Leaderboard
