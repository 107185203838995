import React, { useState, useEffect, ReactElement } from 'react'
import Swal from 'sweetalert2'

import { useUserValue } from 'context/UserContext'
import { actionConfig } from 'components/courseDetail/actionConfig'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import {
  AI_assistantWrapper,
  ButtonsWrapper,
  CourseContentDetails,
  CourseContentDetailsContainer,
  CourseContentDetailsContainerFlex,
  CourseContentDetailsRight,
  CourseDetailsWrapper,
  StyledCompaniesContainer,
  StyledCompanyContainer,
  StyledCompanySpan,
  StyledCourseDetailsBetweenParent,
  StyledCourseDetailsFlex,
  StyledCourseDetailsLeft,
  StyledCourseDetailsLeftParent,
  StyledCourseDetailsSkillsParent,
  StyledInformationTabContainer,
  StyledSkillsContainer,
} from './styled-components'
import { useLazyQuery, useQuery } from '@apollo/client'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import { GET_ALL_COURSE, GET_COURSE } from 'gql/course/course.query'
import Breadcrumb from 'components/common/BreadCrumbV2'
import Player from 'components/common/VideoPlayer/Player'
import { useTranslation } from 'react-i18next'
import { DrawerEventEmitter } from 'helpers/drawer'
import Modal from 'components/common/Modal'
import { AssessmentsTab, ModulesTab } from './Tabs'
import MyLoader from 'loading/loading'

import { useSwal } from 'hooks/useSwal'
import useDeleteCourse from 'hooks/course/useDeleteCourse'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as Learning } from 'components/common/Button/icons/list-checked.svg'
import { VideoModalProps } from './CourseDetail.interface'
import {
  ActionsWrapper,
  ModuleContentDetail,
  ModuleContentDetailsHeader,
  ModulesContentWrapper,
  styles,
} from 'pages/modules/ModuleDetail/ModuleDetailContent/styled-components'
import { formatDuration } from 'helpers/formatDuration'
import { TagsGrid } from 'pages/tests/testDetails/styled-components'
import Tags from 'components/common/Tags/Tags'
import { CenteredText } from 'pages/tests/testLayout/styled-components'
import { CourseStatusContainer } from '../styled-components'
import {
  TabNavigation,
  TabPanel,
  activeTabClassName,
} from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import { GET_COMPANIES_BY_PURCHASED_COURSE } from 'gql/companies.query'
import CourseGroups from '../EditCourseDrawer/Tab2'
import StudentsLayout from '../CourseStudents/StudentsLayout'
import TutorsLayout from '../CourseTutors/TutorsLayout'
import { IGroupBrief } from '../CourseTutors/tutorsLayout.interface'
import {
  usePublishedCourse,
  useReTrainAIAssistant,
  useToggleAIAssistant,
} from 'hooks/course/useEditCourse'
import { ReactComponent as AI_assistantIcon } from 'assets/white-ai-assistant.svg'
import AIAssistant from '../AIAssistant'
import AIAssistantChat from '../AIAssistantChat'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { IGroup } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'

interface IVideoLink {
  url: string
  thumbnail: string
}

interface IItem {
  video?:
    | {
        links: IVideoLink[]
      }
    | boolean
}

const CourseDetail = (): ReactElement => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const navigate = useNavigate()
  const { deleteCourse } = useDeleteCourse()
  const { publishedCourse } = usePublishedCourse()
  const { toggleAIAssistant } = useToggleAIAssistant()
  const { reTrainAIAssistant } = useReTrainAIAssistant()
  const { fireSwal } = useSwal()
  const { id: courseId, groupId } = useParams()
  const [selectedGroup, setSelectedGroup] = useState<IGroupBrief[]>([])

  const { data: purchasedCompanies } = useQuery(
    GET_COMPANIES_BY_PURCHASED_COURSE,
    {
      variables: { courseId },
    },
  )

  const [activeNavigationTab, setActiveNavigationTab] = useState(0)
  const [value, setValue] = useState(0)
  const { state: routerState } = useLocation()

  useEffect(() => {
    if (routerState) {
      const { tab } = routerState
      if (tab && tab !== activeNavigationTab) {
        setActiveNavigationTab(tab)
      }
    }
  }, [routerState])

  const {
    data: courseData,
    loading,
    refetch: refetchCourse,
  } = useQuery(GET_COURSE, {
    variables: { courseId },
  })

  useEffect(() => {
    if (courseData && courseData.getCourseById?.groups.length) {
      const course = courseData.getCourseById
      const groups = course.groups.map((group: IGroup) => ({
        groupId: group.groupId,
        name: group.name,
      }))
      setSelectedGroup(groups)
    }
  }, [courseData])

  const [videoModal, setVideoModal] = useState<VideoModalProps>({
    modal: false,
    data: null,
  })

  useEffect(() => {
    if (groupId && courseId) {
      setActiveNavigationTab(3)
    }
  }, [groupId, courseId])

  const handleVideoModal = (): void => {
    setVideoModal({
      ...videoModal,
      modal: false,
    })
  }
  const confirmPublishClick = (courseId: string[], action: string): void => {
    publishedCourse(courseId, action)
    refetchCourse()
  }

  const handlePublishClick = (courseId: string, action: string): void => {
    let title = ''
    title =
      action === 'published'
        ? t('popups.publish_course')
        : t('popups.unpublish_course')
    fireSwal({
      title,
      onConfirm: () => confirmPublishClick([courseId], action),
      confirmText: `${t('general.yes')}, ${
        action === 'published' ? t('actions.publish') : t('actions.unpublish')
      }`,
    })
  }

  const [allCourseRefetch] = useLazyQuery(GET_ALL_COURSE, {
    variables: {
      filter: {},
    },
  })

  const getCourseState = (state: string): string => {
    let courseState = state
    switch (state) {
      case 'published':
        courseState = t('courses_layout.published')
        break
      case 'unpublished':
        courseState = t('courses_layout.unpublished')
    }

    return courseState
  }

  const getCourseStateAction = (state: string): string => {
    let courseState = state
    switch (state) {
      case 'published':
        courseState = t('courses_layout.unpublish')
        break
      case 'unpublished':
        courseState = t('courses_layout.publish')
    }

    return courseState
  }

  const handleEditClick = (id: string): void =>
    DrawerEventEmitter.emit('openDrawer', 'editCourse', true, {
      id,
    })

  const handleDeleteClick = (id: string): void => {
    fireSwal({
      title: 'Are you sure you want to delete this course ?',
      confirmText: 'Yes, Delete!',
      onConfirm: () => {
        deleteCourse([id], allCourseRefetch)
        navigate('/courses')
      },
    })
  }
  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ): void => {
    setValue(newValue)
  }

  const course = courseData?.getCourseById

  const isAIAssistantEnabled = courseData?.getCourseById?.isAIAssistantEnabled

  const hasIntro = course?.video?.links?.some(
    (link: { url: string }) => !!link.url,
  )

  const confirmAIAssistantClick = async (courseId: string): Promise<void> => {
    const loadingModalCb = (): void => {
      fireSwal({
        icon: 'info',
        title: t('AI_assistant.assistant_is_being_trained'),
        showCancelButton: false,
        showConfirmButton: false,
        onConfirm: () => undefined,
      })
    }

    const closeLoadingModalCb = (): void => {
      Swal.close()
    }

    const createdModalCb = (): void => {
      fireSwal({
        icon: 'success',
        title: t('AI_assistant.assistant_has_been_created'),
        confirmText: 'Okay',
        showCancelButton: false,
        onConfirm: () => undefined,
      })
    }

    await toggleAIAssistant({
      courseId,
      isAIAssistantEnabled,
      loadingModalCb,
      closeLoadingModalCb,
      createdModalCb,
    })
    refetchCourse()
  }

  const handleAIAssistantClick = (courseId: string): void => {
    let title = ''
    title = isAIAssistantEnabled
      ? t('popups.disable_AI_assistant')
      : t('popups.enable_AI_assistant')
    fireSwal({
      icon: null,
      title,
      onConfirm: () => confirmAIAssistantClick(courseId),
      confirmText: isAIAssistantEnabled
        ? t('actions.yes_disable')
        : t('actions.yes_enable'),
    })
  }

  const confirmReTrainAIAssistantClick = async (): Promise<void> => {
    const loadingModalCb = (): void => {
      fireSwal({
        icon: 'info',
        title: t('AI_assistant.assistant_is_being_trained'),
        showCancelButton: false,
        showConfirmButton: false,
        onConfirm: () => undefined,
      })
    }

    const closeLoadingModalCb = (): void => {
      Swal.close()
    }

    const createdModalCb = (): void => {
      fireSwal({
        icon: 'success',
        title: t('popups.assistant_has_been_re_trained'),
        confirmText: 'Okay',
        showCancelButton: false,
        onConfirm: () => undefined,
      })
    }

    await reTrainAIAssistant({
      assistantId: course.assistantId,
      loadingModalCb,
      closeLoadingModalCb,
      createdModalCb,
    })
    refetchCourse()
  }

  const handleReTrainAIAssistantClick = (): void => {
    fireSwal({
      icon: null,
      title: t('popups.re_train_AI_assistant'),
      onConfirm: () => confirmReTrainAIAssistantClick(),
      confirmText: t('popups.confirm_re_train'),
    })
  }

  if (loading) return <MyLoader style={{ height: '83vh' }} speed={0.6} />

  const breadCrumbData = [
    { label: t('general.courses'), link: '/courses', icon: <Learning /> },
    {
      label: course?.name,
      link: '',
    },
  ]

  const isCompanyPurchasedCourse = course?.isBought
  const isCompanyCourse = !!course?.companyId

  const handlePlayIntroClick = (item: IItem): void => {
    if (typeof item.video === 'boolean' || !item.video) return

    const videoData = item.video.links[0]

    setVideoModal((prevState) => ({
      ...prevState,
      modal: !prevState.modal,
      data: !prevState.modal
        ? {
            url: videoData.url,
            thumbnail: videoData.thumbnail,
          }
        : null,
    }))
  }

  const handleDownloadClick = async (): Promise<void> => {
    const originalLink = course.video.links.find(
      (link: { fileType: string; url: string }) => link.fileType === 'Original',
    )?.url
    if (!originalLink) {
      console.error('Original video link not found')
      return
    }

    try {
      const response = await fetch(originalLink)
      if (!response.ok) {
        console.error('Failed to fetch the video:', response.statusText)
        return
      }

      const blob = await response.blob()

      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url

      const filename = originalLink.split('/').pop() || 'original_video'
      link.download = filename

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading the video:', error)
    }
  }

  const hasAssistant = !!course.assistantId

  const actions = actionConfig(
    handleEditClick,
    handleDeleteClick,
    t,
    getCourseStateAction,
    state,
    handlePublishClick,
    handleAIAssistantClick,
    isAIAssistantEnabled,
    isCompanyCourse,
    handlePlayIntroClick,
    handleDownloadClick,
    hasIntro,
    handleReTrainAIAssistantClick,
    hasAssistant,
    isCompanyPurchasedCourse,
  )

  const CourseInformation = (): ReactElement => (
    <StyledCompanyContainer>
      <StyledInformationTabContainer>
        <StyledSkillsContainer>
          <h4 style={styles.detailHeader}>{t('course_details.skills')}</h4>
          {course.skills ? (
            <TagsGrid>
              <Tags
                toolTip={false}
                tags={course?.skills}
                type="courseInformation"
              />
            </TagsGrid>
          ) : (
            <CenteredText>{t('course_details.no_skills')}</CenteredText>
          )}
        </StyledSkillsContainer>

        <StyledCompaniesContainer>
          <h4 style={styles.detailHeader}>
            {t('course_details.purchased_by')}
          </h4>
          <div
            style={{
              display: 'flex',
              padding: '16px',
              gap: '12px',
              flexWrap: 'wrap',
            }}
          >
            {purchasedCompanies?.getCompaniesByPurchasedCourses?.map(
              (company: { id: string; name: string }) => (
                <div key={company.id} style={{ height: '32px' }}>
                  <StyledCompanySpan>{company.name}</StyledCompanySpan>
                </div>
              ),
            )}
          </div>
        </StyledCompaniesContainer>
      </StyledInformationTabContainer>
    </StyledCompanyContainer>
  )

  return (
    <>
      <CourseDetailsWrapper>
        <Breadcrumb data={breadCrumbData} />
        {isCompanyPurchasedCourse && (
          <span
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'normal',
              color: '#FF3A52',
            }}
          >
            {t('courses_layout.suada_course_edit_permission_text')}
          </span>
        )}
        <ModulesContentWrapper>
          <CourseContentDetailsContainer>
            <ModuleContentDetailsHeader>
              <h3>
                <strong>{course.name}</strong>{' '}
              </h3>
              <ButtonsWrapper>
                <CourseStatusContainer $status={course.state === 'published'}>
                  {course.finished === 3
                    ? getCourseState(course.state)
                    : t('courses_layout.draft')}
                </CourseStatusContainer>
                {isCompanyCourse && (
                  <AI_assistantWrapper
                    bgColor={isAIAssistantEnabled ? '#0074D9' : '#68707A'}
                    style={{ minWidth: '175px', textAlign: 'center' }}
                  >
                    <AI_assistantIcon />{' '}
                    {isAIAssistantEnabled
                      ? t('actions.enabled_AI_assistant')
                      : t('actions.disabled_AI_assistant')}
                  </AI_assistantWrapper>
                )}
                {!isCompanyCourse && state.currentUser.companyId ? null : (
                  <ActionsWrapper>
                    <GridActionMenu
                      actionConfig={actions}
                      button={<MoreIcon />}
                      row={course}
                    />
                  </ActionsWrapper>
                )}
              </ButtonsWrapper>
            </ModuleContentDetailsHeader>

            <CourseContentDetailsContainerFlex>
              <StyledCourseDetailsLeftParent>
                <h4 style={styles.detailHeader}>{t('general.overview')}</h4>

                <StyledCourseDetailsLeft>
                  <TextEditor
                    isVisible={false}
                    text={course.editorText}
                    hasControls={false}
                    defaultText={t('default_texts.transcript')}
                  />
                </StyledCourseDetailsLeft>
              </StyledCourseDetailsLeftParent>
              <StyledCourseDetailsBetweenParent>
                <h4 style={styles.detailHeader}>{t('general.details')}</h4>

                <CourseContentDetails>
                  <StyledCourseDetailsFlex>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>
                        {t('course_details.level')}
                      </p>
                      {course.level || '-'}
                    </ModuleContentDetail>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>
                        {t('course_details.duration')}
                      </p>{' '}
                      {formatDuration(course.duration)}
                    </ModuleContentDetail>

                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>
                        {t('course_details.certificate')}
                      </p>
                      {course.certificateIncluded
                        ? t('general.included')
                        : t('general.not_included')}
                    </ModuleContentDetail>
                  </StyledCourseDetailsFlex>
                  <StyledCourseDetailsFlex>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('general.students')}</p>
                      {course.numberOfStudents}
                    </ModuleContentDetail>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('general.groups')}</p>
                      {course.groups.length}
                    </ModuleContentDetail>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>
                        {t('course_details.created_by')}:
                      </p>
                      {course.companyId && !course.isBought ? (
                        <>{course.companyId.name}</>
                      ) : !isCompanyCourse ? (
                        <>SUADA</>
                      ) : (
                        <>Suada</>
                      )}
                    </ModuleContentDetail>
                  </StyledCourseDetailsFlex>
                </CourseContentDetails>
              </StyledCourseDetailsBetweenParent>
              <CourseContentDetailsRight>
                <StyledCourseDetailsSkillsParent>
                  <h4 style={styles.detailHeader}>
                    {t('course_details.skills')}
                  </h4>
                  {course.skills ? (
                    <TagsGrid>
                      <Tags tags={course?.skills} type="course" />
                    </TagsGrid>
                  ) : (
                    <CenteredText>{t('course_details.no_skills')}</CenteredText>
                  )}
                </StyledCourseDetailsSkillsParent>
              </CourseContentDetailsRight>
            </CourseContentDetailsContainerFlex>
          </CourseContentDetailsContainer>
        </ModulesContentWrapper>
        {/* CASE FOR PURCHASED COURSES */}
        {course.isBought ? (
          <TabNavigation>
            <AppBar position="static" style={styles.bar}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label={`${t('general.modules')}`}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.iq_tests')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.groups')}
                  {...activeTabClassName(2)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.students')}
                  {...activeTabClassName(3)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.coaches')}
                  {...activeTabClassName(4)}
                  style={styles.btn}
                />
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant')}
                    {...activeTabClassName(5)}
                    style={styles.btn}
                  />
                )}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant_chat')}
                    {...activeTabClassName(6)}
                    style={styles.btn}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} padding={0}>
              <ModulesTab course={course} refetchCourse={refetchCourse} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <AssessmentsTab course={course} />
            </TabPanel>
            <TabPanel value={value} index={2} padding={0}>
              <CourseGroups
                courseData={{
                  courseId: course.id,
                  name: course.name,
                }}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                formFooterVisible={false}
              />
            </TabPanel>
            <TabPanel value={value} index={3} padding={0}>
              <StudentsLayout
                groups={selectedGroup}
                companyId={course.companyId?.id}
              />
            </TabPanel>
            <TabPanel value={value} index={4} padding={0}>
              <TutorsLayout
                courseId={course.id}
                groups={selectedGroup}
                companyId={course.companyId?.id}
              />
            </TabPanel>
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={5} padding={0}>
                <AIAssistant />
              </TabPanel>
            )}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={6} padding={0}>
                <AIAssistantChat />
              </TabPanel>
            )}
          </TabNavigation>
        ) : isCompanyCourse ? (
          // CASE FOR COMPANY COURSES
          <TabNavigation>
            <AppBar position="static" style={styles.bar}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label={`${t('general.modules')}`}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.iq_tests')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.groups')}
                  {...activeTabClassName(2)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.students')}
                  {...activeTabClassName(3)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.coaches')}
                  {...activeTabClassName(4)}
                  style={styles.btn}
                />
                {/* <Tab
                  label={t('general.information')}
                  {...activeTabClassName(5)}
                  style={styles.btn}
                /> */}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant')}
                    {...activeTabClassName(5)}
                    style={styles.btn}
                  />
                )}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant_chat')}
                    {...activeTabClassName(6)}
                    style={styles.btn}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} padding={0}>
              <ModulesTab course={course} refetchCourse={refetchCourse} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <AssessmentsTab course={course} />
            </TabPanel>

            <TabPanel value={value} index={2} padding={0}>
              <CourseGroups
                courseData={{
                  courseId: course.id,
                  name: course.name,
                }}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                formFooterVisible={false}
              />
            </TabPanel>

            <TabPanel value={value} index={3} padding={0}>
              <StudentsLayout
                groups={selectedGroup}
                companyId={course.companyId?.id}
              />
            </TabPanel>
            <TabPanel value={value} index={4} padding={0}>
              <TutorsLayout
                courseId={course.id}
                groups={selectedGroup}
                companyId={course.companyId?.id}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={5} padding={0}>
              <CourseInformation />
            </TabPanel> */}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={5} padding={0}>
                <AIAssistant />
              </TabPanel>
            )}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={6} padding={0}>
                <AIAssistantChat />
              </TabPanel>
            )}
          </TabNavigation>
        ) : (
          // CASE FOR TEMPLATE COURSES
          <TabNavigation>
            <AppBar position="static" style={styles.bar}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                aria-label="simple tabs example"
              >
                <Tab
                  label={`${t('general.modules')}`}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.iq_tests')}
                  {...activeTabClassName(1)}
                  style={styles.btn}
                />
                <Tab
                  label={t('general.information')}
                  {...activeTabClassName(2)}
                  style={styles.btn}
                />
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant')}
                    {...activeTabClassName(3)}
                    style={styles.btn}
                  />
                )}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant_chat')}
                    {...activeTabClassName(4)}
                    style={styles.btn}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} padding={0}>
              <ModulesTab course={course} refetchCourse={refetchCourse} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <AssessmentsTab course={course} />
            </TabPanel>
            <TabPanel value={value} index={2} padding={0}>
              <CourseInformation />
            </TabPanel>
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={3} padding={0}>
                <AIAssistant />
              </TabPanel>
            )}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={4} padding={0}>
                <AIAssistantChat />
              </TabPanel>
            )}
          </TabNavigation>
        )}
      </CourseDetailsWrapper>

      <Modal isOpened={videoModal.modal} onClose={handleVideoModal}>
        <Player
          url={videoModal.data?.url}
          thumbnail={videoModal.data?.thumbnail}
          subtitle={course.subtitle}
        />
      </Modal>
    </>
  )
}

export default CourseDetail
