import { useMutation, MutationTuple } from '@apollo/client'
import { INSERT_GROUPS_WITH_COURSES } from 'gql/group/group.query'
import { IExtractGroupsWithCoursesMutationResponse } from './uploadGroupsWithCoursesService'
import { IInsertGroupsWithCoursesInput } from 'interfaces/groups'

interface IImportGroupsWithCoursesResponse {
  insertGroupsWithCourses: IExtractGroupsWithCoursesMutationResponse[]
}

interface IImportGroupsWithCoursesVariables {
  groups: IInsertGroupsWithCoursesInput[]
  companyId: string
}

interface IImportGroupsWithCoursesServiceResponse {
  insertGroupsWithCourses: MutationTuple<
    IImportGroupsWithCoursesResponse,
    IImportGroupsWithCoursesVariables
  >[0]
  loading: boolean
}

const importGroupsWithCoursesService =
  (): IImportGroupsWithCoursesServiceResponse => {
    const [insertGroupsWithCourses, { loading }] = useMutation<
      IImportGroupsWithCoursesResponse,
      IImportGroupsWithCoursesVariables
    >(INSERT_GROUPS_WITH_COURSES)

    return {
      insertGroupsWithCourses,
      loading,
    }
  }

export default importGroupsWithCoursesService
