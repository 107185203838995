import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IFilterValue } from './FilterIconHandler.interface'
import {
  IFilterQuery,
  IFilterQueryType,
} from 'components/common/FilterInterface/filter.interface'

/**
 * Checks if the provided filter options indicate that any filtering is active.
 *
 * @param filterOptions - The filter options to check.
 * @param customCheck - Optional custom function to check each filter value.
 * @returns True if any filter is active, false otherwise.
 */
const checkIfFiltered = (
  filterOptions: IFilterQueryType,
  customCheck?: (
    value:
      | IFilterQuery
      | IFilterQuery[]
      | string
      | string[]
      | number
      | number[],
  ) => boolean,
): boolean => {
  return Object.entries(filterOptions || {}).some(([key, value]) => {
    // Use custom check if provided
    if (customCheck) return customCheck(value)

    // Specific checks for certain fields
    const specificChecks: Record<string, boolean> = {
      courses: Array.isArray(value) && value.length > 0,
      coverImage: value?.value !== 'all',
      video: value?.value !== 'all',
      attachment: value?.value !== 'all',
      range: Array.isArray(value) && (value[0] !== 0 || value[1] !== 500),
    }

    if (key in specificChecks) return specificChecks[key]

    // Check if the value is an array and has non-null elements
    if (Array.isArray(value))
      return value.some((v) => v !== null && v !== undefined)

    // Check if the value is an object and not null
    if (typeof value === 'object' && value !== null) {
      const { type, min, max, value: val } = value as IFilterValue

      // Check different filter types and their default values
      return !(
        (type === UpperCaseFilterableFieldType.RANGE &&
          min === 0 &&
          max === 100) ||
        ((type === UpperCaseFilterableFieldType.MATCH ||
          type === UpperCaseFilterableFieldType.EXACT) &&
          val === '') ||
        (type === UpperCaseFilterableFieldType.NESTED_ARRAY_IN &&
          Array.isArray(val) &&
          val.length === 0) ||
        val === 'all'
      )
    }

    // Check if the value is not null, undefined, or an empty string
    return value !== null && value !== undefined && value !== ''
  })
}

export default checkIfFiltered
