import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { IImportStudentsWithGroupsInput, IUser } from 'interfaces/users'
import { useTranslation } from 'react-i18next'

import importStudentsWithGroupsService from 'services/users/importStudentsWithGroupsService'

interface IUseImportStudentsWithGroups {
  importStudentsWithGroups: (
    values: IImportStudentsWithGroupsInput[],
    companyId: string,
    callBack?: (data?: IUser[] | null) => void,
  ) => Promise<void>
  loading: boolean
}

const useImportStudentsWithGroups = (): IUseImportStudentsWithGroups => {
  const { t } = useTranslation()
  const { insertStudentsWithGroups, loading } =
    importStudentsWithGroupsService()
  const { setSnackbar } = useSnackbarAlert()

  const importStudentsWithGroups = async (
    values: IImportStudentsWithGroupsInput[],
    companyId: string,
    callBack?: (data?: IUser[] | null) => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }
    const { errors, data } = await insertStudentsWithGroups({
      variables: {
        students: values,
        companyId,
      },
    })

    let usersData = null

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else {
      message = `${t('general.user')} ${t('messages.edited')}`
      variant = 'success'
      usersData = data?.insertStudentsWithGroups
    }

    if (callBack) callBack(usersData)

    setSnackbar({ message, variant })
  }

  return {
    importStudentsWithGroups,
    loading,
  }
}

export default useImportStudentsWithGroups
