import React, {
  useState,
  useEffect,
  ReactElement,
  Dispatch,
  SetStateAction,
} from 'react'
import { useQuery } from '@apollo/client'

import { FormItemContainer } from './styled-components'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { Input } from 'components/common/TextInput'
import { Button } from 'components/common/Button'
import ReactSelect, { ActionMeta, ValueType } from 'react-select'
import Slider from '@mui/material/Slider'

import IconButton from '@mui/material/IconButton'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { filterDefault, filterOptions } from 'pages/quizzes/staticData'
import { GET_TAGS } from 'gql/skilss.query'
import {
  IQuizzesFilterDrawerValueType,
  IQuizzesFilterType,
  IQuizzesFilterValues,
} from './filterDrawer.interface'
import { IFilterData } from '../quiz.interface'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { TFunction } from 'interfaces/TFunction'

const FilterDrawer = (props: {
  onClose: () => void
  setFilterData: Dispatch<SetStateAction<IFilterData>>
  filterData: IFilterData
  companyId: string
  setCurrentPage: Dispatch<SetStateAction<number>>
  t: TFunction
}): ReactElement => {
  const [filterData, setFilterData] =
    useState<IQuizzesFilterType>(filterOptions)
  const [range, setRange] = useState([0, 300])
  const [passRate, setPassRate] = useState([0, 100])

  const { data: tagsData } = useQuery(GET_TAGS, {
    variables: {
      companyId: props.companyId,
    },
  })

  const tagList =
    (tagsData &&
      tagsData.getAllTags.map((i: { label: string; id: string }) => ({
        label: i.label,
        value: i.id,
      }))) ||
    []

  useEffect(() => {
    if (props?.filterData?.values) {
      setFilterData(props.filterData.values)
      setRange(props.filterData.values.range as number[])
      setPassRate(props.filterData.values.passRate as number[])
    }
  }, [props.filterData])

  const handleFieldChange = (
    field: string,
    action?: string,
  ): ((e: React.ChangeEvent<HTMLInputElement> | string) => void) => {
    if (action && action === 'dropdown') {
      return (e: React.ChangeEvent<HTMLInputElement> | string): void => {
        setFilterData({
          ...filterData,
          [field]: e,
        })
      }
    }

    return (e: React.ChangeEvent<HTMLInputElement> | string): void => {
      setFilterData({
        ...filterData,
        [field]: (e as React.ChangeEvent<HTMLInputElement>).target.value,
      })
    }
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const { name, quizType, quizParticipationType, timeType, tags } = filterData
    const values: IQuizzesFilterValues = {}

    if (name)
      values.name = {
        type: UpperCaseFilterableFieldType.MATCH,
        value: name,
      }
    else delete values.name

    if (quizType.value.toUpperCase() !== 'ALL') {
      values.quizType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: quizType.value.toUpperCase(),
      }
    } else delete values.quizType

    if (quizParticipationType.value.toUpperCase() !== 'ALL') {
      values.quizParticipationType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: quizParticipationType.value.toUpperCase(),
      }
    } else delete values.quizParticipationType

    if (timeType.value.toUpperCase() !== 'ALL')
      values.timeType = {
        type: UpperCaseFilterableFieldType.EXACT,
        value: timeType.value.toUpperCase(),
      }
    else delete values.timeType

    if (tags)
      values.tags = {
        type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: tags.map((tag) => tag.label),
        nestedField: 'LABEL',
      }
    else delete values.tags

    if (
      (range[0] === 0 && range[1] === 0) ||
      (range[0] === 0 && range[1] === 300)
    )
      delete values.time
    else
      values.time = {
        type: UpperCaseFilterableFieldType.RANGE,
        min: range[0],
        max: range[1],
      }

    values.passRate = {
      type: UpperCaseFilterableFieldType.RANGE,
      min: passRate[0],
      max: passRate[1],
    }

    props.setFilterData({
      filterOptions: {
        ...values,
      },
      values: { ...filterData, range, passRate },
    })

    props.setCurrentPage(1)

    props.onClose()
  }

  const { t } = props

  return (
    <>
      <DrawerHeader>
        <DrawerTitle>{t('general.advanced_filters')}</DrawerTitle>
        <IconButton onClick={props.onClose}>
          <HighlightOffRoundedIcon />
        </IconButton>
      </DrawerHeader>

      <br />

      <DrawerContent>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroupWrapper>
            <FormGroupItem>
              <Title>{t('form_fields.name')}</Title>
              <Input
                placeholder={t('form_fields.name')}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={filterData.name}
                onChange={handleFieldChange('name')}
              />
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('quiz_details.entity_type')}</Title>
                <ReactSelect
                  onChange={
                    handleFieldChange('quizType', 'dropdown') as unknown as (
                      value: ValueType<ISelectDropDownOption, false>,
                      actionMeta: ActionMeta<ISelectDropDownOption>,
                    ) => void
                  }
                  options={filterDefault.quizType}
                  value={filterData.quizType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>
                  {t('general.quiz')} {t('quiz_details.participation')}
                  {t('general.type')}
                </Title>
                <ReactSelect
                  onChange={
                    handleFieldChange(
                      'quizParticipationType',
                      'dropdown',
                    ) as unknown as (
                      value: ValueType<ISelectDropDownOption, false>,
                      actionMeta: ActionMeta<ISelectDropDownOption>,
                    ) => void
                  }
                  options={filterDefault.quizParticipationType}
                  value={filterData.quizParticipationType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('quiz_details.time_type')}</Title>
                <ReactSelect
                  onChange={
                    handleFieldChange('timeType', 'dropdown') as unknown as (
                      value: ValueType<ISelectDropDownOption, false>,
                      actionMeta: ActionMeta<ISelectDropDownOption>,
                    ) => void
                  }
                  options={filterDefault.timeType}
                  value={filterData.timeType}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('general.tags')}</Title>
                <ReactSelect
                  isMulti
                  onChange={
                    handleFieldChange('tags', 'dropdown') as unknown as (
                      value: ValueType<IQuizzesFilterDrawerValueType, true>,
                      actionMeta: ActionMeta<IQuizzesFilterDrawerValueType>,
                    ) => void
                  }
                  options={tagList}
                  value={filterData.tags}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <Title id="range-slider">
                {t('quiz_details.time_in_minutes')}
              </Title>
              <Slider
                value={range}
                onChange={(_: Event, i: number | number[]): void =>
                  setRange(i as number[])
                }
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={300}
              />
            </FormGroupItem>

            <FormGroupItem>
              <Title id="range-slider">{t('quiz_details.pass_rate')}</Title>
              <Slider
                value={passRate}
                onChange={(_: Event, i: number | number[]): void =>
                  setPassRate(i as number[])
                }
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={0}
                max={100}
              />
            </FormGroupItem>
          </FormGroupWrapper>

          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={(): void => {
                  props.setFilterData({
                    filterOptions: null,
                    values: null,
                  })
                  props.onClose()
                }}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
