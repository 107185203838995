import React, { ReactElement } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Modal, ImageCropper, TextInput, Button } from 'components/common'
import { FormField, FormFields } from 'components/common/Form'
import { useBookDrawer } from 'hooks/books'
import { FormikFieldChange, IBookProps } from './bookDrawer.interface'
import {
  Container,
  DrawerContent,
  DrawerHeader,
  ErrorSpan,
  FormButtons,
  FormFooter,
} from './styled-components'
import { FormTitle } from 'pages/users/styled-components'
import { IconButton } from '@mui/material'
import Uploader from 'components/common/FileUploaderV2'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { StyledUploadLabel } from 'pages/tests/editTestDrawer/styled-components'

const AddBookDrawer = ({ onClose, companyId }: IBookProps): ReactElement => {
  const {
    t,
    file,
    handleCropSave,
    setFieldValue,
    cropperOpened,
    setCropperOpened,
    values,
    errors,
    touched,
    handleChange,
    handleCloseDrawer,
    handleSubmit,
    handleImageChange,
    handleFileChange,
    imageLoading,
    fileLoading,
  } = useBookDrawer({
    onClose,
    companyId,
  })
  return (
    <>
      <Container>
        <DrawerHeader>
          <FormTitle> {t('books_layout.add_book')} </FormTitle>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <DrawerContent>
          <FormFields>
            <FormField>
              <TextInput
                error={errors.title ? true : false}
                errorMessage={errors.title ? errors.title : ''}
                touched={touched.title}
                title={t('books_layout.title')}
                size="small"
                required
                placeholder={`${t('form_fields.title')}`}
                name="title"
                type="text"
                onChange={handleChange}
              />
            </FormField>
            <FormField>
              <Uploader
                id="contentDocument"
                type={FileTypes.DOCUMENT}
                label={
                  <>
                    <StyledUploadLabel>
                      {t('actions.upload_file')}*
                    </StyledUploadLabel>
                  </>
                }
                deleteFile={(): FormikFieldChange =>
                  setFieldValue('contentDocument', null)
                }
                accept="application/pdf"
                placeHolder={t('books_layout.supported_book_formats')}
                inputValue={
                  values.contentDocument
                    ? (values.contentDocument.name as string)
                    : ''
                }
                onFileChange={(e): void => handleFileChange(e)}
                uploadedFile={values.contentDocument}
                loading={!!fileLoading}
                inputClassName="file"
              />
              {touched.contentDocument && errors.contentDocument && (
                <ErrorSpan>{errors.contentDocument}</ErrorSpan>
              )}
            </FormField>
            <FormField>
              <Uploader
                id="coverImage"
                type={FileTypes.IMAGE}
                label={
                  <>
                    <StyledUploadLabel>
                      {t('books_layout.book_cover')}
                    </StyledUploadLabel>
                  </>
                }
                deleteFile={(): FormikFieldChange =>
                  setFieldValue('coverImage', null)
                }
                accept="image/x-png,image/gif,image/jpeg"
                inputValue={
                  values.coverImage ? (values.coverImage.name as string) : ''
                }
                onFileChange={(e): void => handleImageChange(e)}
                uploadedFile={values.coverImage}
                loading={!!imageLoading}
                showFullPreview
                nameClassName="cover-photo"
              />
              {touched.coverImage && errors.coverImage && (
                <ErrorSpan>{errors.contentDocument}</ErrorSpan>
              )}
            </FormField>
            <FormField>
              <TextInput
                title={t('books_layout.description')}
                placeholder={t('books_layout.type_description')}
                size="small"
                name="description"
                type="text"
                fullWidth={true}
                onChange={handleChange}
                multiline
                rows="4"
              />
            </FormField>
          </FormFields>
        </DrawerContent>
        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.save')}
              type="small"
              onClick={handleSubmit}
              background="#06C68F"
            />
            <Button
              text={t('actions.cancel')}
              type="small"
              onClick={handleCloseDrawer}
              background="#E0E1E2"
              textColor="#414141"
            />
          </FormButtons>
        </FormFooter>
      </Container>
      <Modal
        isOpened={cropperOpened}
        onClose={(): void => setCropperOpened(!cropperOpened)}
      >
        <ImageCropper
          file={file}
          hideCropper={(): void => setCropperOpened(!cropperOpened)}
          getCroppedFile={(_, file): void =>
            handleCropSave(file, 'books', 'coverImage', setFieldValue)
          }
        />
      </Modal>
    </>
  )
}

export default AddBookDrawer
