import React, { ReactElement } from 'react'
import CreatableSelect from 'react-select/creatable'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { t } from 'i18next'
import {
  Container,
  FormFooter,
  FormButtons,
  DrawerHeader,
  DrawerContent,
  Title,
  StyledUploadLabel,
  FormStyles,
} from './styled-components'
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material'
import { Button } from 'components/common/Button'
import { Input } from 'components/common/TextInput'
import Uploader from 'components/common/FileUploaderV2/Uploader'
import { FormTitle } from 'pages/users/styled-components'
import { FormContainer, FormField, FormFields } from 'components/common/Form'
import { ItemWrapper } from 'components/styled-components'
import { HelpButtonContainer } from 'pages/quizzes/AddQuizDrawer/styled-components'
import { IEditTestDrawerProps } from './editTestDrawer.interface'
import { FileTypes } from 'components/common/FileUploaderV2/uploader.interface'
import { FlexWrapper, HelperLabel } from '../addTestDrawer/styled-components'
import Modal from 'components/common/Modal'
import ImageCropper from 'components/common/ImageCropper'
import { InputLabel } from 'pages/quizzes/EditQuizDrawer/styled-components'
import useTestLogic from 'hooks/tests/useTestLogic/useTestLogic'
import { ITagsInterface } from 'interfaces/common'

const EditTestDrawer = ({
  onClose,
  id,
}: IEditTestDrawerProps): ReactElement => {
  const {
    questions,
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleRandomizationChange,
    imageLoading,
    tagList,
    handleImageChange,
    cropperOpened,
    setCropperOpened,
    file,
    handleCropSave,
    handleCloseDrawer,
  } = useTestLogic({ onClose, id })

  return (
    <>
      <Container>
        <DrawerHeader>
          <FormTitle>{t('tests_layout.edit_test')}</FormTitle>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>
        <FormContainer style={FormStyles} onSubmit={handleSubmit}>
          <DrawerContent>
            <FormFields>
              <FormField>
                <Title>
                  {t('tests_layout.test')} {t('form_fields.name')}
                </Title>
                <Input
                  error={errors.name ? true : false}
                  errorMessage={errors.name ? errors.name : ''}
                  touched={touched.name}
                  label={''}
                  size="small"
                  placeholder={`${t('form_fields.name')}`}
                  value={values.name}
                  name="name"
                  type="text"
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <Title>
                  {t('form_fields.select')} {t('general.tags')}
                </Title>
                <CreatableSelect
                  isMulti
                  onChange={(e): void => {
                    setFieldValue('tags', e as ITagsInterface[])
                  }}
                  options={tagList}
                  value={values.tags}
                  placeholder={t('general.tags')}
                />
              </FormField>
              <FormField>
                <Title>{t('general.overview')}</Title>
                <Input
                  label=""
                  placeholder="Type question here"
                  value={values.description}
                  size="small"
                  name="description"
                  type="text"
                  fullWidth={true}
                  onChange={handleChange}
                  multiline
                  rows="4"
                />
              </FormField>
              <FormField>
                <Uploader
                  id="attachment"
                  type={FileTypes.IMAGE}
                  label={
                    <>
                      <StyledUploadLabel>
                        {t('actions.upload_cover_image')}
                      </StyledUploadLabel>
                    </>
                  }
                  deleteFile={(): void => setFieldValue('attachment', null)}
                  accept="image/x-png,image/gif,image/jpeg"
                  inputValue={
                    values.attachment ? (values.attachment.name as string) : ''
                  }
                  onFileChange={(e): void => handleImageChange(e)}
                  uploadedFile={values.attachment}
                  loading={!!imageLoading}
                />
                {touched.attachment && errors.attachment && (
                  <span style={{ color: '#f44336' }}>
                    <>{errors.attachment}</>
                  </span>
                )}
              </FormField>
              <FormField>
                <Title>{t('quiz_details.time_in_minutes')} </Title>
                <Input
                  label=""
                  placeholder="e.g 20 minutes"
                  size="small"
                  name="time"
                  value={values.time}
                  type="number"
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <RadioGroup
                  name="quizParticipationType"
                  value={values.quizParticipationType}
                  onChange={handleChange}
                >
                  <HelperLabel>
                    <Title>
                      {t('quiz_details.participation')} {t('general.type')}
                    </Title>
                    <HelpButtonContainer>
                      <Tooltip
                        title={
                          <span style={{ fontSize: '14px' }}>
                            {values.quizParticipationType &&
                            values.quizParticipationType === 'mandatory'
                              ? t('general.mandatory_helper_test')
                              : t('general.optional_helper_test')}
                          </span>
                        }
                        arrow
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </HelpButtonContainer>
                  </HelperLabel>

                  <FlexWrapper>
                    <ItemWrapper>
                      <FormControlLabel
                        value="mandatory"
                        control={<Radio color="primary" />}
                        label={t('general.mandatory')}
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <FormControlLabel
                        value="optional"
                        control={<Radio color="primary" />}
                        label={t('general.optional')}
                      />
                    </ItemWrapper>
                  </FlexWrapper>
                </RadioGroup>
              </FormField>
              <FormField>
                <Title>{t('quiz_details.pass_rate')}</Title>

                <Input
                  label=""
                  placeholder="e.g 80%"
                  size="small"
                  name="passRate"
                  type="number"
                  disabled={values.quizParticipationType === 'optional'}
                  onChange={handleChange}
                />
              </FormField>
              <FormField>
                <RadioGroup
                  name="randomization"
                  value={values.randomization}
                  onChange={handleRandomizationChange}
                >
                  <HelperLabel>
                    <Title>
                      {t('quiz_details.randomize')} {t('general.question')}
                    </Title>
                    <HelpButtonContainer>
                      <Tooltip
                        title={
                          <span style={{ fontSize: '14px' }}>
                            {t('quiz_details.randomize_helper')}
                          </span>
                        }
                        arrow
                      >
                        <HelpOutlineIcon fontSize="small" />
                      </Tooltip>
                    </HelpButtonContainer>
                  </HelperLabel>
                  <FlexWrapper>
                    <ItemWrapper>
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label={t('general.no')}
                      />
                    </ItemWrapper>
                    <ItemWrapper>
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label={t('general.yes')}
                      />
                    </ItemWrapper>
                  </FlexWrapper>
                </RadioGroup>
              </FormField>
              <FormField>
                <Title>
                  {t('quiz_details.number_of_randomized_questions')}
                </Title>
                <InputLabel>
                  {t('quiz_details.this_test_contains')} {questions.length}{' '}
                  &nbsp;
                  {t('quiz_details.randomize_rule')}
                </InputLabel>
                <Input
                  label=""
                  placeholder="Number of randomized questions"
                  size="small"
                  value={values.randomizationNumber}
                  min={1}
                  max={questions.length}
                  required
                  name="randomizationNumber"
                  type="number"
                  disabled={!values.randomization}
                  onChange={handleChange}
                />
              </FormField>
            </FormFields>
          </DrawerContent>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.save')}
                type="small"
                // onClick={handleSubmit}
                btnType="submit"
                background="#06C68F"
              ></Button>
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={handleCloseDrawer}
                background="#E0E1E2"
                textColor="#414141"
              ></Button>
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </Container>
      <Modal
        isOpened={cropperOpened}
        onClose={(): void => setCropperOpened(!cropperOpened)}
      >
        <ImageCropper
          file={file}
          hideCropper={(): void => setCropperOpened(!cropperOpened)}
          getCroppedFile={(_, file): void =>
            handleCropSave(file as File, 'tests', 'attachment', setFieldValue)
          }
        />
      </Modal>
    </>
  )
}

export default EditTestDrawer
