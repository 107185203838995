import React from 'react'
import { Action } from 'components/common/GridV2'
import { ReactComponent as EditRoundedIcon } from 'assets/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/trash-can-red.svg'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { QuizValue } from './quiz.interface'

type ActionHandler = (action: 'edit' | 'delete', item: QuizValue) => void

type IQuizzesActionItem = {
  hide: boolean
  render: (item: QuizValue) => JSX.Element
}

export const actionConfig = (
  t: TFunction,
  actionHandler: ActionHandler,
  permissions: IPermissionsBool,
  setIsSearchType?: (value: boolean) => void,
): IQuizzesActionItem[] => {
  let action: IQuizzesActionItem[] = [
    {
      hide: !permissions.editQuiz,
      render: (item: QuizValue) => (
        <Action
          className="edit"
          onClick={(): void => actionHandler('edit', item)}
        >
          <EditRoundedIcon />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permissions.deleteQuiz,
      render: (item: QuizValue) => (
        <Action
          className="delete"
          onClick={(): void => {
            setIsSearchType && setIsSearchType(false)
            actionHandler('delete', item)
          }}
        >
          <DeleteIcon />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ].filter((i: IQuizzesActionItem) => !i.hide)

  return action
}
