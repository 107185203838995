import config from '../config'
import { AxiosResponse } from 'axios'
const axios = require('axios').default

export interface IDataPayload {
  email?: string
  newPassword?: string
  confirmPassword?: string
  token?: string
}

export const Api = (
  method: string,
  url: string,
  data: IDataPayload,
): Promise<AxiosResponse> => {
  return axios({
    method,
    url: `${config.APIURL}/${url}`,
    data,
  })
}
