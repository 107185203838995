import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 50px;
  padding: 20px 0;

  @media (min-width: 1476px) {
    padding: 0;
  }
`

export const Action = styled.div<{
  $hoverColor?: string
  hoverBackground?: string
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  transition: 0.3s ease;
  border-radius: 5px;

  ${({ disabled }): string =>
    (disabled &&
      `
  cursor: not-allowed;
  opacity: 0.5;
`) ||
    ''}

  &:hover {
    /* background: ${({ hoverBackground }): string =>
      hoverBackground || 'rgba(0, 0, 0, 0.1)'}; */
    ${({ $hoverColor }): string =>
      ($hoverColor &&
        `svg path {
        fill: ${$hoverColor};
         }`) ||
      ''}
  }
  svg {
    font-size: 1rem;
  }
`
export const ModulesTabWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 120px);
  gap: 20px;
`
export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 30px;
  top: 30px;

  //set width for second child div
  div,
  button {
    width: unset !important;
  }
`

export const CourseDetailSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
`

export const CourseDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  h4 {
    font-size: 1.5rem;
    white-space: nowrap;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
`
export const AssessmentsDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  position: relative;
  margin-bottom: 20px;

  h4 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: rgba(2, 191, 131, 0.5);
  }
`

export const CourseDetailsContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 50px;
  justify-content: space-between;

  @media (max-width: 1476px) {
    gap: 20px;
  }
`

export const CourseDetailsItem = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  // width: calc(33% - 30px);

  .details-item-icon {
    width: 45px;

    @media (max-width: 1476px) {
      width: 30px;
      svg {
        width: 30px !important;
      }
    }
  }

  .details-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    p {
      margin: 0;
    }

    p:first-child {
      font-size: 1.2rem;
      font-weight: 400;
      text-transform: capitalize;
    }

    p:last-child {
      font-size: 1rem;
      font-weight: 300;
      color: rgb(112, 112, 112);
    }

    .details-item-tooltip {
      position: absolute;
      top: 40%;
      left: 90px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
    }
  }
`

export const ToolTipTitle = styled.p`
  font-size: 13px;
  text-align: center;
`

export const OverviewSection = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  height: 40%;
`

export const EditOverviewContainer = styled.div`
  width: 60%;

  article {
    width: unset !important;
  }
`
