import React from 'react'
import { SplashScreen, TextInput } from 'components/common'
import { BooksGrid, ToolbarContainer } from './styled-components'
import BookItem from 'pages/books/BookItem/BookItem'
import { ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as SearchRoundedIcon } from 'assets/search.svg'
import useGroupBooks from 'hooks/books/useGroupBooks/useGroupBooks'
import { IGroup } from 'interfaces/groups'
import MyLoader from 'loading/loading'

const GroupBooks = ({ group }: { group: IGroup }): JSX.Element => {
  const {
    t,
    books,
    createButton,
    searchValue,
    onSearchSubmit,
    handleSearchChange,
    groupId,
    loading,
    isSearchType,
    initialLoad,
  } = useGroupBooks({ group })

  return (
    <>
      {!loading &&
      !isSearchType &&
      books?.data?.length === 0 &&
      !searchValue ? (
        <SplashScreen
          title={t('books_layout.no_books')}
          marginTop={20}
          subTitle={t('books_layout.create_books')}
          createButton={createButton}
          textMarginTop="40px"
          isSearchOrFilter={true}
        />
      ) : (
        <>
          {!initialLoad && (
            <ToolbarContainer>
              <ToolbarItem>
                <div>{createButton()}</div>
              </ToolbarItem>
              <ToolbarItem>
                <form onSubmit={onSearchSubmit}>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type="text"
                    size="small"
                    value={searchValue}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type="submit">
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    $iconInStart
                  />
                </form>
              </ToolbarItem>
            </ToolbarContainer>
          )}
          <>
            {loading ? (
              <MyLoader />
            ) : books && books.data ? (
              <>
                {books.data.length === 0 ? (
                  <SplashScreen
                    title={t('general.no_results_found')}
                    subTitle={t('general.Try_adjusting_your_search_or_filter')}
                    textMarginTop="40px"
                    isSearchOrFilter={true}
                  />
                ) : (
                  <BooksGrid>
                    {books.data.map((book) => (
                      <BookItem
                        key={book.id}
                        groupId={groupId}
                        book={book}
                        enableGroupActions={true}
                      />
                    ))}
                  </BooksGrid>
                )}
              </>
            ) : null}
          </>
        </>
      )}
    </>
  )
}

export default GroupBooks
