import React, { ReactElement } from 'react'
import strCutter from 'utils/strCutter'
import moment from 'moment'

import {
  GroupsWrapper,
  GroupItem,
  CheckBoxAndAvatarContainer,
  GroupTitle,
} from 'components/UsersLayout/styled-components'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { NoValueWrapper } from 'components/common/Grid/styled-components'
import AvatarIcon from 'assets/profile.svg'
import {
  StudentDetailsContainer,
  StudentDetailsTextContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { TFunction } from 'interfaces/TFunction'
import { IConfigItem } from 'pages/tests/testDetails/studentsLayout/columnConfig'

export const columnConfig = (t: TFunction): IConfigItem[] => {
  let grid: IConfigItem[] = [
    {
      id: 'Avatar',
      label: `${t('user_details.tutor_details')}`,
      render: (item) => (
        <StudentDetailsContainer>
          <CheckBoxAndAvatarContainer style={{ marginLeft: 10 }}>
            <img src={item.avatarThumbnail || item.avatar || AvatarIcon} />
          </CheckBoxAndAvatarContainer>
          <StudentDetailsTextContainer>
            <div>
              {item.firstName} {item.lastName}
            </div>
            <div>{item.email}</div>
          </StudentDetailsTextContainer>
        </StudentDetailsContainer>
      ),
      numeric: false,
      disablePadding: true,
      width: '40%',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.phone')}`,
      render: (item): ReactElement => {
        return (
          <div>
            {item.phoneFields &&
              item.phoneFields.code &&
              `(${item.phoneFields.code}) `}
            {item.phone}
          </div>
        )
      },
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: `${t('general.status')}`,
      render: (item) => <StatusIndicator status={item.status} />,
    },
    {
      id: 'group',
      numeric: false,
      disablePadding: false,
      label: `${t('general.groups')}`,
      render: (item): ReactElement => {
        const groups = item.group || []
        const firstTwoGroups = groups.slice(0, 2)
        const exGroups = groups.slice(2, groups.length)
        return (
          <div>
            {firstTwoGroups.length === 0 && (
              <NoValueWrapper>N/A</NoValueWrapper>
            )}
            <GroupsWrapper>
              {firstTwoGroups.map(
                (group, index: number) =>
                  index < 3 && (
                    <GroupItem key={group.groupId}>
                      <IconButton>
                        <span className="material-icons">groups</span>{' '}
                      </IconButton>
                      <GroupTitle className="group-item-title">
                        {group.name}
                      </GroupTitle>
                    </GroupItem>
                  ),
              )}

              {exGroups.length > 0 && (
                <Tooltip
                  title={
                    <>
                      {exGroups.map((exGroup) => (
                        <div key={exGroup.groupId}>{exGroup.name}</div>
                      ))}
                    </>
                  }
                >
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 5,
                    }}
                  >
                    ...
                  </span>
                </Tooltip>
              )}
            </GroupsWrapper>
          </div>
        )
      },
    },
    {
      id: 'companyId',
      numeric: false,
      disablePadding: false,
      label: `${t('user_details.company')}`,
      render: (item) => (
        <div>
          {item.companyId ? strCutter(item.companyId.name, 25) : <>N/A</>}
        </div>
      ),
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: `${t('general.create_date')}`,
      render: (item) => (
        <>{moment(parseInt(item.createDate)).format('DD-MM-YYYY')}</>
      ),
    },
  ]

  return grid
}
