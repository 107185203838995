import roleObj from './rolesObject/rolesObject'

import { ActivityInterface, RolesInterface } from './permissions.interface'
import { IPermissions, Permission } from './rolesObject/rolesObject.interface'
import { IUser } from 'interfaces/users'

export const roles: RolesInterface = {
  super_admin: 'SUPER_ADMIN',
  consultant: 'CONSULTANT',
  admin: 'ADMIN',
  student: 'STUDENT',
  coach: 'COACH',
}

export enum RolesCapitalized {
  SUPER_ADMIN = 'SuperAdmin',
  CONSULTANT = 'Consultant',
  ADMIN = 'Admin',
  STUDENT = 'Student',
  COACH = 'Coach',
}

export const activities: ActivityInterface = {
  create: 'CREATE',
  edit: 'EDIT',
  delete: 'DELETE',
  view: 'VIEW',
  suspended: 'SUSPENDED',
  active: 'ACTIVE',
}

const permissions: IPermissions = {
  // company
  createCompany: 'Create company',
  editCompany: 'Edit company',
  deleteCompany: 'Delete company',
  viewCompanyPage: 'View company page',
  switchToCompanyMode: 'Switch to company mode',

  // superAdmin
  createSuperAdmin: 'Create Super Admin',
  editSuperAdmin: 'Edit Super Admin',
  deleteSuperAdmin: 'Delete Super Admin',
  viewSuperAdminPage: 'View Super Admin page',
  suspendSuperAdmin: 'Suspend Super Admin',
  activateSuperAdmin: 'Activate Super Admin',
  resendUserPasswordSuperAdmin: 'Resend Super Admin Password',
  resetPassword: 'Reset Password', // this is for admins and super admins

  // group
  createGroup: 'Create group',
  editGroup: 'Edit group',
  deleteGroup: 'Delete group',
  viewGroup: 'View group',

  // admins
  createAdmin: 'Create admin',
  editAdmin: 'Edit admin',
  deleteAdmin: 'Delete admin',
  viewAdmin: 'View admin',
  activateAdmin: 'Activate admin',
  suspendAdmin: 'Suspend admin',
  resendUserPasswordAdmin: 'Resend Admin Password',

  // coach
  viewCoach: 'View coach',
  createCoach: 'Create coach',
  editCoach: 'Edit coach',
  deleteCoach: 'Delete coach',
  resendUserPasswordCoach: 'Resend Coach Password',
  activateCoach: 'Activate coach',
  suspendCoach: 'Suspend coach',

  // course
  createCourse: 'Create course',
  editCourse: 'Edit course',
  deleteCourse: 'Delete course',
  viewCourse: 'View course',
  publishCourse: 'Publish course',
  unPublishCourse: 'Un publish course',

  // module
  createModule: 'Create module',
  editModule: 'Edit module',
  deleteModule: 'Delete module',
  viewModule: 'View module',

  // student
  createStudent: 'Create student',
  editStudent: 'Edit student',
  deleteStudent: 'Delete student',
  viewStudent: 'View student',
  resendUserPasswordStudent: 'Resend Student Password',
  activateStudent: 'Activate student',
  suspendStudent: 'Suspend student',

  // topic
  createTopic: 'Create topic',
  editTopic: 'Edit topic',
  deleteTopic: 'Delete topic',
  viewTopic: 'View topic',

  // lesson
  createLesson: 'Create lesson',
  editLesson: 'Edit lesson',
  deleteLesson: 'Delete lesson',
  viewLesson: 'View lesson',

  viewQuestion: 'View question',
  addQuestion: 'Add question',
  editQuestion: 'Edit question',
  deleteQuestion: 'Delete question',
  viewQuiz: 'View quiz',
  addQuiz: 'Add quiz',
  editQuiz: 'Edit quiz',
  deleteQuiz: 'Delete quiz',

  viewTest: 'View test',
  viewTask: 'View task',

  createFlashcard: 'Create flashcard',
  editFlashcard: 'Edit flashcard',
  deleteFlashcard: 'Delete flashcard',

  viewNotificationsPage: 'View notifications page',
  viewSettingPage: 'View settings page',
  viewDashboardPage: 'View dashboard page',
}

const checkPermission = (
  permission: string,
  user: IUser,
  mode: string,
): boolean => {
  const { role } = user

  if (!role) return false
  if ([roles.super_admin, roles.admin].includes(role) && !user.companyId)
    return true

  const obj = roleObj(user)

  const filtered = obj.filter((i) => i.role === role)

  const currPermissions = filtered.length > 0 ? filtered[0].permissions : []

  const result = currPermissions.filter((i) => i.name === permission)

  if (result.length <= 0) {
    return false
  }

  // Ensure 'mode' is a key of the permission object
  if (mode in result[0]) {
    const permissionMode = result[0][mode as keyof Permission]
    if (typeof permissionMode !== 'string') {
      return permissionMode?.can || false
    }
  }

  return false
}

export const setUserPermission = (
  user: IUser,
  mode: string,
): {
  [key: string]: boolean
} => {
  const response: { [key: string]: boolean } = {}

  for (const key in permissions) {
    const permissionKey = key as keyof typeof permissions
    if (permissions[permissionKey]) {
      response[permissionKey] = checkPermission(
        permissions[permissionKey]!,
        user,
        mode,
      )
    }
  }
  return response
}
