// @deprecated
/* eslint-disable */

import React, { useState } from 'react'
import { ToolbarItemWrapper, Title } from './styled-components'
import Grid from 'components/common/Grid'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import useUserDeleteService from 'hooks/users/useDeleteUserService'
import { StudentListParams } from './interfaces'
import { useSwal } from 'hooks/useSwal'
import { Button } from 'components/common/Button'
import Tooltip from '@mui/material/Tooltip'
import EditStudent from './EditStudent'
import TablePagination from 'components/common/Pagination/TablePagination'

import { columnConfig, actionConfig } from './gridConfig'
import { useNavigate } from 'react-router-dom'

const toolbarStyle = {
  justifyContent: 'space-between',
  borderBottom: '1px solid #ccc',
}

const StudentList = ({
  studentsData,
  groupList,
  companyId,
  refetch,
  t,
}: StudentListParams) => {
  const [selectedItem, setSelectedItem] = useState<any>({})
  const [selectAll, setSelectAll] = useState<any>({})
  const [searchText, setSearchText] = useState('')
  const { fireSwal } = useSwal()
  const [checkbox, setCheckbox] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editField, setEditField] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [filterQuery, setFilterQuery] = useState<any>({
    companyId: {
      type: 'exact',
      value: companyId,
    },
  })

  const { deleteUser } = useUserDeleteService()

  const { data: studentList } = studentsData

  const handleSelect = (id: never, event: boolean) => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = selectedItemsOnPage.indexOf(id)
    let newSelected: any = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = () => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = studentList.map((n: any) => n.id)
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const handleDelete = (ids: string[]) => {
    if (ids.length <= 0) return
    const title: string =
      ids.length === 1
        ? `${t('popups.delete_single')} ${t('general.student')}`
        : `${t('popups.delete_many')} ${t('general.students')}`
    const params = {
      title,
      onConfirm: () => {
        deleteUser(ids, 'STUDENT', () => {
          refetch({
            role: 'STUDENT',
            filter: {
              companyId: {
                type: 'exact',
                value: companyId,
              },
            },
            currentPage: 1,
            perPage,
          })

          setSelectedItem([])
          setCheckbox(false)
        })
      },
      confirmText: t('popups.confirm_delete'),
    }

    fireSwal(params)
  }

  const handleEdit = (field: any) => {
    setEditMode(true)
    setEditField(field)
  }

  const actionClick = (e: string, field: any) => {
    switch (e) {
      case 'edit':
        return handleEdit(field)

      case 'delete':
        return handleDelete([field])

      default:
        break
    }
  }

  const handleSearchSubmit = (e: any) => {
    e.preventDefault()
    const formValue = {
      search: {
        type: 'search',
        value: searchText,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    let filterData = { ...filterQuery }
    if (searchText !== '') {
      filterData = { ...filterQuery, ...formValue }
    } else {
      const { search, ...filters } = filterQuery
      filterData = { ...filters }
    }

    refetch({
      role: 'STUDENT',
      filter: filterData,
      currentPage: 1,
      perPage,
    })
    setFilterQuery(filterData)
  }

  const handlePaginationClick = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: filterQuery,
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    refetch({
      role: 'STUDENT',
      filter: filterQuery,
      currentPage: 1,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  const config = columnConfig(handleSelect, t, checkbox)
  const actions = actionConfig(actionClick, t)

  const navigate = useNavigate()

  return (
    <>
      {editMode && (
        <EditStudent
          groupList={groupList}
          item={editField}
          onCancel={() => {
            setEditMode(false)
            setEditField(null)
          }}
          t={t}
        />
      )}

      <Toolbar styles={toolbarStyle}>
        <ToolbarItemWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title>{t('company_wizard_layout.student_list')}</Title>
            <div style={{ marginLeft: '10px' }}>
              {studentList && studentList.length > 0 && (
                <Button
                  text={
                    !checkbox ? t('actions.delete_rows') : t('actions.cancel')
                  }
                  type="small"
                  onClick={() => {
                    if (checkbox) {
                      setSelectedItem({})
                    }
                    setCheckbox(!checkbox)
                  }}
                  background="#06C68F"
                />
              )}
            </div>
            {checkbox && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip title={t('actions.delete')}>
                  <IconButton
                    onClick={() => handleDelete(selectedItem[currentPage])}
                  >
                    <DeleteIcon
                      fontSize="small"
                      color={
                        !selectedItem[currentPage] ||
                        selectedItem[currentPage].length === 0
                          ? 'disabled'
                          : 'secondary'
                      }
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>

          <div className="wrapper">
            <ToolbarItem>
              <form onSubmit={handleSearchSubmit}>
                <Input
                  label={t('general.search_placeholder')}
                  type="text"
                  size="small"
                  value={searchText}
                  onChange={(e: any) => setSearchText(e.target.value)}
                  icon={
                    <IconButton>
                      <SearchRoundedIcon />
                    </IconButton>
                  }
                />
              </form>
            </ToolbarItem>
          </div>
        </ToolbarItemWrapper>
      </Toolbar>

      <div>
        <Grid
          config={config}
          data={studentList || []}
          actionConfig={actions}
          selectAllItem={selectAllItem}
          selectedItems={
            selectedItem[currentPage] ? selectedItem[currentPage] : []
          }
          selected={
            selectedItem[currentPage] ? selectedItem[currentPage].length : 0
          }
          disabledSelect={!checkbox}
          openInExternalTab={true}
        />
        {studentList && studentsData.totalCount > 0 && (
          <TablePagination
            currentPage={studentList ? studentsData.currentPage : 0}
            rowsPerPage={perPage}
            count={studentList ? studentsData.totalCount : 0}
            handleChangePage={handlePaginationClick}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </div>
    </>
  )
}

export default StudentList
