import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useSwal } from 'hooks/useSwal'
import { useAction } from 'store/actions'
import { useData } from 'context/DataContext'
import { CLEAR_FORMDATA, SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { CoachSchema } from 'helpers/validationSchemas'
import { useUserValue } from 'context/UserContext'
import useAddUserServie from 'hooks/users/useAddUserService'
import { checkValues } from 'pages/coaches/checkCoachValues'
import {
  IFormData,
  IOptionType,
  IUseCoachDrawerProps,
  IUseCoachDrawerReturn,
} from 'pages/coaches/AddCoach/addCoach.interface'
import { useLazyQuery } from '@apollo/client'
import { GET_COMPANIES } from 'gql/companies.query'
import { getUserByIdService } from 'services/users/getUserById/getUserByIdService'
import moment from 'moment'
import { genderOptions } from 'components/UsersLayout/staticData'
import useEditUserService from 'hooks/users/useEditUserService'
import { DrawerEventEmitter } from 'helpers/drawer'
import { ISelectFilterOption } from 'interfaces/common'
import { IUser } from 'interfaces/users'
import useCheckUserEmailService from 'hooks/users/checkUserEmail'

const formData: IFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyId: {
    value: '',
  },
  gender: null,
  birthDate: '',
  biography: '',
  note: '',
  requestPasswordChange: false,
  role: '',
  courses: null,
  jobTitle: '',
  location: '',
  phoneFields: null,
  phoneFieldValue: null,
  avatar: '',
}

export const useCoachDrawer = ({
  role,
  drawerData,
  onClose,
  userId,
}: IUseCoachDrawerProps): IUseCoachDrawerReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const [addingCompanyUser, setAddingCompanyUser] = useState(false)
  const [dataState, dispatchData] = useData()
  const { toggleDrawerConfirm } = useAction()
  const { fireSwal } = useSwal()

  const { addUser, addUserLoading } = useAddUserServie()

  const { updateUser, editUserLoading, user } = useEditUserService()
  const { data: userData } = getUserByIdService(userId ?? '')
  const { checkUserEmail } = useCheckUserEmailService()

  const [editingCompanyUser, setEditingCompanyUser] = useState(false)
  const [currentCoach, setCurrentCoach] = useState<IFormData>({})

  const [getCompanyOptions, { data: companyOptions }] =
    useLazyQuery(GET_COMPANIES)

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    setErrors,
  } = useFormik({
    initialValues: formData,
    validationSchema: CoachSchema,
    async onSubmit(values) {
      if (addUserLoading) return
      if (userId) {
        if (userData?.email !== values.email) {
          const emailError = await checkUserEmail(values.email)
          if (emailError) {
            setErrors({ email: emailError })
            return
          }
        }
        const formValues: IFormData = { ...values }
        formValues.gender = formValues.gender
          ? (formValues.gender as ISelectFilterOption).value
          : null
        delete formValues.phoneFieldValue

        if (formValues.phoneFields && formValues.phoneFields.value) {
          formValues.phoneFields = {
            dialCode: formValues.phoneFields.value,
            code: formValues.phoneFields.label,
          }
        } else {
          formValues.phoneFields = null
        }

        updateUser(userId, formValues as IUser)
        return
      }

      const emailError = await checkUserEmail(values.email)
      if (emailError) {
        setErrors({ email: emailError })
        return
      }
      const data: IFormData = { ...values }
      data.role = role
      delete data.companyId
      delete data.phoneFieldValue
      if (data.gender) data.gender = (data.gender as ISelectFilterOption).value

      if (data.courses) {
        data.courses = data.courses.map((i: IOptionType) => ({
          label: i.label,
          value: i.value,
          name: i.label,
          courseId: i.value,
        }))
      } else {
        data.courses = []
      }

      if (data.phoneFields && data.phoneFields.value) {
        data.phoneFields = {
          dialCode: data.phoneFields.value,
          code: data.phoneFields.label,
        }
      } else {
        data.phoneFields = null
      }

      const companyId =
        values.companyId?.value ||
        drawerData?.companyId ||
        (state.selectedCompany?.id as string)

      addUser(data as IUser, role || 'coach', companyId, onSuccess)
    },
  })

  useEffect(() => {
    setFieldValue(
      'requestPasswordChange',
      state.currentUser.settings?.askPassword,
    )
  }, [])

  const onSuccess = (): void => {
    closeDrawer()
    dispatchData({ type: CLEAR_FORMDATA })
    if (drawerData?.onSuccess) {
      drawerData.onSuccess()
    }
  }
  const closeDrawerEdit = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'editCoach', false)
  }

  const handleCloseDrawerEdit = (): void => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editCoach',
        values,
        compareTo: currentCoach,
      },
    })
  }

  const closeDrawer = (): void => {
    if (onClose) onClose()
  }
  const handleCloseDrawer = (): void => {
    const isEmpty = checkValues(values)
    if (isEmpty) {
      if (onClose) {
        onClose()
      } else {
        closeDrawerEdit()
      }
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          if (onClose) {
            onClose()
          } else {
            closeDrawerEdit()
          }
        },

        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }

  const onDateChange = (date: Date | null): void => {
    const birthDate = date
    setFieldValue('birthDate', date)

    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (user) {
      closeDrawerEdit()
      toggleDrawerConfirm(false, '')
    }
  }, [user])

  useEffect(() => {
    if (userData) {
      const {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender,
        phoneFields,
        birthDate,
        biography,
        note,

        avatar = '',
      } = userData
      const userGender = genderOptions.find(
        (option: { value: string; label: string }) => option.value === gender,
      )
      const formData: IFormData = {
        firstName,
        lastName,
        email,
        phone,
        jobTitle,
        location,
        gender: gender
          ? ({
              label: userGender?.label,
              value: gender,
            } as ISelectFilterOption)
          : null,
        phoneFields: phoneFields?.dialCode
          ? { label: phoneFields?.code, value: phoneFields.dialCode }
          : null,
        phoneFieldValue: phoneFields?.dialCode || null,
        biography,
        note,
        birthDate: birthDate
          ? moment(parseInt(birthDate.toString())).format()
          : null,

        avatar,
      }
      setValues({
        ...formData,
      })
      setCurrentCoach(formData)
    }
  }, [userData])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || null,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : null
  }, [values.phoneFields])

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    addUserLoading,
    addingCompanyUser,
    setAddingCompanyUser,
    handleCloseDrawer,
    onDateChange,
    getCompanyOptions,
    companyOptions,
    state,
    t,
    handleCloseDrawerEdit,
    editingCompanyUser,
    setEditingCompanyUser,
    editUserLoading,
  }
}
