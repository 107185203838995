import { OperationVariables, useQuery } from '@apollo/client'

import { GET_AVAILABLE_GROUPS_FOR_TEST } from 'gql/group/group.query'
import { IGroup } from 'interfaces/groups'
import { FilterOptionsType } from 'pages/group/FilterDrawer/filterDrawer.interface'

export const PER_PAGE = 10

export interface IUseAvailableGroupsForTestAssessmentsQueryResponse {
  currentPage: number
  totalCount: number
  data: IGroup[]
}

export interface IUseAvailableGroupsForTestAssessmentsResponse {
  getAvailableGroupsForTestAssessment: IUseAvailableGroupsForTestAssessmentsQueryResponse
}

export interface IUseAvailableGroupsForTestAssessmentReturnType {
  groups: IUseAvailableGroupsForTestAssessmentsQueryResponse
  loading: boolean
  error: string | undefined
  refetch: (variables?: OperationVariables) => void
}

export const useAvailableGroupsForTestAssessment = (
  testAssessmentId: string,
  currentPage = 1,
  perPage: number = PER_PAGE,
  filter: FilterOptionsType,
): IUseAvailableGroupsForTestAssessmentReturnType => {
  const { data, loading, error, refetch } = useQuery(
    GET_AVAILABLE_GROUPS_FOR_TEST,
    {
      variables: {
        testAssessmentId,
        filter,
        currentPage,
        perPage,
      },
    },
  )

  const errorMessage = error && error?.message.split(':')[1]

  return {
    groups: data && data.getAvailableGroupsForTestAssessment,
    loading,
    error: errorMessage,
    refetch,
  }
}
