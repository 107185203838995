import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { IQuiz } from 'pages/quizzes/quiz.interface'
import { useState } from 'react'
import publishUnpublishTestService from 'services/tests/publishUnpublishTestService'

const usePublishUnpublishTestService = (): {
  publishUnpublishTestAssessment: (
    testAssessmentId: string,
    published: boolean,
  ) => Promise<void>
  loading: boolean
  error: string
  data: IQuiz | null
} => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { publishUnpublishTestMutation } = publishUnpublishTestService()
  const [data, setData] = useState<IQuiz | null>(null)

  const publishUnpublishTestAssessment = async (
    testAssessmentId: string,
    published: boolean,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { data, errors } = await publishUnpublishTestMutation({
      variables: {
        testAssessmentId,
        published,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
      setError(message)
    } else if (data) {
      message = `Test ${published ? 'published' : 'unpublished'} successfully`
      variant = 'success'
      setData(data.publishUnpublishTest)
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return { publishUnpublishTestAssessment, loading, error, data }
}

export default usePublishUnpublishTestService
