import { useMutation, MutationTuple } from '@apollo/client'
import { EDIT_LESSON, GET_LESSON_BY_ID } from 'gql/lesson/lesson.query'
import { ILesson } from 'interfaces/modules'

interface IEditLessonResponse {
  editLesson: ILesson
}

interface IEditLessonParams {
  lessonId: string
  input: ILesson
  topicId: string
}

interface IEditLessonServiceResponse {
  editLessonMutation: MutationTuple<IEditLessonResponse, IEditLessonParams>[0]
  loading: boolean
}

export interface IEditLessonServiceVariables {
  lessonId?: string
}

const editLessonService = (
  variables: IEditLessonServiceVariables,
): IEditLessonServiceResponse => {
  const [editLessonMutation, { loading }] = useMutation<
    IEditLessonResponse,
    IEditLessonParams
  >(EDIT_LESSON, {
    refetchQueries: [
      {
        query: GET_LESSON_BY_ID,
        variables,
      },
    ],
  })

  return {
    editLessonMutation,
    loading,
  }
}

export default editLessonService
