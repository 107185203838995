import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import {
  SET_NOTIFICATION_VIEW_STATUS,
  READ_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
  SEND_CUSTOM_NOTIFICATION,
} from 'gql/notifications/notifications.query'
import { IMessageResponse } from 'interfaces/common'
import { ICustomNotification } from 'interfaces/notification'

interface ISetNotificationViewStatusServiceResponse {
  setStatusMutation: MutationTuple<
    ISetNotificationViewStatusResponse,
    ISetNotificationViewStatusParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface ISetNotificationViewStatusResponse {
  setNotificationViewStatus: IMessageResponse
}

interface ISetNotificationViewStatusParams {
  ids: string[]
}

export const setNotificationViewStatusService =
  (): ISetNotificationViewStatusServiceResponse => {
    const [setStatusMutation, { loading, error }] = useMutation<
      ISetNotificationViewStatusResponse,
      ISetNotificationViewStatusParams
    >(SET_NOTIFICATION_VIEW_STATUS)

    return {
      setStatusMutation,
      loading,
      error,
    }
  }

interface IReadNotificationServiceResponse {
  readNotificationMutation: MutationTuple<
    IReadNotificationResponse,
    IReadNotificationParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IReadNotificationResponse {
  readNotification: IMessageResponse
}

interface IReadNotificationParams {
  ids: string[]
  action: string
}

export const readNotificationService = (): IReadNotificationServiceResponse => {
  const [readNotificationMutation, { loading, error }] = useMutation<
    IReadNotificationResponse,
    IReadNotificationParams
  >(READ_NOTIFICATIONS)

  return {
    readNotificationMutation,
    loading,
    error,
  }
}

interface ISendCustomNotificationServiceResponse {
  sendCustomNotificationMutation: MutationTuple<
    ISendCustomNotificationResponse,
    ISendCustomNotificationParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface ISendCustomNotificationResponse {
  sendCustomNotification: IMessageResponse
}

interface ISendCustomNotificationParams {
  input: ICustomNotification
}

export const sendCustomNotificationService =
  (): ISendCustomNotificationServiceResponse => {
    const [sendCustomNotificationMutation, { loading, error }] = useMutation<
      ISendCustomNotificationResponse,
      ISendCustomNotificationParams
    >(SEND_CUSTOM_NOTIFICATION)

    return {
      sendCustomNotificationMutation,
      loading,
      error,
    }
  }

interface IDeleteNotificationsServiceResponse {
  deleteNotificationsMutation: MutationTuple<
    IDeleteNotificationsResponse,
    IDeleteNotificationsParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteNotificationsResponse {
  deleteNotifications: IMessageResponse
}

interface IDeleteNotificationsParams {
  ids: string[]
  action: string
}

export const deleteNotificationsService =
  (): IDeleteNotificationsServiceResponse => {
    const [deleteNotificationsMutation, { loading, error }] = useMutation<
      IDeleteNotificationsResponse,
      IDeleteNotificationsParams
    >(DELETE_NOTIFICATIONS)

    return {
      deleteNotificationsMutation,
      loading,
      error,
    }
  }
