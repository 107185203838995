import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { DELETE_COURSE, GET_ALL_COURSE } from 'gql/course/course.query'
import { IMessageResponse } from 'interfaces/common'
import { updateCache } from 'services/updateCache'

interface IDeleteCourseServiceResponse {
  deleteCourseMutation: MutationTuple<
    IDeleteCourseResponse,
    IDeleteCourseVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

interface IDeleteCourseResponse {
  deleteCourse: IMessageResponse
}

interface IDeleteCourseVariables {
  ids: string[]
}

const deleteCourseService = (): IDeleteCourseServiceResponse => {
  const [deleteCourseMutation, { loading, error }] = useMutation<
    IDeleteCourseResponse,
    IDeleteCourseVariables
  >(DELETE_COURSE, {
    update(cache, { data }) {
      if (data?.deleteCourse) {
        const document = data.deleteCourse
        updateCache({
          objectName: 'getAllCourse',
          query: GET_ALL_COURSE,
          cache,
          document,
          action: 'remove',
        })
      }
    },
  })

  return {
    deleteCourseMutation,
    loading,
    error,
  }
}

export default deleteCourseService
