import { useMutation, MutationTuple } from '@apollo/client'
import {
  DELETE_STUDENTS_FROM_GROUP,
  GET_GROUP_BY_ID,
} from 'gql/group/group.query'
import { IGroup } from 'interfaces/groups'

interface IDeleteStudentsResponse {
  deleteStudentsFromGroup: IGroup
}

interface IDeleteStudentsVariables {
  groupId: string
  ids: string[]
}

interface IDeleteStudentsServiceResponse {
  deleteStudents: MutationTuple<
    IDeleteStudentsResponse,
    IDeleteStudentsVariables
  >[0]
}

const deleteStudentsService = (
  groupId: string,
): IDeleteStudentsServiceResponse => {
  const [deleteStudents] = useMutation<
    IDeleteStudentsResponse,
    IDeleteStudentsVariables
  >(DELETE_STUDENTS_FROM_GROUP, {
    refetchQueries: [
      {
        query: GET_GROUP_BY_ID,
        variables: { id: groupId },
      },
    ],
  })

  return {
    deleteStudents,
  }
}

export default deleteStudentsService
