import { MutationTuple, useMutation } from '@apollo/client'
import { CREATE_LESSON } from 'gql/lesson/lesson.query'
import { ILesson } from 'interfaces/modules'
import { ITopic } from 'pages/modules/ModulesList.interface'

interface ICreateLessonServiceResponse {
  createLessonMutation: MutationTuple<
    ICreateLessonResponse,
    ICreateLessonParams
  >[0]
  loading: boolean
}

interface ICreateLessonResponse {
  createLesson: ITopic
}

interface ICreateLessonParams {
  topicId: string
  input: ILesson
}

const createLessonService = (): ICreateLessonServiceResponse => {
  const [createLessonMutation, { loading }] = useMutation<
    ICreateLessonResponse,
    ICreateLessonParams
  >(CREATE_LESSON)

  return {
    createLessonMutation,
    loading,
  }
}

export default createLessonService
