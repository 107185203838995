import { FetchMoreQueryOptions, useQuery } from '@apollo/client'

import { GET_COMPANIES, GET_COMPANY } from 'gql/companies.query'
import { IGetCompaniesResponse } from './useCompanyLayout.interface'
import { ICompany } from 'pages/companies/CompanyDetails/types'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'

export const PER_PAGE = 12

export interface IUseFetCompaniesVariables {
  currentPage: number
  perPage: number
  filter: IFilterQueryType
}

export interface IUseFetchCompaniesReturnType {
  companies: IGetCompaniesResponse
  loading: boolean
  error: string
  refetch: (variables?: IUseFetCompaniesVariables) => void
  fetchMore: (
    options: FetchMoreQueryOptions<
      IUseFetCompaniesVariables,
      IGetCompaniesResponse
    >,
  ) => void
}

export interface IUseFetchCompanyReturnType {
  company?: ICompany
  loading: boolean
  error?: string
  refetch: () => void
}

export interface IUseFetchCompanyVariables {
  id?: string | null
}

export const useFetchCompanies = (
  currentPage = 1,
  perPage: number = PER_PAGE,
  filter: IFilterQueryType,
): IUseFetchCompaniesReturnType => {
  const { data, loading, error, refetch, fetchMore } = useQuery(GET_COMPANIES, {
    variables: { currentPage, perPage, filter },
  })
  const errorMessage = error ? error?.message.split(':')[1] : ''

  return {
    companies: data && data.companies,
    loading,
    error: errorMessage,
    refetch,
    fetchMore,
  }
}

export const useFetchCompany = (
  companyId?: string | null,
): IUseFetchCompanyReturnType => {
  const { data, loading, error, refetch } = useQuery<
    IUseFetchCompanyReturnType,
    IUseFetchCompanyVariables
  >(GET_COMPANY, {
    variables: { id: companyId },
  })

  const errorMessage = error && error?.message.split(':')[1]

  return {
    company: data && data.company,
    loading,
    error: errorMessage,
    refetch,
  }
}
