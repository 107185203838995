import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import resendUserService from 'services/users/updateUserPasswordService'
import { useTranslation } from 'react-i18next'
import { IUpdateStatusUserServiceParams } from './useUpdateStatusUserService'

interface IUseUpdateUserPasswordService {
  updateUserPassword: (id?: string) => Promise<void>
  user: string | undefined
  error: string
}

const useUpdateUserPasswordService = (
  params?: IUpdateStatusUserServiceParams,
): IUseUpdateUserPasswordService => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [user, setUser] = useState<string | undefined>('')
  const [error, setError] = useState('')

  const { updateUserPasswordMutation } = resendUserService(params)

  const updateUserPassword = async (id?: string): Promise<void> => {
    if (!id) return
    let message = ''
    let variant:
      | 'default'
      | 'error'
      | 'warning'
      | 'success'
      | 'info'
      | undefined
    const { data, errors } = await updateUserPasswordMutation({
      variables: {
        id,
      },
    })

    if (errors) {
      // eslint-disable-next-line no-console
      console.log(errors)
      message = t('messages.something_wrong')
      variant = 'error'
      setError(JSON.stringify(errors))
    } else {
      message = t('messages.new_password_sent')
      variant = 'success'
    }
    if (data) setUser(data.updateUserPassword.id)
    setSnackbar({ message, variant })
  }

  return {
    updateUserPassword,
    user,
    error,
  }
}

export default useUpdateUserPasswordService
