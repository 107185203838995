import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import axios, { AxiosResponse } from 'axios'
import { useData } from 'context/DataContext'
import { useFormik } from 'formik'
import { LessonSchema } from 'helpers/validationSchemas'
import {
  useGenerateUploadVideoLinks,
  useUploadAudioService,
  useUploadCaptions,
  useUploadFile,
  useUploadImage,
} from 'hooks/helpers/useHelpersService'
import useCreateLessonService from 'hooks/lessons/useCreateLessonService'
import { useAddTopic } from 'hooks/topics/useAddTopic'

import { useAction } from 'store/actions'
import { SET_FORMDATA } from 'store/types'
import {
  FileTypes,
  IAudioFile,
  IFormData,
  ITopicOption,
  IUseAddLessonDrawerProps,
  IUseAddLessonDrawerReturn,
} from './useAddLesson.interface'
import { ITopic } from 'pages/modules/ModulesList.interface'
import removeKeys from 'utils/removeKeys'
import { ValueType } from 'react-select'
import { IGenerateUploadVideoLinksMutationResponse } from 'services/helperService/helperService'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { ILesson } from 'interfaces/modules'

const formData: IFormData = {
  name: '',
  description: '',
  transcript: '',
  topic: null,
  video: null,
  attachment: null,
  audio: null,
  duration: 0,
  contentDocument: null,
  subtitle: null,
  thumbnail: null,
}

const constants = {
  video:
    'video/x-msvideo|video/mpeg|video/ogg|video/mp4|video/x-flv|video/quicktime|video/x-msvideo|video/x-ms-wmv',
  audio: 'audio/mpeg|audio/mpeg|audio/aac|audio/mp4a-latm',
  file: 'application/pdf|application/msword|application/plain|text/plain|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.ms-excel',
}
const combinedConstants = Object.values(constants).join().split('|').join(', ')

export const useAddLessonDrawer = ({
  data,
  id,
  onClose,
}: IUseAddLessonDrawerProps): IUseAddLessonDrawerReturn => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const { toggleDrawerConfirm } = useAction()
  const [file, setFile] = useState<File | null>(null)
  const [videoDuration, setVideoDuration] = useState(0)
  const [uploadVideoProgress, setUploadVideoProgress] = useState(0)
  const { createLesson, loading: createLessonLoading } =
    useCreateLessonService()
  const [fileTypes, setFileTypes] = useState<FileTypes>('video')

  const { addTopic, addTopicLoading } = useAddTopic({
    moduleId: id,
    filter: {},
  })

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadCaptions } = useUploadCaptions()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadAudio } = useUploadAudioService()
  const [uploadedVideoName, setUploadedVideoName] = useState<string>('')
  const [isVideoUpdated, setIsVideoUpdated] = useState(false)

  const uploadVideoService = (
    url: string,
  ): void | Promise<AxiosResponse<unknown>> => {
    setUploadedVideoName(url.split('/')[4].split('.')[0])
    return axios.put(url, file, {
      headers: {
        'Content-Type': file?.type,
      },
      onUploadProgress: ({ total, loaded }) => {
        setUploadVideoProgress((loaded / total) * 100)
      },
    })
  }

  const onSuccess = (): void => {
    onClose()
    toggleDrawerConfirm(false, '')
  }

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: formData,
      validationSchema: Yup.object(LessonSchema),
      onSubmit: async (values: IFormData) => {
        const data = { ...values }

        if (createLessonLoading) return

        if (data.topic) {
          data.topic = (data.topic as ITopic).value as string
        }

        if (videoDuration) {
          data.duration = videoDuration
        }

        if (data.video) {
          data.video = {
            id: data.video.id,
            title: data.video.title,
          } as IVideo
        }

        if (data.thumbnail) {
          data.thumbnail = data.thumbnail.link as string
        }
        createLesson(data as unknown as ILesson, onSuccess, data.refetch)
      },
    })

  const handleGenerateUploadLink = (): void => {
    const videos = [{ fileName: file?.name, type: file?.type }] as IVideo[]

    generateUploadVideoLinks(
      videos,
      'lesson',
      (arg: string) => uploadVideoService(arg),
      (args: IGenerateUploadVideoLinksMutationResponse) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setUploadVideoProgress(0)
      },
      true,
    )
  }

  const generateUploadAudioLink = (): void => {
    const audioFile: IAudioFile = { fileName: file?.name, type: file?.type }

    uploadAudio(
      audioFile,
      (arg: string) => uploadVideoService(arg),
      (fileId: string) => {
        setFieldValue('audio', {
          name: file?.name,
          fileType: file?.name.split('.').pop(),
          id: fileId,
        })

        setUploadVideoProgress(0)
      },
    )
  }

  const handleCloseDrawer = (): void => {
    let formValues: IFormData = { ...values }
    if (data.id) {
      const formData = removeKeys(values, ['topic']) as IFormData
      formValues = formData
    }
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'addLesson',
        values: formValues,
        compareTo: {},
        isVideoUpdated,
      },
    })
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (file && file.type && constants.audio.includes(file.type)) {
      generateUploadAudioLink()
      setFileTypes('audio')
    }

    if (file && file.type && constants.video.includes(file.type)) {
      handleGenerateUploadLink()
      setFileTypes('video')
    }

    if (file && file.type && constants.file.includes(file.type)) {
      uploadFile(file, 'lesson/content', (link: string) =>
        setFieldValue('contentDocument', {
          name: file.name,
          fileType: file.name.split('.').slice(-1)[0],
          link,
        }),
      )
      setFileTypes('contentDocument')
    }
  }, [file])

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsVideoUpdated(true)
    const file = e.target?.files?.[0]
    if (file && constants.file.includes(file.type)) {
      setFile(file)
      return
    }

    const video = document.createElement('video')
    video.preload = 'metadata'

    video.onloadedmetadata = function (): void {
      window.URL.revokeObjectURL(video.src)
      const duration = video.duration

      setVideoDuration(Math.round(duration))
    }
    if (file) {
      video.src = URL.createObjectURL(file)
      setUploadVideoProgress(1)
      setFile(file)
    }
  }

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files } = e.target
    const file = files?.[0]
    if (!file) return
    const fileType = file.name.split('.').slice(-1)[0]
    if (field === 'subtitle') {
      uploadCaptions(file, `${uploadedVideoName}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          fileType,
          link,
        })
      })
    } else {
      uploadFile(file, `lesson/${field}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          fileType,
          link,
        })
      })
    }
  }

  const handleImageChange = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files } = e.target
    if (!files) return
    const file = files[0]
    const reader = new FileReader()
    reader.onload = (): void => {
      const img = new Image()
      img.src = reader.result as string
      uploadImage(reader.result as string, `lesson/${field}`, (link: string) =>
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').slice(-1)[0],
        }),
      )
    }
    reader.readAsDataURL(files[0])
  }

  const deleteContentFile = (): void => {
    setFile(null)
    setUploadVideoProgress(0)
    setFieldValue('video', null)
    setFieldValue('audio', null)
    setFieldValue('contentDocument', null)
    setFileTypes('video')
    setFieldValue('subtitle', null)
  }

  const fromTopic = !!(data && data.name)

  useEffect(() => {
    if (fromTopic) {
      setFieldValue('topic', { value: data.id, label: data.name })
    }
  }, [])

  const handleTopicChange = (e: ValueType<ITopicOption, false>): void => {
    if (e && !e.__isNew__) {
      setFieldValue('topic', e)
    }

    if (e && e.__isNew__) {
      addTopic(id, { name: e.value }, (topic) => {
        setFieldValue('topic', {
          value: topic.id,
          label: topic.name,
        })
        data.topicList = [...data.topicList, topic]
      })
    }
  }

  const submitDisabled = !(
    (values.video ||
      values.audio ||
      values.contentDocument ||
      createLessonLoading) &&
    !fileLoading
  )

  const fileExtension =
    fileTypes === 'video'
      ? (values.video &&
          values.video.links?.[2]?.type &&
          `(${values.video.links[2].type})`) ||
        ''
      : (values[fileTypes] &&
          `(${
            (
              values[fileTypes] as unknown as {
                fileType: string
              }
            ).fileType
          })`) ||
        ''

  const topicList =
    ((data.topicList &&
      data.topicList.map((i: ITopic) => ({
        value: i.id,
        label: i.name,
      }))) as ITopicOption[]) || ([] as ITopicOption[])

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    fileTypes,
    fileExtension,
    topicList,
    handleContentChange,
    handleFileChange,
    handleImageChange,
    deleteContentFile,
    handleTopicChange,
    submitDisabled,
    uploadVideoProgress,
    imageLoading,
    addTopicLoading,
    combinedConstants,
    t,
    handleCloseDrawer,
    fileLoading,
  }
}
