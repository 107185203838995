import { IConfigItem, ICourseItem } from './coursesAnalytics.interface'
import React from 'react'

import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'

export const getConfig = (t: (key: string) => string): IConfigItem[] => {
  return [
    {
      id: 'name',
      label: t('general.course_name'),
      render: (item: ICourseItem) => <span>{item.name}</span>,
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'status',
      label: t('general.status'),
      render: (item: ICourseItem) => (
        <StatusIndicator
          status={item.state?.toLocaleUpperCase() as GridStatusEnums}
        />
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'groups',
      label: t('general.groups'),
      render: (item: ICourseItem) => <span>{item.groupsCount || 0}</span>,
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'students',
      label: t('general.students'),
      render: (item: ICourseItem) => <span>{item.numberOfStudents || 0}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'skillTestVideos',
      label: t('general.stv'),
      render: (item: ICourseItem) => (
        <span>{item.numberOfSkillTestVideos || 0}</span>
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'notes',
      label: t('general.notes'),
      render: (item: ICourseItem) => <span>{item.numberOfNotes || 0}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'passedQuizzes',
      label: t('general.passedQuizzes'),
      render: (item: ICourseItem) => (
        <span>{item.passedQuizzesCount || 0}</span>
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'completedCourses',
      label: t('general.completedCourses'),
      render: (item: ICourseItem) => (
        <span>{item.studentsCountWhoCompletedCourse || 0}</span>
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'likes',
      label: t('general.likes'),
      render: (item: ICourseItem) => <span>{item.numberOfLikes || 0}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'comments',
      label: t('general.comments'),
      render: (item: ICourseItem) => <span>{item.numberOfComments || 0}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'posts',
      label: t('general.posts'),
      render: (item: ICourseItem) => <span>{item.numberOfPosts || 0}</span>,
      numeric: false,
      disablePadding: false,
    },
  ]
}
