import React, { ReactElement, useEffect } from 'react'
import {
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
} from 'draft-js-buttons'
import { ToolbarControls } from '../styled-components'
import { EditorState } from 'draft-js'

export interface IHeadlinesPickerProps {
  onOverrideContent: (content: JSX.Element | undefined) => void
  setEditorState: (editorState: EditorState) => void
  getEditorState: () => EditorState
}

const HeadlinesPicker = (props: IHeadlinesPickerProps): ReactElement => {
  const onWindowClick = (): void => props.onOverrideContent(undefined)

  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('click', onWindowClick)
    })

    return (): void => {
      window.removeEventListener('click', onWindowClick)
    }
  }, [])

  const buttons = [HeadlineOneButton, HeadlineTwoButton, HeadlineThreeButton]
  return (
    <ToolbarControls>
      {buttons.map((Button, i) => (
        <Button key={i} {...props} />
      ))}
    </ToolbarControls>
  )
}

export default HeadlinesPicker
