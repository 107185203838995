import React from 'react'
import { Container } from './styled-components'

import ExpansionItem from './ExpansionItem'
import ExpansionNavMenu from './ExpansionNavMenu'

import { getAllNavigation } from 'routerConfig'
import { checkRole } from 'helpers/checkRole'
import { useUserValue } from 'context/UserContext'
import { IExpansionItemProps } from './ExpansionItem.interface'
import { IUserCompany } from 'interfaces/users'

const NavigationBar: React.FC<IExpansionItemProps> = ({
  isOpened,
  currentUser,
}) => {
  const [state] = useUserValue()

  const role = checkRole(currentUser?.role as string, !!currentUser?.companyId)
  const routerData = getAllNavigation(
    state.userPermission,
    state.currentUser,
    state.selectedCompany as IUserCompany,
  )

  return (
    <Container $isOpened={isOpened as boolean}>
      {routerData.map((item, index) =>
        item.children ? (
          <ExpansionNavMenu
            item={item}
            index={index}
            key={index}
            isOpened={isOpened}
            role={role}
          />
        ) : (
          <ExpansionItem
            item={item}
            index={index}
            key={index}
            isOpened={isOpened}
            role={role}
          />
        ),
      )}
    </Container>
  )
}

export default NavigationBar
