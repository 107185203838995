import { useState } from 'react'
import addFlashCardService, {
  IAddFlashCardServiceParams,
} from 'services/modules/addFlashCardService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { IFlashCard } from './FlashCardsLayout/useModuleDetailContent.interface'

const useAddFlashCardService = (
  params: IAddFlashCardServiceParams,
): {
  addFlashCard: (
    moduleId: string,
    input: IFlashCard,
    onSuccess: () => void,
  ) => Promise<void>
  addFlashCardLoading: boolean
  error: string
} => {
  const { t } = useTranslation()
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { addFlashCardMutation } = addFlashCardService(params)

  const addFlashCard = async (
    moduleId: string,
    input: IFlashCard,
    onSuccess: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    const { errors } = await addFlashCardMutation({
      variables: {
        moduleId,
        input,
      },
    })

    if (errors) {
      message = errors[0].message
      if (message === 'User already registered') {
        message = `${t('general.user')} ${t('messages.already_registered')}`
        setError(message)
        return
      }
      variant = 'error'
    } else {
      onSuccess()
      message = `${t('general.flash_card')} ${t('messages.added')}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
    setTimeout(() => {
      setLoading(false)
    }, 300)
  }

  return {
    addFlashCard,
    addFlashCardLoading: loading,
    error,
  }
}

export default useAddFlashCardService
