import styled from 'styled-components'

export const Action = styled.div<{
  hoverColor?: string
  hoverBackground?: string
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  gap: 6px;
  transition: 0.3s ease;
  border-radius: 5px;
  &:hover {
    /* background: ${({ hoverBackground }): string =>
      hoverBackground || 'rgba(0, 0, 0, 0.1)'}; */
    ${({ hoverColor }): string =>
      (hoverColor &&
        `svg path {
        fill: ${hoverColor};
         }`) ||
      ''}
  }
  svg {
    font-size: 1rem;
    transition: 0.3s ease;
  }
  .MuiTypography-body1 {
    font-size: 0.875rem;
  }
`

export const Card = styled.div<{ isSelected: boolean }>`
  cursor: pointer;
  background: #fff;
  padding: 10px;
  border: 1px solid
    ${({ isSelected }): string =>
      isSelected ? '#06C68F' : 'rgba(0, 0, 0, 0.3)'};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Header = styled.header``

export const Type = styled.h2`
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 11px;
`

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
`

export const Text = styled.div`
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #ff3a52;
  /* margin-bottom: 10px; */
`

export const TagItem = styled.div`
  margin-bottom: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
`

export const Content = styled.section`
  font-size: 16px;
  padding: 20px 0;
  width: 100% !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #020210;
`

export const Question = styled.article``

export const FilesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #f50057;

  &:not(:last-child) {
    margin-right: 6px;
  }

  svg {
    width: 16px;
    margin-right: 2px;
  }
`
export const Edited = styled.div`
  display: flex;
  align-items: center;
  color: #7d7d7d;
  opacity: 0.7;
  text-transform: uppercase;
  font-size: 11px;
  margin-right: 50px;

  svg {
    margin-right: 5px;
  }
`

export const QuestionIcon = styled.span`
  display: inline-block;
  cursor: pointer;
  margin-left: 10px;
  svg {
    width: 30px;
    height: 30px;
  }
`

export const Footer = styled.footer`
  padding-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`

export const Modules = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  font-style: italic;

  .with-modules {
    svg {
      fill: #06c68f;
    }
  }
`

export const ModuleItemWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px 6px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  &:not(:last-child) {
    margin-right: 5px;
  }
  /* transition: color 0.3s ease, background 0.3s ease, border 0.3s ease; */

  &:hover {
    cursor: default;
  }

  &.disabled {
    opacity: 0.7;
    /* pointer-events: none; */
  }

  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    fill: #06c68f;
    transition: fill 0.2s ease;

    &:hover {
      fill: #f50057;
    }
  }
`

export const ModuleItem = styled.div``

export const WrapperStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
`

export const CheckBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`
export const RightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`

export const LayoutWrapper = styled.div`
  background-color: #fff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`
