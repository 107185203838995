import { useState } from 'react'
import {
  editCourseService,
  coursePublishedService,
  addCourseModulesService,
  deleteCourseModuleService,
  changeCourseStepService,
  toggleAIAssistantService,
  reTrainAIAssistantService,
} from 'services/course/editCourseService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import { useTranslation } from 'react-i18next'
import { ICourse, ICourseBriefData } from 'pages/courses/courseList.interface'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { INewCourse } from 'components/courseDetail/interfaces'

interface IUseEditCourseParams {
  courseId: string
}

export interface IUseAddCourseModulesParams {
  filter: IFilterQueryType
}

export const usePublishedCourse = (): {
  publishedCourse: (courseId: string[], action: string) => Promise<void>
} => {
  const { t } = useTranslation()
  const { coursePublishedMutation } = coursePublishedService()
  const { setSnackbar } = useSnackbarAlert()

  const publishedCourse = async (
    courseId: string[],
    action: string,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors } = await coursePublishedMutation({
      variables: {
        courseId,
        action,
      },
    })

    if (errors) {
      message = errors[0].message || t('messages.something_wrong')
      variant = 'error'
    } else {
      message = `${t('general.course')} ${t('messages.successfully')} ${t(
        `courses_layout.${action}`,
      )}`
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    publishedCourse,
  }
}

export const useEditCourse = (
  params: IUseEditCourseParams,
): {
  editCourse: (
    id: string,
    course: INewCourse,
    callback?: () => void,
  ) => Promise<void>
  editCourseLoading: boolean
  updatedCourse: ICourse | null
} => {
  const { t } = useTranslation()
  const [updatedCourse, setUpdatedCourse] = useState<ICourse | null>(null)
  const { editCourseMutation, loading } = editCourseService(params)
  const { setSnackbar } = useSnackbarAlert()

  const editCourse = async (
    id: string,
    course: INewCourse,
    callback?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { errors, data } = await editCourseMutation({
      variables: {
        id,
        input: course,
      },
    })

    if (errors) {
      message = t('messages.something_wrong')
      variant = 'error'
    } else if (data) {
      setUpdatedCourse(data.editCourse)
      message = t('messages.changes_saved')
      variant = 'success'
      if (callback) callback()
    }

    setSnackbar({ message, variant })
  }

  return {
    editCourse,
    editCourseLoading: loading,
    updatedCourse,
  }
}

export const useAddCourseModules = (
  params: IUseAddCourseModulesParams,
): {
  addCourseModules: (
    courseData: ICourseBriefData,
    moduleIds: string[],
    onSuccess: () => void,
  ) => Promise<void>
  addCourseModulesLoading: boolean
  newModule: IModule | null
} => {
  const { t } = useTranslation()
  const { addCourseModulesMutation, loading } = addCourseModulesService(params)
  const { setSnackbar } = useSnackbarAlert()
  const [newModule, setNewModule] = useState<IModule | null>(null)

  const addCourseModules = async (
    courseData: ICourseBriefData,
    moduleIds: string[],
    onSuccess: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await addCourseModulesMutation({
      variables: {
        courseData,
        moduleIds,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else if (data) {
      onSuccess()
      setNewModule(data.addModulesToCourse)
      message = t('messages.added_modules_to_courses')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    addCourseModules,
    addCourseModulesLoading: loading,
    newModule,
  }
}

export const useDeleteCourseModule = (
  params: IUseAddCourseModulesParams,
): {
  deleteCourseModule: (
    courseId: string,
    moduleIds: string[],
    onSuccess: () => void,
  ) => Promise<void>
  deleteCourseModuleLoading: boolean
  deletedModule: IModule | null
} => {
  const { t } = useTranslation()
  const { deleteCourseModuleMutation, loading } =
    deleteCourseModuleService(params)
  const { setSnackbar } = useSnackbarAlert()
  const [deletedModule, setDeletedModule] = useState<IModule | null>(null)

  const deleteCourseModule = async (
    courseId: string,
    moduleIds: string[],
    onSuccess: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    const { data, errors } = await deleteCourseModuleMutation({
      variables: {
        courseId,
        moduleIds,
      },
    })

    if (errors) {
      message = errors[0].message
      variant = 'error'
    } else if (data) {
      onSuccess()
      setDeletedModule(data.deleteModuleInCourse)
      message = t('messages.remove_modules_from_course')
      variant = 'success'
    }

    setSnackbar({ message, variant })
  }

  return {
    deleteCourseModule,
    deleteCourseModuleLoading: loading,
    deletedModule,
  }
}

export const useChangeCourseStep = (): {
  changeCourseStep: (
    courseId: string,
    step: number,
    callback: () => void,
  ) => Promise<void>
} => {
  const { changeCourseStepMutation } = changeCourseStepService()

  const changeCourseStep = async (
    courseId: string,
    step: number,
    callback: () => void,
  ): Promise<void> => {
    const { errors } = await changeCourseStepMutation({
      variables: { courseId, step },
    })

    if (errors && errors.length) {
    } else {
      callback()
    }
  }

  return {
    changeCourseStep,
  }
}

interface IToggleAIAssistantParams {
  courseId: string
  isAIAssistantEnabled: boolean
  loadingModalCb: () => void
  closeLoadingModalCb: () => void
  createdModalCb: () => void
}

export const useToggleAIAssistant = (): {
  toggleAIAssistant: (params: IToggleAIAssistantParams) => Promise<void>
} => {
  const { toggleAIAssistantMutation } = toggleAIAssistantService()
  const { setSnackbar } = useSnackbarAlert()

  const toggleAIAssistant = async ({
    courseId,
    isAIAssistantEnabled,
    loadingModalCb,
    closeLoadingModalCb,
    createdModalCb,
  }: IToggleAIAssistantParams): Promise<void> => {
    if (!isAIAssistantEnabled) loadingModalCb()

    const { data } = await toggleAIAssistantMutation({
      variables: { courseId, isAIAssistantEnabled },
    })

    if (!isAIAssistantEnabled) {
      closeLoadingModalCb()
      createdModalCb()
    }

    if (!data?.toggleAIAssistant) {
      setSnackbar({
        message: 'Something went wrong, please try again',
        variant: 'error',
      })
    }
  }

  return {
    toggleAIAssistant,
  }
}
export interface IReTrainAIAssistantReturnType {
  reTrainAIAssistant: (params: {
    assistantId: string
    loadingModalCb: () => void
    closeLoadingModalCb: () => void
    createdModalCb: () => void
  }) => Promise<void>
}

export const useReTrainAIAssistant = (): IReTrainAIAssistantReturnType => {
  const { reTrainAIAssistantMutation } = reTrainAIAssistantService()
  const { setSnackbar } = useSnackbarAlert()

  const reTrainAIAssistant = async ({
    assistantId,
    loadingModalCb,
    closeLoadingModalCb,
    createdModalCb,
  }: {
    assistantId: string
    loadingModalCb: () => void
    closeLoadingModalCb: () => void
    createdModalCb: () => void
  }): Promise<void> => {
    loadingModalCb()

    const { data } = await reTrainAIAssistantMutation({
      variables: { assistantId },
    })

    closeLoadingModalCb()
    createdModalCb()

    if (!data?.reTrainAIAssistant) {
      setSnackbar({
        message: 'Something went wrong, please try again',
        variant: 'error',
      })
    }
  }

  return {
    reTrainAIAssistant,
  }
}
