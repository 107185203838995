import { IFilterData } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { ITopic } from 'pages/modules/ModulesList.interface'

export const filterTopics = (
  filterOptions: IFilterData,
  topics: ITopic[],
): ITopic[] => {
  const filteredTopics = topics.filter((topic: ITopic) => {
    if (filterOptions.name && !topic?.name?.includes(filterOptions.name)) {
      return false
    }

    if (filterOptions.attachment) {
      if (filterOptions.attachment === 'true' && topic.attachment === null) {
        return false
      }

      if (filterOptions.attachment === 'false' && topic.attachment !== null) {
        return false
      }
    }

    if (filterOptions.numberOfLessons !== '0-0') {
      const from = parseInt(
        (filterOptions.numberOfLessons as string).split('-')[0],
      )
      const to = parseInt(
        (filterOptions.numberOfLessons as string).split('-')[1],
      )
      const topicLessonsLength = topic.lessons?.length || 0

      if (topicLessonsLength < from || topicLessonsLength > to) {
        return false
      }
    }

    return topic
  })

  return filteredTopics
}
