import React, {
  useState,
  useEffect,
  useRef,
  ReactElement,
  Dispatch,
  SetStateAction,
} from 'react'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_COACHES_BY_COMPANYID, GET_USERS } from 'gql/users.query'
import { useUserValue } from 'context/UserContext'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { FormItemContainer } from './styled-components'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Input } from 'components/common/TextInput'
import { GET_COMPANIES } from 'gql/companies.query'

import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'

import {
  FormContainer,
  FormGroupWrapper,
  FormGroup,
  FormFooter,
  FormButtons,
  FormGroupItem,
} from 'components/common/Form'
import { generateFullName } from 'utils/utils'

import { Button } from 'components/common/Button'
// import Select from 'components/common/Select'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import {
  courseState,
  courseLevels,
  coursePrivacy,
  filterOptions,
  videosOptions,
} from 'pages/courses/staticData'
import { useFormik } from 'formik'
import {
  FilterOptionsType,
  FormValuesType,
  SkillType,
} from './filterDrawer.interface'
import { TFunction } from 'interfaces/TFunction'
import { IGroupsFilterDrawer } from 'hooks/course/addCourseDrawer/addGroupInCourseDrawer/addGroupInCourseDrawer.interface'
import {
  LowerCaseFilterableFieldType,
  UpperCaseFilterableFieldType,
} from 'enums/filterEnum'

const GET_GROUPS = gql`
  query ($filter: GroupFilter) {
    getAllGroups(filter: $filter) {
      data {
        name
        id
      }
    }
  }
`
export const CourseFilterDrawerContext = React.createContext({
  companyId: '',
})

const FilterDrawer = (props: {
  t: TFunction
  onClose: () => void
  setCurrentPage: Dispatch<SetStateAction<number>>
  setFilterData: Dispatch<SetStateAction<IGroupsFilterDrawer>>
  refetchCourse: () => void
  filterData: IGroupsFilterDrawer
  resetFilter: () => void
  tabValue?: number
  isOnPurchasedCourses?: boolean
  isOnStudentProfile?: boolean
  companyId?: string
  coachIds?: string[]
  isCooursePrivacyHidden?: boolean
}): ReactElement => {
  const [state] = useUserValue()
  const ref = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [studentRange, setStudentRange] = useState([0, 300])
  const [filterData, setFilterData] = useState<FilterOptionsType>(
    filterOptions as FilterOptionsType,
  )

  const [fetchCoach, { data: coachData, loading: userLoading }] =
    useLazyQuery(GET_USERS)

  const [
    fetchCoachesByCompanyId,
    { data: coachesByCompanyIdData, loading: coachingLoading },
  ] = useLazyQuery(GET_COACHES_BY_COMPANYID)

  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)
  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUPS)

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)

  useEffect(() => {
    if (props?.filterData?.values) {
      setFilterData(props.filterData.values)
      setStudentRange(props.filterData.values.studentRange as number[])
    }
  }, [props.filterData])

  const onClearClick = (): void => {
    props.resetFilter()
    props.onClose()
  }

  const { handleSubmit, handleChange, values, setFieldValue } = useFormik({
    initialValues: props?.filterData?.values || filterData,

    onSubmit(values) {
      const formValues: FormValuesType = {}
      if (values.name !== '') {
        formValues.name = {
          type: UpperCaseFilterableFieldType.MATCH,
          value: values.name as string,
        }
      }

      if (values.state && values.state.value !== 'all') {
        formValues.state = {
          type: UpperCaseFilterableFieldType.EXACT,
          value: values.state.value.toUpperCase(),
        }
      }

      if (values.level && values.level.value !== 'all') {
        formValues.level = {
          type: UpperCaseFilterableFieldType.EXACT,
          value: values.level.value.toUpperCase(),
        }
      }

      if (values.video && values.video.value !== 'all') {
        formValues.video = {
          type: UpperCaseFilterableFieldType.EXISTS,
          value: values.video.value,
        }
      }

      if (values?.coaches && values.coaches.length > 0) {
        const selectedCoachesIds = values.coaches.map(
          (i: { value: string }) => i.value,
        )
        formValues.coaches = {
          type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: selectedCoachesIds,
          nestedField: 'USER_ID',
        }
      }

      if (values?.company?.value) {
        formValues.companyId = {
          type: UpperCaseFilterableFieldType.EXACT,
          value: values.company.value,
        }
      }

      if (values?.skills && values.skills.length > 0) {
        formValues.skills = {
          type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: values.skills.map((skill: { label: string }) => skill.label),
          nestedField: 'LABEL',
        }
      }
      if (values?.group && values.group.length > 0) {
        formValues.groups = {
          type: UpperCaseFilterableFieldType.NESTED_ARRAY_IN,
          value: values.group.map(
            (group: { value: string; label: string }) => group.value,
          ),
          nestedField: 'GROUP_ID',
        }
      }

      props.setCurrentPage(1)
      props.setFilterData({
        filterOptions: { ...formValues },
        values: { ...values, studentRange },
      })
      props.refetchCourse()
      props.onClose()
    },
  })

  const loadDataOptions = (e: string, field: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e

      if (field === 'coach') {
        let filter: {
          search: { type: string; value: string; fields: string[] }
          _id?: { type: string; value: string[] }
          companyId?: { type: string; value?: string | null }
        } = {
          search: {
            type: LowerCaseFilterableFieldType.SEARCH,
            value,
            fields: ['firstName', 'lastName'],
          },
        }
        if (
          props.isOnPurchasedCourses &&
          props.coachIds &&
          props.coachIds.length > 0
        ) {
          filter = {
            ...filter,
            _id: {
              type: LowerCaseFilterableFieldType.ARRAY_IN,
              value: props.coachIds,
            },
            companyId: {
              type: LowerCaseFilterableFieldType.EXACT,
              value: null,
            },
          }
        } else
          filter = {
            ...filter,
            companyId: {
              type:
                props.tabValue == 1
                  ? LowerCaseFilterableFieldType.NOTEQUAL
                  : LowerCaseFilterableFieldType.EXACT,
              value:
                props.isOnStudentProfile && props.companyId
                  ? props.companyId
                  : props.tabValue == 1
                  ? null
                  : state.selectedCompany?.id,
            },
          }
        if (props.companyId && props.isOnStudentProfile)
          fetchCoachesByCompanyId({ variables: { companyId: props.companyId } })
        else
          fetchCoach({
            variables: {
              role: 'COACH',
              filter: {
                ...filter,
              },
            },
          })
      }

      if (field === 'company') {
        fetchCompany({
          variables: {
            filter: {
              name: {
                type: UpperCaseFilterableFieldType.MATCH,
                value,
              },
            },
          },
        })
      }

      if (field === 'group') {
        const filter = {
          name: {
            type: LowerCaseFilterableFieldType.MATCH,
            value,
          },
          company: {
            type:
              !!props.companyId && !props.isOnStudentProfile
                ? LowerCaseFilterableFieldType.NOTEQUAL
                : props.tabValue == 1
                ? LowerCaseFilterableFieldType.NOTEQUAL
                : LowerCaseFilterableFieldType.EXACT,
            value:
              props.companyId ||
              (props.tabValue == 1 ? null : state.selectedCompany?.id),
          },
        }

        fetchGroup({
          variables: {
            filter: {
              ...filter,
            },
            perPage: 0,
          },
        })
      }
    }, 200)

  const handleSelectChange = (e: string, field: string): void => {
    if (ref.current !== null) {
      clearTimeout(ref.current)
    }
    if (e) {
      ref.current = loadDataOptions(e, field)
    }
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: SkillType) => ({
      label: i.label,
      id: i.id,
      value: i.id,
    }))

  const { t } = props

  return (
    <DrawerContent>
      <DrawerHeader>
        <DrawerTitle>
          {t('general.advanced_filters')} - {t('actions.to_filter')}{' '}
          {t('general.courses')}
        </DrawerTitle>
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <FormContainer>
        <FormGroupWrapper>
          <FormGroup onSubmit={handleSubmit}>
            <FormGroupItem>
              <Title>{`${t('general.course')} ${t('general.name')}`}</Title>
              <Input
                placeholder={`${t('general.course')} ${t('general.name')}`}
                name="name"
                size="small"
                type="text"
                fullWidth
                value={values.name}
                onChange={handleChange}
              />
            </FormGroupItem>
            {!props.isOnPurchasedCourses && (
              <FormGroupItem>
                <FormItemContainer>
                  <Title>
                    {t('courses_layout.published')}/
                    {t('courses_layout.unpublished')}
                  </Title>
                  <SelectField
                    options={courseState}
                    value={values.state}
                    onChange={(e: { value: string; label: string }): void => {
                      setFieldValue('state', e)
                    }}
                  />
                </FormItemContainer>
              </FormGroupItem>
            )}

            <FormGroupItem>
              <FormItemContainer>
                <Title>
                  {t('general.course')} {t('course_details.level')}
                </Title>
                <SelectField
                  options={courseLevels}
                  value={values.level}
                  onChange={(e: { value: string; label: string }): void => {
                    setFieldValue('level', e)
                  }}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>Intro Video</Title>
                <SelectField
                  onChange={(e: { value: string; label: string }): void => {
                    setFieldValue('video', e)
                  }}
                  options={videosOptions}
                  value={values.video}
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('general.coach')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void =>
                    handleSelectChange(e, 'coach')
                  }
                  onChange={(e: { value: string; label: string }): void => {
                    setFieldValue('coaches', e)
                  }}
                  data={
                    props.isOnStudentProfile &&
                    coachesByCompanyIdData?.getCoachesByCompany
                      ? coachesByCompanyIdData.getCoachesByCompany
                      : (coachData && coachData.usersByRole.data) || []
                  }
                  value={values.coaches}
                  label={t('form_fields.type_to_search_coaches')}
                  loading={
                    props.isOnStudentProfile ? coachingLoading : userLoading
                  }
                  labelDataName={generateFullName}
                  valueDataKey="id"
                />
              </FormItemContainer>
            </FormGroupItem>

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('general.skills')}</Title>
                <SelectField
                  isMulti
                  value={values.skills}
                  placeholder={t('form_fields.select')}
                  options={skillList}
                  onChange={(e: { value: string; label: string }): void => {
                    setFieldValue('skills', e)
                  }}
                />
              </FormItemContainer>
            </FormGroupItem>

            {!props.isCooursePrivacyHidden && (
              <FormGroupItem>
                <FormItemContainer>
                  <Title>
                    {t('general.course')} {t('course_details.privacy')}
                  </Title>
                  <SelectField
                    label={t('course_details.privacy')}
                    options={coursePrivacy}
                    value={values.coursePrivacy}
                    onChange={(e: { value: string; label: string }): void => {
                      setFieldValue('coursePrivacy', e)
                    }}
                  />
                </FormItemContainer>
              </FormGroupItem>
            )}

            {props.tabValue === 1 && (
              <FormGroupItem>
                <FormItemContainer>
                  <Title>{t('general.company')}</Title>
                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string): void =>
                      handleSelectChange(e, 'company')
                    }
                    onChange={(e: { value: string; label: string }): void => {
                      setFieldValue('company', e)
                    }}
                    data={(companyData && companyData.companies.data) || []}
                    value={values.company}
                    label={t('form_fields.type_to_search_companies')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                  />
                </FormItemContainer>
              </FormGroupItem>
            )}

            <FormGroupItem>
              <FormItemContainer>
                <Title>{t('general.groups')}</Title>
                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void =>
                    handleSelectChange(e, 'group')
                  }
                  onChange={(e: { value: string; label: string }): void => {
                    setFieldValue('group', e)
                  }}
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_groups')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormItemContainer>
            </FormGroupItem>
          </FormGroup>
        </FormGroupWrapper>

        <FormFooter>
          <FormButtons>
            <Button
              text={t('actions.filter')}
              type="small"
              color="secondary"
              background="#06C68F"
              onClick={handleSubmit}
            />
            <Button
              text={t('actions.clear')}
              type="small"
              onClick={onClearClick}
              background="#E0E1E2"
              textColor="#414141"
            />
          </FormButtons>
        </FormFooter>
      </FormContainer>
    </DrawerContent>
  )
}

export default FilterDrawer
