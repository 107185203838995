import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { IColumnProps, IRowProps } from './detailsLayout.interface'

export const Row = (props: IRowProps): ReactElement => (
  <FlexRow {...props}>{props.children}</FlexRow>
)

export const Column = (props: IColumnProps): ReactElement => (
  <FlexColumn {...props}>{props.children}</FlexColumn>
)

export const FlexRow = styled.div<{ direction?: string }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }): string => (direction ? direction : 'row')};
`
export const FlexColumn = styled.div<{ flex?: number; centered?: boolean }>`
  flex: ${({ flex }): number => (flex ? flex : 1)};
  text-align: ${({ centered }): string => (centered ? 'center' : 'inherit')};
`

export const Image = styled.figure<{
  width: number | undefined
  height: number | undefined
  borderRadius: number | undefined
}>`
  overflow: hidden;
  width: ${({ width }): string => (width ? width + 'px' : '100%')};
  height: ${({ height }): string => (height ? height + 'px' : '100%')};
  border-radius: ${({ borderRadius }): string =>
    borderRadius ? borderRadius + 'px' : ''};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const DetailsContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const DetailsContentLeft = styled.div`
  width: calc(100% - 360px);
  max-width: 1400px;
  min-height: 468px;
`

export const DetailsContentRight = styled.div`
  padding-top: 20px;
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.noVideo {
    // position: absolute;
    // right: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
  }

  &.textRight {
    text-align: right;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 278px;
  > div:not(:last-child) {
    margin-right: 10px;
  }
`

export const DetailsContentHeader = styled.header`
  display: flex;
`

export const DetailsContentHeaderSection = styled.section`
  margin-top: 20px;
  font-size: 16px;
  width: 100%;
  article {
    margin-top: 6px;
  }
`

export const DetailsTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;

  div > div:not(:last-child) {
    margin-right: 10px;
  }
`
export const DetailsContentDescription = styled.article`
  position: relative;
  font-size: 16px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 50%;
`

export const DetailsContentSkillsWrapper = styled.div`
  width: 40%;
`
export const DetailsContentSkilsTitle = styled.div`
  font-size: 1.2rem;
  position: relative;
  margin-bottom: 25px;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    left: 0;
    transform: translateY(-50%);
    width: 144px;
    height: 2px;
    border-radius: 20px;
    background-color: rgba(2, 191, 131, 0.5);
  }
`

export const DetailsContentSkilsList = styled.ul`
  display: flex;
  padding-left: 0;
  flex-wrap: wrap;
`
export const DetailsContentSkillItem = styled.li`
  padding: 8px;
  border: 1px solid #eee;
  white-space: nowrap;
  border-radius: 16px;
  background: #f9f9f9;
  font-weight: 300;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.07);
  margin-top: 5px;

  &:not(:last-child) {
    margin-right: 8px;
  }
`
export const DetailsContentReview = styled.div`
  padding: 20px 0 0 20px;
  display: flex;
  align-items: center;
`
export const DetailsContentReviewList = styled.div`
  font-size: 12px;
  margin-left: 10px;

  a:hover {
    text-decoration: underline;
  }
`

export const DetailsContentCoachWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100%;
  }
  &:not(:last-child) {
    margin-right: 30px;
  }

  > div:hover ul {
    opacity: 1;
  }

  ul {
    background: #fff;
    pointer-events: none;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    width: 150px;
    top: 0;
    left: 105px;
    opacity: 0;
    transition: opacity 0.2s ease;

    &:before {
      content: '';
      position: absolute;
      left: -6px;
      top: 10px;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid #fff;
    }
  }
`

export const DetailsContentCoachInfo = styled.section`
  font-size: 12px;
`

export const DetailsContentCoachTitle = styled.h4`
  font-size: 14px;
  font-weight: 500;
`

export const CertificateContainer = styled.div`
  margin-top: 15px;
  border-radius: 5px;
  /* background: #fff;
  border: 1px solid #ccc; */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
`

export const CertificateContent = styled.div`
  margin-top: 10px;
`

export const DetailsContentVideoCover = styled.figure<{ noBefore?: boolean }>`
  position: relative;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ noBefore }): string => (noBefore ? '100%' : 'unset')};
  height: ${({ noBefore }): string => (noBefore ? '250px' : 'unset')};
  background: ${({ noBefore }): string =>
    noBefore ? 'rgba(8, 82, 143, 0.8);' : 'none'};
  z-index: ${({ noBefore }): number => (noBefore ? -1 : 1)};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    display: ${({ noBefore }): string => (noBefore ? 'none' : 'block')};
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }

  .MuiCircularProgress-colorPrimary {
    color: #dbdbdb;
  }
`

export const VideoProcessingWrapper = styled.div`
  text-align: center;
  color: #eee;
  font-size: 16px;

  article {
    margin-top: 5px;
  }
`

export const DetailsContentPlayIcon = styled.div`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    fill: #fff;
    font-size: 3rem;
    transition: fill 0.2s ease;
  }

  &:hover svg {
    fill: #ff3a52;
  }
`
export const DetailsListContainer = styled.div`
  width: 280px;
  height: 408px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const DetailsListHeader = styled.div`
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
`

export const DetailsContentCoachFullName = styled.h4`
  font-weight: 300;
  font-size: 14px;
`

export const DetailsList = styled.ul`
  padding: 20px 30px;
  width: 280px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 5px;
  // margin-top: 24px;
`

export const DetailsListItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 13px 0;
  div {
    display: flex;
    flex-direction: column;
    article {
      letter-spacing: 0px;
      color: #707070;
      font-size: 14px;
    }
  }
  strong {
    margin-right: 5px;
    font-size: 16px;
  }
  svg {
    color: #06c68f;
    margin-right: 11px;
  }
  span {
    color: #06c68f;
    margin-right: 11px;
  }
`
export const GroupsWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const GroupsItem = styled.div`
  display: inline-block;
  padding: 0 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #333;

  &:not(:last-child) {
    margin-right: 5px;
  }
`
export const LeftAndRightSideContainter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 60px;
`
