import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSwal } from 'hooks/useSwal'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { useData } from 'context/DataContext'

import { FormikErrors, FormikHandlers, FormikTouched, useFormik } from 'formik'
import { GroupSchema } from 'helpers/validationSchemas'
import { checkValues } from 'pages/group/checkGroupsValues'
import { SET_CREATED_USER } from 'store/types'
import useEditGroup from '../useEditGroup'
import { GET_GROUP_BY_ID } from 'gql/group/group.query'
import { useQuery } from '@apollo/client'
import { generateFullName } from 'utils/utils'
import { User } from '@sentry/react'
import { ICourse, ICourseBriefData } from 'pages/courses/courseList.interface'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import {
  IGroupFormFormValues,
  IGroupsSelectOption,
} from '../useGroupForm/useGroupForm.interface'
import {
  IEditGroupDrawerProps,
  IGroupDrawerFormData,
} from './useGroupDrawer.interface'
import { initialFormData } from './useGroupDrawer'

const useEditGroupDrawer = ({
  id,
  companyId,
  courseData,
  onClose,
  afterSave,
}: IEditGroupDrawerProps): {
  values: IGroupFormFormValues
  errors: FormikErrors<IGroupFormFormValues>
  touched: FormikTouched<IGroupFormFormValues>
  handleChange: FormikHandlers['handleChange']
  handleSubmit: FormikHandlers['handleSubmit']
  setFieldValue: (field: string, value: unknown) => void
  loading: boolean
  handleCloseDrawer: () => void
} => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()

  const { editGroup, loading } = useEditGroup({
    filter: {
      company: {
        type: LowerCaseFilterableFieldType.EXACT,
        value: companyId,
      },
    },
    currentPage: 1,
    perPage: 10,
  })

  const [dataState, dataDispatch] = useData()

  useEffect(() => {
    if (
      dataState.createdObject.obj &&
      dataState.createdObject.type === 'admin'
    ) {
      const adminOption = {
        value: dataState.createdObject.obj.id,
        label: `${dataState.createdObject.obj.firstName} ${dataState.createdObject.obj.lastName}`,
      }

      setFieldValue(
        'admin',
        values.admin
          ? [...(values.admin as IGroupsSelectOption[]), adminOption]
          : [adminOption],
      )

      dataDispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const { data } = useQuery(GET_GROUP_BY_ID, {
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (data && data.getGroupById) {
      setValues({
        name: data.getGroupById.name,
        location: data.getGroupById.location,
        note: data.getGroupById.note,
        isNewsFeedAvailable: data.getGroupById.isNewsFeedAvailable,
        admin: data.getGroupById.admin
          ? data.getGroupById.admin.map((i: User) => ({
              label: generateFullName(i),
              value: i.id,
            }))
          : null,
        courses:
          data.getGroupById.courses?.map((i: ICourse) => ({
            label: i.name,
            value: i.courseId,
          })) || [],
      })
    }
  }, [data])

  const groupData = (data && data.getGroupById) || null

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialFormData,
    validationSchema: GroupSchema,
    onSubmit: async (values: IGroupFormFormValues) => {
      const data = { ...values }

      if (data.admin) {
        data.admin = data.admin.map(
          (i: ISelectDropDownOption) => i.value,
        ) as string[]
      }

      if (courseData) data.courses = courseData
      else
        data.courses =
          (values.courses?.map((i) => ({
            courseId: (i as ISelectDropDownOption).value,
            name: (i as ISelectDropDownOption).label,
          })) as ICourseBriefData[]) || ([] as ICourseBriefData[])

      await editGroup(data as IGroupDrawerFormData, groupData.id, () => {
        if (afterSave) afterSave()
      })

      onClose()
    },
  })

  const handleCloseDrawer = (): void => {
    const isEmpty = checkValues(values)

    if (isEmpty) {
      onClose()
    } else {
      fireSwal({
        title: t('popups.close_popup'),
        text: t('popups.sure'),
        onConfirm: () => {
          onClose()
        },
        onClose: () => undefined,
        confirmText: t('popups.confirm_cancel'),
        cancelText: t('general.no'),
      })
    }
  }
  return {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    loading,
    handleCloseDrawer,
  }
}

export default useEditGroupDrawer
