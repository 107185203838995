import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikErrors, useFormik } from 'formik'
import { Button } from 'components/common/Button'
import {
  TemplateFieldContainer,
  FormFieldStyles,
  MarginNormalizer,
  NotificationContent,
  StyledLabel,
  SubmitContainer,
  Wrapper,
} from './styled-components'
import { FormField, FormGroupItem } from 'components/common/Form'
import { InputV2 } from 'components/common/TextInput'
import { FormControlLabel, Switch } from '@mui/material'
import Checkbox from 'components/common/Checkbox'
import { Send } from '@mui/icons-material'
import { AsyncSelect } from 'components/common/SelectField'
import { GET_COMPANIES } from 'gql/companies.query'
import { useLazyQuery } from '@apollo/client'
import {
  initialValues,
  templates,
  roles,
  CustomNotificationTemplates,
  CustomNotificationDefaultTexts,
  CustomNotificationFields,
} from './content'
import {
  ICustomNotification,
  useSendCustomNotification,
} from 'hooks/notifications/useNotifications'
import { CustomNotificationSchema } from 'helpers/validationSchemas'
import {
  CustomNotificationFormValues,
  ISelectOption,
} from './customNotification.interface'

const CustomNotifications = (): React.JSX.Element => {
  const { t } = useTranslation()
  const [companyOptions, setCompanyOptions] = useState([])

  const { sendCustomNotification, loading } = useSendCustomNotification()
  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: CustomNotificationSchema,
      async onSubmit(values: CustomNotificationFormValues) {
        if (!values.sendAsEmail && !values.sendAsPushNotification) {
          errors.sendAsPushNotification = t('notifications.select_at_least_one')
          return
        }
        const payload: ICustomNotification = {
          subject: values.subject,
          body: values.body,
          sendAsEmail: values.sendAsEmail,
          sendAsPushNotification: values.sendAsPushNotification,
        }

        if (values.sendToAllCompanies) {
          payload.companyIds = []
        }

        if (values.sendToAllRoles) {
          payload.roles = []
        } else {
          payload.roles = values.roles.map((role: ISelectOption) => role.value)
        }

        if (values.companies) {
          payload.companyIds = values.companies.map(
            (company: ISelectOption) => company.value,
          )
        }

        sendCustomNotification(payload)
      },
    })

  const [fetchCompany, { data: companyData, loading: companyLoading }] =
    useLazyQuery(GET_COMPANIES)

  const handleSendToAllCompaniesChange = (e: boolean): void => {
    setFieldValue(CustomNotificationFields.SEND_TO_ALL_COMPANIES, e)
    if (!e && !companyData) {
      fetchCompany({
        variables: {
          perPage: 0,
        },
      })
    }
  }

  useEffect(() => {
    if (companyData) {
      setCompanyOptions(companyData.companies.data)
    }
  }, [companyData])

  const handleTemplateChange = (template: ISelectOption): void => {
    setFieldValue(CustomNotificationFields.TEMPLATE, template)

    let subjectValue = ''
    let bodyValue = ''

    if (template.value === CustomNotificationTemplates.MAINTENANCE) {
      subjectValue = CustomNotificationDefaultTexts.MAINTENANCE_SUBJECT
      bodyValue = CustomNotificationDefaultTexts.MAINTENANCE_BODY
    }

    setFieldValue(CustomNotificationFields.SUBJECT, subjectValue)
    setFieldValue(CustomNotificationFields.BODY, bodyValue)
  }

  return (
    <Wrapper>
      <h1>{t('notifications_layout.title')}</h1>
      <TemplateFieldContainer>
        <StyledLabel>{t('notifications_layout.template')}</StyledLabel>
        <AsyncSelect
          onChange={handleTemplateChange}
          error={errors.template as string}
          errorMessage={errors.template as string}
          data={templates}
          value={values.template}
          labelDataKey="label"
          valueDataKey="value"
          label={t('notifications_layout.template')}
        />
      </TemplateFieldContainer>
      <NotificationContent>
        <FormGroupItem style={MarginNormalizer}>
          <InputV2
            error={!!errors.subject}
            errorMessage={errors.subject ? errors.subject : ''}
            touched={touched.subject}
            label={`${t('notifications_layout.subject')}*`}
            name="subject"
            size="small"
            type="text"
            fullWidth
            value={values.subject}
            onChange={handleChange}
          />
        </FormGroupItem>
        <FormGroupItem style={MarginNormalizer}>
          <InputV2
            error={!!errors.body}
            errorMessage={errors.body ? errors.body : ''}
            touched={touched.body}
            label={`${t('notifications_layout.body')}*`}
            name="body"
            size="small"
            type="text"
            rows={4}
            fullWidth
            value={values.body}
            onChange={handleChange}
          />
        </FormGroupItem>
        <FormField style={FormFieldStyles}>
          <FormControlLabel
            control={
              <Checkbox
                error={errors.sendAsPushNotification}
                checked={values.sendAsPushNotification}
                onChange={():
                  | Promise<void>
                  | Promise<FormikErrors<CustomNotificationFormValues>> =>
                  setFieldValue(
                    CustomNotificationFields.SEND_AS_PUSH_NOTIFICATION,
                    !values.sendAsPushNotification,
                  )
                }
                name="sendAsEmail"
                color="primary"
              />
            }
            label={t('notifications_layout.send_as_push_notification')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.sendAsEmail}
                onChange={():
                  | Promise<void>
                  | Promise<FormikErrors<CustomNotificationFormValues>> =>
                  setFieldValue(
                    CustomNotificationFields.SEND_AS_EMAIL,
                    !values.sendAsEmail,
                  )
                }
                name="sendAsEmail"
                color="primary"
              />
            }
            label={t('notifications_layout.send_as_email')}
          />
        </FormField>
        <FormControlLabel
          style={{ marginBottom: 10 }}
          control={
            <Switch
              checked={values.sendToAllCompanies}
              onChange={(): void =>
                handleSendToAllCompaniesChange(!values.sendToAllCompanies)
              }
              value="sendToAllCompanies"
              style={{ color: '#06C68F' }}
            />
          }
          label={t('notifications_layout.include_all_companies')}
        />
        <FormField>
          <AsyncSelect
            error={errors.companies as string}
            errorMessage={errors.companies as string}
            isMulti
            onChange={(e: ISelectOption): void => {
              setFieldValue(CustomNotificationFields.COMPANIES, e)
            }}
            loading={companyLoading}
            disabled={values.sendToAllCompanies}
            data={companyOptions}
            value={values.companies}
            labelDataKey="name"
            valueDataKey="id"
            label={t('notifications_layout.companies')}
          />
        </FormField>
        <FormControlLabel
          style={{ marginBottom: 10 }}
          control={
            <Switch
              checked={values.sendToAllRoles}
              onChange={():
                | Promise<void>
                | Promise<FormikErrors<CustomNotificationFormValues>> =>
                setFieldValue(
                  CustomNotificationFields.SEND_TO_ALL_ROLES,
                  !values.sendToAllRoles,
                )
              }
              value="sendToAllRoles"
              style={{ color: '#06C68F' }}
            />
          }
          label={t('notifications_layout.include_all_roles')}
        />
        <FormField>
          <AsyncSelect
            isMulti
            onChange={(e: ISelectOption): void => {
              setFieldValue('roles', e)
            }}
            error={errors.roles as string}
            errorMessage={errors.roles as string}
            disabled={values.sendToAllRoles}
            data={roles}
            value={values.roles}
            labelDataKey="label"
            valueDataKey="value"
            label={t('notifications_layout.roles')}
          />
        </FormField>
        <SubmitContainer>
          <Button
            text={t('actions.send')}
            color="secondary"
            isDisabled={loading}
            onClick={handleSubmit}
            icon={<Send />}
            background="#06C68F"
            iconPosition="right"
          />
        </SubmitContainer>
      </NotificationContent>
    </Wrapper>
  )
}

export default CustomNotifications
