import { NoValueWrapper } from 'components/common/Grid/styled-components'
import {
  GridItemWrapper,
  StyledScoreSpan,
} from 'pages/courses/CourseQuizzes/styled.components'
import React from 'react'
import { IGridConfig, IGridItem } from './testsAnalytics.interface'

export const testConfig = (t: (key: string) => string): IGridConfig[] => {
  const grid = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.test_name')}`,
      render: (item: IGridItem) => (
        <GridItemWrapper>
          {item.name || <NoValueWrapper>N/A</NoValueWrapper>}
        </GridItemWrapper>
      ),
    },
    {
      id: 'score',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.test_result')}`,
      render: (item: IGridItem) => (
        <GridItemWrapper>
          {item.score === null ? (
            <NoValueWrapper>N/A</NoValueWrapper>
          ) : (
            <StyledScoreSpan passed={item.passed}>
              {item.score}%
            </StyledScoreSpan>
          )}
        </GridItemWrapper>
      ),
    },
  ]

  return grid.filter((i: IGridConfig) => !i.hide)
}
