import { NoValueWrapper } from 'components/common/Grid/styled-components'
import React from 'react'
import { GridITemWrapper } from '../groups/styled-components'
import {
  StyledSpan,
  StyledScoreSpan,
} from 'pages/courses/CourseQuizzes/styled.components'
import { TFunction } from 'interfaces/TFunction'
import {
  Answer,
  AnswersMapAccumulator,
  IQuestion,
  IQuiz,
} from './gridConfig.interface'

interface GridColumnConfig {
  id: string
  numeric: boolean
  disablePadding: boolean
  label: string
  render: (item: IQuiz) => JSX.Element
}

interface CollapseGridColumnConfig {
  id: string
  numeric: boolean
  disablePadding: boolean
  label: string
  render: (item: IQuestion) => JSX.Element
}

interface CollapsedData {
  question: string
  answer: string | boolean
  isCorrect: boolean
}

export const columnConfig = (
  t: TFunction<'translation', undefined>,
): GridColumnConfig[] => {
  const grid: GridColumnConfig[] = [
    {
      id: 'module',
      numeric: false,
      disablePadding: true,
      label: `${t('general.module')}`,
      render: (item: IQuiz): JSX.Element => (
        <GridITemWrapper>
          {item.module.name || <NoValueWrapper>N/A</NoValueWrapper>}
        </GridITemWrapper>
      ),
    },

    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.quiz_name')}`,
      render: (item: IQuiz): JSX.Element => (
        <GridITemWrapper>
          {item.name || <NoValueWrapper>N/A</NoValueWrapper>}
        </GridITemWrapper>
      ),
    },

    {
      id: 'score',
      numeric: false,
      disablePadding: false,
      label: `${t('user_tabs.quiz_result')}`,
      render: (item: IQuiz): JSX.Element => (
        <GridITemWrapper>
          <StyledScoreSpan passed={item.passed}>{item.score}%</StyledScoreSpan>
        </GridITemWrapper>
      ),
    },
  ]

  return grid
}

export const collapseColumnConfig = (
  t: TFunction<'translation', undefined>,
): CollapseGridColumnConfig[] => {
  const grid: CollapseGridColumnConfig[] = [
    {
      id: 'question',
      numeric: false,
      disablePadding: false,
      label: `${t('general.question')}`,
      render: (item: IQuestion): JSX.Element => (
        <GridITemWrapper>
          {item.question || <NoValueWrapper>N/A</NoValueWrapper>}
        </GridITemWrapper>
      ),
    },

    {
      id: 'answer',
      numeric: false,
      disablePadding: false,
      label: `${t('question_details.answer')}`,
      render: (item: IQuestion): JSX.Element => (
        <GridITemWrapper>
          <StyledSpan passed={item.isCorrect}>{`${item.answer}`}</StyledSpan>
        </GridITemWrapper>
      ),
    },
  ]

  return grid
}

export const getCollapsedData = (row: IQuiz): CollapsedData[] => {
  if (!row || !row.questionList) return []
  return row.questionList.map((question) => {
    let answer

    if (question.userAnswer.boolAnswer !== null) {
      answer = question.userAnswer.boolAnswer
    } else {
      const answersMap = question.answers.reduce(
        (acc: AnswersMapAccumulator, a: Answer) => {
          acc[a.id] = a.value
          return acc
        },
        {},
      )

      answer = question.userAnswer.answers
        .map((a: string) => answersMap[a])
        .join(', ')
    }

    return {
      question: question.question,
      answer,
      isCorrect: question.userAnswer.isCorrect,
    }
  })
}
