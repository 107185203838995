import { ApolloError, useMutation } from '@apollo/client'

import { DELETE_TASK } from 'gql/tasks.query'
import useSnackbarAlert from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'

interface IUseDeleteTaskServiceResponse {
  deleteTask: (ids: string[]) => Promise<void>
  deleting: boolean
  deleteError?: ApolloError
}

interface IDeleteTaskMutationResponse {
  deleteTasks: string
}

interface IDeleteTaskMutationParams {
  ids: string[]
}

export const useDeleteTask = (): IUseDeleteTaskServiceResponse => {
  const [deleteTasksMutation, { loading: deleting, error: deleteError }] =
    useMutation<IDeleteTaskMutationResponse, IDeleteTaskMutationParams>(
      DELETE_TASK,
    )

  const { setSnackbar } = useSnackbarAlert()
  const { t } = useTranslation()

  const deleteTask = async (ids: string[]): Promise<void> => {
    try {
      await deleteTasksMutation({ variables: { ids } })
      setSnackbar({
        message: t('tasks_layout.delete_success'),
        variant: 'success',
      })
    } catch (error) {
      setSnackbar({ message: t('tasks_layout.delete_error'), variant: 'error' })
    }
  }

  return {
    deleteTask,
    deleting,
    deleteError,
  }
}
