import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { ADD_TEST } from 'gql/tests/tests.query'
import { IAddTestDto } from 'hooks/tests/useAddTestService'
import { IQuiz } from 'pages/quizzes/quiz.interface'

interface IAddTestServiceResponse {
  addTestMutation: MutationTuple<IAddTestResponse, IAddTestParams>[0]
  loading: boolean
  error?: ApolloError
}

interface IAddTestResponse {
  createTestAssessment: IQuiz
}

interface IAddTestParams {
  companyId: string
  input: IAddTestDto
}

const addTestService = (): IAddTestServiceResponse => {
  const [addTestMutation, { loading, error }] = useMutation<
    IAddTestResponse,
    IAddTestParams
  >(ADD_TEST)

  return {
    addTestMutation,
    loading,
    error,
  }
}

export default addTestService
