import { OperationVariables, useQuery } from '@apollo/client'

import { GET_SKILL_TEST_VIDEOS_TEMPLATES } from 'gql/skillTestVideos.query'
import { ISkillTestVideo } from 'pages/modules/ModuleDetail/SkillTestVideos/SkillTestVideos.interface'

export const useFetchSkillTestVideosTemplates = (
  moduleId: string,
): {
  skillTestVideosTemplates: ISkillTestVideo[]
  loading: boolean
  error?: string
  refetch: (variables?: OperationVariables) => void
} => {
  const { data, loading, error, refetch } = useQuery(
    GET_SKILL_TEST_VIDEOS_TEMPLATES,
    {
      variables: { moduleId },
    },
  )

  const errorMessage = error && error?.message.split(':')[1]

  return {
    skillTestVideosTemplates: data && data.getSkillTestVideosTemplates,
    loading,
    error: errorMessage,
    refetch,
  }
}
