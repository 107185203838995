import gql from 'graphql-tag'

export const CREATE_LESSON = gql`
  mutation CreateLesson($topicId: String, $input: LessonInput) {
    createLesson(topicId: $topicId, input: $input) {
      id
      name
      description
      editorText
      attachment {
        name
        fileType
        link
      }
      lessons {
        name
        id
        subtitle {
          name
          fileType
          link
        }
        video {
          id
          name
          thumbnail
          duration
          title
          links {
            url
            fileType
            converted
          }
        }
        attachment {
          name
          fileType
          link
        }
        description
        transcript
        duration
        audio {
          name
          duration
          link
          fileType
        }
        contentDocument {
          name
          fileType
          link
          thumbnail
        }
        transcriptFile {
          name
          fileType
          link
        }
      }
    }
  }
`

export const GET_LESSONS = gql`
  query GetLessons($topicId: String) {
    getLessons(topicId: $topicId) {
      name
      id
      subtitle {
        name
        fileType
        link
      }
      video {
        id
        name
        thumbnail
        duration
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      description
      transcript
      duration
      audio {
        name
        duration
        link
        fileType
      }
      contentDocument {
        name
        fileType
        link
        thumbnail
      }
      transcriptFile {
        name
        fileType
        link
      }
    }
  }
`

export const GET_LESSON_BY_ID = gql`
  query GetLessonsById($lessonId: String) {
    getLessonById(lessonId: $lessonId) {
      name
      id
      companyId
      subtitle {
        name
        fileType
        link
      }
      video {
        id
        name
        thumbnail
        duration
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      description
      transcript
      duration
      audio {
        id
        name
        duration
        link
        fileType
      }
      contentDocument {
        name
        fileType
        link
        thumbnail
      }
      topicName
      topicId
      numberOfModuleQuestions
      transcriptFile {
        name
        fileType
        link
      }
      moduleName
      moduleId
    }
  }
`

export const EDIT_LESSON = gql`
  mutation EditLesson(
    $topicId: String
    $lessonId: String
    $input: LessonInput
  ) {
    editLesson(topicId: $topicId, lessonId: $lessonId, input: $input) {
      name
      id
      subtitle {
        name
        fileType
        link
      }
      video {
        id
        name
        thumbnail
        duration
        title
        links {
          url
          fileType
          converted
        }
      }
      attachment {
        name
        fileType
        link
      }
      description
      transcript
      duration
      audio {
        name
        duration
        link
        fileType
      }
      contentDocument {
        name
        fileType
        link
        thumbnail
      }
      topicName
      topicId
      transcriptFile {
        name
        fileType
        link
      }
    }
  }
`

export const DELETE_LESSON = gql`
  mutation DeleteLesson($ids: [String]) {
    deleteLessons(ids: $ids) {
      message
    }
  }
`

export const CHANGE_LESSONS_ORDER = gql`
  mutation ChangeLessonsOrder($draggedId: String!, $droppedId: String!) {
    changeLessonsOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`
