import { useState } from 'react'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'
import addRemoveGroupFromCourseService from 'services/group/addRemoveGroupFromCourseService'
import { useTranslation } from 'react-i18next'

export interface IUseAddRemoveGroupFromCourseReturnType {
  loading: boolean
  addRemoveGroupFromCourse: (
    courseId: string,
    groupIds: string[],
    callback: () => void,
  ) => Promise<void>
  addGroupsToCourse: (
    courseId: string,
    groupIds: string[],
    callback?: () => void,
  ) => Promise<void>
  removeGroupsFromCourse: (
    courseId: string,
    groupIds: string[],
    callback?: () => void,
  ) => Promise<void>
}

const useAddRemoveGroupFromCourse =
  (): IUseAddRemoveGroupFromCourseReturnType => {
    const { t } = useTranslation()
    const { setSnackbar } = useSnackbarAlert()
    const [loading, setLoading] = useState(false)
    const {
      addRemoveGroupFromCourseMutation,
      addGroupsToCourseMutation,
      removeGroupsToCourseMutation,
    } = addRemoveGroupFromCourseService()

    const addRemoveGroupFromCourse = async (
      courseId: string,
      groupIds: string[],
      callback: () => void,
    ): Promise<void> => {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      setLoading(true)

      const { errors } = await addRemoveGroupFromCourseMutation({
        variables: {
          courseId,
          groupIds,
        },
      })

      if (errors && errors.length) {
        message = t('messages.something_wrong')
        variant = 'error'
      } else {
        message = `${t('general.group')} ${t('messages.edited')}`
        variant = 'success'
        if (callback) callback()
      }

      setLoading(false)

      setSnackbar({ message, variant })
    }

    const addGroupsToCourse = async (
      courseId: string,
      groupIds: string[],
      callback?: () => void,
    ): Promise<void> => {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      setLoading(true)

      const { errors } = await addGroupsToCourseMutation({
        variables: {
          courseId,
          groupIds,
        },
      })

      if (errors && errors.length) {
        message = t('messages.something_wrong')
        variant = 'error'
      } else {
        message = `${t('general.group')} ${t('messages.added')}`
        variant = 'success'
        if (callback) callback()
      }

      setLoading(false)

      setSnackbar({ message, variant })
    }

    const removeGroupsFromCourse = async (
      courseId: string,
      groupIds: string[],
      callback?: () => void,
    ): Promise<void> => {
      let { message, variant }: ISnackbarProps = {
        message: '',
        variant: undefined,
      }

      setLoading(true)

      const { errors } = await removeGroupsToCourseMutation({
        variables: {
          courseId,
          groupIds,
        },
      })

      if (errors && errors.length) {
        message = t('messages.something_wrong')
        variant = 'error'
      } else {
        message = `${t('general.group')} ${t('messages.removed')}`
        variant = 'success'
        if (callback) callback()
      }

      setLoading(false)

      setSnackbar({ message, variant })
    }

    return {
      loading,
      addRemoveGroupFromCourse,
      addGroupsToCourse,
      removeGroupsFromCourse,
    }
  }

export default useAddRemoveGroupFromCourse
