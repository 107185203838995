import React, { useState, useEffect, useCallback, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Container,
  ItemContainer,
  HeaderContainer,
  FooterContainer,
  FooterItem,
  Title,
  StyledWrapper,
  HeaderTitleContainer,
  StyledQuizTypeSpan,
  styles,
} from './styled-components'
import update from 'immutability-helper'
import DndItem from 'components/common/Grid/DndGrid/DndItem'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import CheckBox from 'components/common/Checkbox'
import { gql } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import strCutter from 'utils/strCutter'
import { IItemListProps, IQuizItemMap, IQuizListProps } from '../quiz.interface'
import { IQuizItem } from 'pages/courses/CourseQuizzes/courseQuizzes.interface'
import { Tooltip } from '@mui/material'
import Tags from 'components/common/Tags/Tags'
import { TagsGrid } from 'pages/tests/testDetails/styled-components'

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ItemList = ({
  item,
  actions,
  routerState,
  selectItem,
  selectedItems,
  isOriginalCourse,
  navigate,
  hasCheckbox,
  t,
}: IItemListProps): ReactElement => (
  <ItemContainer
    data-cy="quiz-card"
    onClick={(): void =>
      navigate(`/quizzes/${item.id}`, {
        state: routerState,
      })
    }
  >
    <HeaderContainer>
      <Title>
        <HeaderTitleContainer>
          {hasCheckbox && (
            <CheckBox
              onChange={(): void => selectItem(item.id)}
              checked={selectedItems.includes(item.id)}
            />
          )}
          <StyledQuizTypeSpan>
            {item?.quizParticipationType &&
              capitalizeFirstLetter(item?.quizParticipationType)}
          </StyledQuizTypeSpan>
        </HeaderTitleContainer>
      </Title>
      {!isOriginalCourse && (
        <GridActionMenu actionConfig={actions} row={item} />
      )}
    </HeaderContainer>

    <StyledWrapper>{item.name}</StyledWrapper>
    <TagsGrid>
      <Tags tags={item.tags} type="question" />
    </TagsGrid>

    <FooterContainer>
      <FooterItem>
        <span style={styles.cardTitleSpan}>
          {item.IQTestType ? t('general.course') : t('general.module')}{' '}
        </span>

        {item.parentName &&
          (item.parentName?.length > 14 ? (
            <Tooltip title={item.parentName}>
              <span style={styles.cardContentSpan}>
                {strCutter(item.parentName, 14)}
              </span>
            </Tooltip>
          ) : (
            <span style={styles.cardContentSpan}>{item.parentName}</span>
          ))}
      </FooterItem>
      <FooterItem>
        <span style={styles.cardTitleSpan}>{t('general.questions')} </span>
        <span style={styles.cardContentSpan}>
          {(item.questions && item.questions.length) || 0}
        </span>
      </FooterItem>

      {item.quizType !== 'course' && (
        <FooterItem>
          <span style={styles.cardTitleSpan}>
            {t('quiz_details.pass_rate')}
          </span>

          <span style={styles.cardContentSpan}> {item.passRate || 0}%</span>
        </FooterItem>
      )}

      <FooterItem>
        <span style={styles.cardTitleSpan}>{t('general.type')}</span>
        <span style={styles.cardContentSpan}>
          {capitalizeFirstLetter(item.timeType)}
        </span>
      </FooterItem>

      <FooterItem>
        <span style={styles.cardTitleSpan}>{t('quiz_details.randomize')} </span>
        <span style={styles.cardContentSpan}>
          {item.randomization ? t('general.yes') : t('general.no')}
        </span>
      </FooterItem>
    </FooterContainer>
  </ItemContainer>
)

const CHANGE_ORDER = gql`
  mutation ($draggedId: String!, $droppedId: String!) {
    changeQuizzesOrder(draggedId: $draggedId, droppedId: $droppedId)
  }
`

const QuizList = ({
  data,
  gridStyle,
  t,
  isOriginalCourse,
  isDraggable,
  actions,
  selectedItems,
  selectItem,
  routerState,
  hasCheckbox,
}: IQuizListProps): ReactElement => {
  const navigate = useNavigate()
  const [changeQuizzesOrder] = useMutation(CHANGE_ORDER)
  const [cards, setCards] = useState<IQuizItem[]>(data)
  const moveCard = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [cards],
  )

  const handleChangeRequest = (draggedId: string, droppedId: string): void => {
    changeQuizzesOrder({
      variables: { draggedId, droppedId },
    })
  }

  useEffect(() => {
    setCards(data as IQuizItem[])
  }, [data])

  return (
    <Container items={gridStyle}>
      {cards.map((i: IQuizItem, index: number) =>
        isDraggable ? (
          <DndItem
            key={i.id}
            id={i.id}
            index={index}
            moveCard={moveCard}
            onEnd={handleChangeRequest}
            cardIds={cards.map((card: { id: string }) => card.id)}
            isNested
          >
            <ItemList
              actions={actions}
              item={i as unknown as IQuizItemMap} // Type assertion here
              index={index}
              navigate={navigate}
              isOriginalCourse={isOriginalCourse}
              selectItem={selectItem}
              selectedItems={selectedItems}
              isDraggable={isDraggable}
              moveCard={moveCard}
              t={t}
              routerState={routerState}
              hasCheckbox={hasCheckbox}
            />
          </DndItem>
        ) : (
          <ItemList
            key={i.id}
            actions={actions}
            item={i as unknown as IQuizItemMap}
            index={index}
            navigate={navigate}
            isOriginalCourse={isOriginalCourse}
            selectItem={selectItem}
            selectedItems={selectedItems}
            isDraggable={isDraggable}
            moveCard={moveCard}
            routerState={routerState}
            t={t}
            hasCheckbox={hasCheckbox}
          />
        ),
      )}
    </Container>
  )
}

export default QuizList
