import {
  Checkbox as MUICheckBox,
  FormControlLabel,
  Tooltip,
} from '@mui/material'
import React, { ReactElement } from 'react'
import { LabelContainer } from './styled-components'
import { ReactComponent as HelpIcon } from 'assets/help.svg'

const Checkbox = ({
  checked,
  onChange,
  label,
  tooltip,
  name = 'checkbox',
}: {
  checked: boolean
  onChange: () => void
  label: string
  tooltip?: string
  name?: string
}): ReactElement => {
  const checkedColor = '#08c694'
  return (
    <FormControlLabel
      control={
        <MUICheckBox
          checked={checked}
          onChange={onChange}
          name={name}
          sx={{
            color: checked ? checkedColor : undefined,
            '&.Mui-checked': {
              color: checkedColor,
            },
          }}
        />
      }
      label={
        <LabelContainer>
          <span>{label}</span>
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpIcon />
            </Tooltip>
          )}
        </LabelContainer>
      }
    />
  )
}

export default Checkbox
