interface IConfig {
  APIURL: string
  GRAPHQL_URI: string
  WS_GRAPHQL_URI: string
  ENV: string
  IMPORT_GROUPS_TEMPLATE_URL: string
  IMPORT_STUDENTS_TEMPLATE_URL: string
  IMPORT_STUDENTS_WITH_GROUPS_URL: string
  IMPORT_GROUPS_WITH_TESTS_TEMPLATE_URL: string
}

let config: IConfig = {
  APIURL: 'http://localhost:8080',
  GRAPHQL_URI: 'http://localhost:8080/graphql',
  WS_GRAPHQL_URI: 'ws://localhost:8080/graphql',
  ENV: 'development',
  IMPORT_GROUPS_TEMPLATE_URL:
    'https://d2wo8kfh2znil0.cloudfront.net/importGroupsTemplateStyled.xlsx',
  IMPORT_GROUPS_WITH_TESTS_TEMPLATE_URL:
    'https://d2wo8kfh2znil0.cloudfront.net/importGroupsWithTestsTemplate.xlsx',
  IMPORT_STUDENTS_TEMPLATE_URL:
    'https://d2wo8kfh2znil0.cloudfront.net/Template.xlsx',
  IMPORT_STUDENTS_WITH_GROUPS_URL:
    'https://d2wo8kfh2znil0.cloudfront.net/importStudentsWithGroupsTemplateStyled.xlsx',
}

switch (process.env.REACT_APP_STAGE) {
  case 'development':
    config = {
      ...config,
      APIURL: 'https://apidev.suada.com',
      GRAPHQL_URI: 'https://apidev.suada.com/graphql',
      WS_GRAPHQL_URI: 'wss://apidev.suada.com/graphql',
      ENV: 'development',
    }
    break
  case 'staging':
    config = {
      ...config,
      APIURL: 'https://apistaging.suada.com',
      GRAPHQL_URI: 'https://apistaging.suada.com/graphql',
      WS_GRAPHQL_URI: 'wss://apistaging.suada.com/graphql',
      ENV: 'staging',
    }
    break
  case 'production':
    config = {
      ...config,
      APIURL: 'https://api.suada.com',
      GRAPHQL_URI: 'https://api.suada.com/graphql',
      WS_GRAPHQL_URI: 'wss://api.suada.com/graphql',
      ENV: 'production',
    }
    break
  default:
    break
}

export default config
