import React, { ReactElement } from 'react'
import {
  Container,
  StyledLogo,
  LogotypeContainer,
  LogoTypeWrapper,
  AuthContainer,
  AuthWrapper,
} from './styled-components'

const AuthLayout = ({
  children,
}: {
  children: React.ReactNode
}): ReactElement => {
  return (
    <Container>
      <LogotypeContainer>
        <LogoTypeWrapper>
          <StyledLogo src="https://ib-assets-public.s3.eu-west-2.amazonaws.com/Suada-full-logo-filled.png" />
        </LogoTypeWrapper>
      </LogotypeContainer>

      <AuthContainer>
        <AuthWrapper>{children}</AuthWrapper>
      </AuthContainer>
    </Container>
  )
}

export default AuthLayout
