import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { useFormik } from 'formik'
import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import { string as YupString } from 'yup'
import { CHECK_COURSE_NAME, GET_COURSE } from 'gql/course/course.query'
import { GET_ALL_SKILLS } from 'gql/skilss.query'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import {
  CourseCompleteCriteriaEnums,
  IRenderContentItem,
} from 'pages/courses/courseList.interface'
import { CourseSchema } from 'helpers/validationSchemas'
import excludeKeys from 'helpers/excludeKeys'
import removeTypeNameFromObject from 'utils/removeTypeNameFromObject'
import { DrawerEventEmitter } from 'helpers/drawer'
import { IUser } from 'interfaces/users'

import {
  useUploadImage,
  useGenerateUploadVideoLinks,
  useUploadFile,
  useUploadCaptions,
} from 'hooks/helpers/useHelpersService'
import useCreateCourse from 'hooks/course/useCreateCourse'
import { useEditCourse } from 'hooks/course/useEditCourse'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import { IAddCourseDrawerDefaultQuery } from 'hooks/course/addCourseDrawer/addCourseDrawer.interface'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'
import { IUseCourseFormReturnType } from './useCourseForm.interface'
import { CropperResultType } from 'interfaces/common'
import { IVideo } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'

const useCourseForm = ({
  id,
  drawerData = {},
}: {
  id?: string
  drawerData?: {
    companyId?: string
    pagination?: {
      offset?: number
      limit?: number
    }
    onSuccess?: () => void
  }
}): IUseCourseFormReturnType => {
  const { t } = useTranslation()
  const [state, dispatch] = useData()
  const [userData] = useUserValue()
  const [currentCourse, setCurrentCourse] = useState<IRenderContentItem | null>(
    null,
  )
  const [buttonEvent, setButtonEvent] = useState('')
  const [introVideo, setIntroVideo] = useState<File | null>(null)
  const [progress, setProgress] = useState<number>(0)
  const [videoLinks, setVideoLinks] = useState<string>('')
  const [cropperOpen, setCropperOpen] = useState(false)
  const [firstFetch, setFirstFetch] = useState(true)
  const [uploadedVideoName, setUploadedVideoName] = useState<string>('')
  const [isSubtitlesUpdated, setIsSubtitlesUpdated] = useState(false)
  const [isVideoUpdated, setIsVideoUpdated] = useState(false)
  const [validationData, setValidationData] = useState(CourseSchema)

  const { generateUploadVideoLinks } = useGenerateUploadVideoLinks()
  const { uploadImage, imageLoading } = useUploadImage()
  const { uploadFile, fileLoading } = useUploadFile()
  const { uploadCaptions } = useUploadCaptions()

  const { data: skillsData } = useQuery(GET_ALL_SKILLS)
  const { data: course, loading } = useQuery(GET_COURSE, {
    variables: { courseId: id },
    skip: !id,
  })
  const courseData = course && course.getCourseById

  const { editCourse, editCourseLoading } = useEditCourse({
    courseId: id ?? '',
  })

  const query: IAddCourseDrawerDefaultQuery = {
    companyId: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: userData.selectedCompany?.id || null,
    },
  }

  const pagination = drawerData.pagination || {}

  const { useCreateCourseService, createCourseLoading } = useCreateCourse({
    filter: query,
    ...pagination,
  })

  const [checkName] = useMutation(CHECK_COURSE_NAME)

  const formData: IRenderContentItem = {
    name: '',
    description: '',
    editorText: '',
    level: null,
    coursePrivacy: 'private',
    coachType: '',
    coaches: [],
    skills: [],
    groups: [],
    avatar: null,
    price: '',
    currency: '',
    video: null,
    contentLocked: false,
    completeCriteria: {
      label: t('course_complete_criterias.watchAndPassQuizzes'),
      value: CourseCompleteCriteriaEnums.WATCH_AND_PASS_QUIZZES,
    },
    certificateIncluded: false,
  }

  const onSuccess = (): void => {
    closeDrawer()
    dispatch({ type: CLEAR_FORMDATA })
    if (drawerData?.onSuccess) drawerData.onSuccess()
  }

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: formData,
    async onSubmit(values) {
      if (!values.name) {
        errors.name = `${t('general.name')} ${t('validations.required')}`
        return
      }
      if (!values.avatar) {
        errors.avatar = `${t('general.cover_image')} ${t(
          'validations.required',
        )}`
        return
      }

      const data = removeTypeNameFromObject({ ...values })
      data.skills = data.skills.map((skill: ISelectDropDownOption) => ({
        label: skill.label,
      }))
      data.completeCriteria = data.completeCriteria?.value

      if (drawerData.companyId) data.companyId = drawerData.companyId
      if (data.video) {
        const args = excludeKeys(data.video, ['__typename'])
        data.video = { id: args.id, title: args.title }
      }

      if (!data.price) {
        data.price = null
        data.currency = ''
      } else {
        data.price = parseFloat(data.price)
      }

      if (data.level) data.level = data.level.value
      data.coaches =
        data.coaches.length > 0
          ? data.coaches.map((item: ISelectDropDownOption) => item.value)
          : []
      delete data.groups
      data.finished = buttonEvent === 'next' || buttonEvent === 'save' ? 3 : 2
      data.isSubtitlesUpdated = isSubtitlesUpdated
      data.isVideoUpdated = isVideoUpdated

      // for edit checking if name has changed to check if its available
      if (id) {
        if (course?.getCourseById?.name !== values.name) {
          const exists = await checkName({ variables: { name: values.name } })
          if (exists && exists.errors) {
            errors.name = t('validations.courseNameExists')
            return
          }
        }

        editCourse(id, data, () => {
          closeDrawer()
        })
      } else {
        // for creation always checking if name is available
        const exists = await checkName({ variables: { name: values.name } })
        if (exists && exists.errors) {
          errors.name = t('validations.courseNameExists')
          return
        }
        useCreateCourseService(data, [], () => {
          onSuccess()
        })
      }
    },
  })

  const handleImageChange = (
    file: string | null,
    field: string,
    fileName?: string,
  ): void => {
    if (!file) {
      setFieldValue(field, null)
      return
    }
    uploadImage(file, `course/${field}`, (link: string) =>
      setFieldValue(field, {
        name: Date.now().toString(),
        link,
        fileType: fileName?.split('.').pop(),
      }),
    )
  }

  useEffect(() => {
    setValidationData({
      ...validationData,
      currency: values.price
        ? YupString().required(
            `${t('form_fields.currency')} ${t('validations.required')}`,
          )
        : YupString(),
    })
  }, [values.price])

  const uploadVideoService = (url: string): Promise<void> => {
    setUploadedVideoName(url.split('/')[4].split('.')[0])
    if (!introVideo) return Promise.resolve()
    return axios.put(url, introVideo, {
      headers: { 'Content-Type': introVideo.type },
      onUploadProgress: ({ total, loaded }) =>
        setProgress((loaded / total) * 100),
    })
  }

  const cropperModalToggle = (): void => setCropperOpen(!cropperOpen)

  const handleCropSave = (
    field: string,
    croppedFile: CropperResultType,
  ): void => {
    uploadImage(
      croppedFile as string,
      field === 'avatar' ? 'course/avatar' : 'course/certificate',
      (link: string) => setFieldValue(field, link),
    )
  }

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'editCourse', false)
  }

  const handleCloseDrawer = (): void => {
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: id ? 'edit' : 'add',
        drawer: id ? 'editCourse' : 'addCourses',
        values,
        compareTo: currentCourse,
      },
    })
  }

  const handleGenerateUploadLink = (): void => {
    if (!introVideo) return
    const videos = [
      { fileName: introVideo.name, type: introVideo.type },
    ] as IVideo[]
    generateUploadVideoLinks(
      videos,
      'course',
      uploadVideoService,
      (args) => {
        setFieldValue('video', args.video)
        setFieldValue('video.title', args.video.name)
        setProgress(0)
      },
      true,
    )
  }

  useEffect(() => {
    if (state.formData.closeDrawerClick) handleCloseDrawer()
  }, [state.formData.closeDrawerClick])

  useEffect(() => {
    if (introVideo) handleGenerateUploadLink()
  }, [introVideo])

  useEffect(() => {
    if (id && course && course.getCourseById && firstFetch) {
      const courseData = course.getCourseById
      const dataClone: IRenderContentItem = {} as IRenderContentItem

      for (const key in formData) {
        if (courseData[key]) {
          if (key === 'skills' && courseData[key]) {
            dataClone[key] = courseData[key].map(
              (i: ISelectDropDownOption) => ({
                label: i.label,
                value: i.label,
              }),
            )
          }
          if (key === 'coaches' && courseData[key]) {
            dataClone[key] = courseData[key].map((i: IUser) => ({
              label: `${i.firstName} ${i.lastName}`,
              value: i.userId,
            }))
          }
          if (!['skills', 'coaches'].includes(key)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ;(dataClone[key as keyof IRenderContentItem] as any) =
              courseData[key]
          }
        }
        dataClone.coursePrivacy = state.selectedCompany
          ? dataClone.coursePrivacy
          : 'public'
      }

      if (dataClone.level) {
        dataClone.level = {
          label: t(`courses_layout.${dataClone.level}`),
          value: dataClone.level as string,
        }
      }

      if (dataClone.completeCriteria) {
        dataClone.completeCriteria = {
          label: t(`course_complete_criterias.${dataClone.completeCriteria}`),
          value: dataClone.completeCriteria as CourseCompleteCriteriaEnums,
        }
      }

      setValues(dataClone)
      setCurrentCourse(dataClone)
      setFirstFetch(false)
    }
  }, [course])

  const handleVideoChange = (e?: ChangeEvent<HTMLInputElement>): void => {
    setIsVideoUpdated(true)
    if (e?.target?.files) setIntroVideo(e.target.files[0])
    else {
      setIntroVideo(null)
      setFieldValue('video', null)
      setProgress(0)
    }
  }

  const handleUploadFile = (
    e: ChangeEvent<HTMLInputElement>,
    field: string,
  ): void => {
    const { files } = e.target
    if (!files) return
    const file = files[0]

    if (field === 'subtitle') {
      setIsSubtitlesUpdated(true)
      uploadCaptions(file, `${uploadedVideoName}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').pop(),
        })
      })
    } else {
      uploadFile(file, `course/${field}`, (link: string) => {
        setFieldValue(field, {
          name: file.name,
          link,
          fileType: file.name.split('.').pop(),
        })
      })
    }
  }

  const skillList =
    skillsData &&
    skillsData.getAllSkills.map((i: ISelectDropDownOption) => ({
      label: i.label,
      value: i.label,
    }))

  const handleButtonClick = (e: string): void => {
    setButtonEvent(e)
    handleSubmit()
  }

  const courseCompanyId =
    (courseData && courseData.companyId && courseData.companyId.id) || null

  return {
    values,
    errors,
    touched,
    handleChange,
    handleVideoChange,
    videoLinks,
    setVideoLinks,
    progress,
    setProgress,
    introVideo,
    setIntroVideo,
    setFieldValue,
    isCompanyMode: Boolean(state.selectedCompanyId),
    skillList,
    uploadImage,
    uploadFile,
    imageLoading,
    handleUploadFile,
    fileLoading,
    courseData,
    handleButtonClick,
    handleCloseDrawer,
    courseCompanyId,
    t,
    userData,
    handleImageChange,
    loading,
    editCourseLoading,
    cropperOpen,
    cropperModalToggle,
    handleCropSave,
    createCourseLoading,
    isSuperAdmin:
      !Boolean(userData.currentUser.companyId) && !userData.selectedCompany?.id,
  }
}

export default useCourseForm
