import React, { CSSProperties, ReactElement } from 'react'
import { ReactComponent as DropDownIcon } from 'assets/caret-down.svg'
import ReactSelect from 'react-select'
import { StyledLabel } from '../TextInput/styled-components'
import { ContainerSelect, ErrorMessage } from './styled-components'
import { ISelectFieldProps, MUIElementState } from './select.interface'

export const customStyles: object = {
  option: (provided: CSSProperties, state: MUIElementState) => ({
    ...provided,
    '&:hover': {
      backgroundColor: 'rgba(6, 198, 144, 0.1)',
    },
    backgroundColor: state.isSelected
      ? 'rgba(6, 198, 144, 0.3)'
      : 'transparent',
    color: '#000',
    fontFamily: "'Helvetica neue', sans-serif",
    borderRadius: '6px',
  }),
  control: (provided: CSSProperties, state: MUIElementState) => ({
    ...provided,
    borderRadius: '6px',
    transition:
      'border 0.3s ease-in, outline 0.6s ease-in, box-shadow 0.6s ease-in',
    boxShadow: '0 1px 9px 5px rgb(0 0 0 / 3%)',
    outline: 'none',
    borderColor: state.isFocused ? '#06c68f !important' : 'rgba(0, 0, 0, 0.1)',
    fontFamily: "'Helvetica neue', sans-serif",
    border: state.isFocused
      ? '1px solid #06c68f'
      : '1px solid rgba(0, 0, 0, 0.1)',
  }),
  menu: (provided: CSSProperties, state: MUIElementState) => ({
    ...provided,
    zIndex: '99',
    transition:
      'border 0.3s ease-in, outline 0.6s ease-in, box-shadow 0.6s ease-in',
    boxShadow: '0 1px 9px 5px rgb(0 0 0 / 3%)',
    fontFamily: "'Helvetica neue', sans-serif",
    outline: 'none',
    border: state.isFocused
      ? '1px solid #06c68f'
      : '1px solid rgba(0, 0, 0, 0.1)',
    borderColor: state.isFocused ? '#06c68f !important' : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    minHeight: '40px',
  }),
}

const SelectField = (props: ISelectFieldProps): ReactElement => {
  const DropdownIndicator = (): ReactElement => <DropDownIcon />

  return (
    <ContainerSelect error={!!(props.error && props.touched)}>
      {props.label && <StyledLabel>{props.label}</StyledLabel>}
      <ReactSelect
        isMulti={props.isMulti}
        onChange={props.onChange}
        options={props.options || undefined}
        value={props.value}
        placeholder={props.placeholder}
        styles={props.styles || customStyles}
        isClearable={props.isClearable}
        onFocus={props.onFocus}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        components={{ DropdownIndicator }}
      />

      {props.touched && props.error && props.errorMessage && (
        <ErrorMessage>
          <>{props.errorMessage}</>
        </ErrorMessage>
      )}
    </ContainerSelect>
  )
}

export default SelectField
