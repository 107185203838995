import ImageCropper from 'components/common/ImageCropper'
import Modal from 'components/common/Modal'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AvatarContainer,
  EditIconContainer,
  AvatarMenu,
  AvatarMenuItem,
} from './styled-components'
import ProfileIcon from 'assets/profile.svg'
import EditIcon from '@mui/icons-material/Edit'
import Loader from '../Loader'
import { IAvatarInputProps } from './avatarInput.interface'
import { CropperResultType } from 'interfaces/common'

export const AvatarInput: React.FC<IAvatarInputProps> = ({
  setFieldValue,
  initialAvatar,
}) => {
  const { t } = useTranslation()

  const [avatar, setAvatar] = useState<string | null>(null)
  const [cropperOpen, setCropperOpen] = useState(false)

  const [avatarMenu, openAvatarMenu] = useState(false)

  const { uploadImage, imageLoading } = useUploadImage()

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { files } = e.target
    if (files && files[0]) {
      const reader = new FileReader()
      reader.onload = (): void => {
        if (reader.result) {
          const img = new Image()
          img.src = reader.result as string
          setAvatar(reader.result as string)
        }
      }
      reader.readAsDataURL(files[0])

      setCropperOpen(true)
      openAvatarMenu(false)
    }
  }

  const handleUploadAvatar = (
    field: string,
    croppedFile: CropperResultType,
  ): void => {
    uploadImage(croppedFile as string, `${field}`, (link: string) => {
      setFieldValue(field, link)
      setAvatar(null)
    })
  }

  const deleteImage = (field: string): void => {
    setFieldValue(field, '')
    setAvatar(null)
    openAvatarMenu(false)
  }

  return (
    <>
      <AvatarContainer>
        <div style={{ position: 'relative' }}>
          <img src={initialAvatar || ProfileIcon} />
          <EditIconContainer onClick={(): void => openAvatarMenu(true)}>
            <EditIcon
              onClick={(): void => openAvatarMenu(true)}
              style={{ fontSize: 14, color: '#989898' }}
            />
          </EditIconContainer>
        </div>
        {imageLoading ? (
          <Loader />
        ) : (
          <>
            {avatarMenu && (
              <AvatarMenu>
                <AvatarMenuItem>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFileChange}
                    multiple
                  />
                  <label htmlFor="file">{t('actions.upload_avatar')}</label>
                </AvatarMenuItem>

                {(!!avatar || (!avatar && !!initialAvatar)) && (
                  <AvatarMenuItem
                    onClick={(): void => {
                      deleteImage('avatar')
                    }}
                  >
                    {t('actions.delete_avatar')}
                  </AvatarMenuItem>
                )}
              </AvatarMenu>
            )}
          </>
        )}
      </AvatarContainer>
      <Modal
        isOpened={cropperOpen}
        onClose={(): void => setCropperOpen(!cropperOpen)}
      >
        <ImageCropper
          file={{ file: avatar as string }}
          hideCropper={(): void => setCropperOpen(!cropperOpen)}
          getCroppedFile={(_: unknown, file): void =>
            handleUploadAvatar('avatar', file)
          }
        />
      </Modal>
    </>
  )
}
