import React, { FormEvent, ReactElement } from 'react'
import {
  Field,
  ValidationIcon,
  StyledComponentContainer,
  CharactersContainer,
  StyledInput,
  StyledTextArea,
  StyledLabel,
  ErrorMessage,
} from './styled-components'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import './TextInput.scss'
import { IFilterTypes } from 'pages/admins/FilterAdmins/filterAdmins.interface'
import { FormikErrors, FormikTouched } from 'formik'

const TextInputV2 = ({
  label,
  icon,
  name,
  type,
  value,
  error,
  errorMessage,
  touched,
  fullWidth,
  onChange,
  onBlur,
  onFocus,
  rows,
  validate = true,
  disabled = false,
  component,
  maxCharacter,
  placeholder = '',
}: ITextInputProps): ReactElement => (
  <Field className={fullWidth ? 'full' : ''}>
    <StyledLabel>{label}</StyledLabel>
    {rows ? (
      <>
        <StyledTextArea
          id="standard-basic"
          name={name}
          value={value as string}
          rows={parseInt(rows as string)}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          style={{ width: '100%' }}
          disabled={disabled}
          autoComplete="off"
          placeholder={placeholder}
        />
        {touched && error && errorMessage && (
          <ErrorMessage>
            <>{errorMessage}</>
          </ErrorMessage>
        )}
      </>
    ) : (
      <>
        <StyledInput
          id="standard-basic"
          name={name}
          type={type}
          value={value as string}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          style={{ width: '100%' }}
          disabled={disabled}
          autoComplete="off"
          placeholder={placeholder}
        />
        {touched && error && errorMessage && (
          <ErrorMessage>
            <>{errorMessage}</>
          </ErrorMessage>
        )}
      </>
    )}
    {maxCharacter && typeof value === 'string' && (
      <CharactersContainer>
        {value?.length || 0}/{maxCharacter}
      </CharactersContainer>
    )}
    {touched && validate && (
      <ValidationIcon className={`${error ? 'error' : 'valid'}`}>
        {error ? (
          <ErrorOutlineRoundedIcon />
        ) : (
          <CheckCircleOutlineRoundedIcon />
        )}
      </ValidationIcon>
    )}
    {icon && icon}

    {component ? (
      <StyledComponentContainer>{component}</StyledComponentContainer>
    ) : null}

    {}
  </Field>
)

export default TextInputV2

// disabled eslint for interfaces as it can't be described
/* eslint-disable */
export interface ITextInputProps {
  title?: string
  label?: string
  size?: 'small' | 'medium' | undefined
  icon?: React.ReactElement
  $iconInStart?: boolean
  required?: boolean
  name?: string
  id?: string
  type?: string
  value?: string | number | IFilterTypes | null
  error?: boolean
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[]
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
  fullWidth?: boolean
  multiline?: boolean
  rows?: number | string
  validate?: boolean
  disabled?: boolean
  readOnly?: boolean
  min?: number
  max?: number
  onChange?: any
  onBlur?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  onFocus?: (
    eventOrPath: string | FormEvent<any>,
  ) => void | ((eventOrTextValue: string | FormEvent<any>) => void)
  endAdornment?: any
  component?: any
  inputStyle?: React.CSSProperties
  maxCharacter?: number
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  placeholder?: string
  className?: string
}
