import { IconButton, Typography } from '@mui/material'
import Pagination from 'components/common/Pagination'
import { useUserValue } from 'context/UserContext'
import { useFetchCourses } from 'hooks/course/useCourses'
import CourseList from 'pages/courses/CourseList'
import React, { useState, useEffect, ReactElement, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CoursesContainer,
  BuilderHeader,
  WarningText,
} from './styled-components'
import { Input } from 'components/common/TextInput'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { IUserCompany } from 'interfaces/users'
import { UpperCaseFilterableFieldType } from 'enums/filterEnum'

const CertificateBuilder = (): ReactElement => {
  const [state] = useUserValue()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const perPage = 8
  const [searchText, setSearchText] = useState<string>('')

  const companyMode = state.currentUser.companyId as IUserCompany
  const defaultFilter = {
    companyId: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: companyMode?.id,
    },
    isBought: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: false,
    },
    certificateIncluded: {
      type: UpperCaseFilterableFieldType.EXACT,
      value: true,
    },
  }

  const [filterQuery, setFilterQuery] = useState<{
    name?: { type: string; value: string }
  }>({})

  const { courses, loading, refetch, error } = useFetchCourses(
    { ...defaultFilter, ...filterQuery },
    currentPage,
    perPage,
  )

  useEffect(() => {
    refetch()
  }, [])

  const handlePaginationClick = (
    _: ChangeEvent<unknown>,
    value: number,
  ): void => {
    if (value !== currentPage) {
      setCurrentPage(value)
    }
  }

  const handleSearchChange = (eventValue: string): void => {
    setSearchText(eventValue)
    if (!eventValue) {
      setFilterQuery({
        ...filterQuery,
        name: undefined,
      })
      refetch({ ...defaultFilter, ...filterQuery }, currentPage, perPage)
    }
  }

  const onSearchSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    const formValue = {
      name: { type: UpperCaseFilterableFieldType.MATCH, value: searchText },
    }
    setCurrentPage(1)
    setFilterQuery({
      ...filterQuery,
      ...formValue,
    })
    refetch({
      currentPage: 1,
      perPage,
      filter: filterQuery,
    })
  }

  const { t } = useTranslation()
  return (
    <>
      <BuilderHeader>
        <div>
          <Typography variant="h4">
            {t('navigation.certificate_builder')}
          </Typography>
          <Typography variant="body1">
            {t('certificate_builder.description')}
          </Typography>
        </div>
        <div>
          <form onSubmit={onSearchSubmit}>
            <Input
              label={t('general.search_placeholder')}
              type="text"
              size="small"
              value={searchText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                handleSearchChange(e.target.value)
              }}
              icon={
                <IconButton onClick={onSearchSubmit}>
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </div>
      </BuilderHeader>
      <CoursesContainer>
        {error && error}
        {courses && (
          <CourseList
            data={courses.data}
            gridStyle={'card'}
            // fromCertificateBuilder={true}
          />
        )}
      </CoursesContainer>
      {courses && courses.data && courses.data.length > 8 && (
        <div
          style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}
        >
          <Pagination
            currentPage={courses ? courses.currentPage : 1}
            totalPages={courses ? courses.totalPages : 0}
            handleChange={handlePaginationClick}
          />
        </div>
      )}
      {courses?.data?.length === 0 && !loading && searchText === '' && (
        <WarningText>{t('certificate_builder.no_courses_found')}</WarningText>
      )}
      {courses?.data?.length === 0 &&
        !loading &&
        searchText !== '' &&
        filterQuery.name && (
          <WarningText>
            {t('certificate_builder.no_courses_found_with_name')} "
            {filterQuery.name?.value}"
          </WarningText>
        )}
    </>
  )
}

export default CertificateBuilder
