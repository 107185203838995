import { useState, useEffect, ChangeEvent } from 'react'
import { useQuery } from '@apollo/client'
import { useFormik } from 'formik'
import { useCreateQuestion } from 'hooks/questions/useCreateQuestion'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useData } from 'context/DataContext'
import { SET_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { useUploadImage } from 'hooks/helpers/useHelpersService'
import { useUserValue } from 'context/UserContext'
import { QuestionSchema } from 'helpers/validationSchemas'
import { QuestionTypeEnums } from 'pages/questions/CreateQuestionDrawer/CreateQuestion.interface'
import { GET_TAGS } from 'gql/skilss.query'
import { IModule } from 'pages/modules/ModulesList.interface'
import removeKeys from 'utils/removeKeys'
import { ITags } from 'components/common/Tags/tags.interface'
import { IAnswer } from 'pages/questions/CreateQuestionDrawer/QuestionItem'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import { IQuestion } from 'interfaces/questions'
import { CropperResultType, IAttachment } from 'interfaces/common'
import { ValueType } from 'react-select'
import {
  IQuestionsParams,
  IUseCreateQuestionDrawerReturn,
  IUseCreateQuestionFormData,
} from './useCreateQuestionDrawer.interface'

const useCreateQuestionDrawer = ({
  title,
  currentModule,
}: IQuestionsParams): IUseCreateQuestionDrawerReturn => {
  const { t } = useTranslation()
  const [userState] = useUserValue()
  const { createQuestion, createQuestionLoading } = useCreateQuestion()

  const { data } = useQuery(GET_TAGS, {
    variables: {
      companyId: currentModule.manualCompanyId || userState.selectedCompany?.id,
    },
  })

  const tagList =
    (data &&
      data.getAllTags.map((i: ITags) => ({ label: i.label, value: i.id }))) ||
    []

  // const { toggleDrawerConfirm } = useAction()
  const [state, dispatch] = useData()
  // const { fireSwal } = useSwal()
  const [linkModuleDrawerOpened, setLinkModuleDrawerOpened] =
    useState<boolean>(false)

  const [boolAnswer, setBoolAnswer] = useState<boolean>(true)
  const [choiceAnswers, setChoiceAnswers] = useState<IAnswer[]>([])
  const [multiAnswers, setMultiAnswers] = useState<IAnswer[]>([])
  const [selectedChoiceAnswer, setSelectedChoiceAnswer] = useState<
    string | null
  >(null)
  const [selectedMultiAnswers, setSelectedMultiAnswers] = useState<
    string[] | null
  >(null)
  const [selectedModules, setSelectedModules] = useState<IModule[]>([])
  const [cropperOpen, setCropperOpen] = useState<boolean>(false)
  const [cropperImage, setCropperImage] = useState<string>('')
  const [uploadedFiles, setUploadedFiles] = useState<IAttachment[]>([])

  const { uploadImage } = useUploadImage()

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    useFormik({
      initialValues: {
        id: '',
        question: '',
        type: {
          label: t('question_details.boolean'),
          value: QuestionTypeEnums.BOOLEAN,
        },
        tags: [],
        reason: '',
        isShuffleEnabled: false,
        documents: [],
        videos: [],
        images: [],
        audios: [],
        modules: [],
      },
      validationSchema: QuestionSchema,
      onSubmit(values: IUseCreateQuestionFormData) {
        let formValues = removeKeys(values, ['id']) as IQuestion

        if (createQuestionLoading) return

        if (selectedModules.length > 0) {
          formValues.modules = selectedModules
        }

        switch (values.type.value) {
          case QuestionTypeEnums.BOOLEAN:
            formValues.boolAnswer = boolAnswer
            break
          case QuestionTypeEnums.SINGLE:
            formValues.answers = choiceAnswers.map((answer, index: number) => {
              return {
                isCorrect: selectedChoiceAnswer == index.toString(),
                value: answer.value,
              }
            })
            break
          case QuestionTypeEnums.MULTIPLE:
            formValues.answers = multiAnswers.map((answer, index: number) => {
              return {
                isCorrect: !!selectedMultiAnswers?.includes(index.toString()),
                value: answer.value,
              }
            })
            break
        }

        formValues.tags = values.tags.map((tag: ITags) => {
          return { label: tag.label }
        })

        if (uploadedFiles.length > 0) {
          const obj: {
            [key: string]: IAttachment[]
          } = {
            documents: [],
            videos: [],
            audios: [],
            images: [],
          }

          uploadedFiles.map((item) => {
            const { type, ...args } = item

            if (type) {
              obj[type] = [...obj[type], args]
            }
          })

          formValues = {
            ...formValues,
            ...obj,
          }
        }
        formValues.videos = formValues.videos
          ? formValues.videos.map((video) => {
              return { id: video.id, title: video.title }
            })
          : []
        createQuestion(
          formValues,
          currentModule.manualCompanyId ||
            (userState.selectedCompany?.id as string),
          () => {
            closeDrawer()
            if (currentModule.refetch) {
              currentModule.refetch()
            }
          },
        )
      },
    })

  const closeDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'createQuestion', false)
  }

  const checkAnswers = (type: QuestionTypeEnums): boolean => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        const emptyChoices = choiceAnswers.filter(
          (answer) => answer.value === '',
        )
        return !!(choiceAnswers.length && !emptyChoices.length)
      case QuestionTypeEnums.MULTIPLE:
        const emptyValues = multiAnswers.filter((answer) => answer.value === '')

        return !!(multiAnswers.length && !emptyValues.length)
    }
  }

  const checkSelectedAnswers = (type: QuestionTypeEnums): boolean => {
    switch (type) {
      case QuestionTypeEnums.BOOLEAN:
        return true
      case QuestionTypeEnums.SINGLE:
        return selectedChoiceAnswer !== null
      case QuestionTypeEnums.MULTIPLE:
        return selectedMultiAnswers ? selectedMultiAnswers.length > 1 : false
    }
  }

  const handleTypeChange = (
    e: ValueType<ISelectDropDownOption, false>,
  ): void => {
    setFieldValue('type', e)
  }

  const handleBoolChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    const convertedValue = value === 'true' ? true : false
    setBoolAnswer(convertedValue)
  }

  const handleAddAnswerClick = (type: string): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers = [...choiceAnswers, { value: '' }]
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers = [...multiAnswers, { value: '' }]
        setMultiAnswers(updatedMultiAnswers)
    }
  }

  const handleRemoveAnswerClick = (type: string, index: number): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers = [...choiceAnswers]
        updatedChoiceAnswers.splice(index, 1)
        setChoiceAnswers(updatedChoiceAnswers)

        if (index.toString() == selectedChoiceAnswer) {
          setSelectedChoiceAnswer(null)
        }
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers = [...multiAnswers]
        updatedMultiAnswers.splice(index, 1)
        setMultiAnswers(updatedMultiAnswers)

        if (selectedMultiAnswers?.includes(index.toString())) {
          const ind = selectedMultiAnswers.indexOf(index.toString())
          const updatedMultiAnswers = [...selectedMultiAnswers]
          updatedMultiAnswers.splice(ind, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        }
    }
  }

  const handleAnswerChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    type: string,
    index: number,
  ): void => {
    const { value } = e.target

    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const updatedChoiceAnswers = [...choiceAnswers]
        updatedChoiceAnswers[index].value = value
        setChoiceAnswers(updatedChoiceAnswers)
        return
      case QuestionTypeEnums.MULTIPLE:
        const updatedMultiAnswers = [...multiAnswers]
        updatedMultiAnswers[index].value = value
        setMultiAnswers(updatedMultiAnswers)
        return
    }
  }

  const handleSelect = (
    e: React.ChangeEvent<HTMLInputElement> | number,
    type: string,
  ): void => {
    switch (type) {
      case QuestionTypeEnums.SINGLE:
        const { value } = (e as ChangeEvent<HTMLInputElement>).target
        setSelectedChoiceAnswer(value.toString())
        return
      case QuestionTypeEnums.MULTIPLE:
        const eventValue = e as number
        const index = selectedMultiAnswers?.indexOf(eventValue.toString())
        if (typeof index === 'number' && index > -1) {
          const updatedMultiAnswers = [...(selectedMultiAnswers as string[])]
          updatedMultiAnswers.splice(index, 1)
          setSelectedMultiAnswers(updatedMultiAnswers)
        } else {
          const prevMultiAnswers = selectedMultiAnswers || []
          setSelectedMultiAnswers([...prevMultiAnswers, eventValue.toString()])
        }
    }
  }

  const cropperModalToggle = (): void => {
    setCropperOpen(!cropperOpen)
  }

  const handleCropSave = (
    field: string,
    croppedFile: CropperResultType,
  ): void => {
    setCropperImage('')
    const index = uploadedFiles.length
    setUploadedFiles([
      ...uploadedFiles,
      {
        type: 'image',
        link: '',
        uploading: true,
      },
    ])
    uploadImage(
      croppedFile as string,
      (field = 'questions/images'),
      (link: string) => {
        const updatedFiles = [...uploadedFiles]
        updatedFiles[index] = {
          type: 'image',
          link,
          uploading: false,
        }
        setUploadedFiles(updatedFiles)
      },
    )
  }

  useEffect(() => {
    if (currentModule.moduleId) {
      const moduleData = removeKeys(currentModule, [
        'manualCompanyId',
      ]) as IModule
      setSelectedModules([moduleData])
    }
  }, [currentModule])

  const handleCloseDrawer = (): void => {
    const formData = removeKeys(values, ['type']) as IUseCreateQuestionFormData
    formData.multiAnswers = multiAnswers
    formData.choiceAnswers = choiceAnswers
    dispatch({
      type: SET_FORMDATA,
      payload: {
        type: 'add',
        drawer: 'createQuestion',
        values: formData,
        compareTo: {},
      },
    })
  }
  useEffect(() => {
    if (state.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [state.formData.closeDrawerClick])

  const fieldsValid = checkAnswers(values.type.value)
  const selectedValid = checkSelectedAnswers(values.type.value)

  const submitBtnDisabled = !values.question || !fieldsValid || !selectedValid

  return {
    createQuestionLoading,
    linkModuleDrawerOpened,
    setLinkModuleDrawerOpened,
    selectedModules,
    setSelectedModules,
    title,
    handleCloseDrawer,
    t,
    handleTypeChange,
    values,
    setFieldValue,
    tagList,
    setUploadedFiles,
    uploadedFiles,
    currentModule,
    errors,
    touched,
    handleChange,
    boolAnswer,
    choiceAnswers,
    multiAnswers,
    selectedChoiceAnswer,
    selectedMultiAnswers,
    handleSelect,
    handleBoolChange,
    handleAddAnswerClick,
    handleRemoveAnswerClick,
    handleAnswerChange,
    fieldsValid,
    selectedValid,
    submitBtnDisabled,
    cropperOpen,
    cropperModalToggle,
    cropperImage,
    handleCropSave,
    handleSubmit,
  }
}

export default useCreateQuestionDrawer
