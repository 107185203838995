import React, { ReactElement } from 'react'
import {
  DrawerHeader,
  DrawerTitle,
  DrawerContent,
  Title,
} from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form/Form'

import Loader from 'components/common/Loader'
import IconButton from '@mui/material/IconButton'
import TextInput from 'components/common/TextInput/TextInput'
import { Button } from 'components/common/Button'
import { AsyncSelect, SelectField } from 'components/common/SelectField'
import {
  statuses,
  filterGenderOptions,
} from 'components/UsersLayout/staticData'
import DateRangePicker from 'components/common/DateRangePicker'
import RangeSlider from 'components/common/RangeSlider'
import CloseIcon from '@mui/icons-material/Close'
import { IFilterAdminsProps } from './filterAdmins.interface'
import { useFilterAdmins } from 'hooks/admins/useFilterAdmins'

const FilterAdmins = ({
  closeDrawer,
  filterLoading,
  manualCompanyId,
  useCompanyId,
  filterOptions,
  setFilterOptions,
  filter,
}: IFilterAdminsProps): ReactElement => {
  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    t,
    error,
    handleRangeChange,
    companyOptions,
    companyLoading,
    handleSelectChange,
    groupLoading,
    groupData,
    createDate,
    setCreateDate,
    ageValue,
    isCompanySelected,
  } = useFilterAdmins({
    setFilterOptions,
    filterOptions,
    manualCompanyId,
    useCompanyId,
    filter,
  })

  return (
    <>
      {filterLoading && <Loader />}
      <DrawerHeader>
        <DrawerTitle>{t('actions.filters')}</DrawerTitle>
        <IconButton onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.first_name')}
                  error={errors.firstName ? true : false}
                  errorMessage={errors.firstName ? errors.firstName : ''}
                  touched={touched.firstName}
                  placeholder={t('user_details.first_name')}
                  size="small"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('user_details.last_name')}
                  error={errors.lastName ? true : false}
                  errorMessage={errors.lastName ? errors.lastName : ''}
                  touched={touched.lastName}
                  placeholder={t('user_details.last_name')}
                  size="small"
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('general.status')}</Title>
                <SelectField
                  name="status"
                  options={statuses}
                  value={values.status}
                  onChange={(e: string): void => setFieldValue('status', e)}
                />
              </FormField>

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('groups_layout.select_group')}</Title>

                <AsyncSelect
                  isMulti
                  onInputChange={(e: string): void =>
                    handleSelectChange(e, 'group')
                  }
                  onChange={(e: string): void => setFieldValue('group', e)}
                  data={(groupData && groupData.getAllGroups.data) || []}
                  value={values.group}
                  label={t('form_fields.type_to_search_group')}
                  loading={groupLoading}
                  labelDataKey="name"
                  valueDataKey="id"
                />
              </FormField>

              {!isCompanySelected && (
                <FormField width="48%" style={{ position: 'relative' }}>
                  <Title>{t('groups_layout.select_company')}</Title>

                  <AsyncSelect
                    isMulti
                    onInputChange={(e: string): void =>
                      handleSelectChange(e, 'company')
                    }
                    onChange={(e: string): void => setFieldValue('company', e)}
                    data={companyOptions}
                    value={values.company}
                    label={t('form_fields.type_to_search_company')}
                    loading={companyLoading}
                    labelDataKey="name"
                    valueDataKey="id"
                    iconDataKey="icon"
                  />
                </FormField>
              )}
              {/* )} */}

              <FormField width="48%" style={{ position: 'relative' }}>
                <Title>{t('user_details.gender')}</Title>
                <SelectField
                  placeholder={t('user_details.gender')}
                  options={filterGenderOptions}
                  value={values.gender}
                  onChange={(e: string): void => setFieldValue('gender', e)}
                />
              </FormField>
              <FormField width="48%">
                <TextInput
                  title={t('form_fields.location')}
                  placeholder={t('form_fields.location')}
                  size="small"
                  name="location"
                  type="text"
                  value={values.location}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width={isCompanySelected ? '100%' : '48%'}>
                <TextInput
                  title={t('user_details.note')}
                  placeholder={t('user_details.note')}
                  size="small"
                  name="note"
                  type="text"
                  value={values.note}
                  onChange={handleChange}
                  validate={false}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('general.create_date')}</Title>
                <DateRangePicker
                  values={createDate}
                  setCreateDate={setCreateDate}
                />
              </FormField>
              <FormField width="100%">
                <Title>{t('form_fields.age_range')}</Title>
                <RangeSlider
                  value={ageValue}
                  onRangeChange={handleRangeChange}
                  style={{ color: '#08C694' }}
                />
              </FormField>
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                text={t('actions.filter')}
                type="small"
                color="secondary"
                btnType="submit"
                background="#06C68F"
                isDisabled={!!error}
              />
              <Button
                text={t('actions.cancel')}
                type="small"
                onClick={closeDrawer}
                background="#E0E1E2"
                textColor="#414141"
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterAdmins
