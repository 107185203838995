import React, { useState } from 'react'
import { DrawerEventEmitter } from 'helpers/drawer'
import { useFetchFlashCards } from 'hooks/modules/useModules'
import useDeleteFlashCardsService from 'hooks/modules/useDeleteFlashCardsService'
import { useSwal } from 'hooks/useSwal'
import { useTranslation } from 'react-i18next'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import { actionConfig } from 'pages/modules/FlashCards/actionConfig'
import {
  Field,
  IFlashCardsParams,
  IUseFlashCardsLayoutReturn,
} from './useFlashCardsLayout.interface'

const useFlashCardsLayout = ({
  module,
}: IFlashCardsParams): IUseFlashCardsLayoutReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const { flashCards, loading, refetch } = useFetchFlashCards(module.id)

  const {
    deleteFlashCards,
  }: {
    deleteFlashCards: (ids: string[], callback: () => void) => void
    deleteFlashCardsLoading: boolean
  } = useDeleteFlashCardsService()
  const { fireSwal } = useSwal()

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedItem, setSelectedItem] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [audioModalOpened, setAudioModalOpened] = useState<boolean>(false)
  const [currentAudio, setCurrentAudio] = useState<string>('')

  const selectItem = (id: string): void => {
    const selectedIndex = selectedItem.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItem, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItem.slice(1))
      setSelectAll(false)
    } else if (selectedIndex === selectedItem.length - 1) {
      newSelected = newSelected.concat(selectedItem.slice(0, -1))
      setSelectAll(false)
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItem.slice(0, selectedIndex),
        selectedItem.slice(selectedIndex + 1),
      )
      setSelectAll(false)
    }

    if (selectedItem.length === flashCards.length) {
      setSelectAll(true)
    }

    setSelectedItem(newSelected)
  }

  const selectAllItem = (): void => {
    if (!selectAll) {
      if (selectedItem.length === filteredFlashCards.length) {
        setSelectedItem([])
        setSelectAll(false)
      } else {
        const newArr: string[] = filteredFlashCards.map((n) => n.id) as string[]
        setSelectedItem(newArr)
        setSelectAll(true)
      }

      return
    }

    setSelectedItem([])
    setSelectAll(false)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchValue(e.target.value)

  const handleCreateFlashCardClick = (): void =>
    DrawerEventEmitter.emit('openDrawer', 'addFlashCard', true, {
      data: {
        module,
      },
    })

  const handelOpenEditDrawer = (id: string): void => {
    DrawerEventEmitter.emit('openDrawer', 'editFlashCard', true, {
      data: {
        id,
        moduleId: module.id,
        refetch: () => refetch(),
      },
    })
  }

  const handleDeleteClick = (ids: string[]): void => {
    const title: string =
      ids.length > 1
        ? t('popups.delete_flashcard_many')
        : t('popups.delete_flashcard_single')

    fireSwal({
      title,
      onConfirm: () => {
        deleteFlashCards(ids, () => {
          setSelectedItem([])
          setSelectAll(false)
          refetch()
        })
      },
      confirmText: t('popups.confirm_delete'),
    })
  }

  const handleAudioClick = (audio?: string): void => {
    setAudioModalOpened(true)
    setCurrentAudio(audio || '')
  }

  const actionHandler = (
    event: string,
    field: Field,
  ): (() => void) | undefined => {
    switch (event) {
      case 'edit':
        handelOpenEditDrawer(field.id.toString())
        break
      case 'delete':
        handleDeleteClick([field.id.toString()])
        break
      default:
        return () => console.log('null')
    }
  }

  const actions = actionConfig(actionHandler, t)

  const filteredFlashCards = flashCards?.filter((flashCard) =>
    flashCard.question.includes(searchValue),
  )

  const createButton = (): React.JSX.Element => (
    <Button
      text={t('flashcards_layout.create_flashcard')}
      size="small"
      color="secondary"
      onClick={(): void => handleCreateFlashCardClick()}
      icon={<AddCircleRoundedIcon />}
      background="#06C68F"
    />
  )

  return {
    state,
    handleCreateFlashCardClick,
    selectedItem,
    handleDeleteClick,
    t,
    selectAllItem,
    filteredFlashCards,
    searchValue,
    handleSearchChange,
    actions,
    selectItem,
    handleAudioClick,
    audioModalOpened,
    setAudioModalOpened,
    currentAudio,
    flashCards,
    createButton,
    loading,
  }
}

export default useFlashCardsLayout
