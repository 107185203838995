import { useMutation, MutationTuple, ApolloError } from '@apollo/client'
import { DELETE_MODULE } from 'gql/modules.query'
import { IModule } from 'pages/modules/ModulesList.interface'

// Interfaces for deleteModuleService
interface IDeleteModuleResponse {
  deleteModule: IModule[]
}

interface IDeleteModuleVariables {
  ids: string[]
}

interface IDeleteModuleServiceResponse {
  deleteModuleMutation: MutationTuple<
    IDeleteModuleResponse,
    IDeleteModuleVariables
  >[0]
  loading: boolean
  error?: ApolloError
}

const deleteModuleService = (): IDeleteModuleServiceResponse => {
  const [deleteModuleMutation, { loading, error }] = useMutation<
    IDeleteModuleResponse,
    IDeleteModuleVariables
  >(DELETE_MODULE)

  return {
    deleteModuleMutation,
    loading,
    error,
  }
}

export default deleteModuleService
