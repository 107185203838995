import { useState } from 'react'
import deleteSkillTestVideoService, {
  IDeleteSkillTestVideoTemplateServiceParams,
} from 'services/modules/deleteSkillTestVideoTemplateService'
import useSnackbarAlert from 'hooks/useSnackbar'
import { ISnackbarProps } from 'interfaces/snackbar'

const useDeleteSkillTestVideoTemplateService = (
  params: IDeleteSkillTestVideoTemplateServiceParams,
): {
  deleteSkillTestVideoTemplate: (
    ids: string[],
    onSuccess?: () => void,
  ) => Promise<void>
  deleteSkillTestVideoTemplateLoading: boolean
  error: string
} => {
  const { setSnackbar } = useSnackbarAlert()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { deleteSkillTestVideoTemplateMutation } =
    deleteSkillTestVideoService(params)

  const deleteSkillTestVideoTemplate = async (
    ids: string[],
    onSuccess?: () => void,
  ): Promise<void> => {
    let { message, variant }: ISnackbarProps = {
      message: '',
      variant: undefined,
    }

    setLoading(true)

    try {
      const { errors } = await deleteSkillTestVideoTemplateMutation({
        variables: {
          ids,
        },
      })
      if (errors) {
        message = errors[0].message
        if (message === 'User already registered') {
          message = message
          setError(message)
          return
        }
      } else {
        message = 'Skill Test Video has been deleted'
        variant = 'success'

        if (onSuccess) {
          onSuccess()
        }
      }
      setSnackbar({ message, variant })
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  return {
    deleteSkillTestVideoTemplate,
    deleteSkillTestVideoTemplateLoading: loading,
    error,
  }
}

export default useDeleteSkillTestVideoTemplateService
