import { useMutation } from '@apollo/client'

import {
  GET_COMPANIES,
  DELETE_COMPANY,
  ADD_COMPANY,
  UPDATE_COMPANY,
  CHANGE_COMPANY_STATUS,
  CHANGE_COMPANY_PLATFORM_SERVICE,
  ADD_MANAGER,
  EDIT_MANAGER,
  SET_COMPANY_STATE,
  CHECK_COMPANY_EMAIL,
  REMOVE_COMPANY_AVATAR,
} from 'gql/companies.query'

import { updateCache } from 'services/updateCache'
import {
  IAddCompanyResponse,
  IAddCompanyVariables,
  IAddManagerResponse,
  IAddManagerVariables,
  IChangeCompanyPlatformServiceResponse,
  IChangeCompanyPlatformServiceVariables,
  IChangeCompanyStatusResponse,
  IChangeCompanyStatusVariables,
  ICheckCompanyEmailResponse,
  ICheckCompanyEmailVariables,
  ICompaniesServiceResponse,
  IDeleteCompanyResponse,
  IDeleteCompanyVariables,
  IEditManagerResponse,
  IEditManagerVariables,
  IRemoveCompanyAvatarResponse,
  IRemoveCompanyAvatarVariables,
  ISetCompanyStateResponse,
  ISetCompanyStateVariables,
  IUpdateCompanyResponse,
  IUpdateCompanyVariables,
} from './companiesService.interface'

const companiesService = (): ICompaniesServiceResponse => {
  const [addCompanyMutation] = useMutation<
    IAddCompanyResponse,
    IAddCompanyVariables
  >(ADD_COMPANY, {
    refetchQueries: [
      {
        query: GET_COMPANIES,
        variables: {
          currentPage: 1,
          perPage: 12,
          filter: {},
        },
      },
    ],
  })

  const [removeCompanyAvatarMutation] = useMutation<
    IRemoveCompanyAvatarResponse,
    IRemoveCompanyAvatarVariables
  >(REMOVE_COMPANY_AVATAR)

  const [changeCompanyStatusMutation] = useMutation<
    IChangeCompanyStatusResponse,
    IChangeCompanyStatusVariables
  >(CHANGE_COMPANY_STATUS, {
    update(cache, { data }) {
      if (data?.changeCompanyStatus) {
        const document = data.changeCompanyStatus
        updateCache({
          objectName: 'companies',
          query: GET_COMPANIES,
          cache,
          document,
          action: 'update',
        })
      }
    },
  })

  const [changeCompanyPlatformServiceMutation] = useMutation<
    IChangeCompanyPlatformServiceResponse,
    IChangeCompanyPlatformServiceVariables
  >(CHANGE_COMPANY_PLATFORM_SERVICE)

  const [updateCompanyMutation] = useMutation<
    IUpdateCompanyResponse,
    IUpdateCompanyVariables
  >(UPDATE_COMPANY)

  const [deleteCompanyMutation] = useMutation<
    IDeleteCompanyResponse,
    IDeleteCompanyVariables
  >(DELETE_COMPANY)

  const [checkCompanyEmailMutation] = useMutation<
    ICheckCompanyEmailResponse,
    ICheckCompanyEmailVariables
  >(CHECK_COMPANY_EMAIL)

  const [addManagerMutation] = useMutation<
    IAddManagerResponse,
    IAddManagerVariables
  >(ADD_MANAGER)

  const [editManagerMutation] = useMutation<
    IEditManagerResponse,
    IEditManagerVariables
  >(EDIT_MANAGER)

  const [setCompanyStateMutation] = useMutation<
    ISetCompanyStateResponse,
    ISetCompanyStateVariables
  >(SET_COMPANY_STATE)

  return {
    addCompanyMutation,
    changeCompanyStatusMutation,
    changeCompanyPlatformServiceMutation,
    updateCompanyMutation,
    deleteCompanyMutation,
    addManagerMutation,
    editManagerMutation,
    setCompanyStateMutation,
    checkCompanyEmailMutation,
    removeCompanyAvatarMutation,
  }
}

export default companiesService
