import { useState, useEffect, useRef } from 'react'
import { useFormik } from 'formik'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useData } from 'context/DataContext'
import { useUserValue } from 'context/UserContext'
import { StudentSchema, UserSchema } from 'helpers/validationSchemas'
import useAddStudentService from 'hooks/users/useAddStudentService'
import useEditStudentService from 'hooks/users/useEditStudentService'
import { SET_FORMDATA, CLEAR_FORMDATA } from 'store/types'
import { genderOptions } from 'components/UsersLayout/staticData'
import { useTranslation } from 'react-i18next'
import { GET_GROUP } from 'gql/group/group.query'
import { CHECK_USER_EMAIL, GET_USER } from 'gql/users.query'
import { ILoadDataOptionsFilter } from 'pages/students/AddStudent/addStudent.interface'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { DrawerEventEmitter } from 'helpers/drawer'
import {
  ISelectedStudent,
  IStudentsFormValues,
  IUseStudentFormProps,
  IUseStudentFormReturn,
} from './useStudentsForm.interface'
import { ISelectFilterOption } from 'interfaces/common'
import { IUser } from 'interfaces/users'

const useStudentForm = ({
  id,
  setFormData,
  drawerData,
  setSelectedStudents,
  onClose,
  group,
  role,
}: IUseStudentFormProps): IUseStudentFormReturn => {
  const { t } = useTranslation()
  const [state] = useUserValue()
  const companyId = state.selectedCompany?.id || ''
  const [dataState, dispatchData] = useData()
  const ref = useRef<NodeJS.Timeout | undefined>(undefined)
  const [generalError, setGeneralError] = useState('')
  const [currentStudent, setCurrentStudent] = useState<ISelectedStudent | null>(
    null,
  )

  const { addStudent, addStudentLoading } = useAddStudentService(companyId)
  const { updateStudent, editStudentLoading, user } = useEditStudentService()
  const [checkEmail] = useMutation(CHECK_USER_EMAIL)

  const [fetchGroup, { data: groupData, loading: groupLoading }] =
    useLazyQuery(GET_GROUP)
  const { data, loading } = useQuery(GET_USER, {
    variables: { id: id },
    skip: !id,
  })

  const {
    values,
    setFieldValue,
    setValues,
    setErrors,
    handleChange,
    errors,
    touched,
    handleSubmit,
  } = useFormik<IStudentsFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      gender: null,
      birthDate: undefined,
      biography: '',
      group: [],
      note: '',
      jobTitle: '',
      location: '',
      phoneFields: undefined,
      phoneFieldValue: undefined,
      avatar: '',
    },
    validationSchema: group ? UserSchema : StudentSchema,
    async onSubmit(values) {
      if (values.email !== currentStudent?.email) {
        const res = await checkEmail({ variables: { email: values.email } })
        if (res.errors) {
          setErrors({ email: res.errors[0].message })
          return false
        }
      }

      const formValues: IStudentsFormValues = { ...values }
      delete formValues?.phoneFieldValue

      if (values?.group.length !== 0) {
        formValues.group = values?.group?.map(
          (group: { label?: string; value?: string }) => {
            return { name: group.label || '', groupId: group.value || '' }
          },
        )
      } else if (group && group.name) {
        formValues.group.push({
          name: group.name,
          groupId: group.id || group.groupId,
          status: group.status,
        })
      }

      if (formValues.phoneFields && formValues.phoneFields.value) {
        formValues.phoneFields = {
          dialCode: formValues.phoneFields.value,
          code: formValues.phoneFields.label,
        }
      } else {
        formValues.phoneFields = undefined
      }

      formValues.gender = formValues.gender
        ? (formValues.gender as ISelectFilterOption).value
        : null

      if (id) {
        formValues.role = data.user.role
        updateStudent(id, formValues)
      } else {
        formValues.role = role
        addStudent(formValues, role, companyId, onSuccess)
      }
    },
  })

  const onSuccess = (student: IUser): void => {
    if (setSelectedStudents) {
      setSelectedStudents(student)
    }
    closeDrawer()
    dispatchData({ type: CLEAR_FORMDATA })
    drawerData?.onSuccess()
  }

  const handleCloseDrawer = (): void => {
    if (setFormData) {
      setFormData(values)
    } else {
      dispatchData({
        type: SET_FORMDATA,
        payload: {
          type: id ? 'edit' : 'add',
          drawer: id ? 'editStudent' : 'addStudent',
          values: values,
          compareTo: currentStudent,
        },
      })
    }
  }

  useEffect(() => {
    if (user) {
      closeEditDrawer()
    }
  }, [user])

  useEffect(() => {
    if (dataState.formData.closeDrawerClick) {
      handleCloseDrawer()
    }
  }, [dataState.formData.closeDrawerClick])

  useEffect(() => {
    if (data) {
      const {
        firstName,
        lastName,
        email,
        phone,
        gender,
        birthDate,
        biography,
        group,
        note,
        jobTitle,
        location,
        phoneFields,
        avatar,
      } = data.user
      const userGender = genderOptions.find(
        (option: { value: string; label: string }) => option.value === gender,
      )
      const formData: IStudentsFormValues = {
        firstName,
        lastName,
        email,
        phone,
        gender: gender ? { label: userGender?.label, value: gender } : null,
        birthDate: birthDate ? new Date(parseInt(birthDate)) : undefined,
        biography,
        jobTitle,
        location,
        group: group.map((group: { name: string; groupId: string }) => ({
          label: group.name,
          value: group.groupId,
        })),
        note,
        phoneFields: phoneFields.dialCode
          ? { label: phoneFields.code, value: phoneFields.dialCode }
          : undefined,
        phoneFieldValue: phoneFields?.dialCode || null,
        avatar,
      }
      setValues({ ...formData })
      setCurrentStudent(formData)
    }
  }, [data])

  const loadDataOptions = (e: string): NodeJS.Timeout =>
    setTimeout(() => {
      const value = e
      const filter: ILoadDataOptionsFilter = {
        name: {
          type: LowerCaseFilterableFieldType.MATCH,
          value,
        },
      }

      if (state.selectedCompany?.id) {
        filter['company'] = {
          type: LowerCaseFilterableFieldType.EXACT,
          value: state.selectedCompany?.id,
        }
      }
      fetchGroup({
        variables: {
          filter,
        },
      })
    }, 200)

  const handleSelectChange = (e: string): void => {
    clearTimeout(ref.current as NodeJS.Timeout)
    if (e) {
      ref.current = loadDataOptions(e)
    }
  }
  useEffect(() => {
    loadDataOptions('')
  }, [])

  useEffect(() => {
    setFieldValue(
      'phoneFieldValue',
      (values.phoneFields && values.phoneFields.label) || undefined,
    )
    values.phoneFieldValue = values.phoneFields
      ? values.phoneFields.value
      : undefined
  }, [values.phoneFields])

  const onDateChange = (date: Date | null): void => {
    const birthDate = date
    setFieldValue('birthDate', date)
    if (birthDate) {
      values.birthDate = birthDate
    }
  }

  const closeDrawer = (): void => onClose && onClose()

  const closeEditDrawer = (): void => {
    DrawerEventEmitter.emit('openDrawer', 'editStudent', false)
  }

  const handleCloseEditDrawer = (): void => {
    dispatchData({
      type: SET_FORMDATA,
      payload: {
        type: 'edit',
        drawer: 'editStudent',
        values,
        compareTo: currentStudent,
      },
    })
  }

  return {
    values,
    handleChange,
    handleSubmit,
    loading: loading || addStudentLoading || editStudentLoading,
    groupData,
    groupLoading,
    handleSelectChange,
    handleCloseDrawer,
    generalError,
    setGeneralError,
    touched,
    errors,
    setFieldValue,
    onDateChange,
    closeEditDrawer,
    handleCloseEditDrawer,
    t,
  }
}

export default useStudentForm
