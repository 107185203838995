import { ApolloError, MutationTuple, useMutation } from '@apollo/client'
import { CREATE_QUESTION } from 'gql/questions.query'
import { IQuestion } from 'interfaces/questions'

interface ICreateQuestionServiceResponse {
  createQuestionMutation: MutationTuple<
    ICreateQuestionResponse,
    ICreateQuestionParams
  >[0]
  loading: boolean
  error?: ApolloError
}

interface ICreateQuestionResponse {
  createQuestion: IQuestion
}

interface ICreateQuestionParams {
  input: IQuestion
  companyId: string
}

const createQuestionService = (): ICreateQuestionServiceResponse => {
  const [createQuestionMutation, { loading, error }] = useMutation<
    ICreateQuestionResponse,
    ICreateQuestionParams
  >(CREATE_QUESTION)

  return {
    createQuestionMutation,
    loading,
    error,
  }
}

export default createQuestionService
